// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import Modal from '../utilities/Modal.jsx';

type Props = {
  t: any,
  delete: () => void,
  close: () => void 
};

const DeletePanelModal = (props: Props) => (
  <Modal
    title={ props.t(`app:genericConfirm` ) }
    onConfirm={ props.delete }
    onClose={ props.close }
    confirmText={ props.t(`app:confirm`) }
    cancelText={ props.t(`app:cancel`) }>
    <p>
      { props.t(`deletePanelConfirm`) }
    </p>
  </Modal>
);

export { DeletePanelModal as PureDeletePanelModal };
export default translate(`boards`)(DeletePanelModal);
