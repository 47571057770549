// @flow
'use strict';

import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useInfiniteQuery } from '@tanstack/react-query';
import { 
  SideBar, 
  TableHeader,
  TableBody,
  ToolPanelColumn,
  ToolPanelFilter,
  PAGE_SIZE,
  FILTER_CONTROL_TEXT,
  DEFAULT_CACHE_TIME,
  DEFAULT_FILTER_FN
} from '../DataTable';
import ProgressSpinner from '../ProgressSpinner.jsx';

type Props = {
  id?: string,
  columns: Array<{
    title: string,
    key: string,
    display?: Function,
    thClass?: string,
    tdClass?: string,
    compareValue?: Function,
    enableHiding?: boolean,
    enableSorting?: boolean,
    enableFilter?: boolean,
    enablePinning?: boolean,
    filterControl?: {
      type?: string,
      options?: Array<any> 
    }
  }>,
  data?: Array<any>,
  defaultSortBy?: string,
  rowHover?: Function,
  rowUnhover?: Function,
  tableUnhover?: Function,
  hasCollapsibleRows?: boolean,
  filterText?: string,
  isFullWidth?: boolean,
  fetchData?: Function,
  forceRefetch: any,
  onStateChange?: Function,
  serverSideSorting?: boolean,
  serverSideFiltering?: boolean,
  noDataText?: string
};

const DataTable = (props: Props) => {
  const { id = `data-table`, columns, data: dataProp = [], defaultSortBy, rowHover, rowUnhover, tableUnhover, hasCollapsibleRows,
    filterText, isFullWidth = true, fetchData, forceRefetch, onStateChange, serverSideSorting = true, serverSideFiltering = false, noDataText } = props;

  const [sorting, setSorting] = useState( defaultSortBy ? [{ "id": defaultSortBy.substr(1), "desc": defaultSortBy.startsWith(`-`) }] : []);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [expanded, setExpanded] = useState({});
  const [toggleColumns, setToggleColumns] = useState(false);
  const [toggleFilters, setToggleFilters] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(filterText);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnPinning, setColumnPinning] = useState({ left: [], right: [] });
  const [columnOrder, setColumnOrder] = useState([]);
  const [columnFilterFn, setColumnFilterFn] = useState({});
  const tableContainerRef = useRef();

  const tableColumns = columns.map((c) => (
    {
      id: c.key,
      accessorFn: (row) => c?.compareValue ? c.compareValue(row)?.toString() : row[c.key]?.toString(),
      header: () => c.title,
      cell: (info) => c?.display ? c.display(info.row.original, info.row.original[c.key]) : info.row.original[c.key],
      meta: {
        thClass: c.thClass,
        tdClass: c.tdClass,
        filterControl: c.filterControl ?? { type: FILTER_CONTROL_TEXT }
      },
      enableHiding: c.enableHiding ?? true,
      enableSorting: c.enableSorting ?? false,
      enableMultiSort: false,
      enableColumnFilter: c.enableFilter ?? true,
      enableGlobalFilter: c.enableFilter ?? true,
      enablePinning: c.enablePinning ?? true,
      filterFn: columnFilterFn[c.key] ?? DEFAULT_FILTER_FN,
    }
  ));
  
  const { data, fetchNextPage, isFetching, isLoading, refetch } =
    useInfiniteQuery(
      [id, sorting, globalFilter, columnFilters], //causes table to reset and fetch from new beginning
      async ({ pageParam = 0 }) => {
        if (fetchData) {
          const fetchedData = await fetchData( pageParam + 1, PAGE_SIZE);
          return fetchedData;
        }
      },
      {
        getNextPageParam: (_lastGroup, groups) => groups.length,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        cacheTime: DEFAULT_CACHE_TIME
      }
    );

  const flatData = useMemo(
    () => data?.pages?.flatMap((page) => page?.result) ?? [],
    [data]
  );
  
  const totalRecords = data?.pages?.[0]?.totalRecords ?? 0;
  
  const totalFetched = flatData.length;

  const tableData = fetchData ? flatData : dataProp;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 300 &&
          !isFetching && 
          totalFetched < totalRecords
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalRecords]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    state: {
      columnVisibility,
      expanded,
      sorting,
      globalFilter,
      columnFilters,
      columnPinning,
      columnOrder
    },
    //getSubRows: (row) => row.collapsibleData, (parent & children same structure)
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowCanExpand: () => hasCollapsibleRows,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onColumnOrderChange: setColumnOrder,
    onColumnPinningChange: setColumnPinning,
    onColumnVisibilityChange: setColumnVisibility,
    onExpandedChange: setExpanded,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    manualSorting: serverSideSorting,
    manualFiltering: serverSideFiltering
  });

  useEffect(() => {
    const hasNewColumns = columnOrder.some((col) => tableColumns.findIndex((dc) => dc.id === col) === -1);
    
    if (columnOrder.length === 0 || columnOrder.length !== tableColumns.length || hasNewColumns) { 
      //must start out with populated columnOrder so we can splice
      setColumnOrder(tableColumns.map((column) => column.id));

      const fFn = tableColumns.reduce((accum, dc) => {
        return {
          ...accum,
          [dc.id]: DEFAULT_FILTER_FN
        };
      }, {});

      setColumnFilterFn(fFn);
    }
  }, [columns]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setGlobalFilter(filterText) , 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [filterText]);

  useEffect(() => {
    const timeoutId = setTimeout(() => onStateChange && onStateChange({ columnVisibility, sorting, globalFilter, columnFilters, columnPinning, columnOrder }) , 500);

    return () => {
      clearTimeout(timeoutId);
    };

  }, [columnVisibility, sorting, globalFilter, columnFilters, columnPinning, columnOrder]);

  //a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    refetch();
  } , [forceRefetch]);

  return (
    <div className="data-table-component">

      <SideBar 
        toggleColumns={ toggleColumns } 
        setToggleColumns={ setToggleColumns } 
        toggleFilters={ toggleFilters }
        setToggleFilters={ setToggleFilters } />

      <div 
        ref={ tableContainerRef }
        /* style={ { overflow: `auto`, maxHeight: `400px`, } } */
        className={ `data-table-wrapper ${toggleColumns || toggleFilters ? `tool-panel-open` : ``} ${isFullWidth ? `full-width` : ``} ` }
        onMouseLeave={ () => { tableUnhover && tableUnhover(); } }
        onScroll={ (e) => fetchMoreOnBottomReached(e.target) }>        
        { !isLoading &&
          <table className='data-table'>
            <TableHeader table={ table } tableUnhover={ tableUnhover }/>
            <TableBody table={ table } tableContainerRef={ tableContainerRef } sorting={ sorting } rowHover={ rowHover } rowUnhover={ rowUnhover }/>
          </table>
        }
        { !isLoading && !isFetching && tableData.length === 0 && 
          <div className='no-data'>
            { noDataText ||  `There is no data to show for this view.` }
          </div>
        }
        { (isLoading || isFetching) && <ProgressSpinner /> }
      </div>

      { toggleColumns && <ToolPanelColumn table={ table } /> }

      { toggleFilters && <ToolPanelFilter table={ table } setColumnFilterFn={ setColumnFilterFn } /> }

    </div>
  );
};

export default DataTable;
