/* eslint-disable no-unused-vars */
// @flow
'use strict';

import React from 'react';
import i18n from "i18next";
import { connect } from 'react-redux';
import { Shortcuts } from 'react-shortcuts';
import { PropTypes } from 'prop-types';
import * as exchangeApi from '../helpers/api/ExchangeApi';
import * as currencyApi from '../helpers/api/CurrencyApi';
import * as balanceApi from '../helpers/api/BalanceApi';
import * as accountApi from '../helpers/api/AccountApi';
import * as userApi from '../helpers/api/UserApi.js';
import * as orderApi from '../helpers/api/OrderApi.js';
import { getSize } from '../helpers/WindowSizeHelper.js';
import { getActiveTheme, renderTheme, validateTheme, THEMES, getDefaultTheme } from '../helpers/ThemeHelper.js';
import { subscribe, unsubscribe } from '../helpers/SocketClusterHelper.js';
import { subscribeToNotifications } from '../helpers/NotificationHelper.js';
import { 
  listenForEvent, 
  emitEvent, 
  NOTIFICATION,
  WINDOW_RESIZED, 
  PAGE_SHORTCUT,
  REFRESH_ORDERS, 
  REFRESH_BALANCE,
  SILENT_NOTIFICATION,
  REFRESH_ACCOUNTS,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
  ESCAPE,
  PUSH_REDIS_PREFS
} from '../helpers/EventHelper.js';
import { updateFavorites } from '../actions/app/updateFavorites.js';
import { batchUpdateRedisPrefs, setFiatCurrencies } from '../actions/redisPrefs/updateRedisPrefs.js';
import { updateUserData } from '../actions/userInfo/updateUserData.js';
import { rewriteUserTheme } from '../actions/theme/updateUserTheme.js';
import { setPriceTypes } from '../actions/orders/setPriceTypes.js';
import { setOrderTypes } from '../actions/orders/setOrderTypes.js';
import { setStatusTypes } from '../actions/orders/setStatusTypes.js';
import { setSubscriptionInfo } from '../actions/app/setSubscriptionInfo.js';
import { setSubscriptionTypes } from '../actions/app/setSubscriptionTypes.js';
import { setUserApplications } from '../actions/app/setUserApplications.js';
import { setApplicationsStatuses } from '../actions/app/setApplicationsStatuses.js';
import { ShortcutManager } from 'react-shortcuts';
import Header from './Header.jsx';
import Footer from './Footer.jsx';
import type { Market } from '../types/Market.js';
import type { Exchange } from '../types/Exchange.js';
import type { Currency } from '../types/Currency.js';
import type { Balance } from "../types/Balance.js";
import type { Account } from "../types/Account.js";
import type { Favorite } from "../types/Favorite.js";
import type { UserPrefs } from "../types/UserPrefs.js";
import type { User } from "../types/User.js";
import type { Theme } from "../types/Theme.js";
import type { RedisPrefs } from "../types/RedisPrefs.js";
import keymap from '../kb.js';
import { withRouter, Switch, Route, Provider, Redirect } from 'react-router-dom';
import OrdersPage from '../routes/orders.jsx';
import AlertsPage from '../routes/alerts.jsx';
import BalancesPage from '../routes/balances.jsx';
import AccountsPage from '../routes/accounts.jsx';
import UserPage from '../routes/user.jsx';
import BoardsPage from '../routes/boards.jsx';
import MarketsPage from '../routes/markets.jsx';
import ScreenerPage from '../routes/screener.jsx';
import ArbMatrixPage from '../routes/arbMatrix.jsx';
import SettingsPage from '../routes/settings.jsx';
import redisPrefs from '../reducers/redisPrefs';
import GenericError from '../components/GenericError.jsx';
import { CURRENCY_MAP } from "../constants/balances.js";

type Props = {
  main: any,
  theme: string,
  updateFavorites: Function,
  updatePrefs: (obj: any) => void,
  updateUserData: (obj: any) => void,
  setUserTheme: (theme: Theme) => void,
  favorites: Array<Favorite>,
  userPrefs: UserPrefs,
  user: User,
  userTheme: Theme,
  redisPrefs: RedisPrefs,
  selectedQuoteCurrencyCode: string,
  setOrderTypes: (b: any) => void,
  setFiatCurrencies: (b: any) => void,
  setPriceTypes: (b: any) => void,
  setStatusTypes: (b: any) => void,
  keymap: *,
  rehydrated: boolean,
  setSubscriptionInfo: (b: any) => void,
  setSubscriptionTypes: (b: any) => void,
  setUserApplications: (b: any) => void,
  setApplicationsStatuses: (b: any) => void,
};

type State = {
  exchanges: Array<Exchange>,
  currencies: Array<Currency>,
  aggregatedMarkets: [],
  markets: Array<Market>,
  balances: Array<Balance>,
  accounts: Array<Account>,
  windowSize: string,
  lastPulledRedisPrefs: string,
  initialRedisPrefsPulled: boolean,
  hasError: boolean,
  isBalancesInit: boolean,
  isAccountsInit: boolean,
  isExchangesInit: boolean,
};

const SUPPRESS_NOTIFICATIONS = [169, 167, 221, 222, 175]; // first two are related to balance updates. 221/222 are new "silent" refresh notifications (v2 api accounts only)
// 175 = "Note inserted" notification.
const REDIS_PREFS_COMPARE = [
  `marketInfoOpen`,
  `marketSwitcherOpen`,
  `ordersTableOpen`,
  `mktColors`,
  `showOrderHistory`,
  `showOpenPositions`,
  `showCurrentAskLine`,
  `showCurrentBidLine`,
  `kbShortcutsEnabled`,
  `autoSaveDisabled`,
  `depthStyle`,
  `priceClick`,
  `zeroStyle`,
  `theme`,
  `language`,
  `tradeAlertSound`,
  `prefPage`,
  `tickerPosition`,
  `overrideChartTheme`
];

export class App extends React.Component<Props, State> {
  shortcutManager: *;

  constructor(props: Props) {
    super(props);

    this.shortcutManager = new ShortcutManager();

    this.state = {
      exchanges: [],
      currencies: [],
      markets: [],
      aggregatedMarkets: [],
      accounts: [],
      balances: [],
      windowSize: getSize(window.innerWidth),
      lastPulledRedisPrefs: ``,
      initialRedisPrefsPulled: false,
      hasError: false,
      isBalancesInit: false,
      isAccountsInit: false,
      isExchangesInit: false,
    };
  }

  getChildContext() {
    return {
      shortcuts: this.shortcutManager
    };
  }

  componentDidMount() {
    subscribeToNotifications();

    this.shortcutManager.setKeymap({ App: { ...keymap, ...this.props.keymap } });

    this.refreshLongPollData(true); // firstRun=true, to prevent double balances fetching

    subscribe([{ type: `user` }, `NEWS`, `NEWMARKET`], (channels) => {
      channels.user.channel.watch((d) => {
        switch (d.MessageType) {
        case `Favorite`:
          this.props.updateFavorites(this.mergeFavorites(d.Data.map((f) => ({
            quoteCurrCode: f.base_curr,
            displayName: f.display_name,
            exchCode: f.exch_code,
            exchName: f.exch_name,
            last: f.last_price,
            marketName: f.mkt_name,
            baseCurrCode: f.primary_curr,
            volume: f.volume_24,
            percent: f.percent_24
          }))));
          break;
        case `Notification`:
          if (SUPPRESS_NOTIFICATIONS.includes(d.Data.type)) {
            emitEvent(SILENT_NOTIFICATION, d.Data);
            return;
          }
          if (d.Data.type == 3 && !this.props.userPrefs.prefAlertSite) return;
          if (d.Data.type == 5 && !this.props.userPrefs.prefTradeSite) return;
          if (d.Data.notification_id == 0) d.Data.notification_id = Date.now() * Math.random();

          emitEvent(NOTIFICATION, d.Data);
          break;
        }
      });

      channels[`NEWS`].channel.watch((d) => {
        if (!this.props.userPrefs.prefAlertNews) return false;

        let message = d.title,
          max_chars = 38,
          formatted_message = ``;

        while (message.length > max_chars) {
          let end = max_chars;
          if (message[max_chars - 1].match(/\w/) && message[max_chars] && message[max_chars].match(/\w/)) end -= 1;

          let line = message.substr(0, end);

          if (message[end - 1].match(/\w/) && message[end] && message[end].match(/\w/)) line += `-`;

          formatted_message += line + `<br />`;
          message = message.substr(end, message.length);
        }

        formatted_message += message;

        emitEvent(NOTIFICATION, {
          notification_id: d.id,
          title: d.feed_name,
          title_vars: ``,
          message_raw: d.title,
          message: `<a href="${ d.url }" target="_blank" rel="noopener noreferrer">
                      <img src="https://www.coinigy.com/assets/img/news/${ d.feed_image }" alt="" />
                      <span>${ formatted_message }</span>
                    </a>`,
          message_vars: ``,
          pinned: false,
          style: `info news`,
          url: d.url,
          expire: 20000
        });
      });

      channels[`NEWMARKET`].channel.watch((d) => {

        if (!this.props.userPrefs.prefAlertNewMarket) return;

        emitEvent(NOTIFICATION, {
          notification_id: d.Markets[0].exchmkt_id,
          title: `New Market Discovered`,
          title_vars: ``,
          message_raw: `${ d.Exchange.exch_code }:${ d.Markets[0].display_name }`,
          message: `<a style="display: block;">
                      <div style="text-align: center;">
                        <img src="${ window.WWW_URL }/assets/img/exchange/${ d.Exchange.exch_code }.png" />
                      </div>
                      <div style="text-align: center; color: white; margin-top: 1rem;">
                        <strong>${ 
  d.Exchange.exch_code 
}:${ 
  d.Markets[0].display_name 
}</strong>
                        <br />
                        <br />
                      </div>
                    </a>`,
          message_vars: ``,
          pinned: false,
          style: `info newmarket`,
          url: ``
        });
      });
    }, `GLOBAL`);

    // This is the length of time between long polls for account data and other things
    setInterval(() => {
      this.refreshLongPollData();
    }, 58000);

    window.onresize = () => {
      this.setState({
        windowSize: getSize(window.innerWidth)
      });
      emitEvent(WINDOW_RESIZED);
    };

    listenForEvent(REFRESH_BALANCE, (e) => this.refreshBalance(e.detail));
    listenForEvent(REFRESH_ACCOUNTS, () => this.getAccounts());
    listenForEvent(UPDATE_ACCOUNT, (e) => this.updateAccount(e.detail));
    listenForEvent(DELETE_ACCOUNT, (e) => this.deleteAccount(e.detail));
    listenForEvent(PUSH_REDIS_PREFS, (e) => this.pushRedisPrefs(this.props.redisPrefs, this.props.userTheme));
    listenForEvent(SILENT_NOTIFICATION, (e: any) => {
      switch (e.detail.type) {
      case 222: // balances
        this.getBalances();
        break;
      }
    });
  }


  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (JSON.stringify(this.props.keymap) !== JSON.stringify(nextProps.keymap)) {
      this.shortcutManager.setKeymap({ App: { ...keymap, ...nextProps.keymap } });
    }

    let _nextRedisPrefs = JSON.stringify(REDIS_PREFS_COMPARE.reduce((o, k: any) => ({ ...o, [k]: nextProps.redisPrefs[k] }), { })),
      _thisRedisPrefs = JSON.stringify(REDIS_PREFS_COMPARE.reduce((o, k: any) => ({ ...o, [k]: this.props.redisPrefs[k] }), { })),
      _nextUserTheme = JSON.stringify(nextProps.userTheme),
      _thisUserTheme = JSON.stringify(this.props.userTheme);

    if ((_nextUserTheme !== _thisUserTheme) ||
        (_nextRedisPrefs !== _thisRedisPrefs && _nextRedisPrefs !== this.state.lastPulledRedisPrefs && this.state.lastPulledRedisPrefs.length > 0)) {
      this.pushRedisPrefs(nextProps.redisPrefs, nextProps.userTheme).then(() => this.getRedisPrefs());
    }

    if (nextProps.redisPrefs.language !== this.props.redisPrefs.language) {
      i18n.changeLanguage(nextProps.redisPrefs.language);
    }

    if (this.props.rehydrated) {
      if (this.props.selectedQuoteCurrencyCode !== nextProps.selectedQuoteCurrencyCode) {
        this.getBalances(nextProps);
      }
    }

    if (this.props.rehydrated !== nextProps.rehydrated) {
      this.getBalances(nextProps);
    }
  }

  componentWillUnmount() {
    unsubscribe(undefined, `GLOBAL`);
  }

  pushRedisPrefs(redisPrefs: RedisPrefs, userTheme: Theme): Promise<void> {
    return new Promise((resolve, reject) => {
      userApi.setSettings({ body: {
        right: redisPrefs.marketInfoOpen ? `show` : `hide`,
        left: redisPrefs.marketSwitcherOpen ? `show` : `hide`,
        bottom: redisPrefs.ordersTableOpen ? `show` : `hide`,
        assigned_colors: JSON.stringify(redisPrefs.mktColors || []),
        show_order_history: redisPrefs.showOrderHistory ? `true` : `false`,
        show_open_positions: redisPrefs.showOpenPositions ? `true` : `false`,
        showCurrentAskLine: redisPrefs.showCurrentAskLine ? `true` : `false`,
        showCurrentBidLine: redisPrefs.showCurrentBidLine ? `true` : `false`,
        keyboardShortcutsEnabled: redisPrefs.kbShortcutsEnabled,
        autoSaveDisabled: redisPrefs.autoSaveDisabled,
        ui_depthStyle: redisPrefs.depthStyle,
        ui_priceClick: redisPrefs.priceClick,
        ui_zeroStyle: redisPrefs.zeroStyle,
        ui_theme: redisPrefs.theme,
        ui_user_theme: JSON.stringify(userTheme || { }),
        language: redisPrefs.language,
        trade_alert_sound: redisPrefs.tradeAlertSound,
        pref_accounting_valuation: redisPrefs.prefAccountingValuation,
        pref_page: redisPrefs.prefPage,
        ui_ticker_position: redisPrefs.tickerPosition,
        ui_overrideChartTheme: redisPrefs.overrideChartTheme,
      } }, (d) => {
        if (!d.success) {
          reject(d.error);
        } else {
          resolve();
        }
      });
    });
  }

  getRedisPrefs() {
    userApi.getSettings((data) => {
      if (data.success) {        
        let userTheme = JSON.parse(
          data.result.ui_user_theme || 
          JSON.stringify(this.props.userTheme || {}));

        // Only load certain redisPrefs during long polling to prevent the UI from automagically changing unexpectedly
        let redisPrefs = {
          mktColors: data.result.assigned_colors,
          showOrderHistory: data.result.show_order_history == `true`,
          showOpenPositions: data.result.show_open_positions == `true`,
          showCurrentAskLine: data.result.showCurrentAskLine == `true`,
          showCurrentBidLine: data.result.showCurrentBidLine == `true`,
          kbShortcutsEnabled: data.result.keyboardShortcutsEnabled,
          autoSaveDisabled: !!data.result.autoSaveDisabled,
          depthStyle: data.result.ui_depthStyle,
          priceClick: data.result.ui_priceClick,
          zeroStyle: data.result.ui_zeroStyle,
          theme: Object.keys(userTheme).length === 0 && userTheme.constructor === Object ? getDefaultTheme() : data.result.ui_theme,
          language: !data.result.language || data.result.language == `en-US` ? `en` : data.result.language,
          prefNativeCurrencyId: data.result.pref_native_currency_id,
          prefNativeCurrency: data.result.pref_native_currency,
          tradeAlertSound: data.result.trade_alert_sound,
          prefAccountingValuation: data.result.pref_accounting_valuation,
          prefPage: data.result.pref_page || {},
          tickerPosition: data.result.ui_ticker_position,
          overrideChartTheme: data.result.ui_overrideChartTheme,
        };
        
        if (!this.state.initialRedisPrefsPulled) {
          redisPrefs = {
            ...redisPrefs,
            marketInfoOpen: data.result.right == `show`,
            marketSwitcherOpen: data.result.left == `show`,
            ordersTableOpen: data.result.bottom == `show`,
          };
        }
        
        let { valid } = validateTheme(userTheme);
        if (!valid) userTheme = THEMES[getDefaultTheme()];

        this.setState({
          lastPulledRedisPrefs: JSON.stringify(redisPrefs)
        }, () => {
          this.props.updatePrefs(redisPrefs);
          this.props.setUserTheme(userTheme);
        });
      }
    });
  }
  
  getBalances(props: Props = this.props) {
    balanceApi.getBalances({
      params: {
        QuoteCurrCode: props.selectedQuoteCurrencyCode,
      } },
    (data) => {
      if (data.success) {
        this.setState({
          balances: data.result
        });
      }

      if (!this.state.isBalancesInit) {
        this.setState({
          isBalancesInit: true
        });
      }
    }
    );
  }

  getAccounts() {
    accountApi.getAccounts_v2((data) => {
      if (data.success) {
        this.setState({
          accounts: data.result
        });
      }

      if (!this.state.isAccountsInit) {
        this.setState({
          isAccountsInit: true
        });
      }
    });
  }

  updateAccount({ authId, update }: { authId: number, update: * }) {
    this.setState({
      accounts: this.state.accounts.map((a) => {
        if (a.authId !== authId) return a;
        return {
          ...a,
          ...update
        };
      })
    });
  }

  deleteAccount({ authId }: { authId: number }) {
    this.setState({
      accounts: this.state.accounts.filter(function(a) { 
        return a.authId !== authId;
      })
    });
  }

  refreshBalance(balanceAuthId: number, dontGetBalances: boolean = false, silenceNotifications: boolean = false): Promise<any> {
    const account = this.state.accounts.find((a) => a.authId == balanceAuthId);

    if (!account || account.authTradingType == `thirdparty`) return new Promise((r) => r());

    let refreshBalances = account.authVersion == 1 ? balanceApi.refreshBalanceV1 : balanceApi.refreshBalanceV2;
    let refreshOrders = account.authVersion == 1 ? balanceApi.refreshOrdersV1 : balanceApi.refreshOrdersV2;
    // silenceNotifications only applies to V1 accounts.
    if (silenceNotifications) {
      refreshBalances = account.authVersion == 1 ? balanceApi.refreshBalanceV1Silent : balanceApi.refreshBalanceV2;
      refreshOrders = account.authVersion == 1 ? balanceApi.refreshOrdersV1Silent : balanceApi.refreshOrdersV2;
    }

    return new Promise((res) => {
      refreshBalances({
        balanceAuthId
      }, () => {
        if (!dontGetBalances) {
          this.getBalances();
          this.getAccounts();
        }

        res();

        if (account.authExchId !== 999 && account.authTrade) {
          refreshOrders({
            balanceAuthId
          }, () => {
            emitEvent(REFRESH_ORDERS);
          });
        }
      });
    });
  }

  refreshLongPollData(firstRun: boolean = false) {
    exchangeApi.getExchanges((data) => {
      if (data.success) {
        this.setState({
          exchanges: data.result
        });
      }

      if (!this.state.isExchangesInit) {
        this.setState({
          isExchangesInit: true
        });
      }
    });

    currencyApi.getTrackableCurrencies()
      .then((data) => {
        if (data.success) {
          this.setState({
            currencies: data.result
          });
        }
      });

    exchangeApi.getMarkets((data) => {
      if (data.success) {
        const markets = data.result;
        this.setState({
          markets: markets,
        });
      }
    });

    exchangeApi.getAggregatedMarkets((data) => {
      if (data.success) {
        if (data.result.length > 0) {
          this.setState({
            aggregatedMarkets: data.result,
          });
        }
      }
    });

    userApi.getUserApplications({ params: {} }, (data) => {
      this.props.setUserApplications(data.result);
    });

    userApi.getUserApplicationsStatuses((data) => {
      this.props.setApplicationsStatuses(data.result);
    });    

    userApi.getUserData((data) => {
      this.props.updateUserData({
        user: data.result.user,
        userPrefs: data.result.userPrefs
      });
    });

    userApi.getFavorites((data) => {
      if (data.success) {
        this.props.updateFavorites(data.result.sort((a, b) => {
          let _a = `${ a.exchCode }:${ a.displayName }`,
            _b = `${ b.exchCode }:${ b.displayName }`;

          if (_a < _b) return -1;
          if (_b < _a) return 1;
          return 0;
        }));
      }
    });

    if (!firstRun) this.getBalances();
    this.getAccounts();

    userApi.getSettings((data) => {
      if (data.success) {
        let userTheme = JSON.parse(
          data.result.ui_user_theme || 
          JSON.stringify(this.props.userTheme || {}));
        
        // Only load certain redisPrefs during long polling to prevent the UI from automagically changing unexpectedly
        let redisPrefs = {
          mktColors: data.result.assigned_colors,
          showOrderHistory: data.result.show_order_history == `true`,
          showOpenPositions: data.result.show_open_positions == `true`,
          showCurrentAskLine: data.result.showCurrentAskLine == `true`,
          showCurrentBidLine: data.result.showCurrentBidLine == `true`,
          kbShortcutsEnabled: data.result.keyboardShortcutsEnabled,
          autoSaveDisabled: !!data.result.autoSaveDisabled,
          depthStyle: data.result.ui_depthStyle,
          priceClick: data.result.ui_priceClick,
          zeroStyle: data.result.ui_zeroStyle,
          theme: Object.keys(userTheme).length === 0 && userTheme.constructor === Object ? getDefaultTheme() : data.result.ui_theme,
          language: !data.result.language || data.result.language == `en-US` ? `en` : data.result.language,
          prefNativeCurrencyId: data.result.pref_native_currency_id,
          prefNativeCurrency: data.result.pref_native_currency,
          tradeAlertSound: data.result.trade_alert_sound,
          prefAccountingValuation: data.result.pref_accounting_valuation,
          prefPage: data.result.pref_page || {},
          tickerPosition: data.result.ui_ticker_position,
          overrideChartTheme: data.result.ui_overrideChartTheme,
        };
        
        if (!this.state.initialRedisPrefsPulled) {
          redisPrefs = {
            ...redisPrefs,
            marketInfoOpen: data.result.right == `show`,
            marketSwitcherOpen: data.result.left == `show`,
            ordersTableOpen: data.result.bottom == `show`,
          };
        }

        let { valid } = validateTheme(userTheme);
        if (!valid) userTheme = THEMES[getDefaultTheme()];

        this.setState({
          lastPulledRedisPrefs: JSON.stringify(redisPrefs),
          initialRedisPrefsPulled: true
        }, () => {
          this.props.updatePrefs(redisPrefs);
          this.props.setUserTheme(userTheme);
        });
      }
    });

    orderApi.getPriceTypes((data) => {
      if (data.success) {
        this.props.setPriceTypes(data.result);
      }
    });

    orderApi.getStatusTypes((data) => {
      if (data.success) {
        this.props.setStatusTypes(data.result);
      }
    });

    orderApi.getOrderTypes((data) => {
      if (data.success) {
        this.props.setOrderTypes(data.result);
      }
    });

    currencyApi.getAllFiatCurrencies((data) => {
      if (data.success) {
        const fiatCurrencies = data.result.map((c) => ({ currCode: c.currCode, currId: c.currId, currName: c.currName }));
        let filteredFiatCurrs = [];
        
        CURRENCY_MAP.forEach(function(key, val){
          
          const matchCurr = fiatCurrencies.filter((c) => c.currCode == val);
          
          if (matchCurr.length > 0) {
            filteredFiatCurrs.push(matchCurr[0]);
          } 
        });
        
        this.props.setFiatCurrencies(filteredFiatCurrs);
      }
    });

    userApi.getSubscriptionData((data) => {
      if (data.success) {
        if (data.result.subscriptionInfo && data.result.subscriptionTypes) {
          this.props.setSubscriptionInfo(data.result.subscriptionInfo);
          this.props.setSubscriptionTypes(data.result.subscriptionTypes);
        }
      }
    });
  }

  mergeFavorites(favorites: Array<Favorite>) {
    let _fs = this.props.favorites;

    favorites.forEach((f) => {
      let _f = _fs.filter((a0) => a0.displayName == f.displayName && a0.exchCode == f.exchCode)[0];

      if (_f) {
        _fs = _fs.map((a0) => a0.displayName == f.displayName && a0.exchCode == f.exchCode ? f : a0);
      }
    });

    return _fs;
  }

  handleShortcuts = (action: string, event: any) => {
    emitEvent(PAGE_SHORTCUT, { action, event });
    if (action == ESCAPE) {
      emitEvent(ESCAPE);
    }
  }

  render() {

    const sharedState = {
      markets: this.state.markets,
      aggregatedMarkets: this.state.aggregatedMarkets,
      exchanges: this.state.exchanges,
      currencies: this.state.currencies,
      favorites: this.props.favorites,
      balances: this.state.balances,
      accounts: this.state.accounts,
      size: this.state.windowSize,
      params: { exch: undefined, primaryCoin: undefined, baseCoin: undefined },
      marketSwitcherOpen: this.state.lastPulledRedisPrefs && JSON.parse(this.state.lastPulledRedisPrefs).marketSwitcherOpen == `show`,
      marketInfoOpen: this.state.lastPulledRedisPrefs && JSON.parse(this.state.lastPulledRedisPrefs).marketInfoOpen == `show`,
      updatePrefs: () => this.props.updatePrefs(redisPrefs),
      isAccountsInit: this.state.isAccountsInit, 
      isBalancesInit: this.state.isBalancesInit,
      isExchangesInit: this.state.isExchangesInit,
    };

    const isTickerPositionHeader = this.props.redisPrefs.tickerPosition === `header`;

    return (
      <Shortcuts
        isolate
        name="App"
        handler={ this.handleShortcuts }>
        <div className={ `app ${ getActiveTheme(this.props.theme).bordersNoShadows ? `borders-no-shadows` : `` } ${ isTickerPositionHeader ? `ticker-header` : ``}` }>
          { renderTheme(this.props.theme) }
          <Header 
            size={ this.state.windowSize } 
            user={ this.props.user } 
            accounts={ this.state.accounts } 
            exchanges={ this.state.exchanges }
            markets={ this.state.markets } />
          <main>
            <Switch>
              <Route
                path="/orders"
                render={ (routeProps) =>
                  <OrdersPage
                    { ...sharedState }
                    params={ { exch: routeProps.match.params.exch, primaryCoin: routeProps.match.params.primaryCoin, baseCoin: routeProps.match.params.baseCoin } }
                    refreshBalance={ this.refreshBalance.bind(this) } />
                } />
              <Route
                path="/alerts"
                render={ (routeProps) =>
                  <AlertsPage
                    { ...sharedState }
                    params={ { exch: routeProps.match.params.exch, primaryCoin: routeProps.match.params.primaryCoin, baseCoin: routeProps.match.params.baseCoin } }
                    refreshBalance={ this.refreshBalance.bind(this) } />
                } />
              <Route
                path="/portfolio"
                render={ (routeProps) =>
                  <BalancesPage
                    { ...sharedState }
                    params={ { exch: routeProps.match.params.exch, primaryCoin: routeProps.match.params.primaryCoin, baseCoin: routeProps.match.params.baseCoin } }
                    refreshBalance={ this.refreshBalance.bind(this) } />
                } />
              <Route
                path="/screener"
                render={ (routeProps) =>
                  <ScreenerPage { ...sharedState }/>
                } />
              <Route
                path="/arbmatrix"
                render={ (routeProps) =>
                  <ArbMatrixPage { ...sharedState }/>
                } />                
              <Route
                path="/wallet/:accountId?"
                render={ (routeProps) => {
                  return (
                    <AccountsPage
                      { ...sharedState }
                      params={ { exch: routeProps.match.params.exch, primaryCoin: routeProps.match.params.primaryCoin, baseCoin: routeProps.match.params.baseCoin, tab: `details`, } }
                      refreshBalance={ this.refreshBalance.bind(this) }
                      currencies={ this.state.currencies } />
                  );
                } }/>
              <Route
                path="/accounts"
                render={ (routeProps) => {
                  return (
                    <AccountsPage
                      { ...sharedState }
                      params={ { exch: routeProps.match.params.exch, primaryCoin: routeProps.match.params.primaryCoin, baseCoin: routeProps.match.params.baseCoin, tab: `accounts` } }
                      refreshBalance={ this.refreshBalance.bind(this) } />
                  );
                } }/>
              <Route
                path="/user/profile"
                render={ (routeProps) =>
                  <UserPage
                    { ...sharedState }
                    params={ { tab: `profile` } }/>
                } />
              <Route
                path="/user/preferences"
                render={ (routeProps) =>
                  <UserPage
                    { ...sharedState }
                    params={ { tab: `preferences` } } />
                } />
              <Route
                path="/user/2fa"
                render={ (routeProps) =>
                  <UserPage
                    { ...sharedState }
                    params={ { tab: `2fa` } } />
                } />
              <Route
                path="/user/referrals"
                render={ (routeProps) =>
                  <UserPage
                    { ...sharedState }
                    params={ { tab: `referrals` } } />
                } />
              <Route
                path="/user/activity"
                render={ (routeProps) =>
                  <UserPage
                    { ...sharedState }
                    params={ { tab: `activity` } } />
                } />
              <Route
                path="/user/sessions"
                render={ (routeProps) =>
                  <UserPage
                    { ...sharedState }
                    params={ { tab: `sessions` } } />
                } />
              <Route
                path="/user/favorites"
                render={ (routeProps) =>
                  <UserPage
                    { ...sharedState }
                    params={ { tab: `favorites` } } />
                } />
              <Route
                path="/boards/:source_tag?"
                render={ (routeProps) =>
                  <BoardsPage
                    { ...sharedState }
                    params={ { source_tag: routeProps.match.params.source_tag } }
                    refreshBalance={ this.refreshBalance.bind(this) } />
                } />
              <Route
                path="/markets/:exch/:primaryCoin/:baseCoin"
                render={ (routeProps) =>
                  <MarketsPage
                    { ...sharedState }
                    params={ { exch: routeProps.match.params.exch, primaryCoin: routeProps.match.params.primaryCoin, baseCoin: routeProps.match.params.baseCoin } } />
                }/>
              <Route
                path="/markets"
                render={ (routeProps) =>
                  <MarketsPage
                    { ...sharedState } />
                } />
              <Route
                path="/settings/interface"
                render={ (routeProps) =>
                  <SettingsPage
                    { ...sharedState }
                    params={ { tab: `interface` } }/>
                } />
              <Route
                path="/settings/api"
                render={ (routeProps) =>
                  <SettingsPage
                    { ...sharedState }
                    params={ { tab: `api` } } />
                } />
              <Route
                path="/settings/notifications"
                render={ (routeProps) =>
                  <SettingsPage
                    { ...sharedState }
                    params={ { tab: `notifications` } } />
                } />                
              <Route
                path="/"
                render={ (routeProps) =>
                  <Redirect to="/markets" />
                } />
              <Route path="" render={ (routeProps) => <Redirect to="/" /> } />
            </Switch>
          </main>
          <Footer
            markets={ this.state.markets } 
            user={ this.props.user }
            accounts={ this.state.accounts } 
            exchanges={ this.state.exchanges }/>
        </div>
      </Shortcuts>
    );
  }
}

App.childContextTypes = {
  shortcuts: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  theme: state.redisPrefs.theme,
  redisPrefs: state.redisPrefs,
  favorites: state.app.favorites,
  userPrefs: state.userInfo.userPrefs,
  user: state.userInfo.user,
  userTheme: state.theme,
  selectedQuoteCurrencyCode: state.app.selectedQuoteCurrencyCode,
  keymap: state.kb,
  rehydrated: state._persist.rehydrated
});

const mapDispatchToProps = (dispatch) => ({
  updatePrefs: (collapse) => dispatch(batchUpdateRedisPrefs(collapse)),
  updateFavorites: (favorites) => dispatch(updateFavorites(favorites)),
  updateUserData: (data) => dispatch(updateUserData(data)),
  setUserTheme: (t) => dispatch(rewriteUserTheme(t)),
  setPriceTypes: (d) => dispatch(setPriceTypes(d)),
  setOrderTypes: (d) => dispatch(setOrderTypes(d)),
  setStatusTypes: (d) => dispatch(setStatusTypes(d)),
  setFiatCurrencies: (d) => dispatch(setFiatCurrencies(d)),
  setSubscriptionInfo: (d) => dispatch(setSubscriptionInfo(d)),
  setSubscriptionTypes: (d) => dispatch(setSubscriptionTypes(d)),
  setUserApplications: (d) => dispatch(setUserApplications(d)),
  setApplicationsStatuses: (d) => dispatch(setApplicationsStatuses(d)),  
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
