// @flow
'use strict';

import React, { useEffect, useState } from 'react';
import { parse } from 'rss-to-json';
import moment from 'moment';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { getPublicNews } from '../../helpers/api/NewsApi.js';
import { getActiveTheme } from '../../helpers/ThemeHelper.js';
import Modal from '../utilities/Modal.jsx';

type Props = {
  setMOTDModalOpen: any,
  theme: any,
};

const DEFAULT_IMAGE = `/assets/img/newswire.png`;

const MOTDModal = (props: Props) => {
  const { setMOTDModalOpen, theme } = props;

  const [news, setNews] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);
  const [momentNow, setMomentNow] = useState(moment().format(`LLL`).toString());
  
  const invertToLight = getActiveTheme(theme)?.invertToLight;

  useEffect(() => {        
    getPublicNews()
      .then((data) => setNews(data.result.filter((n) => n.feedEnabled)));

    parse(`${window.API_V2_URL}/public/thirdparty/insights/rss`)
      .then((rss) => setBlogPosts(rss.items) );

    const intervalNow = setInterval(() => {
      setMomentNow(moment().format(`LLL`).toString());
    }, 1000);      

    return () => {
      clearInterval(intervalNow);
    };      
  }, []);

  const renderArticle = (key, title, url, image) => {
    return (
      <li key={ key } className='article'>
        <img 
          src={ image } 
          onError={ (e) => {e.target.onerror = null; e.target.src=`${ window.WWW_URL }${DEFAULT_IMAGE}`;} } /> 
        <a target="_blank" rel="noopener noreferrer" href={ url }>
          { title } 
        </a>
      </li>
    );
  };
  
  const onClose = () => {
    setMOTDModalOpen(false);
  };

  return (
    <div className="motd-modal">
      <Modal
        title={ `Daily Briefing - ` + momentNow }
        onClose={ () => onClose()  }>
        { news.length  > 0 && 
            <section>
              <h3>
                { `Crypto News` }
              </h3>
              <ul className='articles'>
                {
                  news.sort((a, b) => {
                    if (a.timestamp > b.timestamp) return -1;
                    return 0;
                  })
                    .slice(0, 3)
                    .map((n) => renderArticle(n.id, n.title, n.url, `${ window.WWW_URL }/${ n.feedImage }`))
                }
              </ul>
            </section>
        }

        { blogPosts.length > 0&& 
            <section>
              <h3>
                { `Coinigy News` }
              </h3>
              <ul className='articles'>
                {
                  blogPosts.sort((a, b) => {
                    if (a.created > b.created) return -1;
                    return 0;
                  })
                    .slice(0, 2)
                    .map((p, idx) => renderArticle(idx, p.title, p.link, p.media?.thumbnail?.url || `empty` ))
                }
                <li className='article tweet'>
                  <TwitterTimelineEmbed
                    sourceType="widget"
                    screenName="coinigy"
                    tweetLimit="1"
                    autoHeight
                    noHeader
                    noFooter
                    noScrollbar
                    noBorders
                    transparent={ invertToLight }
                    theme={ !invertToLight ? `dark` : `light` }/>
                </li>
              </ul>
            </section> 
        }
      </Modal>
    </div>
  );
};

export default MOTDModal;
