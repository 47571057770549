// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';

import Modal from '../utilities/Modal.jsx';
import PrimeTrustTermsIframe from '../utilities/PrimeTrustTermsIframe.jsx';

type Props = {
  t: any,
  onClose: () => void,
};

const PrimeTrustAcceptTermsModal = (props: Props) => {
  const { t, onClose } = props;

  return (
    <div className={ `prime-trust-accept-terms-modal` }>
      <Modal titleIcon={ window.WWW_URL + `/assets/img/exchange/LSCX-icon.png` } title={ t(`tradeWithLightSpeed`) } onClose={ onClose } >
        <PrimeTrustTermsIframe t={ t } onClose={ onClose }/>
      </Modal>
    </div>
  );
};

export default translate(`primeTrustModal`)(PrimeTrustAcceptTermsModal);
