// @flow
'use strict';

import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import type { Account } from '../../types/Account.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Market } from '../../types/Market.js';
import { getExchangeTradeApiVersion } from '../../helpers/ExchangeTradingHelper';
import {
  listenForEvent,
  removeEventListener,
  ORDER_ADDED,
  ORDER_ADDED_FAILED,
  PENDING_ORDER_ADDED,
  SILENT_NOTIFICATION,
} from '../../helpers/EventHelper.js';
import { getMarketFromPanelSettings } from '../../helpers/MarketPairHelper.js';
import { translateOrder } from '../../helpers/OrderTransformationHelper.js';
import { cancelV1Order, cancelV2Order, getOrders, getOrderHistory } from '../../helpers/api/OrderApi.js';
import BalancesOrdersHistory from '../markets/BalancesOrdersHistory.jsx';

type Settings = {
  exchange: string,
  market: string,
};

type Props = {
  t: any,
  accounts: Array<Account>,
  exchanges: Array<Exchange>,
  markets: Array<Market>,
  pageSize: any,
  settings: Settings,
};

const OrdersPanel = (props: Props) => {
  const { t, accounts, exchanges, markets, pageSize, settings } = props;
  
  const [activeTab, setActiveTab] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [currentAuthId, setCurrentAuthId] = useState(null);
  const [currentExch, setCurrentExch] = useState(null);
  const [currentMarket, setCurrentMarket] = useState(null);
  
  const [exchFilter, setExchFilter] = useState(`all`);
  const [mktFilter, setMktFilter] = useState(`all`);
  const [authFilter, setAuthFilter] = useState(`all`);
  
  const [openOrdersPage, setOpenOrdersPage] = useState(1);
  const [openOrdersPages, setOpenOrdersPages] = useState(1);
  const [openOrders, setOpenOrders] = useState([]);

  const [orderHistoryPage, setOrderHistoryPage] = useState(1);
  const [orderHistoryPages, setOrderHistoryPages] = useState(1);
  const [orderHistory, setOrderHistory] = useState([]);

  const changeActiveTab = (n: number) => {
    setActiveTab(n);
  };

  const getApiVersion = () => {
    return currentExch && getExchangeTradeApiVersion(currentExch);
  };

  const activeAccounts = accounts
    .filter((account: Account) => account.authVersion == getApiVersion()
      && account.authExchId === currentExch?.exchId
      && account.authTrade 
      && account.authActive)
    .sort((a, b) => {
      if (a.authNickname > b.authNickname) return 1;
      if (b.authNickname > a.authNickname) return -1;
      return 0;
    });


  const showMultiAuth = activeAccounts.length > 1;

  const getCurrentAuthIdValue = (force: boolean) => { 
    let initCurrentAuthId = !force && currentAuthId || 0;
    
    if (initCurrentAuthId === 0) { 
      initCurrentAuthId = ``;

      if (activeAccounts.length >= 1) {
        initCurrentAuthId = activeAccounts[0].authId;
      }

      setCurrentAuthId(initCurrentAuthId);
    }

    return initCurrentAuthId.toString();
  };

  const updateUserOrderFilters = (exchFilter: ?string, mktFilter: ?string, authFilter: ?string) => { 
    if (exchFilter) setExchFilter(exchFilter);

    if (mktFilter) setMktFilter(mktFilter);

    if (authFilter) {
      setAuthFilter(authFilter);
      if (showMultiAuth) setCurrentAuthId(authFilter);
    }
  };  

  const getUserOrderData = (pageNumber?: number, activeTab?: number) => {
    if (!isReady) return false;

    let searchTerm = ``;
    let authId = 0;

    // force pageNumber change early, 
    // previously showed the old pageNumber until the API call completed. 
    if (pageNumber) {
      setOpenOrdersPage(pageNumber);
      setOrderHistoryPage(pageNumber);
    }

    if (exchFilter !== `all` && mktFilter == `all`) {
      searchTerm = `${exchFilter}:`;
    }

    if (exchFilter == `all` && mktFilter !== `all`) {
      searchTerm = mktFilter;
    }

    if (exchFilter == `all` && mktFilter == `all`) {
      searchTerm = `all`;
    }

    if (exchFilter !== `all` && mktFilter !== `all`) {
      searchTerm = `${exchFilter}:${mktFilter}`;
    }

    if (authFilter && authFilter !== `all`) {
      authId = parseInt(authFilter);
    }

    const options = {
      params: {
        pageSize: pageSize,
        pageNumber: 0,
        SearchTerm: ``, 
        AuthId: undefined
      }
    };

    if (searchTerm === `all`) {
      options.params.SearchTerm = ``;
    } else if (searchTerm && searchTerm.length > 0) {
      options.params.SearchTerm = searchTerm; 
    }

    if (authId) options.params.AuthId = authId;

    if (!activeTab || activeTab === 0) {
      options.params.pageNumber = pageNumber ?? openOrdersPage;
      getOrders(options, (data) => {
        setOpenOrders(data.result?.length > 0 ? data.result.map(translateOrder) : []);
        setOpenOrdersPage(options.params.pageNumber);
        setOpenOrdersPages(data.result?.length > 0 ? data.totalPages : 1);  
      });
    }

    if (!activeTab || activeTab === 1) {
      options.params.pageNumber = pageNumber ?? orderHistoryPage;
      getOrderHistory(options, (data) => {
        setOrderHistory(data.result?.length > 0 ? data.result.map(translateOrder) : []);
        setOrderHistoryPage(options.params.pageNumber);
        setOrderHistoryPages(data.result?.length > 0 ? data.totalPages : 1);  
      });
    }
  };

  const deleteOrder = (authId: number, orderId: number, exchCode: string) => {
    if (exchanges.some((e) => e.exchCode == exchCode && e.exchTradeEnabledV2 === true)) {
      cancelV2Order({ authId, orderId }, (data) => {
        if (data.success) setOpenOrders(openOrders.filter((o) => o.orderId !== orderId));
      });
    } else {
      cancelV1Order(orderId, () => {
        setOpenOrders(openOrders.filter((o) => o.orderId !== orderId));
      });
    }

    return true;
  };  

  const orderEventCallback = () => {
    getUserOrderData();
  };

  const silentNotificationCallback = (e: any) => {
    switch (e.detail.type) {
    case 221: // orders
      getUserOrderData();
      break;
    }
  };

  useEffect(() => {
    setCurrentExch(exchanges.find((e) => e.exchCode === settings.exchange));
    setCurrentMarket(getMarketFromPanelSettings(markets, settings));
  }, [exchanges, markets, settings]);

  useEffect(() => {
    if (currentExch && currentMarket) {
      setIsReady(true);
      setAuthFilter(`all`);
      updateUserOrderFilters(currentExch.exchCode, currentMarket.displayName ,null);
      getCurrentAuthIdValue(true);
    } else {
      setIsReady(false);
    }
  }, [currentExch?.exchCode, currentMarket?.displayName]);

  useEffect(() => {
    listenForEvent(ORDER_ADDED, orderEventCallback);
    listenForEvent(ORDER_ADDED_FAILED, orderEventCallback);
    listenForEvent(PENDING_ORDER_ADDED, orderEventCallback);
    listenForEvent(SILENT_NOTIFICATION, silentNotificationCallback);

    return () => {
      removeEventListener(ORDER_ADDED, orderEventCallback);
      removeEventListener(ORDER_ADDED_FAILED, orderEventCallback);
      removeEventListener(PENDING_ORDER_ADDED, orderEventCallback);
      removeEventListener(SILENT_NOTIFICATION, silentNotificationCallback);
    };

  }, [exchFilter, mktFilter, authFilter]);

  return (
    <section className='balances-orders-history-panel'>
      {
        currentExch && currentMarket && 
        (
          <BalancesOrdersHistory
            marketSwitcherOpen={ false }
            toggleOpen={ true }
            showLarger={ true }
            active={ { market: currentMarket, exchange: currentExch } }
            markets={ markets }
            activeBOHTab={ activeTab }
            updateBOHTab={ changeActiveTab }
            isFull={ true }
            openOrders={ openOrders }
            openOrdersPage={ openOrdersPage }
            openOrdersPages={ openOrdersPages }
            orderHistory={ orderHistory }
            orderHistoryPage={ orderHistoryPage }
            orderHistoryPages={ orderHistoryPages }
            exchFilter={ exchFilter }
            mktFilter={ mktFilter }
            authFilter={ authFilter }
            getCurrentAuthIdValue={ getCurrentAuthIdValue }
            updateUserOrderFilters={ updateUserOrderFilters }
            getUserOrderData={ getUserOrderData }
            deleteOrder={ deleteOrder }
            balances={ [] }
            accounts={ accounts }
            exchanges={ exchanges }
            currentAuthId={ currentAuthId } 
            tabs={ [
              { key: `openOrders`, title: t(`orders:openOrders`) },
              { key: `orderHistory`, title: t(`orders:orderHistory`) }
            ] }
            showMultiAuth={ showMultiAuth }/>
        )
      }
      
    </section>
  );
};

OrdersPanel.getPanelTitle = (settings: Settings, markets: Array<Market>) => {
  const market = getMarketFromPanelSettings(markets, settings);

  return market && settings ? `${ market.exchName } - ${ market.displayName }` : `Orders`;
};

const mapStateToProps = (state) => ({
  pageSize: state.app.pageSize,
});

export default translate(`orders`)(connect(mapStateToProps)(OrdersPanel));
