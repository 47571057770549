// @flow
'use strict';

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { batchUpdateRedisPrefs } from '../../actions/redisPrefs/updateRedisPrefs.js';
import { updateResolution } from '../../actions/markets/updateResolution.js';
import { changeMarket } from '../../actions/markets/changeMarket.js';
import type { Market } from '../../types/Market.js';
import type { Order } from '../../types/Order.js';
import type { Alert } from '../../types/Alert.js';
import type { Trade } from '../../types/Trade.js';
import type { Theme } from '../../types/Theme.js';
import {
  emitEvent,
  listenForEvent,
  removeEventListener,
  MARKET_BUY_MODE_ACTIVATED,
  MARKET_BUY_MODE_DEACTIVATED,
  MARKET_SELL_MODE_ACTIVATED,
  MARKET_SELL_MODE_DEACTIVATED,
  MARKET_ALERT_MODE_ACTIVATED,
  MARKET_ALERT_MODE_DEACTIVATED,
  DELETE_BUY_NODE,
  DELETE_SELL_NODE,
  DELETE_BUY_STOP_NODE,
  DELETE_SELL_STOP_NODE,
  DATA_MODE_REQUESTED,
  ALERT_MODE_REQUESTED,
  ALERT_CONFIRM_REQUESTED,
  SET_SYMBOL_COMPLETE,
  BUY_MODE_REQUESTED,
  BUY_MODE_REQUESTED_WITH_PRICE,
  BUY_MODE_REQUESTED_WITH_STOP_PRICE,
  BUY_CONFIRM_REQUESTED,
  SELL_MODE_REQUESTED,
  SELL_MODE_REQUESTED_WITH_PRICE,
  SELL_MODE_REQUESTED_WITH_STOP_PRICE,
  SELL_CONFIRM_REQUESTED,
  PRICE_CHANGE_REQUESTED,
  STOP_PRICE_CHANGE_REQUESTED,
  MARKET_ALERT_MODE_ACTIVATION_ACKNOWLEDGED,
  MARKET_BUY_MODE_ACTIVATION_ACKNOWLEDGED,
  MARKET_SELL_MODE_ACTIVATION_ACKNOWLEDGED,
  MARKET_RECEIVED_NEW_TRADE,
  UPDATE_CURRENT_AUTHID,
  NOTIFICATION,
  ADD_CHART_SYMBOL,
  PENDING_ORDER_ADDED,
  ORDER_ADDED,
  ORDER_ADDED_FAILED,
  REFRESH_ORDERS,
} from '../../helpers/EventHelper.js';
import { getMarketPair } from '../../helpers/MarketPairHelper.js';
import { getActiveTheme } from '../../helpers/ThemeHelper.js';
import * as jstz from 'jstz';
import * as orderApi from '../../helpers/api/OrderApi.js';

type Props = {
  market: Market,
  userTheme: Theme,
  theme: string,
  user_hash: string,
  openOrders: Array<Order>,
  orderHistory: Array<Order>,
  activeAlerts: Array<Alert>,
  showOrderHistory: boolean,
  marketsAreClickable: any,
  showOpenOrders: boolean,
  showCurrentAskBidLine: boolean,
  bid: number,
  ask: number,
  updatePrefs: (obj: any) => void,
  updateInterval: (obj: string) => void,
  autoSaveDisabled: boolean,
  resolution: string,
  history: { push: (any) => void, replace: (any) => void },
  iframeSrc: string,
  changeMarket: (b: boolean) => void,
  marketSwitcherTriggered: boolean,
};

type State = {
  tvChartLoaded: boolean,
  backfillComplete: boolean,
  tradeBuffer: Array<Trade>
};

let overrideCmds = [`updateOpenOrders`, `updateOrderHistory`, `updateBid`, `updateAsk`],
  cmdTimeouts = { };

export class TradingViewChart extends React.Component<Props, State> {
  onMessage: (msg: any) => void;
  onAppMessage: (e: any) => void;

  constructor(props: Props) {
    super(props);

    this.onMessage = this.handleTVMessage.bind(this);
    this.onAppMessage = this.handleAppMessage.bind(this);

    this.state = {
      tvChartLoaded: false,
      backfillComplete: false,
      tradeBuffer: []
    };
  }

  componentDidMount() {
    window.addEventListener(`message`, this.onMessage, false);

    listenForEvent(DELETE_BUY_NODE, this.onAppMessage);
    listenForEvent(DELETE_SELL_NODE, this.onAppMessage);
    listenForEvent(DELETE_BUY_STOP_NODE, this.onAppMessage);
    listenForEvent(DELETE_SELL_STOP_NODE, this.onAppMessage);
    listenForEvent(MARKET_BUY_MODE_ACTIVATED, this.onAppMessage);
    listenForEvent(MARKET_BUY_MODE_DEACTIVATED, this.onAppMessage);
    listenForEvent(MARKET_SELL_MODE_ACTIVATED, this.onAppMessage);
    listenForEvent(MARKET_SELL_MODE_DEACTIVATED, this.onAppMessage);
    listenForEvent(MARKET_ALERT_MODE_ACTIVATED, this.onAppMessage);
    listenForEvent(MARKET_ALERT_MODE_DEACTIVATED, this.onAppMessage);
    listenForEvent(MARKET_RECEIVED_NEW_TRADE, this.onAppMessage);
    listenForEvent(UPDATE_CURRENT_AUTHID, this.onAppMessage);
    listenForEvent(ADD_CHART_SYMBOL, this.onAppMessage);

  }

  componentWillUnmount() {
    window.removeEventListener(`message`, this.onMessage, false);

    removeEventListener(DELETE_BUY_NODE, this.onAppMessage);
    removeEventListener(DELETE_SELL_NODE, this.onAppMessage);
    removeEventListener(DELETE_BUY_STOP_NODE, this.onAppMessage);
    removeEventListener(DELETE_SELL_STOP_NODE, this.onAppMessage);
    removeEventListener(MARKET_BUY_MODE_ACTIVATED, this.onAppMessage);
    removeEventListener(MARKET_BUY_MODE_DEACTIVATED, this.onAppMessage);
    removeEventListener(MARKET_SELL_MODE_ACTIVATED, this.onAppMessage);
    removeEventListener(MARKET_SELL_MODE_DEACTIVATED, this.onAppMessage);
    removeEventListener(MARKET_ALERT_MODE_ACTIVATED, this.onAppMessage);
    removeEventListener(MARKET_ALERT_MODE_DEACTIVATED, this.onAppMessage);
    removeEventListener(MARKET_RECEIVED_NEW_TRADE, this.onAppMessage);
    removeEventListener(UPDATE_CURRENT_AUTHID, this.onAppMessage);
    removeEventListener(ADD_CHART_SYMBOL, this.onAppMessage);

  }

  shouldComponentUpdate(nextProps: Props) {
    
    if (nextProps.theme !== this.props.theme || 
        JSON.stringify(nextProps.userTheme) !== JSON.stringify(this.props.userTheme)) {
      this.sendTVMessage({
        cmd: `changeTheme`,
        data: getActiveTheme(nextProps.theme)
      });
    }

    if (nextProps.market.exchmktId !== this.props.market.exchmktId) {
      if(nextProps.marketSwitcherTriggered) {
        this.sendTVMessage({
          cmd: `changeSymbol`,
          data: `${ nextProps.market.exchCode }:${ getMarketPair(nextProps.market).toString() }`
        });
        nextProps.changeMarket(false);
      }
    }

    if (JSON.stringify(this.props.orderHistory) !== JSON.stringify(nextProps.orderHistory)) {
      this.sendTVMessage({
        cmd: `updateOrderHistory`,
        data: nextProps.orderHistory
      });
    }

    if (JSON.stringify(this.props.openOrders) !== JSON.stringify(nextProps.openOrders)) {
      this.sendTVMessage({
        cmd: `updateOpenOrders`,
        data: nextProps.openOrders
      });
    }

    if (JSON.stringify(this.props.activeAlerts) !== JSON.stringify(nextProps.activeAlerts)) {
      this.sendTVMessage({
        cmd: `updateActiveAlerts`,
        data: nextProps.activeAlerts
      });
    }

    if (this.props.ask !== nextProps.ask) {
      this.sendTVMessage({
        cmd: `updateAsk`,
        data: nextProps.ask
      });
    }

    if (this.props.bid !== nextProps.bid) {
      this.sendTVMessage({
        cmd: `updateBid`,
        data: nextProps.bid
      });
    }

    return false;
  }

  handleAppMessage(e: any) {
    if (!e) return;
    switch(e.type) {
    case MARKET_BUY_MODE_ACTIVATED:
      this.sendTVMessage({
        cmd: `buyModeActivated`,
        data: e.detail
      });

      break;
    case MARKET_BUY_MODE_DEACTIVATED:
      this.sendTVMessage({ cmd: `buyModeDeactivated` });
      break;
    case MARKET_SELL_MODE_ACTIVATED:
      this.sendTVMessage({
        cmd: `sellModeActivated`,
        data: e.detail
      });
      break;
    case MARKET_SELL_MODE_DEACTIVATED:
      this.sendTVMessage({ cmd: `sellModeDeactivated` });
      break;
    case MARKET_ALERT_MODE_ACTIVATED:
      this.sendTVMessage({
        cmd: `alertModeActivated`,
        data: {
          price: e.detail.price,
          string: e.detail.string,
          alertType: e.detail.alertType
        }
      });
      break;
    case MARKET_ALERT_MODE_DEACTIVATED:
      this.sendTVMessage({ cmd: `alertModeDeactivated` });
      break;
    case MARKET_RECEIVED_NEW_TRADE:
      if (this.state.backfillComplete) {
        this.sendTVMessage({
          cmd: `addTrade`,
          data: e.detail.trade
        });
      } else {
        this.setState({
          tradeBuffer: this.state.tradeBuffer.concat([e.detail.trade])
        });
      }
      break;
    case DELETE_BUY_NODE:
      this.sendTVMessage({
        cmd: `deleteBuyNode`
      });
      break;
    case DELETE_SELL_NODE:
      this.sendTVMessage({
        cmd: `deleteSellNode`
      });
      break;
    case DELETE_BUY_STOP_NODE:
      this.sendTVMessage({
        cmd: `deleteBuyStopNode`
      });
      break;
    case DELETE_SELL_STOP_NODE:
      this.sendTVMessage({
        cmd: `deleteSellStopNode`
      });
      break;
    case UPDATE_CURRENT_AUTHID:
      this.sendTVMessage({
        cmd: `updateCurrentAuthId`,
        data: e.detail
      });
      break;
    case ADD_CHART_SYMBOL:
      this.sendTVMessage({
        cmd: `addChartSymbol`,
        data: e.detail
      });
      break;
    }
  }

  handleTVMessage(msg: { data: { id: string, data: any } }) {
    if (!msg || msg.data.id !== `tradingView`) return;
    let { data: { data: { event, data } } } = msg;
    switch (event) {
    case `chartReady`:
      this.sendTVMessage({
        cmd: `changeTheme`,
        data: getActiveTheme(this.props.theme)
      });
      return this.setState({
        tvChartLoaded: true
      }, () => this.sendTVMessage({ cmd: `chartReadyAcknowledged` }));
    case `symbolChange`:
      if(window.location.pathname != `/markets/${ data.exchange }/${ data.description }`)
        return this.props.history.push(`/markets/${ data.exchange }/${ data.description }`);
      else
        return this.props.history.replace(`/markets/${ data.exchange }/${ data.description }`);
    case `intervalChange`:
      window.ChartHistoryResolution = data;
      return this.props.updateInterval(data);
    case `toggleShowOrderHistory`:
      return this.props.updatePrefs({
        showOrderHistory: data
      });
    case `toggleShowOpenOrders`:
      return this.props.updatePrefs({
        showOpenPositions: data
      });
    case `toggleShowAskBid`:
      return this.props.updatePrefs({
        showCurrentAskLine: data,
        showCurrentBidLine: data
      });
    case `modeDeactivated`:
      return emitEvent(DATA_MODE_REQUESTED);
    case `alertModeActivated`:
      return emitEvent(ALERT_MODE_REQUESTED);
    case `alertConfirmed`:
      return emitEvent(ALERT_CONFIRM_REQUESTED);
    case `buyModeActivated`:
      return emitEvent(BUY_MODE_REQUESTED);
    case `buyModeActivatedAndRequestPriceChange`:
      return emitEvent(BUY_MODE_REQUESTED_WITH_PRICE, data);
    case `buyConfirmed`:
      return emitEvent(BUY_CONFIRM_REQUESTED);
    case `sellModeActivated`:
      return emitEvent(SELL_MODE_REQUESTED);
    case `sellModeActivatedAndRequestPriceChange`:
      return emitEvent(SELL_MODE_REQUESTED_WITH_PRICE, data);
    case `sellConfirmed`:
      return emitEvent(SELL_CONFIRM_REQUESTED);
    case `requestPriceChange`:
      return emitEvent(PRICE_CHANGE_REQUESTED, data);
    case `requestStopPriceChange`:
      return emitEvent(STOP_PRICE_CHANGE_REQUESTED, data);
    case `buyModeActivatedAndRequestBuyStopPriceChange`:
      return emitEvent(BUY_MODE_REQUESTED_WITH_STOP_PRICE, data);  
    case `sellModeActivatedAndRequestSellStopPriceChange`:
      return emitEvent(SELL_MODE_REQUESTED_WITH_STOP_PRICE, data);
    case `marketAlertModeActivatedAck`:
      return emitEvent(MARKET_ALERT_MODE_ACTIVATION_ACKNOWLEDGED);
    case `marketBuyModeActivatedAck`:
      return emitEvent(MARKET_BUY_MODE_ACTIVATION_ACKNOWLEDGED);
    case `marketSellModeActivatedAck`:
      return emitEvent(MARKET_SELL_MODE_ACTIVATION_ACKNOWLEDGED);
    case `setSymbolComplete`:
      return emitEvent(SET_SYMBOL_COMPLETE);
    case `replaceOrder`:
      return this.replaceOrder(data.order, data.newPrice);
    case `replaceOrderNotAvailable`:
      return emitEvent(NOTIFICATION, {
        notification_id: new Date().getTime(),
        title: `Replace Order`,
        title_vars: ``,
        message_raw: `Replace Order function is only available on V2 API Accounts.`,
        message: `Replace Order function is only available on V2 API Accounts.`,
        message_vars: ``,
        pinned: false,
        style: `error`,
        url: ``
      });
    case `error`: 
      return emitEvent(NOTIFICATION, {
        notification_id: new Date().getTime(),
        title: `Error Rendering Chart`,
        title_vars: ``,
        message_raw: data ? data : `Unexpected error, please try again.`,
        message: data ? data : `Unexpected error, please try again.`,
        message_vars: ``,
        pinned: false,
        style: `error`,
        url: ``
      });
    case `backfillComplete`: {
      let trades = this.state.tradeBuffer;
      this.setState({
        backfillComplete: true,
        tradeBuffer: []
      }, () => {
        trades.forEach((t) => {
          this.sendTVMessage({
            cmd: `addTrade`,
            data: t
          });
        });
      });
    }
    }
  }
  getIframe() {
    return this.refs[`tv-frame`];
  }

  sendTVMessage(msg: { cmd: string, data?: any }) {
    if (overrideCmds.includes(msg.cmd)) clearTimeout(cmdTimeouts[msg.cmd]);
    if (!this.state.tvChartLoaded) return cmdTimeouts[msg.cmd] = setTimeout(() => this.sendTVMessage(msg), 500);
    if (this.getIframe() && this.getIframe().contentWindow) {
      this.getIframe().contentWindow.postMessage(msg, window.location.origin);
    }
  }

  convertToURLParams(params: any) {
    return `?${ Object.keys(params)
      .map((opt: string) => {
        return `${ opt }=${ encodeURIComponent(params[opt]) }`;
      }).join(`&`) }`;
  }

  getUserTimezone() {
    return jstz.determine().name() === undefined ? `UTC` : jstz.determine().name();
  } 

  replaceOrder(order: any, newPrice: any) {
    if (order.authVersion !== 2) return;

    orderApi.cancelV2Order({ authId: order.authId, orderId: order.orderId }, (data) => {
      if (data.success) {

        const pendingOrder = {
          authId: order.authId,
          authNickname: order.authNickname,
          displayName: order.displayName,
          exchCode: order.exchCode,
          exchange: order.exchName,
          exchmktId: order.exchmktId,
          foreignOrderId: Math.floor(Math.random() * (1439418887 - 1449418887) + 1439418887),
          limitPrice: newPrice,
          market: order.marketName,
          orderCurrency: order.baseCurrency,
          orderId: Math.floor(Math.random() * (1439418887 - 1449418887) + 1439418887),
          //orderOperator: body.order_operator,
          orderStatus: `Placing`,
          orderStatusId: 1,
          status: 1,
          side: order.side,
          orderTime: new Date().toISOString(),
          orderType: order.orderType,
          priceType: order.orderType === 3 ? `Limit` : `Stop (Limit)`,
          priceTypeId: order.orderType,
          quantity: order.quantity,
          quantityRemaining: order.quantity,
          quoteCurrency: order.quoteCurrency,
          stopPrice: order.stopPrice,
          //autoDestroy: true,
        };
        emitEvent(PENDING_ORDER_ADDED, pendingOrder);

        const getRequest = (order, newPrice) => {
          if (order.orderType === 3) { // LIMIT
            return {
              api: orderApi.setV2LimitOrder,
              body: {
                ExchMktId: order.exchmktId,
                priceType: order.orderType,
                side: order.side,
                price: parseFloat(newPrice),
                quantity: parseFloat(order.quantity)
              }
            }; 
          }
          if (order.orderType === 6) { //STOP (LIMIT)
            return {
              api: orderApi.setV2StopLimitOrder,
              body: {
                ExchMktId: order.exchmktId,
                TriggerExchMktId: order.triggerExchMktId,
                TriggerPrice: parseFloat(newPrice),
                side: order.side,
                price: parseFloat(order.limitPrice),
                quantity: parseFloat(order.quantity),
                priceType: order.orderType,
                conditionalOperator: order.conditionalOperator
              }
            };
          }
        };

        const orderRequest = getRequest(order, newPrice);

        orderRequest?.api(order.authId, orderRequest.body, (d) => {
          if (d.success) {
            emitEvent(ORDER_ADDED, d.result);
          } else {
            emitEvent(ORDER_ADDED_FAILED);
          }
        });  
      } else {
        emitEvent(REFRESH_ORDERS);
      }
    });
  } 


  render() {
    if (!this.props.market) return (<div />);

    return (
      <iframe
        id="tv-frame-id"
        width="100%"
        height="100%"
        ref="tv-frame"
        src={ this.props.iframeSrc }
        key={ this.props.iframeSrc }
        frameBorder="0"
        scrolling="no" />
    );
  }
}


const mapStateToProps = (state) => ({
  userTheme: state.theme,
  theme: state.redisPrefs.theme,
  showOrderHistory: state.redisPrefs.showOrderHistory,
  showOpenOrders: state.redisPrefs.showOpenPositions,
  marketsAreClickable: state.browser.marketsAreClickable,
  showCurrentAskBidLine: state.redisPrefs.showCurrentAskLine,
  resolution: state.markets.resolution,
  marketSwitcherTriggered: state.markets.marketSwitcherTriggered
});

const mapDispatchToProps = (dispatch) => ({
  updatePrefs: (p) => dispatch(batchUpdateRedisPrefs(p)),
  updateInterval: (p) => dispatch(updateResolution(p)),
  changeMarket: (b) => dispatch(changeMarket(b))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TradingViewChart));
