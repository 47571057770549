// @flow
'use strict';

import type { UserApplication } from '../types/UserApplication.js';

const AWAITING_APPLICATION = 0;
const PENDING = 1;
const VERIFIED = 2;
const DENIED = 3;

const getApplication = (exchCode: string, userApplications: Array<any> = []) => userApplications?.find((ua) => ua.exchCode === exchCode);

const getApplicationStatus = (applicationsStatuses: Array<any> = [], statusCode: number = -1) => applicationsStatuses?.find((a) => a.id === statusCode);

const getUserApplication= (exchCode: string, userApplications: Array<any> = [], applicationsStatuses: Array<any> = []): UserApplication => {
  const userApplication = getApplication(exchCode, userApplications);  
  const applicationStatus = getApplicationStatus(applicationsStatuses, userApplication?.status);

  return {
    userId: userApplication?.userId || null,
    exchId: userApplication?.exchId || null,
    exchCode: userApplication?.exchCode || ``,
    isEligible: !!userApplication?.eligible,
    isAccountClosed: !!userApplication?.accountClosed,
    isAgreementSigned: !!userApplication?.agreementDateSigned,
    isAwaiting: applicationStatus?.id === AWAITING_APPLICATION,
    isPending: applicationStatus?.id === PENDING,
    isVerified: applicationStatus?.id === VERIFIED,
    isDenied: applicationStatus?.id === DENIED,
  };
};

export { getUserApplication };
