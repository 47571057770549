// @flow
'use strict';

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { translate } from 'react-i18next';

import Modal from '../utilities/Modal.jsx';
import type { User } from '../../types/User.js';
import { readCookie, writeCookie } from '../../helpers/CookieHelper.js';
import LogoLightspeedCrypto from '../../svgs/LogoLightspeedCrypto.jsx';

type Props = {
  t: any,
  user: User,
  isEligible: boolean,
  isVerified: boolean,
};

const COOKIE = `expired_access_modal`;

const RenderModalContent = ({ props }) => {
  return <>
    <div>
      <LogoLightspeedCrypto />
    </div>

    <p>
      { props.t(`mainContent`) }
    </p>

    <p className="footer">
      { props.t(`bottomInfo`) }
    </p>

    
  </>;
};

const ExpiredAccessModal = (props: Props) => {
  const DAYS_CONDITION = 25;
  const currentDate = moment(new Date()); 
  const userCreatedDate = moment(props.user.createdOn * 1000); 
  const duration = moment.duration(currentDate.diff(userCreatedDate));
  const daysDiff = duration.asDays();
  const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
  
  const isDaysDiff = daysDiff > DAYS_CONDITION;
  
  useEffect(() => {
    if(readCookie(COOKIE).length == 0 && props.user.id !== 0 && isDaysDiff) {
      setIsModalOpen(true); 
    }  
  }, []);
  
  return props.isEligible && !props.isVerified && isModalOpen && (
    <div className={ `expired-access-modal` }>
      <Modal  
        title={ `` }  
        onClose={ () => {
          writeCookie(COOKIE, true);  
          setIsModalOpen(false); } } >  
        <RenderModalContent { ...{ props } }  />
      </Modal>            
    </div>
  );
};

export default translate(`expiredAccessModal`)(ExpiredAccessModal);
