// @flow
'use strict';

import React, { useState } from 'react';

type Props = {
  content: any,
  position?: string,
  children?: any
};

const Hint = (props: Props) => {
  const [hovering, setHovering] = useState<boolean>(false);
  const handleHoverIn = () => setHovering(true);
  const handleHoverOut = () => setHovering(false);

  return (
    <div className="hint-wrapper" onMouseOver={ () => handleHoverIn() } onMouseOut={ () => handleHoverOut() }>
      { props.children }
      {
        hovering ? (
          <div className={ `hint-popover ${ props.position ? `positioned-${ props.position }` : `` }` }>
            { props.content }
          </div>
        ) : ``
      }
    </div>
  );
  
};

export default Hint;
