// @flow
'use strict';

import React, { useState } from 'react';
import Button from '../utilities/Button.jsx';
import Modal from '../utilities/Modal.jsx';
import TextField from '../utilities/TextField.jsx';
import type { User } from '../../types/User.js';
import ChangePasswordModal from './ChangePasswordModal.jsx';
export type UserProfileProps = {
  t: any,
  user?: User,
  onChange?: (key: string, value: any) => void,
  save?: () => boolean
};

const UserProfile = (props: UserProfileProps) => {
  const { t, user, onChange, save } = props;
  const [ isEditModalOpen, setIsEditModalOpen ] = useState(false);
  const [ isChangePasswordModalOpen, setIsChangePasswordModalOpen ] = useState(false);
  
  const isLscxUser = user?.platformId === 1;

  const handleInputChange = (prop: string, key: string, value: string) => {    
    onChange && onChange(prop, { [key]: value });
  };

  const RenderLSCXSupportLink = () => {
    const lsLink = `https://lightspeedcrypto.com/en/support/`;
  
    return (
      <p className="support-link">
        <a 
          href={ lsLink } 
          target="_blank" 
          rel="noopener noreferrer">
          { t(`user:contactSupport`) }
        </a>
      </p>
    );
    
  };

  if (!user) return null;

  return (
    <>
      <div className="billing-info">
        <h2>
          { isLscxUser ? t(`Personal Information`) : t(`billingInformation`) }
        </h2>
        { user &&
          <div>
            <div>
              <div style={ { fontWeight: `bold` } }>
                { `${[user.firstName, user.lastName].join(` `)}` }
              </div>
              <div>
                { `${user.company}` }
              </div>

              <div>
                { `${user.email}` }
              </div>

              <div>
                { `${user.phone}` }
              </div>

              { (user.street1 || user.street2 || user.city || user.state || user.zip || user.country) &&
              <div className="address">
                <span>
                  { `${t(`Address`)}` }
                </span>

                <div>
                  { `${[user.street1, user.street2].join(` `)}` }
                </div>

                <div>
                  { `${[user.city, user.state].filter((s) => s).join(`, `)} ${user.zip}` }
                </div>

                <div>
                  { `${user.country}` }
                </div>
              </div>
              }
            </div>

            <div>
              <span className="button-container">
                <Button
                  type="primary"
                  onClick={ () => { setIsEditModalOpen(true); return false; } }>
                  { t(`app:edit`) }
                </Button>

                <Button 
                  type="secondary"
                  onClick={ () => { setIsChangePasswordModalOpen(true); return false; } }>
                  { t(`changePassword`) }
                </Button>              
              </span>
            </div>
          </div>
        }
      </div>

      {
        isEditModalOpen && (
          <Modal
            title={ isLscxUser ? t(`Personal Information`) : t(`billingInformation`) }
            onClose={ () => setIsEditModalOpen(false) }
            onConfirm={ () => { 
              if (save) {
                save();
                setIsEditModalOpen(false);
              } 
            } }
            confirmText={ t(`saveChanges`) }>
            <div className="group-input">
              {
                [
                  {
                    label: t(`firstName`),
                    name: `first-name`,
                    value: user.firstName || ``,
                    key: `firstName`,
                    disabled: false
                  },
                  {
                    label: t(`lastName`),
                    name: `last-name`,
                    value: user.lastName || ``,
                    key: `lastName`,
                    disabled: false
                  }, {
                    label: t(`companyName`),
                    name: `company`,
                    value: user.company || ``,
                    key: `company`,
                    disabled: false
                  }, {
                    label: t(`email`),
                    name: `email`,
                    value: user.email || ``,
                    key: `email`,
                    disabled: true
                  }, {
                    label: t(`phone`),
                    name: `phone`,
                    value: user.phone || ``,
                    key: `phone`,
                    disabled: false
                  }, {
                    label: t(`street`),
                    name: `street`,
                    value: user.street1 || ``,
                    key: `street1`,
                    disabled: false
                  }, {
                    label: t(`street2`),
                    name: `apartment`,
                    value: user.street2 || ``,
                    key: `street2`,
                    disabled: false
                  }, {
                    label: t(`city`),
                    name: `city`,
                    value: user.city || ``,
                    key: `city`,
                    disabled: false
                  }, {
                    label: t(`state`),
                    name: `state`,
                    value: user.state || ``,
                    key: `state`,
                    disabled: false
                  }, {
                    label: t(`zip`),
                    name: `zip`,
                    value: user.zip || ``,
                    key: `zip`,
                    disabled: false
                  }, {
                    label: t(`country`),
                    name: `country`,
                    value: user.country || ``,
                    key: `country`,
                    disabled: false
                  },
                ].map((props, i) => {
                  return <div key={ i }>
                    <TextField 
                      { ...props } 
                      disabled={ props.disabled }
                      onChange={ (e) => handleInputChange(`user`, props.key, e.target.value) } />
                    { isLscxUser && props.disabled && <RenderLSCXSupportLink /> }
                  </div>;
                })
              }
            </div>
          </Modal>
        )
      }

      {
        isChangePasswordModalOpen && (
          <ChangePasswordModal onClose={ () => setIsChangePasswordModalOpen(false) }/>
        )
      }
    </>
  );
};


export default UserProfile;
