// @flow
'use strict';

import React from 'react';
import Modal from '../../../utilities/Modal.jsx';

const CryptoAddressDeleteModal = (props: any) => {
  const { onConfirm, onClose, t, addressInfo, loading } = props;
  let content = `Are you sure you’d like to delete the following`;
  if(addressInfo.type === `crypto`) {
    content = `${content} ${addressInfo.currency} Address: '${addressInfo.label}'?`;
  } else {
    content = `${content} Bank Account: '${addressInfo.label}'?`;
  }
  return (
    <div>
      <Modal
        title={ t(`app:genericConfirm`) }
        onConfirm={ onConfirm }
        onClose={ onClose }
        confirmText={ t(`app:confirm`) }
        cancelText={ t(`app:cancel`) }
        confirmDisabled={ false } >
        <span>
          { content }
        </span>
        { loading && (<div>
          Loading...
        </div>) }
      </Modal>
    </div>
  );
};

export default CryptoAddressDeleteModal;
