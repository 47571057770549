// @flow
'use strict';

import { $ } from './api/ApiHelper.js';
import store from '../helpers/StoreHelper.js';
import { updateOnline } from '../actions/app/updateOnline.js';
import { setUserHash } from '../actions/userInfo/setUserHash.js';

const { socketCluster } = window,
  socket = socketCluster.create({
    hostname: process.env.SCC_URL || `ws.coinigy.com`,
    secure: true,
    port: 443
  });


socket.on(`error`, () => {
  store.dispatch(updateOnline(false));
});

socket.on(`connect`, () => {
  $({
    url: `/private/user/hash`,
    success: (data) => {
      user_hash = data.result;
      store.dispatch(setUserHash(user_hash));
      /* eslint-disable */
      // $FlowIgnore: suppressing this error
      socket.authenticate(/authToken\=([^$;]*)/gi.exec(document.cookie)[1] || `` , () => {
        authenticated = true;
        store.dispatch(updateOnline(true));
      });
      /* eslint-enable */
    }
  });
});

let channels = { }, user_hash, authenticated;

let subscribe = (subs: Array<string | { type: string, data?: any }>, fn: any, scope: string = `default`) => {
  if (!authenticated) return setTimeout(() => subscribe(subs, fn, scope), 500);
  if (!channels.hasOwnProperty(scope)) channels[scope] = { };

  subs.forEach((s) => {
    if (typeof s == `string`) {
      if (channels[scope][s]) return;

      channels[scope][s] = {
        type: `string`,
        name: s,
        channel: socket.subscribe(s)
      };
    } else if (s.type == `market` && s.data && s.data.length >= 2) {
      let mkt = `${ s.data[0] }:${ s.data[1] }`,
        data = [s.data[0], s.data[1], s.data[2]];

      if (channels[scope][mkt]) return;

      let trades_channel_name = `TRADE-${ data[0] }--${ data[1].replace(`/`, `--`) }`,
        orders_channel_name = `ORDER-${ data[0] }--${ data[1].replace(`/`, `--`) }`,
        blocks_channel_name = `BLOCK-${ data[1].split(`/`)[0] }`;

      if (data[2] && Array.isArray(data[2])) {
        channels[scope][mkt] = {
          type: `market`
        };

        if (data[2].indexOf(`trades`) > -1) {
          channels[scope][mkt].trades = socket.subscribe(trades_channel_name);
        }

        if (data[2].indexOf(`orders`) > -1) {
          channels[scope][mkt].orders = socket.subscribe(orders_channel_name);
        }

        if (data[2].indexOf(`blocks`) > -1) {
          channels[scope][mkt].blocks = socket.subscribe(blocks_channel_name);
        }
      } else {
        channels[scope][mkt] = {
          type: `market`,
          trades: socket.subscribe(trades_channel_name),
          orders: socket.subscribe(orders_channel_name),
          blocks: socket.subscribe(blocks_channel_name)
        };
      }
    } else if (s.type == `user`) {
      if (channels[scope][`user`]) return;

      channels[scope][`user`] = {
        type: `user`,
        channel: socket.subscribe(user_hash)
      };
    }
  });

  fn && fn(channels[scope]);
};

const subscribeToAggregatedMarkets = (marketsToSubscribe: Array<string | { type: string, data?: any }>, mainMarket: any, fn: any, scope: string = `default`) => {
  if (!authenticated) return setTimeout(() => subscribeToAggregatedMarkets(marketsToSubscribe, mainMarket, fn, scope), 500);
  if (!channels.hasOwnProperty(scope)) channels[scope] = { };

  marketsToSubscribe.forEach((market: any) => {
    if (market.type == `market` && market.data && market.data.length >= 2) {
      const mkt = `${ market.data[0] }:${ market.data[1] }`;
      const data = [market.data[0], market.data[1], market.data[2]];

      if (channels[scope][mkt]) return;

      const trades_channel_name = `TRADE-${ data[0] }--${ data[1].replace(`/`, `--`) }`;

      if (data[2] && Array.isArray(data[2])) {
        channels[scope][mkt] = {
          type: `market`
        };

        if (data[2].indexOf(`trades`) > -1) {
          channels[scope][mkt].trades = socket.subscribe(trades_channel_name);
        }

        if (data[2].indexOf(`orders`) > -1) {
          // channels[scope][mkt].orders = socket.subscribe(orders_channel_name);
        }
      } else {
        channels[scope][mkt] = {
          type: `market`,
          trades: socket.subscribe(trades_channel_name),
          //orders: socket.subscribe(orders_channel_name),
          //blocks: socket.subscribe(blocks_channel_name)
        };
      }
    } 
  });

  if (mainMarket && mainMarket.data && mainMarket.data.length >= 2) {
    const mkt = `${ mainMarket.data[0] }:${ mainMarket.data[1] }`;
    const data = [mainMarket.data[0], mainMarket.data[1], mainMarket.data[2]];

    const orders_channel_name = `ORDER-${ data[0] }--${ data[1].replace(`/`, `--`) }`;
    channels[scope][mkt] = {
      type: `market`
    };
    channels[scope][mkt].orders = socket.subscribe(orders_channel_name);
  }


  fn && fn(channels[scope]);
};

let unsubscribe = (subs?: Array<string | { type: string, data?: any }>, scope: string = `default`) => {
  if (!channels.hasOwnProperty(scope)) return;

  if (subs) {
    subs.forEach((s) => {
      if (typeof s == `string`) {
        socket.destroyChannel(s);
        delete channels[scope][s];
      } else if (s.type == `market` && s.data && s.data.length >= 2) {
        const mkt = `${ s.data[0] }:${ s.data[1] }`;
        const data = [s.data[0], s.data[1]];
        if (typeof channels[scope][mkt] !== `undefined`) {
          if (channels[scope][mkt].hasOwnProperty(`trades`) && channels[scope][mkt].trades.state !== channels[scope][mkt].trades.UNSUBSCRIBED) {
            socket.destroyChannel(`TRADE-${ data[0] }--${ data[1].replace(`/`, `--`) }`);
          }
          if (channels[scope][mkt].hasOwnProperty(`orders`)) {
            socket.destroyChannel(`ORDER-${ data[0] }--${ data[1].replace(`/`, `--`) }`);
          }
          if (channels[scope][mkt].hasOwnProperty(`blocks`) && channels[scope][mkt].blocks.state !== channels[scope][mkt].blocks.UNSUBSCRIBED) {
            socket.destroyChannel(`BLOCK-${ data[1].split(`/`)[0] }`);
          }

          delete channels[scope][mkt];
        }
      } else if (s.type == `user`) {
        socket.destroyChannel(user_hash);
        delete channels[scope][`user`];
      }
    });
  } else {
    Object.keys(channels[scope]).forEach((k) => {
      let v = channels[scope][k];

      if (v.type == `string`) {
        socket.destroyChannel(v.name);

        delete channels[scope][k];
      } else if (v.type == `market`) {
        let mkt = k.split(`:`);

        if (channels[scope][k].hasOwnProperty(`trades`)) {
          socket.destroyChannel(`TRADE-${ mkt[0] }--${ mkt[1].replace(`/`, `--`) }`);
        }

        if (channels[scope][k].hasOwnProperty(`orders`)) {
          socket.destroyChannel(`ORDER-${ mkt[0] }--${ mkt[1].replace(`/`, `--`) }`);
        }

        if (channels[scope][k].hasOwnProperty(`blocks`)) {
          socket.destroyChannel(`BLOCK-${ mkt[1].split(`/`)[0] }`);
        }

        delete channels[scope][k];
      } else if (v.type == `user`) {
        socket.destroyChannel(user_hash);

        delete channels[scope][`user`];
      }
    });
  }
};

export { subscribe, subscribeToAggregatedMarkets, unsubscribe };
