// @flow
`use strict`;

import { $ } from "./ApiHelper";

export function getTrackableCurrencies(): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/private/chains/trackable`,
      success: resolve,
      error: reject
    });
  });
}

export function getSingleCurrency(currCode: string): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/public/currencies/${currCode}`,
      success: resolve,
      error: reject
    });
  });
}

export function getCurrencyImages(): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/public/currencies/images`,
      success: resolve,
      error: reject
    });
  });
}

export function getAllFiatCurrencies(onSuccess: (data: any) => void) {
  $({
    url: `/public/currencies?IsFiat=true`,
    success: onSuccess,
  });
}

export function getSummariesCurrencies(options: any, onSuccess: (data: any) => void) {
  const { SearchTerm } = options.params;
  const qs = `${SearchTerm ? `?SearchTerm=`+SearchTerm:``}`;
  $({
    url: `/public/currencies/summaries${qs}`,
    success: onSuccess,
  });
}
