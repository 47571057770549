// @flow
'use strict';

import React, { useEffect, useState } from 'react';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { InView } from 'react-intersection-observer';
import MUIDataTable from "mui-datatables";
import ProgressSpinner from '../utilities/ProgressSpinner.jsx';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import Typography from '@mui/material/Typography';
import { toFixedDecimalsHTML } from '../../helpers/NumberHelper.js';
import ScreenerFilterList from './ScreenerFilterList.jsx';
import ScreenerFilterModal from './ScreenerFilterModal.jsx';
import ScreenerToolbar from './ScreenerToolbar.jsx';
import ScreenerExpandableRow from './ScreenerExpandableRow.jsx';
import ScreenerSearch from './ScreenerSearch.jsx';


const PAGE_SIZES = [10, 25, 50, 100, 250];
const SORT_ASC = 0;
const SORT_DESC = 1;
export type Props = {
  t: any,
  markets: Array<any>, 
  marketsBalances: Array<any>, 
  marketsCurrencies: Array<any>, 
  totalRecords: number,
  range: number, 
  pageNumber: number,
  setPageNumber: any,
  pageSize: any,
  searchTerm: string,
  setSearchTerm: any,
  sort: number,
  sortOrder: number,
  myPositions: boolean,
  isLoading: boolean,
  isMarketsCurrenciesLoading: boolean,
  hideColumns: Array<string>, 
  changeSettings: (obj: any) => void,
  changeMarketSubscription: (market: string, isSubscribed: boolean) => void,
  miningAlgorithm: string,
  miningType: string,
  exchangeTags: string,
  exchangeTagsOptions: Array<any>,
  rsi7Min: any,
  rsi14Min: any,
  rsi7Hour: any,
  rsi14Hour: any,
  rsi7Day: any,
  rsi14Day: any,
  ignoreDead: boolean,
  percentBuys: any,
  activityScore: any,
  isFullAccess: boolean
}

const ScreenerTable = (props: Props) => { 
  const { t, markets, marketsBalances, marketsCurrencies, totalRecords, range, pageNumber, setPageNumber, pageSize, 
    searchTerm, setSearchTerm, sort, sortOrder, myPositions, isLoading, isMarketsCurrenciesLoading, hideColumns, changeSettings, 
    changeMarketSubscription, miningAlgorithm, miningType, exchangeTags, exchangeTagsOptions,
    rsi7Min, rsi14Min, rsi7Hour, rsi14Hour, rsi7Day, rsi14Day, ignoreDead, percentBuys, activityScore, isFullAccess } = props;

  const [currentRowsExpanded, setCurrentRowsExpanded] = useState([]);
  const [search, setSearch] = useState(searchTerm);
  const [openFilterModal, setOpenFilterModal] = useState(false); 

  const columns = [
    {
      name: `market`,
      label: t(`app:market`),
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        viewColumns: false,
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: {
            whiteSpace: `nowrap`,
            position: `sticky`,
            left: 40,
            background: `var(--gray-1)`,
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: `nowrap`,
            position: `sticky`,
            left: 40,
            zIndex: 101,
          },
        }),        
      }
    },
    {
      name: `baseCurrencyName`,
      label: t(`screener:baseCurrency`),
      options: {
        filter: false,
        sort: false,
        display: !hideColumns.find((c) => c === `baseCurrencyName`),
      }
    },
    {
      name: `quoteCurrencyName`,
      label: t(`screener:quoteCurrency`),
      options: {
        filter: false,
        sort: false,
        display: !hideColumns.find((c) => c === `quoteCurrencyName`)
      }
    },    
    {
      name: `exchange`,
      label: t(`app:exchange`),
      options: {
        filter: false,
        sort: false,
        display: !hideColumns.find((c) => c === `exchange`)
      }
    },
    {
      name: `miningAlgorithm`,
      label: t(`screener:miningAlgorithm`),
      options: {
        filter: false,
        sort: false,
        display: !hideColumns.find((c) => c === `miningAlgorithm`)
      }
    },
    {
      name: `miningType`,
      label: t(`screener:miningType`),
      options: {
        filter: true,
        sort: false,
        display: !hideColumns.find((c) => c === `miningType`),
      }
    },        
    {
      name: `lastPrice`,
      label: t(`app:last`),
      options: {
        filter: false,
        sort: false,
        display: !hideColumns.find((c) => c === `lastPrice`),
        setCellHeaderProps: () => ({ className: `align-right` })
      }
    },
    {
      name: `chart`,
      label: t(`screener:chart`),
      options: {
        filter: false,
        sort: false,
        display: !hideColumns.find((c) => c === `chart`)
      }
    },    
    {
      name: `change`,
      label: t(`app:change`),
      options: {
        filter: false,
        sort: false,
        display: !hideColumns.find((c) => c === `change`),
        setCellHeaderProps: () => ({ className: `align-right` })    
      }
    },
    {
      name: `percentChange`,
      label: `% ${t(`app:change`)}`,
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        sortThirdClickReset: true,
        display: !hideColumns.find((c) => c === `percentChange`),
        setCellHeaderProps: () => ({ className: `align-right` })
      }
    },
    {
      name: `high`,
      label: t(`app:high`),
      options: {
        filter: false,
        sort: false,
        display: !hideColumns.find((c) => c === `high`),
        setCellHeaderProps: () => ({ className: `align-right` })
      }
    },
    {
      name: `low`,
      label: t(`app:low`),
      options: {
        filter: false,
        sort: false,
        display: !hideColumns.find((c) => c === `low`),
        setCellHeaderProps: () => ({ className: `align-right` })
      }
    },    
    {
      name: `volume`,
      label: `24H ${t(`screener:volume`)} (BTC)`,
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        sortThirdClickReset: true,
        display: !hideColumns.find((c) => c === `volume`),
        setCellHeaderProps: () => ({ className: `align-right` })
      }
    },
    {
      name: `marketCap`,
      label: `${t(`markets:marketCap`)} (USD)`,
      options: {
        filter: false,
        sort: false,
        display: !hideColumns.find((c) => c === `marketCap`),
        setCellHeaderProps: () => ({ className: `align-right` })
      }
    },
    {
      name: `percentBuys`,
      label: `% ${t(`screener:buys`)}`,
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        sortThirdClickReset: true,
        display: !hideColumns.find((c) => c === `percentBuys`),
        setCellHeaderProps: () => ({ className: `align-right` })
      }
    },
    {
      name: `activityScore`,
      label: `${t(`screener:activityScore`)}`,
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        sortThirdClickReset: true,
        display: !hideColumns.find((c) => c === `activityScore`),
        setCellHeaderProps: () => ({ className: `align-right` })
      }
    },    
    {
      name: `rsi7Min`,
      label: `RSI (7, Min)`,
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        sortThirdClickReset: true,        
        display: !hideColumns.find((c) => c === `rsi7Min`),
        setCellHeaderProps: () => ({ className: `align-right` })
      }
    },
    {
      name: `rsi14Min`,
      label: `RSI (14, Min)`,
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        sortThirdClickReset: true,        
        display: !hideColumns.find((c) => c === `rsi14Min`),
        setCellHeaderProps: () => ({ className: `align-right` })
      }
    },
    {
      name: `rsi7Hour`,
      label: `RSI (7, Hour)`,
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        sortThirdClickReset: true,        
        display: !hideColumns.find((c) => c === `rsi7Hour`),
        setCellHeaderProps: () => ({ className: `align-right` })
      }
    },
    {
      name: `rsi14Hour`,
      label: `RSI (14, Hour)`,
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        sortThirdClickReset: true,        
        display: !hideColumns.find((c) => c === `rsi14Hour`),
        setCellHeaderProps: () => ({ className: `align-right` })
      }
    },
    {
      name: `rsi7Day`,
      label: `RSI (7, Day)`,
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        sortThirdClickReset: true,        
        display: !hideColumns.find((c) => c === `rsi7Day`),
        setCellHeaderProps: () => ({ className: `align-right` })
      }
    },
    {
      name: `rsi14Day`,
      label: `RSI (14, Day)`,
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        sortThirdClickReset: true,        
        display: !hideColumns.find((c) => c === `rsi14Day`),
        setCellHeaderProps: () => ({ className: `align-right` })
      }
    },    
    {
      name: `position`,
      label: t(`screener:position`),
      options: {
        filter: false,
        sort: false,
        display: !hideColumns.find((c) => c === `position`),
        setCellHeaderProps: () => ({ className: `align-right` })
      }
    },
  ];

  const sortOptions = {
    'volume': {
      name: `Volume`,
      value: 0,
    },
    'market': {
      name: `Alphabetical`,
      value: 2,
    },
    'percentChange': {
      name: `PercentChange`,
      value: 3,
    },
    'rsi7Min': {
      name: `Rsi7_Min`,
      value: 4,
    },
    'rsi14Min': {
      name: `Rsi14_Min`,
      value: 5,
    },
    'rsi7Hour': {
      name: `Rsi7_Hour`,
      value: 6,
    },
    'rsi14Hour': {
      name: `Rsi14_Hour`,
      value: 7,
    },
    'rsi7Day': {
      name: `Rsi7_Day`,
      value: 8,
    },
    'rsi14Day': {
      name: `Rsi14_Day`,
      value: 9,
    },
    'percentBuys': {
      name: `percentBuys`,
      value: 10,
    },
    'activityScore': {
      name: `activityScore`,
      value: 11,
    },
  };

  const getTradeMarketUrl = (marketDisplayName, exchCode) => `/markets/${ exchCode }/${ marketDisplayName }`;

  const customViewColumnIcon = () => (
    <span className='view-column-icon'>
      <ViewColumnIcon/>
      { t(`screener:editColumns`) }
    </span>
  );

  const reset = () => {
    setCurrentRowsExpanded([]);
    setPageNumber(0);
  };

  const data = markets?.map((mkt) =>{
    const changePrice = mkt.miniChartData ? mkt.miniChartData[mkt.miniChartData.length -1]?.price - mkt.miniChartData[0]?.price : 0;

    const percentChange = mkt.miniChartData && mkt.miniChartData[0]?.price > 0 ? (changePrice * 100 / mkt.miniChartData[0]?.price) : 0;

    const position = marketsBalances
      .filter((bln) => bln.marketId === mkt.marketId && bln.exchId === mkt.exchId)
      .reduce((accum, balance) => accum + balance.balanceAmountTotal, 0);

    const baseCurrencyCode = mkt.displayBaseCurrencyCode ?? mkt.baseCurrencyCode;
    const quoteCurrencyCode = mkt.displayQuoteCurrencyCode ?? mkt.quoteCurrencyCode;

    const currency = marketsCurrencies?.find((curr) => curr.currCode === baseCurrencyCode);

    const isTwoDecimals = mkt.pricePrecision === 2 ? `two_decimals` : ``;

    const rsi7Min = mkt.indicators?.rsi?.find((r) => r.period === 7 && r.interval === 0);
    const rsi14Min = mkt.indicators?.rsi?.find((r) => r.period === 14 && r.interval === 0);
    const rsi7Hour = mkt.indicators?.rsi?.find((r) => r.period === 7 && r.interval === 1);
    const rsi14Hour = mkt.indicators?.rsi?.find((r) => r.period === 14 && r.interval === 1);
    const rsi7Day = mkt.indicators?.rsi?.find((r) => r.period === 7 && r.interval === 2);
    const rsi14Day = mkt.indicators?.rsi?.find((r) => r.period === 14 && r.interval === 2);

    return {
      ...mkt,
      market:
        <InView as="div" onChange={ (inView, entry) => changeMarketSubscription(entry.target.id, inView) } id={ `${ mkt.exchCode }#${ mkt.marketName }` }>
          <span className='mui-cell-span'>
            <span className='currencies-section'>
              <img
                src={ `${ window.WWW_URL }/assets/img/currency/${ quoteCurrencyCode }.png` } 
                width={ 20 }
                height={ 20 }
                onError={  (e)=>{e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } />
              <img
                src={ `${ window.WWW_URL }/assets/img/currency/${ baseCurrencyCode }.png` }
                className='base-curr-logo'
                width={ 30 } 
                height={ 30 }        
                onError={ (e) => {e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } />
            </span>
            { mkt.displayName }
          </span>
        </InView>,
      exchange:
        <span className='mui-cell-span'>
          <img 
            width={ 20 }              
            src={ `${ window.WWW_URL }/assets/img/exchange/${ mkt.exchCode }-icon.png` }
            onError={ (e) => {e.target.onerror = null; e.target.src=`${ window.WWW_URL }/assets/img/currency/empty.png`;} } /> 
          { mkt.exchName } 
        </span>,
      baseCurrencyName: 
        <span className='mui-cell-span'>
          { mkt.displayBaseCurrencyName ?? mkt.baseCurrencyName }
        </span>,
      quoteCurrencyName: 
        <span className='mui-cell-span'>
          { mkt.displayQuoteCurrencyName ?? mkt.quoteCurrencyName }
        </span>,
      miningAlgorithm: 
      <div className={ (columns.length - hideColumns.length) > 7 ?  `max-width-200` : `` }>
        { mkt.miningAlgorithm?.indexOf(`http`) >= 0 ? 
          <a
            href={ mkt.miningAlgorithm } target="_blank" rel="nofollow noopener noreferrer">
            { mkt.miningAlgorithm }
          </a> 
          : 
          mkt.miningAlgorithm 
        }
      </div>,
      lastPrice:
        <div 
          className={ `nowrap align-right` }
          dangerouslySetInnerHTML={
            toFixedDecimalsHTML(mkt.lastTradePrice || 0, false, isTwoDecimals)
          }/>,
      chart: 
        <span className='mui-cell-span'>
          <Sparklines
            svgWidth={ 50 }
            svgHeight={ 20 }
            data={ mkt.miniChartData? mkt.miniChartData.reduce((accum, candle) => [...accum, candle.price], []) : [1, 1] }
            style={ { marginRight: `1rem` } }>
            <SparklinesLine
              style={ { strokeWidth: 6, fill: `none` } }
              color={ changePrice >= 0 ? `var(--green-)` : `var(--red-)` }/>
          </Sparklines>
        </span>,      
      change: 
        <div 
          className={ `nowrap align-right ${changePrice >= 0 ? `green` : `red` }` }
          dangerouslySetInnerHTML={
            toFixedDecimalsHTML(changePrice, false, isTwoDecimals)
          }/>,
      percentChange:
        <div
          className={ `nowrap align-right ${changePrice >= 0 ? `green` : `red` }` }
          dangerouslySetInnerHTML={
            toFixedDecimalsHTML(percentChange, false,`two_decimals`, undefined, false, false, `%`)
          }/>,
      high:
        <div
          className={ `nowrap align-right` }
          dangerouslySetInnerHTML={
            toFixedDecimalsHTML(mkt.high, false, isTwoDecimals)
          }/>,
      low:
        <div
          className={ `nowrap align-right` }
          dangerouslySetInnerHTML={
            toFixedDecimalsHTML(mkt.low, false, isTwoDecimals)
          }/>,
      volume:
        <div 
          className={ `nowrap align-right` }
          dangerouslySetInnerHTML={
            toFixedDecimalsHTML(mkt.volume24Btc, false,`two_decimals`)
          }/>,
      marketCap:
          isMarketsCurrenciesLoading ? 
            <div className={ `nowrap align-center` }>
              <ProgressSpinner className={ `market-cap-spinner` }/>
            </div> :  
            <div 
              className={ `nowrap align-right` }
              // $FlowIgnore: suppressing this error
              dangerouslySetInnerHTML={ toFixedDecimalsHTML(currency?.marketCapUsd || 0, false,`two_decimals`)  }/>,
      percentBuys:
        <div
          className={ `nowrap align-right` }
          dangerouslySetInnerHTML={
            toFixedDecimalsHTML(mkt.percentBuys || 0, false,`two_decimals`, undefined, false, false, `%`)
          }/>,
      activityScore:
        <div 
          className={ `nowrap align-right` }          
          dangerouslySetInnerHTML={
            toFixedDecimalsHTML(mkt.activityScore || 0, false,`two_decimals`)
          }/>,
      rsi7Min:
        rsi7Min?.value !== undefined ?
          <div
            className={ `nowrap align-right` }
            dangerouslySetInnerHTML={
              toFixedDecimalsHTML(rsi7Min.value, false,`two_decimals`)
            }/>
          :
          <div className={ `nowrap align-right` }>
            { `N/A` }
          </div>,
      rsi14Min: 
        rsi14Min?.value !== undefined  ?
          <div
            className={ `nowrap align-right` }
            dangerouslySetInnerHTML={
              toFixedDecimalsHTML(rsi14Min.value, false,`two_decimals`)
            }/>
          :
          <div className={ `nowrap align-right` }>
            { `N/A` }
          </div>,
      rsi7Hour: 
      rsi7Hour?.value !== undefined ?
        <div
          className={ `nowrap align-right` }
          dangerouslySetInnerHTML={
            toFixedDecimalsHTML(rsi7Hour.value, false,`two_decimals`)
          }/>
        :
        <div className={ `nowrap align-right` }>
          { `N/A` }
        </div>,
      rsi14Hour: 
        rsi14Hour?.value !== undefined  ?
          <div
            className={ `nowrap align-right` }
            dangerouslySetInnerHTML={
              toFixedDecimalsHTML(rsi14Hour.value, false,`two_decimals`)
            }/>
          :
          <div className={ `nowrap align-right` }>
            { `N/A` }
          </div>,
      rsi7Day:
        rsi7Day?.value !== undefined  ?
          <div
            className={ `nowrap align-right` }
            dangerouslySetInnerHTML={
              toFixedDecimalsHTML(rsi7Day.value, false,`two_decimals`)
            }/>
          :
          <div className={ `nowrap align-right` }>
            { `N/A` }
          </div>,
      rsi14Day: 
        rsi14Day?.value !== undefined  ?
          <div
            className={ `nowrap align-right` }
            dangerouslySetInnerHTML={
              toFixedDecimalsHTML(rsi14Day.value, false,`two_decimals`)
            }/>
          :
          <div className={ `nowrap align-right` }>
            { `N/A` }
          </div>,   
      position: 
        <div 
          className={ `nowrap align-right` }
          dangerouslySetInnerHTML={
            toFixedDecimalsHTML(position, false, isTwoDecimals)
          }/>
    };
  });
  
  const options = {
    tableId: `mui-datatables-screener`,
    responsive: `standard`,
    download: false,
    filter: false,
    print: false,
    selectableRows: `none`,
    serverSide: true,
    rowsPerPageOptions: PAGE_SIZES,
    onRowClick: (rowData, rowState) => {
      if (isFullAccess) {
        // link to the correct market on the markets page
        window.open(getTradeMarketUrl(data[rowState.rowIndex].displayName, data[rowState.rowIndex].exchCode), `_blank`);
      }
    },
    pagination: isFullAccess,
    page: pageNumber,
    rowsPerPage: parseInt(pageSize),
    count: totalRecords,
    searchText: search,
    search: false,
    searchAlwaysOpen: true,
    searchOpen: true,
    sort: isFullAccess,
    sortOrder: {
      name: Object.keys(sortOptions).find((s) => sortOptions[s].value === sort) || `Favorites`,
      direction: sortOrder === SORT_ASC ? `asc` : `desc`,
    },
    viewColumns: isFullAccess,
    onViewColumnsChange: (changedColumn: string, action: string) => {
      if (action === `add`) changeSettings({ 'hideColumns': hideColumns.filter((c) => c !== changedColumn) });
      if (action === `remove`) changeSettings({ 'hideColumns': [...hideColumns, changedColumn] });
    },
    expandableRowsHeader: false,
    expandableRows: true,
    rowsExpanded: currentRowsExpanded,
    isRowExpandable: (idx) => {
      const currentMkt = data[idx];
      return marketsBalances.some((bln) => currentMkt.accounts?.includes(bln.authId) && bln.marketId === currentMkt.marketId && bln.exchId === currentMkt.exchId);
    },
    onRowExpansionChange: (rowData, rowsExpanded) => setCurrentRowsExpanded(rowsExpanded.reduce((accum, rowsExpanded) => [...accum, rowsExpanded.index], [])),
    renderExpandableRow: (rowData, rowMeta) => <ScreenerExpandableRow 
      columns={ columns }
      currentMkt={ data[rowMeta.rowIndex] }
      hideColumns={ hideColumns }
      marketsBalances={ marketsBalances }/>,
    customToolbar: () => isFullAccess && <ScreenerToolbar 
      t={ t }  
      changeSettings={ changeSettings }
      range={ range }
      reset={ reset }
      toggleFilterModal={ setOpenFilterModal } />,
    customSearchRender: (searchText: string, handleSearch, hideSearch, options) => isFullAccess ? 
      <ScreenerSearch 
        t={ t }
        handleSearch={ handleSearch }
        options={ options }
        searchText={ searchText }/> 
      :
      <div className='mui-custom-title'>
        <Typography variant="h6">
          { t(`screener:title`) }
        </Typography>
      </div>,
    onTableChange: (action, tableState) => {
      const isNoneOrder = tableState?.sortOrder?.direction === `none`;

      switch (action) {
      case `changePage`:
        setCurrentRowsExpanded([]);
        setPageNumber(tableState.page);
        break;
      case `changeRowsPerPage`:
        reset();
        changeSettings({ pageSize: tableState.rowsPerPage });
        break;
      case `sort`:
        reset();

        changeSettings({
          sort: isNoneOrder ? 1 : sortOptions[tableState.sortOrder.name].value,
          sortOrder: isNoneOrder ? SORT_DESC : (tableState.sortOrder.direction === `asc` ? SORT_ASC : SORT_DESC) 
        });
        break;
      case `search`:
        if ((tableState.searchText || ``) !== search) {
          setSearch(tableState.searchText || ``);
        }
        break;
      }
    },
    textLabels: {
      body: {
        noMatch: isLoading ? <ProgressSpinner /> :  t(`app:noData`),
      },
      pagination: {
        next: t(`app:next`),
        previous: t(`app:previous`),
        rowsPerPage: t(`app:rows`),
        displayRows: t(`app:of`),
      },
      toolbar: {
        search: t(`app:search`),
        viewColumns: t(`screener:editColumns`),
      },
      viewColumns: {
        title: t(`screener:editColumns`),
      },
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (search.length === 0 || search.length > 1) {
        reset();
        setSearchTerm(search);
      }
    }, 400);

    return () => {
      clearTimeout(timeout);
    };

  }, [search]);

  return (
    <section className="fade-in">
      <MUIDataTable
        data={ data }
        columns={ columns }
        options={ options }
        components={ 
          { 
            icons: { ViewColumnIcon: customViewColumnIcon },
            TableFilterList: (props) => (
              ScreenerFilterList({ ...props, t, myPositions, miningAlgorithm, miningType, exchangeTags, rsi7Min, rsi14Min, rsi7Hour, rsi14Hour, rsi7Day, rsi14Day,
                ignoreDead,percentBuys, activityScore, changeSettings, reset })
            ),
          }
        }/>

      { openFilterModal && 
        <ScreenerFilterModal 
          close={ () => setOpenFilterModal(false) }
          changeSettings={ changeSettings }
          reset={ reset }
          myPositions={ myPositions }
          miningAlgorithm={ miningAlgorithm }
          miningType={ miningType }
          exchangeTags={ exchangeTags }
          exchangeTagsOptions={ exchangeTagsOptions }
          rsi7Min={ rsi7Min }
          rsi14Min={ rsi14Min }
          rsi7Hour={ rsi7Hour }
          rsi14Hour={ rsi14Hour }
          rsi7Day={ rsi7Day }
          rsi14Day={ rsi14Day }
          ignoreDead={ ignoreDead } 
          percentBuys={ percentBuys }
          activityScore={ activityScore }/>
      }

    </section>
  );
};
export default ScreenerTable;
