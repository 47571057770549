import React, { useState } from 'react';
import Modal from '../../../utilities/Modal.jsx';
import Button from '../../../utilities/Button.jsx';
import SelectField from '../../../utilities/SelectField.jsx';
import TextField from '../../../utilities/TextField.jsx';
import PillToggle from '../../../utilities/PillToggle.jsx';
import { postFundTransfer } from '../../../../helpers/api/UserApi.js';

export const NewBankAccountModal = ({ close, t, currCode, setAccountTransfers }) => {
  const [errors, setErrors ] = useState(``);
  const [loading, setLoading ] = useState(false);
  const [routingNumber, setRoutingNumber] = useState(``);
  const [accountNumber, setAccountNumber] = useState(``);
  const [bankAccountName, setBankAccountName ] = useState(``);
  const [accountNickname, setAccountNickname ] = useState(``);
  const [accountType, setAccountType] = useState(0);
  const [fundTransferType, setFundTransferType] = useState(1);

  const createNewBankAccount = () => {
    setLoading(true);
    setErrors();
    const accountTypeParam = accountType === 0 ? `checking` : `savings`;
    const fundTransferTypeParam = fundTransferType === 0 ? `ach` : `wire`;
    const body = {
      bankAccountName: bankAccountName,
      routingNumber: routingNumber,
      bankAccountType: accountTypeParam,
      bankAccountNumber: accountNumber,
      fundsTransferType: fundTransferTypeParam,
      currCode: currCode,
      last4: ``,
      achCheckType: `personal`,
      label: accountNickname
    };
    postFundTransfer(body, (data) => {
      if (data.success) {
        setLoading(false);
        setErrors();
        close(false);
        setAccountTransfers();
      } else {
        setLoading(false);
        setErrors(data.error);
      }
    }, () => {});
  };

  return (
    <div className="withdraw-modal">
      <Modal
        title={ `New Bank Account` }
        onClose={ () => close(false) }
        onConfirm={ true }>
        <div className="row">
          <span className="account-type-label">
            { t(`Funds transfer type`) }
          </span>
        </div>
        <div className="row">
          <PillToggle
            options={ [
              {
                value: 1,
                label: t(`Wire Transfer`)
              },
              { 
                value: 0,
                label: t(`ACH Transfer`)
              },
            ] }
            value={ fundTransferType }
            onChange={ (e, v) => setFundTransferType(v) } />
        </div>
        <div className="row">
          <TextField 
            label={ t(`Account Nickname`) }
            name={ `accountNickname` }
            value={ accountNickname }
            onChange={ (e) => setAccountNickname(e.target.value) }/>
        </div>
        <div className="row account-type">
          <SelectField
            label="Bank account type"
            options={ [
              {
                label: t(`Checking account`),
                value: 0
              },
              { 
                label: t(`Savings account`),
                value: 1
              },
            ] }
            value={ accountType }
            onChange={ (e, v) => setAccountType(v) } />
        </div>
        <div className="row">
          <TextField 
            label={ t(`Name on Bank Account`) }
            name={ `bankAccountName` }
            value={ bankAccountName }
            onChange={ (e) => setBankAccountName(e.target.value) }/>
        </div>
        <div className="row">
          <TextField 
            label={ t(`My Bank's Routing number`) }
            name={ `routingNumber` }
            value={ routingNumber }
            onChange={ (e) => setRoutingNumber(e.target.value) }/>
        </div>
        <div className="row">
          <TextField 
            label={ t(`My Account Number`) }
            name={ `accountNumber` }
            value={ accountNumber }
            onChange={ (e) => setAccountNumber(e.target.value) }/>
        </div>      
        {
          loading &&
            <div className="row loading-message-row">
              { t(`app:loading`) }
            </div>
        }
        {
          errors &&
            <div className="row errors-message-row">
              { errors }
            </div>
        }
        <div className="row modal-footer">
          <Button type="primary" onClick={ () => createNewBankAccount() }>
            { t(`create`) }
          </Button>
          <Button type="secondary" onClick={ () => close(false)  }>
            { t(`cancel`) }
          </Button>
        </div>
      </Modal>
    </div>
  );
};
