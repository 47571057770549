// @flow
'use strict';

import React, { useState, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { translate } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import LSCXButton from '../components/markets/LSCXButton.jsx';
import type { Account } from "../types/Account.js";
import type { Exchange } from "../types/Exchange.js";
import type { UserApplication } from '../types/UserApplication.js';
import LogoMark from '../svgs/LogoMark.jsx';
import Markets from '../svgs/Markets.jsx';
import AlertBell from '../svgs/AlertBell.jsx';
import Portfolio from '../svgs/Portfolio.jsx';
import Boards from "../svgs/Boards.jsx";
import Accounts from "../svgs/Accounts.jsx";
import Orders from "../svgs/Orders.jsx";
import Screener from '../svgs/Screener.jsx';
import Wallet from '../svgs/Wallet.jsx';
import Menu from '../svgs/Menu.jsx';
import ArbMatrix from '../svgs/ArbMatrix.jsx';
import StandardPlatformMenu from '../components/header/StandardPlatformMenu.jsx';
import LSCXUserMenu from '../components/header/LSCXUserMenu.jsx';
import { 
  emitEvent, 
  START_TOUR,
  SHOW_MOTD_MODAL
} from '../helpers/EventHelper.js';
import { getUserApplication } from '../helpers/UserApplicationsHelper';
import ProBadge from '../components/utilities/ProBadge.jsx';
import type { Market } from "../types/Market.js";
import Ticker from './utilities/Ticker.jsx';

type Props = {
  t: any,
  size: string,
  accounts: Array<Account>,
  exchanges: Array<Exchange>,
  markets: Array<Market>,
  tickerPosition: string
};

const Header = (props: Props) => {
  const { t, size, accounts, exchanges, markets = [], tickerPosition } = props;
  const platformId = useSelector((state) => state.userInfo.user.platformId);
  const isLscxUser = (useSelector((state) => state.userInfo.user.platformId) === 1);
  const subscriptionInfo = useSelector((state) => state.userInfo.subscriptionInfo);
  const theme = useSelector((state) => state.redisPrefs.theme);
  const userApplications = useSelector((state) => state.userInfo.userApplications);
  const applicationsStatuses = useSelector((state) => state.userInfo.applicationsStatuses);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [helpMenuOpen, setHelpMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
  const isDevelopmentOrStaging: boolean = (process.env.NODE_ENV === `development` || !!process.env.STAGING);

  const userApplication: UserApplication = useMemo(() => getUserApplication(`LSCX`, userApplications, applicationsStatuses), [userApplications, applicationsStatuses]);

  const isTickerPositionHeader = tickerPosition === `header`;

  
  const toggleHelpMenu = () => {
    setProfileMenuOpen(false);
    setHelpMenuOpen(!helpMenuOpen);
    setMobileMenuOpen(false);
    setSettingsMenuOpen(false);
  };

  const toggleProfileMenu = () => {
    setProfileMenuOpen(!profileMenuOpen);
    setHelpMenuOpen(false);
    setMobileMenuOpen(false);
    setSettingsMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setProfileMenuOpen(false);
    setHelpMenuOpen(false);
    setSettingsMenuOpen(false);
    setMobileMenuOpen((menuOpen) => !menuOpen);
  };
  
  const toggleHamburgerMenu = () => {
    if (hamburgerMenuOpen) {
      setProfileMenuOpen(false);
      setHelpMenuOpen(false);
      setMobileMenuOpen(false);
      setSettingsMenuOpen(false);
    }
    setHamburgerMenuOpen((menuOpen) => !menuOpen);
  };

  const toggleSettingsMenu = () => {
    setProfileMenuOpen(false);
    setHelpMenuOpen(false);
    setMobileMenuOpen(false);
    setSettingsMenuOpen((menuOpen) => !menuOpen);
  };

  const closeDropdownMenus = () => {
    setProfileMenuOpen(false);
    setHelpMenuOpen(false);
    setMobileMenuOpen(false);
    setHamburgerMenuOpen(false);
    setSettingsMenuOpen(false);
  };

  const triggerPageTour = () => {
    emitEvent(START_TOUR, `markets`);
  };

  const triggerMOTDModal = () => {
    emitEvent(SHOW_MOTD_MODAL, `markets`);
  };

  const isSmall = (size: string) => {
    return [`md`, `sm`, `xs`].includes(size);
  };

  const getLSCXAuthId = () => {
    const LSCXExchange = exchanges.find((e) => e.exchCode === `LSCX`);
    if (!LSCXExchange) return ``;

    return accounts.find((a) => a.authExchId == LSCXExchange?.exchId)?.authId || ``;
  };

  const renderNavs = (navs: Array<{
    icon: any,
    text: string,
    route: string,
    disabled?: boolean,
    external?: boolean,
    className?: string,
    pro?: boolean
  }>): any => {
    return navs.map((item, i) => (
      <li key={ i }>
        {
          item.disabled ? (
            <a className="disabled" title="Coming Soon">
              { item.icon }
              <span>
                { item.text }
              </span>
            </a>
          ) : item.external ? (
            <a href={ item.route } target="_blank" rel="noopener noreferrer">
              { item.icon }
              <span>
                { item.text }
              </span>
            </a>
          ) : (
            <NavLink to={ item.route } className={ item.className ? item.className : `` } activeClassName="nav-selected">
              { item.icon }
              <span>
                { item.text }
                { item?.pro && !isSmall(size) && <ProBadge /> }
              </span>
            </NavLink>
          )
        }
      </li>
    ), []);
  };

  const leftNavs = (size: string) => {    
    const navs = [{
      icon: Markets,
      text: isSmall(size) ? `` : t(`markets`),
      route: `/markets`,
      className: `tour-markets-nav`
    }, {
      icon: Portfolio,
      text: isSmall(size) ? `` : t(`portfolio`),
      route: `/portfolio`
    }, {      
      icon: Orders,
      text: isSmall(size) ? `` : t(`orders`),
      route: `/orders`
    }, {
      icon: AlertBell,
      text: isSmall(size) ? `` : t(`alerts`),
      route: `/alerts`
    }, {
      icon: Accounts,
      text: isSmall(size) ? `` : t(`accounts`),
      route: `/accounts`
    }, {
      icon: Boards,
      text: isSmall(size) ? `` : t(`boards`),
      route: `/boards`
    }, {
      icon: Screener,
      text: isSmall(size) ? `` : t(`screener`),
      route: `/screener`,
      pro: true
    }, {
      icon: ArbMatrix,
      text: isSmall(size) ? `` : t(`arbMatrix`),
      route: `/arbmatrix`,
      pro: true
    }];

    if (userApplication.isEligible) {
      const walletNav = {
        icon: Wallet,
        text: isSmall(size) ? `` : t(`Wallet`),
        route: `/wallet/${getLSCXAuthId()}`
      };

      navs.splice(1, 0, walletNav);
    }
    
    return renderNavs(navs);
  };

  return (
    <header className={ `${size} fade-in` }>
      {
        helpMenuOpen || profileMenuOpen || mobileMenuOpen || settingsMenuOpen ? (
          <div className="dropdown-helper" data-testid={ `dropdown-helper` } onClick={ closeDropdownMenus }/>
        ) : ``
      }
      <nav>
        <Link to="/" className="logo-wrapper">
          { LogoMark[platformId ? platformId : 0] }
        </Link>
        <ul className="leftNav">
          { leftNavs(size) }
        </ul>
        { size === `xs` &&
          <ul className='hamburger-menu' onClick={ toggleHamburgerMenu }>
            { Menu }
          </ul>
        }
        <ul className={ `rightNav ${!hamburgerMenuOpen && size === `xs` ? `hide` : ``}` }>
          {
            userApplication.isEligible && (
              <LSCXButton 
                accounts={ accounts } 
                exchanges={ exchanges } 
                onClick={ closeDropdownMenus }/> 
            ) 
          }
          { // LSCX User should see a different Header-Right Menu
            isLscxUser ? 
              <LSCXUserMenu
                toggleHelpMenu={ toggleHelpMenu }
                size={ size }
                helpMenuOpen={ helpMenuOpen }
                profileMenuOpen={ profileMenuOpen }
                triggerPageTour={ triggerPageTour }
                toggleProfileMenu={ toggleProfileMenu }
                isDevelopmentOrStaging={ isDevelopmentOrStaging }
                closeDropdownMenus={ closeDropdownMenus }
                mobileMenuOpen={ mobileMenuOpen }
                toggleMobileMenu={ toggleMobileMenu }
                settingsMenuOpen={ settingsMenuOpen }
                toggleSettingsMenu={ toggleSettingsMenu }/>                 
              :
              <StandardPlatformMenu
                toggleHelpMenu={ toggleHelpMenu }
                size={ size }
                helpMenuOpen={ helpMenuOpen }
                profileMenuOpen={ profileMenuOpen }
                triggerPageTour={ triggerPageTour }
                toggleProfileMenu={ toggleProfileMenu }
                isDevelopmentOrStaging={ isDevelopmentOrStaging }
                closeDropdownMenus={ closeDropdownMenus }
                mobileMenuOpen={ mobileMenuOpen }
                toggleMobileMenu={ toggleMobileMenu }
                settingsMenuOpen={ settingsMenuOpen }
                toggleSettingsMenu={ toggleSettingsMenu }
                triggerMOTDModal={ triggerMOTDModal }/> 
          }
          { !isLscxUser && (
            subscriptionInfo && (subscriptionInfo.subscriptionStatus === `active` || subscriptionInfo.subscriptionStatus === `lifetime`) ?  
              subscriptionInfo.prefLifetime === true ? (
                <li className="subscription-status" onClick={ closeDropdownMenus }>
                  <Link to="/user/profile">
                    <div className={ `lifetime ${theme === `Light` ? ` light` : ``} ${hamburgerMenuOpen && size === `xs` ? `align-left` : ``}` }>
                    Lifetime
                    </div>
                  </Link>
                </li>
              ) : (
                <li className="subscription-status" onClick={ closeDropdownMenus }>
                  <Link to="/user/profile">
                    <div className={ `pro-trader ${hamburgerMenuOpen && size === `xs` ? `align-left` : ``}` }>
                    Pro
                    </div>
                  </Link>
                </li>
              )
              : (
                <li className="subscription-status" onClick={ closeDropdownMenus }>
                  <Link to="/user/profile">
                    <div className={ `trial ${hamburgerMenuOpen && size === `xs` ? `align-left` : ``}` }>
                      Trial
                    </div>
                  </Link>
                </li>
              ) 
          ) }            
        </ul>   
      </nav>
      { isTickerPositionHeader && <Ticker t={ t } markets={ markets } /> }
    </header>
  );
};

const mapStateToProps = (state) => ({
  tickerPosition: state.redisPrefs.tickerPosition
});

export { Header as PureHeader };
export default translate(`header`)(connect(mapStateToProps)(Header));
