'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path d="M3.11132 3.6298C4.31939 2.32143 6.06222 1.5 7.99998 1.5C11.6473 1.5 14.5 4.41015 14.5 8C14.5 11.5899 11.6473 14.5 7.99998 14.5C4.92276 14.5 2.33712 12.4285 1.604 9.625" fill="none" stroke="currentColor" stroke-opacity="1" stroke-width="1" stroke-linecap="round"/>
  <path d="M1 2.20711L1 5.45238C1 5.72852 1.22386 5.95238 1.5 5.95238L4.74527 5.95238C5.19073 5.95238 5.41381 5.41381 5.09883 5.09883L1.85355 1.85355C1.53857 1.53857 1 1.76166 1 2.20711Z" stroke="none" fill="currentColor" fill-opacity="1"/>
  <path d="M8 6V8L10 10" stroke="currentColor" fill="none" stroke-opacity="1" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>`,
  {
    width: `16px`,
    height: `16px`,
    viewBox: `0 0 16 16`
  }
);
