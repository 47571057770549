// @flow
'use strict';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Layout, Model, Actions } from 'flexlayout-react';
import Panel from './Panel.jsx';
import PanelModal from './PanelModal.jsx';
import DeletePanelModal from './DeletePanelModal.jsx';
import MarketColorPicker from '../markets/MarketColorPicker.jsx';
import { getBoardLayout, putBoardLayout } from "../../helpers/api/BoardsApi.js";
import { getComponentFromPanelTypeId, NOTES } from '../../helpers/PanelTypeHelper.js';
import { PANEL_ID_PREFIX, BOARD_ID_PREFIX, migrateBoardToFlexlayoutVersion } from '../../helpers/BoardHelper.js';
import { getMarketFromPanelSettings, getMarketPair } from '../../helpers/MarketPairHelper.js';
import type { Market } from '../../types/Market.js';
import type { Account } from '../../types/Account.js';
import type { Balance } from '../../types/Balance.js';
import type { Currency } from '../../types/Currency.js';
import type { Exchange } from '../../types/Exchange.js';
import type { PanelType } from '../../types/PanelType.js';
import type { Board as BoardEntity } from '../../types/Board.js';
import type { Panel as PanelEntity } from '../../types/Panel.js';
import NewPanel from '../../svgs/NewPanel.jsx';
import Plus from '../../svgs/Plus.jsx';
import SelectField from "../utilities/SelectField.jsx";
import Gear from "../../svgs/Gear.jsx";
import Edit from "../../svgs/Edit.jsx";
import Copy from '../../svgs/Copy.jsx';
import 'flexlayout-react/style/dark.css';

type Props = {
  locked: boolean,
  t: any,
  dataHasLoaded: boolean,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  accounts: Array<Account>,
  balances: Array<Balance>,
  currencies: Array<Currency>,
  board: BoardEntity,
  panels: Array<PanelEntity>,
  panelTypes: Array<PanelType>,
  newPanel: () => void,
  deletePanel: (id: number) => void,
  editPanelInline: (id: number) => void,
  cancelEditPanelInline: (id: number) => void,
  savePanelInline: (id: number, data: any) => void,
  canCreateNewPanel: boolean,
  modifyingPanelsInline: Array<number>,
  duplicatePanel: (panel: any, id: any) => void,
  editMarket: (panel: any) => void,
  panelData: any,
  setPanelMarket: (exchCode: string, mktName: string, activePanelEdit: any) => void,
  refreshBalance: (n: number, d: boolean) => Promise<any>,
  isNewPanelModalOpen: boolean,
  closeNewPanelModal: () => void,
  saveNewPanel: (body: any, id: any) => Promise<any>,
  isDeletePanelModalOpen: boolean,
  deletePanelId: (boardId: any, id: any) => Promise<any>,
  closeDeletePanelModal: () => void,
  mktColors: any
};

const Board = (props: Props) => {
  const { 
    board, panels, panelData, panelTypes, dataHasLoaded, t, markets, exchanges, deletePanel, editPanelInline, 
    savePanelInline, canCreateNewPanel, modifyingPanelsInline, newPanel, editMarket, duplicatePanel,
    setPanelMarket, currencies, accounts, balances, refreshBalance, cancelEditPanelInline,
    isNewPanelModalOpen, closeNewPanelModal,  saveNewPanel, isDeletePanelModalOpen, deletePanelId, closeDeletePanelModal,
    locked, mktColors
  } = props; 

  const [model, setModel] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [marketColorModalOpen, setMarketColorModalOpen] = useState(false);
  const [marketColorModalMarket, setMarketColorModalMarket] = useState(``);

  const layoutRef = useRef();

  const modelJson = {
    "global": {
      "tabEnableFloat": true,
      "tabEnableRename": false
    },
    "borders": [],
    "layout": {}
  };

  const getPanelId = (node) => {
    return parseInt(node.replace(PANEL_ID_PREFIX, ``));
  };

  // const getBoardId = (node) => {
  //   return parseInt(node.replace(BOARD_ID_PREFIX, ``));
  // };

  const onAction = (action) => {
    if (action.type === `FlexLayout_DeleteTab`) {
      const nodePanelId = getPanelId(action.data.node);
      deletePanel(nodePanelId);
    } else if (locked && [`FlexLayout_MoveNode`,`FlexLayout_AdjustSplit`].includes(action.type)) {
      return {
        ...action,
        data: {}
      };
    } else {
      return action;
    }
  };

  const onModelChange = (model) => {
    const id = setTimeout(() => {
      const jsonText = JSON.stringify(model.toJson().layout);
      putBoardLayout(board?.boardId, jsonText);
    }, 800);

    // $FlowIgnore: suppressing this error
    setTimeoutId(id);
  };

  const onDeletePanel = async () => {
    await deletePanelId().then((panelId) => 
      model?.doAction(Actions.deleteTab(`${PANEL_ID_PREFIX}${panelId}`))
    );
  };

  const dragAndDropPanel = (panel) => {
    const newNodeJson = {
      id: `${PANEL_ID_PREFIX}${panel?.panelId || `` }`,
      name: ``,
      config: {
        "panelId": `${PANEL_ID_PREFIX}${panel?.panelId || `` }`,
        "boardId": `${BOARD_ID_PREFIX}${panel?.boardId || `` }`,
        "typeId": panel?.typeId
      }
    };

    layoutRef?.current?.addTabToActiveTabSet(newNodeJson);


    // layoutRef?.current?.addTabWithDragAndDrop(
    //   `Add panel\n(Drag to location)`, 
    //   newNodeJson,
    //   (node) => {
    //     const nodeBoardId = getBoardId(newNodeJson.config.boardId);
    //     const nodePanelId = getPanelId(newNodeJson.config.panelId);
    //     if (!node) deletePanelId(nodeBoardId, nodePanelId);
    //   }
    // );
  };

  const addDragAndDropPanel = async (body: any, id: any = board.boardId) => {
    const data = await saveNewPanel(body, id);
    dragAndDropPanel(data);
  };

  const duplicateDragAndDropPanel = async (body: any, id: any = board.boardId) => {
    const data = await duplicatePanel(body, id);
    dragAndDropPanel(data);
  };

  const renderTabSet = (node, renderValues) => {
    const nodeJson = node.toJson();
    
    renderValues.stickyButtons.push(
      <div
        key="add-panel"
        title="Add panel"
        className="flexlayout__tab_toolbar_button add-panel"
        onClick={ () => {
          model?.doAction(Actions.setActiveTabset(nodeJson.id));
          newPanel();
        } }>
        <Plus />
      </div>
    );

    const getActions = () => {
      const actions = [
        {
          label: t(`app:duplicate`),
          icon: Copy,
          value: `duplicate`
        }
      ];

      const nodeConfig = nodeJson.children[nodeJson?.selected ?? 0]?.config;
      
      if (nodeConfig?.panelId) {
        const nodePanelId = getPanelId(nodeConfig.panelId);
        const panel = props.panels.find( (p) => p.panelId === nodePanelId && p.typeId === nodeConfig?.typeId);
        const { customOptions } = panelTypes.find((t) => t.typeId == panel?.typeId) || { customOptions: { } };
        const settings = JSON.parse(panel?.settingsJson || `{}`);
    
        if (Object.keys(customOptions).filter((c) => c !== `market` && c !== `exchange`).length) {
          actions.unshift({
            label: t(`app:edit`),
            icon: Edit(),
            value: `edit`
          });
        }
    
        if (settings.hasOwnProperty(`market`) && settings.hasOwnProperty(`exchange`) || panel?.typeId === NOTES) {
          actions.unshift({
            label: `Edit Market`,
            icon: Edit(),
            value: `editMarket`
          });
        }
      }

      return actions;
    };

    const invokeMethod = (e?: any, action: any = 0) => {
      const nodeConfig = nodeJson.children[nodeJson?.selected ?? 0]?.config;
      const nodePanelId = getPanelId(nodeConfig?.panelId);
      const panel = props.panels.find( (p) => p.panelId === nodePanelId && p.typeId === nodeConfig?.typeId);
      model?.doAction(Actions.setActiveTabset(nodeJson.id));

      if (action === `edit`) {
        editPanelInline(nodePanelId);
      } else if (action == `editMarket`) {
        editMarket(panel);
      } else if (action == `duplicate`) {
        duplicateDragAndDropPanel(panel);
      }
    };

    renderValues.buttons.push(
      <div
        key="panel-settings"
        className="panel-settings">
        <SelectField
          options={ getActions() }
          icon={ Gear }
          hideValue={ true }
          label=""
          name=""
          value=""
          onChange={ invokeMethod }/>
      </div> 
    );    
  };

  const titleFactory = (node) => {
    const nodeJson = node.toJson();
    const nodeConfig = nodeJson.config;
    const Component = getComponentFromPanelTypeId(nodeConfig.typeId);
    const nodePanelId = getPanelId(nodeConfig.panelId);
    const panel = props.panels.find( (p) => p.panelId === nodePanelId && p.typeId === nodeConfig.typeId);
    const settings = JSON.parse(panel?.settingsJson || `{}`);
    const title = (Component.hasOwnProperty(`getPanelTitle`) && Component.getPanelTitle(settings, markets, exchanges));

    const mkt = getMarketFromPanelSettings(markets, settings);
    const mktDisplayName = mkt && getMarketPair(mkt).toString();
    const mktColor = mktDisplayName && mktColors?.find((m) => m.display_name === mktDisplayName)?.color;

    return {
      titleContent: <div className='title-content'> 
        { mktDisplayName &&
        <div 
          className='market-color'
          style={ { background: mktColor, border: !mktColor ? `1px solid` : `` } } 
          onMouseDown={ (e) => e.stopPropagation() }
          onTouchStart={ (e) => e.stopPropagation() }
          onClick={ () => { 
            setMarketColorModalOpen(true);
            setMarketColorModalMarket(mktDisplayName);
          } }/> 
        }
        <span>
          { title }
        </span>
      </div>,
      name: `${node.getName()}`
    };
  };

  const factory = (node) => {
    const nodeJson = node.toJson();
    const nodeConfig = nodeJson.config;
    const nodePanelId = getPanelId(nodeConfig.panelId);
    const config = panels.find( (p) => p.panelId === nodePanelId && p.typeId === nodeConfig.typeId);

    return (
      <>
        { config && 
          <Panel 
            panel={ config }
            width={ node[`_rect`].width }
            height={ node[`_rect`].height - 20 }
            locked={ true }
            t={ t }
            markets={ markets }
            exchanges={ exchanges }
            balances={ balances }
            accounts={ accounts }
            board={ board }
            panels={ panels }
            cancelEditPanelInline={ cancelEditPanelInline }
            savePanelInline={ savePanelInline }
            canCreateNewPanel={ canCreateNewPanel }
            modifyingPanelsInline={ modifyingPanelsInline }
            editMarket={ editMarket }
            currencies={ currencies }
            panelTypes={ panelTypes }
            panelData={ panelData }
            setPanelMarket={ setPanelMarket }
            refreshBalance={ refreshBalance }/>
        }
      </>);
  };

  useEffect(()=> {
    if (board?.boardId) {
      modelJson.layout = {};
      setModel(Model.fromJson(modelJson));

      if (dataHasLoaded) {
        getBoardLayout(board?.boardId)
          .then((data) => {
            if (data.result && data.result !== `{}`) {
              modelJson.layout = JSON.parse(data.result);
            } else {
              modelJson.layout = migrateBoardToFlexlayoutVersion(panels);
            }
            setModel(Model.fromJson(modelJson));
          });
      }
    }
  }, [board.boardId, dataHasLoaded]);

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  return (
    <div className="board-wrapper">
      {  
        model && dataHasLoaded ? (
          <div className="board">
            { marketColorModalOpen && (
              <MarketColorPicker 
                marketDisplayName={ marketColorModalMarket } 
                onClose={ () => {
                  setMarketColorModalOpen(false); 
                  setMarketColorModalMarket(``); 
                } }/> 
            ) }
            {
              panels.length ?
                <Layout 
                  ref={ layoutRef }
                  model={ model } 
                  titleFactory={ titleFactory }
                  factory={ factory } 
                  onAction={ onAction }
                  onModelChange={ onModelChange }
                  popoutURL={ `/demo/board/popout` }
                  realtimeResize={ true }
                  onRenderTabSet={ renderTabSet }/>
                :
                <a
                  className="new-panel-placeholder"
                  onClick={ () => newPanel() }>
                  { NewPanel }
                </a>
            }
          </div>
        ) : (
          <div className="board-loading">
            <span>
              { t(`app:loading`) }
            </span>
          </div>
        )
      }
      {
        isNewPanelModalOpen && (
          <PanelModal
            title={ t(`newPanel`) }
            panelTypes={ panelTypes }
            markets={ markets }
            exchanges={ exchanges }
            accounts={ accounts }
            data={ null }
            save={ addDragAndDropPanel }
            disabled={ !canCreateNewPanel }
            close={ closeNewPanelModal } 
            panels={ panels }/>
        )
      }
      {
        isDeletePanelModalOpen && (
          <DeletePanelModal
            delete={ onDeletePanel }
            close={ closeDeletePanelModal } />
        )
      }  
    </div>
  );
};

const mapStateToProps = (state) => ({
  mktColors: state.redisPrefs.mktColors
});

export { Board as PureBoard };
export default connect(mapStateToProps)(Board);
