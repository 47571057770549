import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Refresh from '../../../svgs/Refresh.jsx';
import Checkbox from '../../utilities/Checkbox.jsx';
import { DetailsBalances } from './DetailsBalances.jsx';
import { DetailsPendingDeposits } from './DetailsPendingDeposits.jsx';
import { DetailsHistory } from './DetailsHistory.jsx';
import { FiatWithdrawModal } from './AssetOperations/FiatWithdrawModal.jsx';
import { CryptoWithdrawModal } from './AssetOperations/CryptoWithdrawModal.jsx';
import { CryptoDepositModal } from './AssetOperations/CryptoDepositModal.jsx';
import { NewBankAccountModal } from './AssetOperations/NewBankAccountModal.jsx';
import { NewCryptoWalletAddressModal } from './AssetOperations/NewCryptoWalletAddressModal.jsx';
import { FiatDepositModal } from './AssetOperations/FiatDepositModal.jsx';
import { getAccountBalance } from '../../../helpers/api/BalanceApi.js';
import { getSummariesCurrencies } from '../../../helpers/api/CurrencyApi';
import { getCurrencies, getAssetTransferMethods, getFundTransferMethods, deleteFundTransfer, deleteAssetTransferMethod } from '../../../helpers/api/UserApi.js';
import { toFixedDecimalsHTML } from '../../../helpers/NumberHelper.js';
import { ucfirst } from '../../../helpers/StringHelper.js';
import AddressDeleteModal from './AssetOperations/AddressDeleteModal.jsx';
import {
  emitEvent,
  NOTIFICATION,
} from '../../../helpers/EventHelper.js';
import { updateTitle } from '../../../helpers/BrowserTitleHelper.js';
import LSCXButton from '../../markets/LSCXButton.jsx';
import LogoLightspeedCrypto from '../../../svgs/LogoLightspeedCrypto.jsx';
import { getUserApplication } from '../../../helpers/UserApplicationsHelper.js';
import Wallet from '../../../svgs/Wallet.jsx';
import History from '../../../svgs/History.jsx';
import Globe from '../../../svgs/Download.jsx';
import { setBalanceFormat } from "../../../helpers/BalancesHelper.js";
import SelectField from "../../utilities/SelectField.jsx";
import DatePicker from "../../utilities/DatePicker.jsx";
import CustomerStatements from '../CustomerStatements.jsx';

const API_POLL_MS = 90000;
const END_DATE = `endDate`;
const START_DATE = `startDate`;
const DATE_FORMAT = `MM-dd-yyyy`;
const ACTUAL_DATE = new Date(); 
const MIN_DATE = new Date(2022, 0, 1);

export const AccountDetails = (props) => {
  const { accounts, exchanges, t, refreshBalance, isAccountsInit, isExchangesInit } = props;
  const { accountId } = useParams();
  const [ activeAccount, setActiveAccount ] = useState(null);
  const [ selectedExchange, setSelectedExchange ] = useState(null);
  const [ activeTab, setActiveTab ] = useState(0);
  const [ showZeroBalance, setShowZeroBalance ] = useState(true);
  const [ fiatWithdrawModalOpen, setFiatWithdrawModalOpen ] = useState(false);
  const [ cryptoWithdrawModalOpen, setCryptoWithdrawModalOpen ] = useState(false);
  const [ fiatDepositModalOpen, setFiatDepositModalOpen ] = useState(false);
  const [ cryptoDepositModalOpen, setCryptoDepositModalOpen ] = useState(false);
  const [ newBankAccountModalOpen, setNewBankAccountModalOpen ] = useState(false);
  const [ newWalletAddressModalOpen, setNewWalletAddressModalOpen ] = useState(false);
  const [ savedBankAccounts, setSavedBankAccounts ] = useState([]);
  const [ savedWalletAddress, setSavedWalletAddress ] = useState([]);
  //const [ filterText, setFilterText] = useState(``);
  const [ balances, setBalances ] = useState([]);
  const [ totalBalance, setTotalBalance ] = useState(0);
  const [ selectedCurrCode, setSelectedCurrCode ] = useState(null);
  const [ selectedQuoteCurrCode, setSelectedQuoteCurrCode ] = useState(null);
  const [ refreshing, setRefreshing ] = useState(false);

  const [ deleteAddressModalOpen, setDeleteAddressModalOpen ] = useState(false);
  const [ deleteAddressInfo, setDeleteAddressInfo ] = useState(false);
  const [ deletingAddress, setDeletingAddress ] = useState(false);
  const [ currencies, setCurrencies ] = useState(null);
  const [ isLoading, setIsLoading ]  = useState(true);
  const [ intervalId, setIntervalId ] = useState(null);
  const [period, setPeriod] = useState(`ALL_TIME`);
  const [ endDate, setEndDate ] = useState(new Date());
  const [ startDate, setStartDate ] = useState(MIN_DATE);
  
  const history = useHistory();

  const platformId = useSelector((state) => state.userInfo.user.platformId);
  const userApplications = useSelector((state) => state.userInfo.userApplications);
  const applicationsStatuses = useSelector((state) => state.userInfo.applicationsStatuses);

  updateTitle(`Wallet`, platformId);

  const userApplication = useMemo(() => getUserApplication(`LSCX`, userApplications, applicationsStatuses), [userApplications, applicationsStatuses]);

  const handleClick = () => history.push(`/markets/LSCX/BTC/USD`);

  const setAccountInfo = () => {
    getAccountBalance(accountId, (balancesResponse) => {
      if (balancesResponse.success) {
        const balances = balancesResponse.result.map((b) =>({ ...b, hasBalance: true }));
        setTotalBalance(balances.reduce((total, value) => total + value.quoteBalance, 0));        
        getCurrencies((data) => {
          if (data.success && data.result?.value?.length) {
            const supportedBalances = data.result.value.map((curr) => {
              const balance = balances.find((b) => b.balanceCurrCode === curr.id);
              if (balance) {
                return {
                  ...balance,
                  fiat: curr.fiat
                };
              }
              
              return {
                balanceAuthId: 0,
                balanceCurrCode: curr.id,
                balanceCurrId: 0,
                balanceCurrName: curr.name || ucfirst(curr.primetrustAssetTransferType),
                balanceAmountAvailable: 0,
                balanceAmountHeld: 0,
                balanceAmountTotal: 0,
                quoteBalance: 0,
                balanceQuoteCurrCode: `USD`,
                lastPrice: 0,
                balanceHidden: false,
                hasImage: false,
                fiat: curr.fiat,
                hasBalance: false,
              };
            });
            setBalances(supportedBalances);
          } else {
            setBalances(balances);
          }
        });
      }
    });
  };

  const setAccountTransfers = () => {
    getAssetTransferMethods((response) => {
      if (response.success) {
        if (response.result.length > 0) {
          setSavedWalletAddress(response.result);
        } else {
          setSavedWalletAddress([{ label: `No wallet addresses were found.`, value: null }]);
        }
      }
    });
    getFundTransferMethods((response) => {
      if (response.success) {
        if (response.result.length > 0) {
          setSavedBankAccounts(response.result);
        } else {
          setSavedBankAccounts([{ label: `No Bank Accounts Found`, value: null }]);
        }
      }
    });
  };

  useEffect(() => {
    if (accounts && accountId) {
      const getActiveAccount = accounts.filter((account) => account.authId == accountId)[0];
      setActiveAccount(getActiveAccount);
    }
  }, [accounts, accountId]);

  useEffect(() => {
    if (exchanges && activeAccount) {
      setSelectedExchange(exchanges.find((e) => e.exchId == activeAccount.authExchId));
    }
  }, [exchanges, activeAccount]);

  useEffect(() => {
    if (selectedExchange) {
      getSummariesCurrencies({ params: { SearchTerm: `${selectedExchange.exchCode}:` } }, (data) => {
        const curr = data.result.reduce((accum, curr) => {
          return [
            ...accum, 
            {
              currId: curr.currId,
              currCode: curr.currCode,
              currName: curr.currName 
            }
          ];
        }, []);
  
        setCurrencies(curr);
      });

      setIsLoading(false);
    }
  }, [selectedExchange]);  

  useEffect(() => {
    if (isAccountsInit && isExchangesInit) {
      if (accountId) {
        setAccountInfo();
        setAccountTransfers();

        const interval = setInterval(() => {
          setAccountInfo();
          setAccountTransfers();
        }, API_POLL_MS);
  
        setIntervalId(interval);
      } else {
        const LSCXExchange = exchanges.find((e) => e.exchCode === `LSCX`);
        if (LSCXExchange) {
          const authId = accounts.find((a) => a.authExchId == LSCXExchange?.exchId)?.authId;
          if (authId) history.push(`/wallet/${authId}`);
        }
      }
    }
  }, [accountId, isAccountsInit, isExchangesInit]);

  useEffect(() => {
    if (refreshing) {
      refreshBalance(accountId)
        .then(() => {
          setAccountInfo();
          setRefreshing(false);
        });
    }
  }, [refreshing]);

  useEffect(() => {
    if (userApplication.isEligible && !activeAccount && !selectedExchange && !userApplication.isVerified) {
      setIsLoading(false);
    }
  }, [userApplication.isEligible, userApplication.isVerified]);

  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [intervalId]);

  const getExchangeIcon = () => {
    if ( selectedExchange && activeAccount ) {
      let src = `${ window.WWW_URL }/assets/img/currency/empty.png`;
      if (selectedExchange) {
        src = `${ window.WWW_URL }/assets/img/exchange/${selectedExchange.exchCode}-icon.png`;
      } else if (activeAccount.authOptional && activeAccount.authOptional.length > 0) {
        src = `${ window.WWW_URL }/assets/img/currency/${activeAccount.authOptional}-icon.png`;
      }
      return (
        <img
          src={ src }/>
      );
    }
  };

  const openNewBankAccountModal = () => {
    setFiatWithdrawModalOpen(false);
    setNewBankAccountModalOpen(true);
  };

  const openNewWalletAddressModal = () => {
    setNewWalletAddressModalOpen(true);
    setCryptoWithdrawModalOpen(false);
  };

  const handleWithdrawModal = (open, rowDetails) => {
    setSelectedCurrCode(rowDetails.balanceCurrCode);
    if (rowDetails.fiat) {
      setFiatWithdrawModalOpen(open);
    } else {
      setCryptoWithdrawModalOpen(open);
    }
  };

  const handleDepositModal = (open, rowDetails) => {
    setSelectedCurrCode(rowDetails.balanceCurrCode);
    setSelectedQuoteCurrCode(rowDetails.balanceQuoteCurrCode); 
    if (rowDetails.fiat) {
      setFiatDepositModalOpen(open);
    } else {
      setCryptoDepositModalOpen(open);
    }
  };

  const handleDeleteAssetClick = (data) => {
    setDeleteAddressModalOpen(true);
    setDeleteAddressInfo(data);
  };

  const handleDeleteAssetConfirm = () => {
    setDeletingAddress(true);
    if (deleteAddressInfo.type === `crypto`) {
      deleteAssetTransferMethod({ assetTransferMethodId: deleteAddressInfo.id }, () => {
        setDeleteAddressModalOpen(false);
        setDeleteAddressInfo(null);
        setAccountTransfers();
        setDeletingAddress(false);
        emitEvent(NOTIFICATION, {
          notification_id: new Date().getTime(),
          title: `Address deleted`,
          title_vars: ``,
          message_raw: `Your Crypto Address has been deleted.`,
          message: `Your Crypto Address has been deleted.`,
          message_vars: ``,
          pinned: false,
          style: `success`,
          url: ``
        });
      });
    } else {
      deleteFundTransfer({ fundTransferMethodsId: deleteAddressInfo.id }, () => {
        setDeleteAddressModalOpen(false);
        setDeleteAddressInfo(null);
        setAccountTransfers();
        setDeletingAddress(false);
        emitEvent(NOTIFICATION, {
          notification_id: new Date().getTime(),
          title: `Bank Account deleted`,
          title_vars: ``,
          message_raw: `Your Bank Account has been deleted.`,
          message: `Your Bank Account has been deleted.`,
          message_vars: ``,
          pinned: false,
          style: `success`,
          url: ``
        });
      });
    }
  };

  const handlePeriod = (e, period) => {
    setPeriod(period);
  
    switch(period) {
    case `ALL_TIME`:
      setStartDate(MIN_DATE);
      setEndDate(ACTUAL_DATE);
      break;
    case `THIS_MONTH`:
    case `CUSTOM`:
      setStartDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), 1));
      setEndDate(ACTUAL_DATE);
      break;
    case `LAST_MONTH`:
      setStartDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth() -1, 1));
      setEndDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), 0));
      break;
    case `THIS_YEAR`:
      setStartDate(new Date(ACTUAL_DATE.getFullYear(), 0, 1));
      setEndDate(new Date(ACTUAL_DATE.getFullYear() + 1, 0, 0));
      break;
    case `TODAY`:
      setStartDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), ACTUAL_DATE.getDate() -1));
      setEndDate(ACTUAL_DATE);
      break;
    case `YESTERDAY`:
      setStartDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), ACTUAL_DATE.getDate() -2));
      setEndDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), ACTUAL_DATE.getDate() -1));
      break;
    case `THIS_WEEK`:
      setStartDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), ACTUAL_DATE.getDate() - ACTUAL_DATE.getDay()));
      setEndDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), ACTUAL_DATE.getDate() + 6 - ACTUAL_DATE.getDay()));
      break;
    case `LAST_WEEK`:
      setStartDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), ACTUAL_DATE.getDate() - ACTUAL_DATE.getDay() - 7));
      setEndDate(new Date(ACTUAL_DATE.getFullYear(), ACTUAL_DATE.getMonth(), ACTUAL_DATE.getDate() + 6 - ACTUAL_DATE.getDay() - 7));
      break;
    }

    return false;
  };

  const handleUpdateState = (key, date) => {
    if (key && date) {
      key === START_DATE ? setStartDate(date) : setEndDate(date);
    }
  };

  const renderEntityTable = () => {
    const tableProps = {
      t,
      tableData: [],
      //filterText,
      selectedExchange,
      startDate,
      endDate,
    };

    if (balances) {
      tableProps.tableData = balances;
    }

    if (!showZeroBalance && balances.length > 0) {
      tableProps.tableData = balances.filter((balance) => {
        return (balance.balanceAmountTotal > 0);
      });
    }

    switch (activeTab) {
    case 0:
      return (
        <DetailsBalances
          { ...tableProps }
          handleDepositModal={ handleDepositModal }
          handleWithdrawModal={ handleWithdrawModal } />
      );
    case 1:
      return (<DetailsPendingDeposits { ...tableProps } />);
    case 2:
      return (<DetailsHistory { ...tableProps } currencies={ currencies } />);
    case 3:
      return (<CustomerStatements t={ t }/>);  
    }
  };

  const changeActiveTab = (v) => {
    setActiveTab(v);
  };

  const tabs = [
    {
      index: 0,
      text: <span>
        { Wallet }
        <span style={ { paddingLeft: `1.5rem` } }>
          { t(`Balances`) }
        </span>
      </span>
    },
    {
      index: 2,
      text: <span>
        { History }
        <span style={ { paddingLeft: `1.5rem` } }>
          { t(`History`) }
        </span>
      </span>
    },
    {
      index: 3,
      text: <span>
        { Globe }
        <span style={ { paddingLeft: `1.5rem` } }>
          { t(`Customer Statements`) }
        </span>
      </span>
    }    
  ];

  const accountExchName = (exchanges && activeAccount) && (exchanges.find((e) => e.exchId == activeAccount.authExchId))?.exchName || t(`Not found`);
  const accountExchCode = (exchanges && activeAccount) && (exchanges.find((e) => e.exchId == activeAccount.authExchId))?.exchCode || t(`Not found`);
  

  if (isLoading || !userApplication.isEligible) {
    return ``;
  } else if (activeAccount && selectedExchange && userApplication.isVerified && !userApplication.isAccountClosed) {
    return (
      <div className="accounts account-details-header fade-in">
        <section>
          <div className="accounts-contain">
            <div className="header top-header">
              <div>
                <div className="left-side clickable" onClick={ () => handleClick() }>
                  <div className="exch-icon">
                    { getExchangeIcon() }
                  </div>
                  <div className="exch-account">
                    <div className="exch-name">
                      { `${accountExchName} (${accountExchCode})` }
                    </div>
                    <div className="exch-authName">
                      { activeAccount.authNickname }
                    </div>
                  </div>
                </div>
                <div
                  onClick={ () => setRefreshing(true) }
                  className={
                    `refresh-balance ${refreshing ? `animate` : ``}`
                  }>
                  { Refresh() }
                </div>
              </div>
              <div className="right-side">
                <span className="total-balance" dangerouslySetInnerHTML={ toFixedDecimalsHTML(totalBalance, true, setBalanceFormat(`USD`)) } />
                <h3>
                  USD
                </h3>
              </div>
            </div>
            <div className="header sub-header">
              <div className="left-side">
                <ul className="header-tab">
                  {
                    tabs.map((t, i) => (
                      <li
                        key={ i }
                        className={ activeTab == t.index ? `active` : `` }
                        onClick={ () => changeActiveTab(t.index) }>
                        <a

                          activeclassname="active">
                          <span>
                            { t.text }
                          </span>
                        </a>
                      </li>
                    ))
                  }
                </ul>
              </div>
              <div className="right-side">
                {
                  activeTab == 0 && (
                    <div className='filter-container'>
                      <div className='flex period-filter'>
                        <SelectField
                          label={ `` }
                          name={ `period` }
                          value={ period }
                          options={ [
                            { value: `TODAY`, label: `Today` },
                            { value: `YESTERDAY`, label: `Yesterday` },
                            { value: `THIS_WEEK`, label: `This Week` },
                            { value: `LAST_WEEK`, label: `Last Week` },
                            { value: `THIS_MONTH`, label: `This Month` },
                            { value: `LAST_MONTH`, label: `Last Month` },
                            { value: `THIS_YEAR`, label: `This Year` },
                            { value: `ALL_TIME`, label: `All Time` },
                            { value: `CUSTOM`, label: `Custom` },
                          ] }
                          onChange={ (e, v) => handlePeriod(e, v) }/>
                        { period === `CUSTOM` && 
                        <>
                          <DatePicker
                            value={ startDate }
                            min={ MIN_DATE }
                            max={ ACTUAL_DATE }
                            dateFormat={ DATE_FORMAT }
                            name={ `startDate` }
                            onChange={ (value) => handleUpdateState(START_DATE, value, period) }/>

                          <DatePicker
                            value={ endDate }
                            min={ MIN_DATE }
                            max={ ACTUAL_DATE }
                            dateFormat={ DATE_FORMAT }
                            name={ `endDate` }
                            onChange={ (value) => handleUpdateState(END_DATE, value, period) }/>
                        </>
                        }
                      </div>
                      <div className='show-wallets-filter'>
                        <Checkbox
                          name={ `zeroBalance` }
                          label={ t(`Show Empty Wallets`) }
                          value={ showZeroBalance }
                          onChange={ (e) => {
                            setShowZeroBalance(e.target.checked);
                          } } />
                      </div>
                    </div>                      
                  )
                }
                { /* 
                Search commented out for now
                <div className="search">
                  <TextField
                    icon={ Search }
                    label={ t(`Find balances`) }
                    name={ `balances` }
                    value={ filterText }
                    onChange={ (e) => setFilterText(e.target.value) } />
                </div> */
                }
              </div>
            </div>
            {
              renderEntityTable()
            }
          </div>
          {
            fiatWithdrawModalOpen && (
              <FiatWithdrawModal
                t={ t }
                currCode={ selectedCurrCode }
                close={ () => setFiatWithdrawModalOpen(false) }
                bankAccounts={ savedBankAccounts }
                openNewBankAccountModal={ openNewBankAccountModal }
                setAccountInfo={ setAccountInfo }
                onDeleteAddressClick={ handleDeleteAssetClick } />
            )
          }
          {
            cryptoWithdrawModalOpen && (
              <CryptoWithdrawModal
                t={ t }
                currencies={ currencies }
                currCode={ selectedCurrCode }
                close={ () => setCryptoWithdrawModalOpen(false) }
                walletAddresses={ savedWalletAddress }
                openNewWalletAddressModal={ openNewWalletAddressModal }
                setAccountInfo={ setAccountInfo }
                setAccountTransfers={ setAccountTransfers }
                onDeleteAddressClick={ handleDeleteAssetClick } />
            )
          }
          {
            fiatDepositModalOpen && (
              <FiatDepositModal
                t={ t }
                currCode={ selectedCurrCode }
                close={ () => setFiatDepositModalOpen(false) }
                setAccountInfo={ setAccountInfo } />
            )
          }
          {
            cryptoDepositModalOpen && (
              <CryptoDepositModal
                t={ t }
                currCode={ selectedCurrCode }
                close={ () => setCryptoDepositModalOpen(false) }
                setAccountInfo={ setAccountInfo } 
                exchCode={ accountExchCode } 
                quoteCurrCode={ selectedQuoteCurrCode }/>
            )
          }
          {
            newBankAccountModalOpen && (
              <NewBankAccountModal
                t={ t }
                currCode={ selectedCurrCode }
                close={ () => setNewBankAccountModalOpen(false) }
                setAccountTransfers={ setAccountTransfers } />
            )
          }
          {
            newWalletAddressModalOpen && (
              <NewCryptoWalletAddressModal
                t={ t }
                currCode={ selectedCurrCode }
                close={ () => setNewWalletAddressModalOpen(false) }
                setAccountTransfers={ setAccountTransfers } />
            )
          }
          {
            deleteAddressModalOpen && (
              <AddressDeleteModal
                t={ t }
                onClose={ () => setDeleteAddressModalOpen(false) }
                onConfirm={ handleDeleteAssetConfirm }
                addressInfo={ deleteAddressInfo }
                loading={ deletingAddress } />
            )
          }
        </section>
      </div>
    );
  } else {
    return (
      <div className="accounts wallet-signup">
        <section>
          <div className="notification">
            <LogoLightspeedCrypto />
            
            <span className='signup-message'>
              <h2>
Welcome to Lightspeed Crypto!
              </h2>
              <p className={ `first-p-block` }>
We’re thrilled to show you why Lightspeed Crypto is 
                { ` ` }
                <em>
the
                      
                      
trading technology built for active traders.
                </em>
              </p>
              <div className="main-body">
                <p className="medium-text">
During your trial, you will have the ability to manage, track, and trade your digital 
wallets across multiple exchanges by linking them to Lightspeed Crypto. For you to access our 
industry-leading prices on Bitcoin and Ethereum, you must complete our application.
                        
                </p>
              </div>
            </span>
            <p className="action-text">
To activate your wallet, please complete the following steps:
            </p>

            <LSCXButton accounts={ accounts } exchanges={ exchanges } />
          </div>
        </section>
      </div>
    );    
  }
};
