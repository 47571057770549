// @flow
'use strict';

import {
  SET_USER_HASH,
  UPDATE_USER_DATA,
  SET_SUBSCRIPTION_INFO,
  SET_SUBSCRIPTION_TYPES,
  SET_USER_APPLICATIONS,
  SET_APPLICATIONS_STATUSES
} from '../actions/index.js';

import type { User } from '../types/User.js';
import type { UserPrefs } from '../types/UserPrefs.js';

type State = {
  user: User,
  userPrefs: UserPrefs,
  user_hash: string,
};

type Action = {
  type: string,
  payload: any
};

export const initialState: State = {
  user_hash: ``,
  user: {
    id: 0,
    username: ``,
    email: ``,
    createdOn: 0,
    lastLogin: 0,
    active: true,
    firstName: ``,
    lastName: ``,
    company: ``,
    phone: ``,
    street1: ``,
    street2: ``,
    city: ``,
    state: ``,
    zip: ``,
    country: ``,
    customTicker: true,
    tickerEnabled: true,
    chatEnabled: true,
    newsletter: true,
    sendBalanceEmail: true,
    platformId: 0,
    fA2Enabled: false
  },
  userPrefs: {
    lscxAgreementSigned: false,
    lscxApplied: false,
    lscxEligible: false,
    lscxVerified: false,
    lscxDenied: false,
    prefUserId: 0,
    prefAlertEmail: true,
    prefAlertSms: true,
    prefTradeEmail: true,
    prefTradeSms: true,
    prefBalanceEmail: true,
    prefDefaultPage: ``,
    prefSmsVerified: true,
    prefSmsNumber: ``,
    prefSmsCountry: ``,
    profSmsQuota: 0,
    prefSmsRate: 0,
    prefSubscriptionExpires: (new Date()).toString(),
    prefTradeMobile: true,
    prefAlertMobile: true,
    prefLifetime: true,
    prefServiceTier: 0,
    prefStopSms: true,
    prefStopEmail: true,
    prefReferralCode: ``,
    prefReferredBy: ``,
    prefReferralPayout: 0,
    prefTradeSite: true,
    prefAlertSite: true,
    prefAlertNews: true,
    prefBlockSite: true,
    prefBetaTester: true,
    prefAlertNewMarket: true,
    prefBoardLimit: 3,
    prefPanelLimit: 8,
    prefIsFreemium: true,
    prefIsUsernameSet: true
  },
  subscriptionInfo: {},
  subscriptionTypes: [],
  userApplications: [],
  applicationsStatuses: []
};

export default function userInfo(state: State = initialState, action: Action) {
  switch(action.type) {
  case UPDATE_USER_DATA:
    return {
      ...state,
      user: {
        ...state.user,
        ...(action.payload.user || { })
      },
      userPrefs: {
        ...state.userPrefs,
        ...(action.payload.userPrefs || { })
      }
    };
  case SET_USER_HASH:
    return {
      ...state,
      user_hash: action.payload
    };
  case SET_SUBSCRIPTION_INFO:
    return {
      ...state,
      subscriptionInfo: action.payload
    };
  case SET_SUBSCRIPTION_TYPES:
    return {
      ...state,
      subscriptionTypes: action.payload
    };
  case SET_USER_APPLICATIONS:
    return {
      ...state,
      userApplications: action.payload
    };
  case SET_APPLICATIONS_STATUSES:
    return {
      ...state,
      applicationsStatuses: action.payload
    };
  }
  return state;
}
