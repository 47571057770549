// @flow
'use strict';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { translate } from 'react-i18next';
import MarketDepthChartData from "./MarketDepthChartData.jsx";
import MarketDepthChartGraph from "./MarketDepthChartGraph.jsx";
import MarketDepthChartExtraInfo from './MarketDepthChartExtraInfo.jsx';
import { getSpreadFromBidAndAsk, toFixedDecimalsHTML } from '../../helpers/NumberHelper.js';
import type { Market } from "../../types/Market";
import type { MarketOrder } from '../../types/MarketOrder.js';
import type { Exchange } from "../../types/Exchange";
import SelectField from "../utilities/SelectField.jsx";
import { updateRedisPrefs } from '../../actions/redisPrefs/updateRedisPrefs.js';
import { 
  emitEvent, 
  MARKETS_PRICE_CLICK, 
} from '../../helpers/EventHelper.js';

const THROTTLE_MS = 2000;

type Props = {
  t: any,
  marketSwitcherOpen: boolean,
  marketInfoOpen: boolean,
  showLarger: boolean,
  buys: Array<MarketOrder>,
  sells: Array<MarketOrder>,
  market: Market,
  openOrders: Array<any>,
  activeAlerts: Array<any>,
  showDepthOrdersAlerts: boolean,
  toggleShowDepthOrdersAlerts: () => void,
  lastPrice: number,
  lastTradeType: string,
  exchange: Exchange
};


const MarketDepthChartWrapper = (props: Props) => {
  const { t, marketSwitcherOpen, marketInfoOpen, showLarger, buys, sells, market, openOrders, activeAlerts,
    showDepthOrdersAlerts, toggleShowDepthOrdersAlerts, lastPrice, lastTradeType, exchange } = props;

  const limitDeviation = useSelector((state) => {state.redisPrefs.limitDepthChartDeviation;});
  const dispatch = useDispatch();
  const [ group, setGroup ] = useState(`none`);
  const [ lastUpdated, setLastUpdated ] = useState(Date.now());
  const [ filteredOpenOrders, setFilteredOpenOrders ] = useState([]);

  useEffect(() => {
    if (Date.now() - lastUpdated >= THROTTLE_MS) {
      setLastUpdated(Date.now()); 
    }
  }, [group, buys, sells, showLarger, marketSwitcherOpen, marketInfoOpen, limitDeviation, showDepthOrdersAlerts ]);

  useEffect(() => {
    const filteredOpenOrders = openOrders.filter((o) => { 
      return o.exchmktId == market.exchmktId;
    });
    setFilteredOpenOrders(filteredOpenOrders);
  }, [openOrders]);

  const getOptions = (): Array<{ 
    label: string,
    value: any,
    compareValue?: any,
    icon?: any 
  }> => {
    return [{ label: `Default`, value: `none` }].concat([
      -7,
      -6,
      -5,
      -4,
      -3,
      -2,
      -1,
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7
    ].map((value) => {
      let label = Math.pow(10, -(value));
      if (value > 0) {
        label = label.toFixed(8);
        label = label.replace(/(\.[0-9]*[1-9])0+$|\.0*$/,`$1`);
      }
      return ({
        label: label.toString(),
        value: value.toString()
      });
    }));
  };

  const round = (total: number, precision: number) => {
    const factor = Math.pow(10, precision);
    return Math.round(total * factor) / factor;
  };

  const applyGrouping = (data: Array<MarketOrder>, groupValue: string = `none`): Array<MarketOrder> => {
    if (groupValue === `none`) return data;

    const group = parseInt(groupValue);
    const groupedData: Map<number, MarketOrder> = new Map();
    const result: Array<MarketOrder> = [];
    data.forEach((item) => {
      let key = round(item.price, group);
      if (groupedData.has(key)) {
        const oldItem: ?MarketOrder = groupedData.get(key);
        if (oldItem) {
          groupedData.set(key, {
            ...oldItem,
            quantity: oldItem.quantity + item.quantity,
            total: oldItem.total + item.total,
          });
        }
      } else {
        if (item.price < key) {
          key = round(key, group);
        }
        groupedData.set(key, { ...item, price: key });
      }
    });
    groupedData.forEach((value) => {
      result.push(value);
    });
    return result;
  };

  const sortBuys = (buys: Array<MarketOrder> = buys): Array<MarketOrder> => {
    return buys.sort((a, b) => {
      if (a.price < b.price) return 1;
      if (b.price < a.price) return -1;
      return 0;
    }).filter((item) => item.price > 0);
  };

  const sortSells = (sells: Array<MarketOrder> = sells): Array<MarketOrder> => {
    return sells.sort((a, b) => {
      if (a.price > b.price) return 1;
      if (b.price > a.price) return -1;
      return 0;
    }).filter((item) => item.price > 0);
  };

  const interpretGroupVal = (): string => {
    let g = -1 * parseInt(group),
      val = Math.pow(10, g);

    if (g < 0) {
      return val.toFixed(Math.abs(g));
    } else {
      return val.toString();
    }
  };

  let minAsk = 0,
    maxBid = 0,
    sortedSells = sortSells(applyGrouping(sells, group)),
    sortedBuys = sortBuys(applyGrouping(buys, group));

  //Calculate min, max bid and min ask. Used for delineating different zones in chart and table data display.
  if (sortedBuys.length > 0) {
    maxBid = sortedBuys[0].price;
  }

  if (sortedSells.length > 0) {
    minAsk = sortedSells[0].price;
  }

  if (sells.length == 0 || buys.length == 0) return (
    <div className="market-depth-chart-wrapper loading">
      <span>
        { t(`marketDepthLoading`) }
      </span>
    </div>
  );

  return (
    <div className={ `market-depth-chart-wrapper ${ showLarger ? `larger` : `` }` }>
      <div className="box">
        <div className="header-container">
          <label htmlFor="limitDeviation">
            <input 
              type="checkbox"
              id="limitDeviation"
              data-testid={ `limit-deviation-input` }
              checked={ limitDeviation }
              onChange={ (e) => {
                dispatch(
                  updateRedisPrefs({ 
                    key: `limitDepthChartDeviation`, 
                    value: e.target.checked 
                  })); } }/>
            <span>
              { t(`limitDeviation`) }
            </span>
          </label>

          <label htmlFor="hideShowMarkers">
            <input 
              type="checkbox"
              id="hideShowMarkers"
              data-testid={ `hide-show-markers-input` }
              checked={ showDepthOrdersAlerts }
              onChange={ () => { toggleShowDepthOrdersAlerts();  } } />
            <span>
              { t(`showOrdersAlerts`) }
            </span>
          </label>

          <div className={ `grouping ${ group !== `none` ? `selected` : `` }` }>
            <SelectField
              label=""
              name="group"
              value={ group }
              hideValue={ true }
              icon={ group == `none` ? `Price Precision` : interpretGroupVal() }
              options={ getOptions() }
              onChange={ (e, v) => setGroup(v) }/>
            <div className="clearfix" />
          </div>
        </div>
        <div className="depth-chart-container">
          <MarketDepthChartExtraInfo market={ market } exchCode={ exchange?.exchCode } maxBid={ maxBid } minAsk={ minAsk } lastPrice={ lastPrice } lastTradeType={ lastTradeType }/>
          <MarketDepthChartGraph
            resetZoomText={ t(`resetZoom`) }
            showLarger={ showLarger }
            marketInfoOpen={ marketInfoOpen }
            marketSwitcherOpen={ marketSwitcherOpen }
            market={ market }
            maxBid={ maxBid }
            minAsk={ minAsk }
            buys={ sortedBuys }
            sells={ sortedSells }
            openOrders={ filteredOpenOrders }
            activeAlerts={ activeAlerts }
            showDepthOrdersAlerts={ showDepthOrdersAlerts } />
        </div>
        
        <div className="header-container">
          <div>
            <span 
              style={ { 
                cursor: `pointer`
              } }
              data-testid={ `bid-price` }
              onClick={ () => { 
                emitEvent(MARKETS_PRICE_CLICK, {
                  price: maxBid
                });
              } } 
              dangerouslySetInnerHTML={ 
                toFixedDecimalsHTML(maxBid, false, `price`, market) 
              } />
            <br />
            <span className="header-container-bid">
              { t(`app:bid`).toUpperCase() }
            </span>
          </div>
          <div>
            <span dangerouslySetInnerHTML={ 
              toFixedDecimalsHTML(getSpreadFromBidAndAsk(maxBid, minAsk), false, `price`, market, true)
            } />
            <br />
            <span className="header-container-spr">
              { t(`app:spr`).toUpperCase() }
            </span>
          </div>
          <div>
            <span
              data-testid={ `min-ask-price` }
              style={ { 
                cursor: `pointer`
              } }
              onClick={ () => { 
                emitEvent(MARKETS_PRICE_CLICK, {
                  price: minAsk
                });
              } } 
              dangerouslySetInnerHTML={
                toFixedDecimalsHTML(minAsk, false, `price`, market) 
              } />
            <br />
            <span className="header-container-ask">
              { t(`app:ask`).toUpperCase() }
            </span>
          </div>
        </div>
        <MarketDepthChartData
          market={ market }
          buys={ sortedBuys }
          sells={ sortedSells }
          showCumulative={ marketInfoOpen ? false : true }/>
      </div>
    </div>
  );
};

export { MarketDepthChartWrapper as PureMarketDepthChartWrapper };
export default translate(`markets`)(connect()(MarketDepthChartWrapper));
