// @flow
'use strict';

import React from 'react';
import User from "../../svgs/User.jsx";

export const ToggleProfileButton = (props: any) => {
  const { text, size, toggleProfileMenu } = props;

  return(
    <a onClick={ () => toggleProfileMenu() } data-testid={ `profile-menu-link` } className="profile-button">
      { User }
      { size === `xs` &&
        <label>
          &nbsp;
          { text }
        </label>
      }
    </a>
  );
};
