// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toPng } from 'html-to-image';
import Button from '../utilities/Button.jsx';
import Board from './Board.jsx';
import BoardFormModal from './BoardFormModal.jsx';
import DeleteBoardModal from './DeleteBoardModal.jsx';
import UpgradeRequiredModal from './UpgradeRequiredModal.jsx';
import BoardSnapshotModal from './BoardSnapshotModal.jsx';
import PanelModal from './PanelModal.jsx';
import ImportBoardModal from './ImportBoardModal.jsx';
import MarketSelectorModal from './MarketSelectorModal.jsx';
import CoinigyBaseComponent from '../CoinigyBaseComponent.jsx';
import Camera from '../../svgs/Camera.jsx';
import Copy from '../../svgs/Copy.jsx';
import Edit from '../../svgs/Edit.jsx';
import Trash from '../../svgs/Trash.jsx';
import BoardLock from '../../svgs/BoardLock.jsx';
import BoardUnlock from '../../svgs/BoardUnlock.jsx';
import TextField from '../utilities/TextField.jsx';
import { forceUTCParsing } from '../../helpers/DateHelper.js';
import { $ } from '../../helpers/api/ApiHelper.js';
import { subscribe, unsubscribe, subscribeToAggregatedMarkets } from '../../helpers/SocketClusterHelper.js';
import { updateTitle } from '../../helpers/BrowserTitleHelper.js';
import { updateActiveBoardId } from '../../actions/boards/updateActiveBoardId.js';
import { toggleBoardLocked } from '../../actions/boards/toggleBoardLocked.js';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Board as BoardType } from '../../types/Board.js';
import type { Panel } from '../../types/Panel.js';
import type { PanelType } from '../../types/PanelType.js';
import type { MarketOrder } from '../../types/MarketOrder.js';
import type { Trade } from '../../types/Trade.js';
import type { Account } from '../../types/Account.js';
import type { Currency } from '../../types/Currency.js';
import type { Balance } from '../../types/Balance.js';
import * as exchangeApi from "../../helpers/api/ExchangeApi.js";
import {
  getNotes
} from "../../helpers/api/UserApi.js";
import { 
  MARKET_CHART, 
  MARKET_DATA, 
  MARKET_DEPTH,
  MARKET_RECENT_TRADES,
  MARKET_ORDERBOOK,
  NEWS_FEED,
  PRICE_TREND,
  NOTES,
  INSIGHTS,
  ORDER_FORM,
  TRADING_VIEW_CHART,
  MARKET_SPLIT_TRADES,
} from '../../helpers/PanelTypeHelper.js';
import {
  addNewBoard, 
  addNewPanel, 
  editPanel,
  deleteBoard, 
  deletePanel,
  editBoard, 
  getBoardPanels,
  getBoards,
  getPanelTypes,
  getSharedBoard, 
  importBoard, 
  updatePanelOrder,
  postBoardSnapshots
} from "../../helpers/api/BoardsApi.js";

import { emitEvent,
  NOTIFICATION } from '../../helpers/EventHelper.js';
import ProgressSpinner from '../utilities/ProgressSpinner.jsx';

type Props = {
  t: any,
  updateActiveBoardId: (id: number) => void,
  currencies: Array<Currency>,
  activeBoardId: number,
  maxBoards: number,
  maxPanels: number,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  accounts: Array<Account>,
  balances: Array<Balance>,
  sourceTag?: string,
  boardLocked: boolean,
  toggleBoardLock: () => void,
  history: any,
  platformId: number,
  balances: Array<Balance>,
  refreshBalance: (n: number, d: boolean) => Promise<any>,
  aggregatedMarkets: Array<any>,
};

type State = {
  newBoardModalOpen: boolean,
  canCreateNewBoard: boolean,
  editBoardModalOpen: boolean,
  deleteBoardModalOpen: boolean,
  newPanelModalOpen: boolean,
  canCreateNewPanel: boolean,
  deletePanelModalOpen: boolean,
  marketSelectorModalOpen: boolean,
  modifyingPanelId: number,
  modifyingPanelsInline: Array<number>,
  activeBoardPanels: Array<Panel>,
  boards: Array<BoardType>,
  panelTypes: Array<PanelType>,
  getDataInterval: any,
  panelData: any,
  activePanelEdit: any,
  throttleTimeout: any,
  boardUpgradeRequiredModalOpen: boolean,
  panelUpgradeRequiredModalOpen: boolean,
  dataHasLoaded: boolean,
  draggingBoard: number,
  boardSortOrderTimeout: any,
  boardSnapshotUrl: string,
  isBoardSnapshotLoading: boolean
};

const DEMO_BOARD = `5c661f53-fc09-43e8-85f4-1683d9b972a3`;
const THROTTLE_MS = 500;
const POLL_MS = 20000;
const NEED_TRADES = [MARKET_CHART, MARKET_DATA, MARKET_RECENT_TRADES, PRICE_TREND, TRADING_VIEW_CHART, MARKET_DEPTH, MARKET_SPLIT_TRADES];
const NEED_TICKER = [MARKET_DATA, ORDER_FORM, TRADING_VIEW_CHART];
const NEED_DEPTH = [MARKET_DEPTH, MARKET_ORDERBOOK, ORDER_FORM, TRADING_VIEW_CHART];
const NEED_NEWS = [NEWS_FEED, INSIGHTS];
const NEED_NOTES = [NOTES];
const NEED_CURRENCY_DATA = [INSIGHTS];
const NEED_SIGNALS = [INSIGHTS];

class BoardsDataLayerComponent extends CoinigyBaseComponent<Props, State> {
  bufferedTrades: *;

  constructor(props: Props) {
    super(props);

    this.bufferedTrades = { };

    this.state = {
      newBoardModalOpen: false,
      canCreateNewBoard: true,
      editBoardModalOpen: false,
      deleteBoardModalOpen: false,
      newPanelModalOpen: false,
      canCreateNewPanel: true,
      deletePanelModalOpen: false,
      boardUpgradeRequiredModalOpen: false,
      panelUpgradeRequiredModalOpen: false,
      marketSelectorModalOpen: false,
      modifyingPanelId: -1,
      modifyingPanelsInline: [],
      activeBoardPanels: [],
      panelTypes: [],
      boards: [],
      getDataInterval: -1,
      panelData: { },
      activePanelEdit: null,
      throttleTimeout: -1,
      dataHasLoaded: false,
      draggingBoard: -1,
      boardSortOrderTimeout: -1,
      boardSnapshotUrl: ``,
      isBoardSnapshotLoading: false
    };
  }


  componentDidMount(override: boolean = false) {
    this._isMounted = true;
    this.getPanelTypes();
    this.getBoards();

    if (this.props.sourceTag && !override) {
      this.getSharedBoard(this.props.sourceTag);
    } else {
      this.setStateSafe({
        getDataInterval: setInterval(() => {
          this.getPanelData();
        }, POLL_MS)
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.state.throttleTimeout);
    clearInterval(this.state.getDataInterval);
    unsubscribe(undefined, `boards-aggregated-markets`);
    unsubscribe(undefined, `boards`);
  }

  getPanelTypes() {
    getPanelTypes((data) => {
      this.setStateSafe({ panelTypes: data.result });
    });
  }

  updateBoardSortOrderBasedOnIndex(boards: Array<BoardType> = this.state.boards) {
    boards = boards.map((b, sortOrder) => ({
      ...b,
      sortOrder
    }));

    clearTimeout(this.state.boardSortOrderTimeout);

    this.setStateSafe({
      boards, 
      boardSortOrderTimeout: setTimeout(() => {
        boards.map((b) => {
          return new Promise((resolve) => {
            editBoard({ board: b }, () => resolve(b));
          });
        });
      }, 1500)
    });
  }

  getBoards() {
    getBoards((data) => {
      this.setStateSafe({
        boards: data.result
          .concat(this.state.boards)
          .reduce((boards, board) => {
            return !boards.find((b) => b.boardId == board.boardId) ? boards.concat([board]) : boards;
          }, []),
        newBoardModalOpen: data.result.length == 0 && !this.props.sourceTag
      }, () => {
        if (this.state.boards.length > 0) {
          const sortOrders = this.state.boards.reduce((s, b) => {
            return s.includes(b.sortOrder) ? s : s.concat([b.sortOrder]);
          }, []);

          if (this.state.boards.length !== sortOrders.length) {
            let { boards } = this.state;

            if (sortOrders.length > 0) {
              boards = boards.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);
            }

            this.updateBoardSortOrderBasedOnIndex(boards);
          }

          const active = this.state.boards.find((b) => b.boardId == this.props.activeBoardId);
          if (active) {
            updateTitle(active.label, this.props.platformId);
            this.getBoardPanels(this.props.activeBoardId);
          } else {
            this.setActiveBoard(this.state.boards[0].boardId, this.state.boards[0].label);
          }
        }
      });
    });
  }

  importDemoBoard(sourceTag: string = DEMO_BOARD) {
    this.setStateSafe({
      canCreateNewBoard: false
    }, () => {
      getSharedBoard(sourceTag, ({ success, result }) => {
        if (!success) return emitEvent(NOTIFICATION, {
          notification_id: new Date().getTime(),
          title: `Error`,
          title_vars: ``,
          message_raw: this.props.t(`viewError`),
          message: this.props.t(`viewError`),
          message_vars: ``,
          pinned: false,
          style: `error`,
          url: ``
        });

        importBoard(result.boardId, () => {
          this.setStateSafe({
            canCreateNewBoard: true,
            newBoardModalOpen: false,
            boards: this.state.boards.concat([result])
          }, () => this.setActiveBoard(result.boardId, result.label));
        });
      });
    });

    return true;
  }

  getSharedBoard(sourceTag: string) {
    if (!sourceTag) return;
    getSharedBoard(sourceTag, (data) => {
      if (data.success) {
        this.setStateSafe({
          boards: this.state.boards.concat([{
            ...data.result,
            preview: true
          }])
        }, () => this.setActiveBoard(data.result.boardId, data.result.label));
      } else {
        emitEvent(NOTIFICATION, {
          notification_id: new Date().getTime(),
          title: this.props.t(`viewError`),
          title_vars: ``,
          message_raw: this.props.t(`viewError`) + `\n\nMessage: ${ data.error }`,
          message: this.props.t(`viewError`) + `\n\nMessage: ${ data.error }`,
          message_vars: ``,
          pinned: false,
          style: `error`,
          url: ``
        });
      }
    });
  }

  addNewBoard(label: string) {
    if (label.length == 0) label = `${ this.props.t(`board`) } ${ this.state.boards.length + 1 }`;

    this.setState({
      canCreateNewBoard: false
    }, () => {
      addNewBoard(label, (data) => {
        if (data.success){
          this.setState({
            boards: this.state.boards.concat([data.result]),
            canCreateNewBoard: true,
            newBoardModalOpen: false
          }, () => this.setActiveBoard(data.result.boardId, data.result.label));
        } else {
          this.setState({
            canCreateNewBoard: true,
            newBoardModalOpen: false
          });
        }
      });
    });
  }


  editBoard(label: string) {
    const board = this.state.boards.find((b) => b.boardId == this.props.activeBoardId);
    if (!board) return;
    if (label.length == 0) label = `${ this.props.t(`board`) } ${ this.state.boards.length + 1 }`;
    editBoard({
      boardId: this.props.activeBoardId,
      label,
      board,
    }, (data) => {
      if (data.success) {
        updateTitle(label || `Boards`, this.props.platformId);
        this.setState({
          boards: this.state.boards.map((b) => {
            if (b.boardId == this.props.activeBoardId) b = data.result;
            return b;
          }),
          editBoardModalOpen: false
        });
      }
    });
  }

  deleteBoard(id: number = this.props.activeBoardId) {
    if (!id) return;
    deleteBoard(id, () => {
      this.setState({
        boards: this.state.boards.filter((b) => b.boardId !== id),
        deleteBoardModalOpen: false,
      }, () => this.setActiveBoard(
        this.state.boards[0] ? this.state.boards[0].boardId : -1, 
        this.state.boards[0] ? this.state.boards[0].label : ``
      ));
    });
  }

  addNewPanel(body: any, id: number = this.props.activeBoardId): Promise<any>  {
    return new Promise((resolve, reject) => {
      if (!body) return resolve();
      
      this.setState({
        canCreateNewPanel: false
      }, () => {
        addNewPanel(id, body, (data) => {
          if (data.success) {
            resolve(data.result);
            this.setState({
              activeBoardPanels: this.state.activeBoardPanels.concat([data.result]),
              newPanelModalOpen: false,
              canCreateNewPanel: true
            }, () => {
              const board = this.state.boards.find((b) => b.boardId == id);
              if (!board) return;
              this.updatePanelOrder(board.panelOrder.concat([data.result.panelId]), id);
              this.getPanelData(this.state.activeBoardPanels.concat([data.result]), true, board.preview);
              return resolve(data.result);
            });
          } else {
            this.setState({
              canCreateNewPanel: true
            }, () => emitEvent(NOTIFICATION, {
              notification_id: new Date().getTime(),
              title: `Error`,
              title_vars: ``,
              message_raw: `An error occurred adding the new panel: ${data.error}`,
              message: `An error occurred adding the new panel: ${data.error}`,
              message_vars: ``,
              pinned: false,
              style: `error`,
              url: ``
            }));
            return reject(data.error);
          }
        });
      });
    });

  }

  duplicatePanel(panel: any, id: number = this.props.activeBoardId): Promise<any>  {
    return new Promise((resolve, reject) => {
      if (this.state.activeBoardPanels.length >= this.props.maxPanels) {
        this.openPanelUpgradeRequiredModal();
        reject();
      }

      const payload = {
        panelSetting: panel.settingsJson,
        sizeId: panel.sizeId,
        typeId: panel.typeId
      };

      this.setState({
        canCreateNewPanel: false
      }, () => {
        addNewPanel(id, payload, (data) => {
          this.setState({
            activeBoardPanels: this.state.activeBoardPanels.concat([data.result]),
            newPanelModalOpen: false,
            canCreateNewPanel: true
          }, () => {
            const board = this.state.boards.find((b) => b.boardId == id);
            if (!board) return;
            this.updatePanelOrder(board.panelOrder.concat([data.result.panelId]), id);
            this.getPanelData(this.state.activeBoardPanels.concat([data.result]), true, board.preview);
            resolve(data.result);
          });
        });
      });
    });
  }

  setPanelMarket(exchCode: string, mktName: string, activePanelEdit: * = this.state.activePanelEdit) {
    this.editPanel({
      typeId: activePanelEdit.typeId,
      sizeId: activePanelEdit.sizeId,
      panelSetting: JSON.stringify({
        ...JSON.parse(activePanelEdit.settingsJson || `{}`),
        exchange: exchCode,
        market: mktName,
        account: ``
      })
    },
    this.props.activeBoardId,
    activePanelEdit.panelId
    );
  }

  editPanelInline(id: number) {
    this.setState({
      modifyingPanelsInline: this.state.modifyingPanelsInline
        .concat([id])
        .reduce((a, i) => a.includes(i) ? a : a.concat([i]), [])
    });
  }

  cancelEditPanelInline(panelId: number) {
    this.setState({
      modifyingPanelsInline: this.state.modifyingPanelsInline.filter((id) => id !== panelId),
    });
  }

  savePanelInline(panelId: number, body: any, boardId: number = this.props.activeBoardId) {
    if (!body) return;

    this.setState({
      canCreateNewPanel: false
    }, () => {
      editPanel(boardId, panelId, body, (data) => {
        if (!data.success) return;
        const currentPanels = [...this.state.activeBoardPanels];
        const currentIndex = currentPanels.findIndex((panel) => panelId == panel.panelId);
        if (currentIndex >= 0) {
          currentPanels[currentIndex] = {
            ...currentPanels[currentIndex],
            ...body,
            settingsJson: body.panelSetting,
          };
        }
        this.setState({
          activeBoardPanels: currentPanels,
          modifyingPanelsInline: this.state.modifyingPanelsInline.filter((id) => id !== panelId),
          canCreateNewPanel: true
        }, () => {
          const board = this.state.boards.find((b) => b.boardId == boardId);
          if (!board) return;
          this.updatePanelOrder(board.panelOrder, boardId);
          this.getPanelData(currentPanels, true, board.preview);
        });
      });
    });
  }

  editPanel(body: any, id: number = this.props.activeBoardId, panelId?: number) {
    if (!body) return;

    this.setState({
      canCreateNewPanel: false
    }, () => {
      const currentPanelId = panelId ? panelId : this.state.activePanelEdit.panelId;
      editPanel(id, currentPanelId, body, (data) => {
        if (!data.success) return;
        const currentPanels = [...this.state.activeBoardPanels];
        const currentIndex = currentPanels.findIndex((panel) => currentPanelId == panel.panelId);
        if (currentIndex >= 0) {
          currentPanels[currentIndex] = {
            ...currentPanels[currentIndex],
            ...body,
            settingsJson: body.panelSetting,
          };
        }
        this.setState({
          activeBoardPanels: currentPanels,
          newPanelModalOpen: false,
          canCreateNewPanel: true,
          activePanelEdit: null,
        }, () => {
          const board = this.state.boards.find((b) => b.boardId == id);
          if (!board) return;
          this.updatePanelOrder(board.panelOrder, id);
          this.getPanelData(currentPanels, true, board.preview);
        });
      });
    });
  }

  deletePanel(boardId: number = this.props.activeBoardId, id: number = this.state.modifyingPanelId): Promise<any>  {
    return new Promise((resolve) => {
      if (!boardId || !id) return resolve();

      deletePanel(boardId, id, () => {
        this.setState({
          activeBoardPanels: this.state.activeBoardPanels.filter((p) => p.panelId !== id),
          deletePanelModalOpen: false,
          modifyingPanelId: -1
        }, () => {
          const board = this.state.boards.find((b) => b.boardId == boardId);
          if (!board) return;
          this.updatePanelOrder(board.panelOrder.filter((n) => n !== id));
          return resolve(id);
        });
      });
    });
  }

  editPanelCustomSize(panelId: number, width: number, height: number, boardId: number = this.props.activeBoardId) {
    const settings = this.state.activeBoardPanels.filter((e) => e.panelId == panelId)[0].settingsJson;
   
    const body = {
      customHeight: height,
      customWidth: width,
      panelSetting: settings,
    };
    editPanel(boardId, panelId, body, (response) => {
      if (!response.success) {
        emitEvent(NOTIFICATION, {
          notification_id: new Date().getTime(),
          title: `Error`,
          title_vars: ``,
          message_raw: `An error occurred while resizing the panel, please reload and try again.`,
          message: `An error occurred while resizing the panel, please reload and try again.`,
          message_vars: ``,
          pinned: false,
          style: `error`,
          url: ``
        });
      }
    });
  }

  updatePanelOrder(panelOrder: Array<number>, id: number = this.props.activeBoardId) {
    const board = this.state.boards.find((b) => b.boardId == id);
    if (!board || !panelOrder || panelOrder.length === 0) return;
    clearTimeout(window.updatePanelTimeout);
    window.updatePanelTimeout = setTimeout(() => {
      updatePanelOrder(id, panelOrder.filter((p) => p > 0));
    }, 2000);
  }

  getBoardPanels(id: number = this.props.activeBoardId) {
    if (!id) return;
    this.setStateSafe({
      activeBoardPanels: [],
      modifyingPanelsInline: [],
      dataHasLoaded: false
    }, () => {
      getBoardPanels(id, (data) => {
        if (data.success) {
          this.setStateSafe({
            activeBoardPanels: data.result,
            dataHasLoaded: true
          }, () => {
            const board = this.state.boards.find((b) => b.boardId == id);

            if (board && board.panelOrder && board.panelOrder.length == 0 && data.result.length !== 0) {
              this.updatePanelOrder(data.result.map((p) => p.panelId));
            }

            this.getPanelData(data.result, true, (board || { preview: false }).preview);
          });
        }
      });
    });
  }

  orderPanels(panels: Array<Panel> = this.state.activeBoardPanels, id: number = this.props.activeBoardId): Array<Panel> {
    const board = this.state.boards.find((b) => b.boardId == id);
    if (!board || !board.panelOrder || board.panelOrder.length == 0) return panels;

    let ret = board.panelOrder.reduce((panelOrder, id) => {
      if (!panelOrder.includes(id)) return panelOrder.concat([id]);
      return panelOrder;
    }, []).map((id) => {
      const panel = panels.find((p) => p.panelId == id);
      if (panel) return panel;
      // $FlowIgnore: suppressing this error
      return null;
    }).filter((p) => !!p);

    ret = ret.concat(panels.filter((p) => !ret.find((q) => q.panelId == p.panelId)));

    return ret;
  }

  setActiveBoard(id: number, label: string) {
    if (id == this.props.activeBoardId) return true;

    if (label && label.length > 0) {
      updateTitle(label,this.props.platformId);
    } else {
      updateTitle(`Boards`, this.props.platformId);
    }

    this.props.updateActiveBoardId(id);

    if (id > 0) {
      this.getBoardPanels(id);
    } else {
      this.setStateSafe({
        modifyingPanelsInline: [],
        activeBoardPanels: [],
        dataHasLoaded: true
      });
    }

    return true;
  }

  openNewBoardModal() {
    if (this.state.boards.length >= this.props.maxBoards) {
      return this.openBoardUpgradeRequiredModal();
    }

    this.setState({
      newBoardModalOpen: true,
      newPanelModalOpen: false,
      editBoardModalOpen: false,
      deleteBoardModalOpen: false,
      deletePanelModalOpen: false
    });
    return true;
  }

  openEditBoardModal(e: any) {
    if (e) e.stopPropagation();

    this.setState({
      newBoardModalOpen: false,
      newPanelModalOpen: false,
      editBoardModalOpen: true,
      deleteBoardModalOpen: false,
      deletePanelModalOpen: false
    });
  }

  openDeleteBoardModal(e: any) {
    if (e) e.stopPropagation();

    this.setState({
      newBoardModalOpen: false,
      newPanelModalOpen: false,
      editBoardModalOpen: false,
      deleteBoardModalOpen: true,
      deletePanelModalOpen: false
    });
  }

  openNewPanelModal(panelId?: number, panel?: any) {
    if (!panelId && this.state.activeBoardPanels.length >= this.props.maxPanels) {
      return this.openPanelUpgradeRequiredModal();
    }

    this.setState({
      newBoardModalOpen: false,
      newPanelModalOpen: true,
      editBoardModalOpen: false,
      deleteBoardModalOpen: false,
      deletePanelModalOpen: false,
      activePanelEdit: panelId ? {
        panelId,
        ...panel,
      } : null,
    });
  }

  openPanelMarketSelectorModal(panel: any) {
    this.setState({
      marketSelectorModalOpen: true,
      activePanelEdit: panel
    });
  }

  exitPanelMarketSelectorModal() {
    this.setState({
      marketSelectorModalOpen: false,
      activePanelEdit: null
    });
  }

  openDeletePanelModal(id: number) {
    this.setState({
      newBoardModalOpen: false,
      newPanelModalOpen: false,
      editBoardModalOpen: false,
      deleteBoardModalOpen: false,
      deletePanelModalOpen: true,
      modifyingPanelId: id
    });
  }

  closeNewBoardModal() {
    this.setState({
      newBoardModalOpen: false
    });
  }

  closeEditBoardModal() {
    this.setState({
      editBoardModalOpen: false
    });
  }

  closeDeleteBoardModal() {
    this.setState({
      deleteBoardModalOpen: false
    });
  }

  closeNewPanelModal() {
    this.setState({
      newPanelModalOpen: false,
      activePanelEdit: null
    });
  }

  closeDeletePanelModal() {
    this.setState({
      deletePanelModalOpen: false
    });
  }

  openPanelUpgradeRequiredModal() {
    this.setState({
      panelUpgradeRequiredModalOpen: true
    });
  }

  closePanelUpgradeRequiredModal() {
    this.setState({
      panelUpgradeRequiredModalOpen: false
    });
  }

  openBoardUpgradeRequiredModal() {
    this.setState({
      boardUpgradeRequiredModalOpen: true
    });
    return true;
  }

  closeBoardUpgradeRequiredModal() {
    this.setState({
      boardUpgradeRequiredModalOpen: false
    });
  }

  compileOrderData(newData: Array<MarketOrder>, existingData: Array<MarketOrder>, type: string): Array<MarketOrder> {
    if (newData.length == 0) return existingData;

    if (type == `buys`) {
      // New data will be ordered with lowest price item first (newData[0]). Filter out all existing data
      // that where the price is less than newData[0]. Cap size at 500.
      return existingData.filter((o) =>  o.price < newData[0].price).concat(newData).slice(0, 500);
    } else if (type == `sells`) {
      // New data will be ordered with lowest price item first (newData[0]). Need to append all existing data
      // sells where the price is greater than the last array item price in the new data newData[newData.length - 1].
      // Cap size at 500.
      return newData.concat(existingData.filter((o) =>  o.price > newData[newData.length - 1].price)).slice(0, 500);
    }

    return existingData;
  }

  sortOrders(orders: Array<MarketOrder>): Array<MarketOrder> {
    return orders.sort((a, b) => {
      if (a.price > b.price) return 1;
      if (b.price > a.price) return -1;
      return 0;
    });
  }

  sortTrades(trades: Array<Trade>): Array<Trade> {
    return trades.sort((a, b) => {
      if (+new Date(a.time) > +new Date(b.time)) return -1;
      if (+new Date(b.time) > +new Date(a.time)) return 1;
      if (a.market_history_id < b.market_history_id) return 1;
      if (b.market_history_id < a.market_history_id) return -1;
      if (a.price < b.price) return 1;
      if (b.price < a.price) return -1;
      return 0;
    });
  }

  getPanelData(
    panels: Array<Panel> = this.state.activeBoardPanels,
    resetSocket: boolean = false,
    isPreview: boolean = false
  ) {
    if (!panels) return;

    // TRADES  
    const tradesPromise = Promise.all(panels.reduce((mkts, p) => {
      if (NEED_TRADES.includes(p.typeId)) {
        const settings = JSON.parse(p.settingsJson || `{}`);
        const mkt: string = `${ settings.exchange }:${ settings.market }`;

        if (settings.exchange && settings.market && settings.exchange.length > 0 && settings.market.length > 0) {
          if (!mkts.includes(mkt)) return mkts.concat([mkt]);
        }
      }

      return mkts;
    }, []).map((mkt: string) => {
      return new Promise((resolve) => {
        const [exchange, market] = mkt.split(`:`);

        exchangeApi.getTrades(exchange, market, (data) => {
          if (data.success) {
            resolve({
              [mkt]: data.result.map((t) => ({ ...t, backfillTrade: true }))
            });
          } else {
            resolve({
              [mkt]: []
            });
          }
        });
      });
    })).then((tradeData) => tradeData.reduce((obj, mkt) => ({ ...obj, ...mkt }), { }));

    // TICKER DATA  
    const tickerPromise = Promise.all(panels.reduce((mkts, p) => {
      if (NEED_TICKER.includes(p.typeId)) {
        const settings = JSON.parse(p.settingsJson || `{}`);
        const mkt = `${ settings.exchange }:${ settings.market }`;
        
        if (settings.exchange && settings.market && settings.exchange.length > 0 && settings.market.length > 0) {
          if (!mkts.includes(mkt)) return mkts.concat([mkt]);
        }
      }

      return mkts;
    }, []).map((mkt: string) => {
      return new Promise((resolve) => {
        const [exchange, market] = mkt.split(`:`);

        exchangeApi.getTickerData(exchange, market, (data) => {
          if (data.success) {
            resolve({
              [mkt]: data.result
            });
          } else {
            resolve({
              [mkt]: {
                ask: 0,
                bid: 0,
                high: 0,
                last: 0,
                low: 0,
                volume: 0
              }
            });
          }
        });
      });
    })).then((tradeData) => tradeData.reduce((obj, mkt) => ({ ...obj, ...mkt }), { }));

    // MARKET DEPTH  
    const depthPromise = Promise.all(panels.reduce((mkts, p) => {
      if (NEED_DEPTH.includes(p.typeId)) {
        const settings = JSON.parse(p.settingsJson || `{}`);
        const mkt = `${ settings.exchange }:${ settings.market }`;

        if (settings.exchange && settings.market && settings.exchange.length > 0 && settings.market.length > 0) {
          if (!mkts.includes(mkt)) return mkts.concat([mkt]);
        }
      }

      return mkts;
    }, []).map((mkt: string) => {
      return new Promise((resolve) => {
        const [exchange, market] = mkt.split(`:`);

        exchangeApi.getMarketDepthData(exchange, market, (data) => {
          if (data.success) {
            resolve({
              [mkt]: {
                asks: this.sortOrders(data.result.asks).slice(0, 500),
                bids: this.sortOrders(data.result.bids).reverse().slice(0, 500).reverse()
              }
            });
          } else {
            resolve({
              [mkt]: {
                asks: [],
                bids: []
              }
            });
          }
        });
      });
    })).then((tradeData) => tradeData.reduce((obj, mkt) => ({ ...obj, ...mkt }), { }));

    // NEWS 
    const newsPromise = Promise.all(panels.reduce((terms, p) => {
      if (NEED_NEWS.includes(p.typeId)) {
        const settings = JSON.parse(p.settingsJson || `{}`);
        const search = `${ settings.searchTermOptional || `` }`;

        if (!terms.includes(search)) return terms.concat([search]);
      }

      return terms;
    }, []).map((term: string) => {
      return new Promise((resolve, reject) => {
        let payloadString =  `/private/news`;
        if (term.length > 0) payloadString = `/private/news/${encodeURIComponent(term)}`;
        $({
          url: payloadString,
          success: ({ result }) => resolve({ [term]: result }),
          error: reject,
        });
      });
    })).then((searchData) => searchData.reduce((obj, term) => ({ ...obj, ...term }), { }));

    // NOTES 
    const notesPromise = Promise.all(panels.reduce((mkts, p) => {
      if (NEED_NOTES.includes(p.typeId)) {
        const settings = JSON.parse(p.settingsJson || `{}`);
        const mkt: string = `${ settings.market }`;
        if (settings.market && settings.market.length > 0) {
          if (!mkts.includes(mkt)) return mkts.concat([mkt]);
        }
      }
      return mkts;
    }, []).map((mkt: string) => {
      return new Promise((resolve) => {
        getNotes(mkt, (data) => {
          if (data.success) {
            resolve({
              [mkt]: data.result
            });
          } else {
            resolve({
              [mkt]: ``
            });
          }
        });
      });
    })).then((tradeData) => tradeData.reduce((obj, mkt) => ({ ...obj, ...mkt }), { }));


    // BASE CURRENCY DATA
    const baseCurrencyPromise = Promise.all(panels.reduce((mkts, p) => {
      if (NEED_CURRENCY_DATA.includes(p.typeId)) {
        const settings = JSON.parse(p.settingsJson || `{}`);
        const mkt: string = `${ settings.exchange }:${ settings.market }`;
        if (settings.exchange && settings.market && settings.exchange.length > 0 && settings.market.length > 0) {
          if (!mkts.includes(mkt)) return mkts.concat([mkt]);
        }
      }
      return mkts;
    }, []).map((mkt: string) => {
      return new Promise((resolve) => {
        const [ exchCode, market ] = mkt.split(`:`);
        const baseMarket = market.split(`/`)[0];
    
        exchangeApi.getTradingCurrencyDetail(exchCode, baseMarket, (data) => { 
          if (data.success) {
            resolve({ [baseMarket]: data.result });
          } else {
            resolve({ [baseMarket]: `` });
          }
        });

      });
    })).then((tradeData) => tradeData.reduce((obj, mkt) => ({ ...obj, ...mkt }), { }));


    // QUOTE CURRENCY DATA
    const quoteCurrencyPromise = Promise.all(panels.reduce((mkts, p) => {
      if (NEED_CURRENCY_DATA.includes(p.typeId)) {
        const settings = JSON.parse(p.settingsJson || `{}`);
        const mkt: string = `${ settings.exchange }:${ settings.market }`;
        if (settings.exchange && settings.market && settings.exchange.length > 0 && settings.market.length > 0) {
          if (!mkts.includes(mkt)) return mkts.concat([mkt]);
        }
      }
      return mkts;
    }, []).map((mkt: string) => {
      return new Promise((resolve) => {
        const [ exchCode, market ] = mkt.split(`:`);
        const quoteMarket = market.split(`/`)[1];
        exchangeApi.getTradingCurrencyDetail(exchCode, quoteMarket, (data) => { 
          if (data.success) {
            resolve({ [quoteMarket]: data.result });
          } else {
            resolve({ [quoteMarket]: `` });
          }
        });
      });
    })).then((tradeData) => tradeData.reduce((obj, mkt) => ({ ...obj, ...mkt }), { }));

    // BASE MARKET SIGNALS
    const baseSignalsPromise = Promise.all(panels.reduce((mkts, p) => {
      if (NEED_SIGNALS.includes(p.typeId)) {
        const settings = JSON.parse(p.settingsJson || `{}`);
        const mkt: string = `${ settings.exchange }:${ settings.market }`;

        if (settings.exchange && settings.market && settings.exchange.length > 0 && settings.market.length > 0) {
          if (!mkts.includes(mkt)) return mkts.concat([mkt]);
        }
      }
      return mkts;
    }, []).map((mkt: string) => {
      return new Promise((resolve) => {
        const market = mkt.split(`:`)[1];
        const baseMarket = market.split(`/`)[0];
        exchangeApi.getItbSignals(baseMarket, (data) => {
          if (data) {
            resolve({ [baseMarket]: data });
          } else {
            resolve({ [baseMarket]: `` });
          }
        }, () => {
          resolve({ [baseMarket]: `` });
        });
      });
    })).then((tradeData) => tradeData.reduce((obj, mkt) => ({ ...obj, ...mkt }), { }));

    // QUOTE MARKET SIGNALS
    const quoteSignalsPromise = Promise.all(panels.reduce((mkts, p) => {
      if (NEED_SIGNALS.includes(p.typeId)) {
        const settings = JSON.parse(p.settingsJson || `{}`);
        const mkt: string = `${ settings.exchange }:${ settings.market }`;

        if (settings.exchange && settings.market && settings.exchange.length > 0 && settings.market.length > 0) {
          if (!mkts.includes(mkt)) return mkts.concat([mkt]);
        }
      }
      return mkts;
    }, []).map((mkt: string) => {
      return new Promise((resolve) => {
        const market = mkt.split(`:`)[1];
        const quoteMarket = market.split(`/`)[1]; 
        exchangeApi.getItbSignals(quoteMarket, (data) => {
          if (data) {
            resolve({ [quoteMarket]: data });
          } else {
            resolve({ [quoteMarket]: `` });
          }
        }, () => {
          resolve({ [quoteMarket]: `` });
        });
      });
    })).then((tradeData) => tradeData.reduce((obj, mkt) => ({ ...obj, ...mkt }), { }));


    Promise.all([
      tradesPromise, tickerPromise, depthPromise, newsPromise, notesPromise, 
      baseCurrencyPromise, quoteCurrencyPromise, baseSignalsPromise, 
      quoteSignalsPromise
    ]).then(([trades, tickers, depths, news, notes, baseCurrency, quoteCurrency, baseSignals, quoteSignals]) => {
      const panelData = this.state.panelData;

      Object.keys(trades).forEach((mkt) => {
        if (!panelData.hasOwnProperty(mkt)) panelData[mkt] = { };
        this.bufferedTrades[mkt] = [];
        panelData[mkt].trades = trades[mkt];
      });

      Object.keys(tickers).forEach((mkt) => {
        if (!panelData.hasOwnProperty(mkt)) panelData[mkt] = { };
        panelData[mkt].ticker = tickers[mkt];
      });

      Object.keys(depths).forEach((mkt) => {
        if (!panelData.hasOwnProperty(mkt)) panelData[mkt] = { };
        panelData[mkt].depth = depths[mkt];
      });


      Object.keys(notes).forEach((mkt) => {
        if (!panelData.hasOwnProperty(mkt)) panelData[mkt] = { };
        panelData[mkt].notes = notes[mkt];
      });

      Object.keys(baseCurrency).forEach((mkt) => {
        if (!panelData.hasOwnProperty(mkt)) panelData[mkt] = { };
        panelData[mkt].currData = baseCurrency[mkt];
      });

      Object.keys(quoteCurrency).forEach((mkt) => {
        if (!panelData.hasOwnProperty(mkt)) panelData[mkt] = { };
        panelData[mkt].currData = quoteCurrency[mkt];
      });

      Object.keys(baseSignals).forEach((mkt) => {
        if (!panelData.hasOwnProperty(mkt)) panelData[mkt] = { };
        panelData[mkt].signals = baseSignals[mkt];
      });

      Object.keys(quoteSignals).forEach((mkt) => {
        if (!panelData.hasOwnProperty(mkt)) panelData[mkt] = { };
        panelData[mkt].signals = quoteSignals[mkt];
      });

      Object.keys(news).forEach((term) => {
        if (!panelData[`NEWSFEED`]) panelData[`NEWSFEED`] = { };
        if (!panelData[`NEWSFEED`][term]) panelData[`NEWSFEED`][term] = [];
        panelData[`NEWSFEED`][term] = panelData[`NEWSFEED`][term].concat(news[term]).reduce((items, item) => {
          if (!items.find(({ id }) => id == item.id)) return items.concat([item]);
          return items;
        }, []);
      });

      this.setStateSafe({
        panelData
      }, () => this.applyTradeBuffer());

    }).then(() => {
      if (resetSocket) this.subscribeToPanelData(panels.reduce((_mkts, p) => {
        const settings = JSON.parse(p.settingsJson || `{}`);
        const mkts = { ..._mkts };
        const mkt = `${ settings.exchange }:${ settings.market }`;

        if (NEED_TRADES.includes(p.typeId)) {
          if (!mkts.hasOwnProperty(mkt)) mkts[mkt] = [];
          if (!mkts[mkt].includes(`trades`)) mkts[mkt].push(`trades`);
        }

        if (NEED_DEPTH.includes(p.typeId)) {
          if (!mkts.hasOwnProperty(mkt)) mkts[mkt] = [];
          if (!mkts[mkt].includes(`orders`)) mkts[mkt].push(`orders`);
        }

        return mkts;
      }, { }), isPreview);
    });
  }

  subscribeToPanelData(markets: { [string]: Array<string> }, isPreview: boolean = false) {
    unsubscribe(undefined, `boards-aggregated-markets`);
    unsubscribe(undefined, `boards`);
  
    if (!markets || isPreview) return;

    const arrSubs = [`NEWS`];
  
    Object.keys(markets).map((m: any) => {
      const [exchCode, marketName] = m.split(`:`);
      const realtimeMktName = exchCode == `DERI` ? marketName.substr(4, marketName.length) : marketName;
      let marketsToSubscribe = [];

      if (this.props.aggregatedMarkets?.length > 0 && exchCode === `LSCX`) {
        marketsToSubscribe = this.props.aggregatedMarkets?.filter((agm) => agm.exchCode === exchCode && agm.marketName === marketName )[0].aggregatedMarkets.map((selectedAggregatedMarket) => {
          const [eCode, mName] = selectedAggregatedMarket.split(`:`);
          return {
            type: `market`,
            data: [eCode, mName]
          };
        });
      }

      if (marketsToSubscribe && marketsToSubscribe.length > 1) {
        subscribeToAggregatedMarkets(marketsToSubscribe, { type: `market`, data: [exchCode, realtimeMktName, markets[m]] }, (ch) => this.subscribeToPanelDataCallback(ch, m), `boards-aggregated-markets`);
      } else {
        arrSubs.push({ type: `market`, data: [exchCode, realtimeMktName, markets[m]] });
      }
    });

    subscribe(arrSubs, (ch) => this.subscribeToPanelDataCallback(ch), `boards`);
  }
  
  subscribeToPanelDataCallback(channels: any, market: any) {
    for (const m in channels) {
      if (channels[m].trades) {
        channels[m].trades.watch((d) => {
          const currentMkt = market ?? m;
          if (this.state.panelData[currentMkt] && this.state.panelData[currentMkt].trades && this.bufferedTrades.hasOwnProperty(currentMkt)) {
            if(d.exchange != m.split(`:`)[0] || d.label != currentMkt.split(`:`)[1]){
              return;
            }

            this.bufferedTrades[currentMkt].push({
              ...d,
              time: forceUTCParsing(d.time_local),
              tradeId: d.tradeid
            });
          }
        });
      }
  
      if (channels[m].orders) {
        channels[m].orders.watch((d) => {
          if (this.state.panelData[m] && this.state.panelData[m].depth) {
            this.setStateSafe({
              panelData: {
                ...this.state.panelData,
                [m]: {
                  ...this.state.panelData[m],
                  depth: {
                    bids: this.compileOrderData(
                      this.sortOrders(d.filter((o) => o.ordertype == `Buy`)),
                      this.sortOrders(this.state.panelData[m].depth.bids),
                      `buys`
                    ),
                    asks: this.compileOrderData(
                      this.sortOrders(d.filter((o) => o.ordertype == `Sell`)),
                      this.sortOrders(this.state.panelData[m].depth.asks),
                      `sells`
                    )
                  }
                }
              }
            });
          }
        });
      }
    }
  
    if (channels[`NEWS`] && this.state.panelData[`NEWSFEED`]) {
      channels[`NEWS`].channel.watch((n) => {
        const news = this.state.panelData[`NEWSFEED`] || { };
  
        Object.keys(news).filter((k) => n.title.indexOf(k) > -1).forEach((k) => {
          news[k].push({
            title: n.title,
            id: n.id.toString(),
            url: n.url,
            feedImage: n.feed_image,
            feedName: n.feed_name,
            pubDate: n.published_date
          });
        });
  
        this.setStateSafe({
          panelData: {
            ...this.state.panelData,
            "NEWSFEED": news
          }
        });
      });
    }
  }

  applyTradeBuffer() {
    clearTimeout(this.state.throttleTimeout);

    const panelData = JSON.parse(JSON.stringify(this.state.panelData));

    Object.keys(this.bufferedTrades).forEach((mkt) => {
      panelData[mkt].trades = this.sortTrades(this.bufferedTrades[mkt].concat(panelData[mkt].trades)).slice(0, 50);
      this.bufferedTrades[mkt] = [];
    });

    this.setStateSafe({ 
      panelData,
      throttleTimeout: setTimeout(() => {
        this.applyTradeBuffer();
      }, THROTTLE_MS)
    });
  }

  copyURLToClipboard(e: any) {
    e.preventDefault();

    const toCopy: any = document.getElementById(`boardShareURL`);

    if (toCopy) {
      toCopy.focus();
      toCopy.select();
      document.execCommand(`copy`);
    }
  }

  importBoard() {
    const board = this.state.boards.find((b) => b.boardId == this.props.activeBoardId);

    if (!board) return;

    if (this.state.activeBoardPanels.length > this.props.maxPanels) {
      return this.openPanelUpgradeRequiredModal();
    }

    importBoard(board.boardId, () => {
      this.setState({
        boards: this.state.boards.map((b) => {
          if (b.boardId == board.boardId) delete b.preview;

          return b;
        })
      }, () => {
        this.props.history.push(`/boards`);
        this.componentDidMount(true);
      });
    });
  }

  exitSharedBoard() {
    this.setState({
      boards: this.state.boards.filter((b) => !b.preview)
    }, () => {
      if (this.state.boards[0]) this.setActiveBoard(this.state.boards[0].boardId, this.state.boards[0].label);
      this.props.history.push(`/boards`);
      this.componentDidMount(true);
    });
  }

  handleBoardDragStart(e: any) {
    this.setState({
      draggingBoard: parseInt(e.target.id.split(`BOARD_`)[1])
    });

    e.dataTransfer.effectAllowed = `move`;
    e.dataTransfer.setData(`text/plain`, `fixforfirefox`);
  }

  handleBoardDragLeave(e: any) {
    let target = e.target;

    if (!target.classList.contains(`board-dropzone`)) {
      target = target.parentNode;
    }

    this.handleBoardsDrop(this.state.draggingBoard, parseInt(target.id.split(`BOARD_`)[1]));
  }

  handleBoardsDrop(from: number, to: number) {
    const fromBoard = this.state.boards.find((b) => b.boardId == from);
    const fromIndex = this.state.boards.findIndex((b) => b.boardId == from);
    const toIndex = this.state.boards.findIndex((b) => b.boardId == to);

    if (fromIndex == -1 || toIndex == -1 || !fromBoard) return;

    const filteredBoards = this.state.boards.filter((b) => b.boardId !== from);

    if (fromIndex > toIndex) {
      filteredBoards.splice(toIndex, 0, fromBoard);
    } else {
      filteredBoards.splice(toIndex + 1, 0, fromBoard);
    }

    this.updateBoardSortOrderBasedOnIndex(filteredBoards);
  }

  boardSnapshot() {
    if (this.state.isBoardSnapshotLoading) return;

    this.setState({ isBoardSnapshotLoading: true });
    
    const filter =  (node) => {  
      if (node?.src && !node.src.startsWith(window?.location?.origin)) return false;

      if ((node?.getAttribute && node.getAttribute(`xlink:href`))) return false;
 
      return true;
    };

    toPng(document.querySelector(`.board-wrapper .board .flexlayout__layout`), { filter, height: window.height })
      .then((dataUrl) => {
        const body = {
          boardId: this.props.activeBoardId,
          fileExtension: `png`,
          encodedImage: dataUrl.replace(`data:image/png;base64,`, ``)
        };

        postBoardSnapshots(body)
          .then((data) => {
            if (data.success) {
              this.setState({
                boardSnapshotUrl: data.result,
                isBoardSnapshotLoading: false
              }, () => window.twttr.widgets.load());
            } else {
              this.setState({ isBoardSnapshotLoading: false }); 
            }
          });
      })
      .catch(() => this.setState({ isBoardSnapshotLoading: false }));
  }

  render() {
    const board = this.state.boards.find((b) => b.boardId == this.props.activeBoardId);

    if (this.state.newBoardModalOpen) updateTitle(`Boards`, this.props.platformId);

    return (
      <div className="boards-page">
        <div className="boards-header">
          <div className="boards-list">
            {
              (board && board.preview ? [board] : this.state.boards).sort((a, b) => {
                if (a.sortOrder > b.sortOrder) return 1;
                if (b.sortOrder > a.sortOrder) return -1;
                return 0;
              }).map((b) => (
                <Button
                  customProps={ {
                    id: `BOARD_${ b.boardId }`,
                    draggable: true,
                    onDragStart: this.handleBoardDragStart.bind(this),
                    onDragLeave: this.handleBoardDragLeave.bind(this)
                  } }
                  className="board-dropzone"
                  useSpanInsteadOfButton
                  key={ `board-pill-${ b.boardId }` }
                  type={ `ghost${ b.boardId == this.props.activeBoardId ? ` active` : `` }` }
                  onClick={ this.setActiveBoard.bind(this, b.boardId, b.label) }>
                  { b.label }
                  {
                    b.boardId == this.props.activeBoardId && (
                      <a 
                        className={ b.preview ? `disabled` : `` }
                        onClick={ b.preview ? undefined : this.openEditBoardModal.bind(this) }>
                        { Edit(`${ b.boardId }--Edit`) }
                      </a>
                    )
                  }
                  {
                    b.boardId == this.props.activeBoardId && (
                      <a 
                        className={ b.preview ? `disabled` : `` }
                        onClick={ b.preview ? undefined : this.openDeleteBoardModal.bind(this) }>
                        { Trash(`${ b.boardId }--Trash`) }
                      </a>
                    )
                  }
                </Button>
              ))
            }

            {
              (!board || (board && !board.preview)) && (
                <Button
                  type="ghost"
                  onClick={ this.openNewBoardModal.bind(this) }>
                  + 
                  { ` ` }
                  { this.props.t(`newBoard`) }
                </Button>
              )
            }
          </div>

          <div className="board-snapshot">
            <a onClick={ this.boardSnapshot.bind(this) }>
              <span className={ `action-container` } >
                { Camera }
                { this.state.isBoardSnapshotLoading && (
                  <span className={ `spinner-container` } >
                    <ProgressSpinner className={ `spinner` }/>
                  </span>
                ) }
              </span>
            </a>
          </div>

          <div className="board-lock">
            <a onClick={ this.props.toggleBoardLock }>
              { this.props.boardLocked ? BoardLock : BoardUnlock }
            </a>
          </div>

          {
            board && !board.preview && !this.state.panelUpgradeRequiredModalOpen && (
              <div className="board-share">
                <TextField
                  readOnly
                  label={ this.props.t(`shareBoardUrl`) }
                  name="boardShareURL"
                  compact={ true }
                  value={ `${ window.location.protocol }//${ window.location.host }/boards/${ board.tag }` } />
                <button onClick={ this.copyURLToClipboard.bind(this) }>
                  { Copy }
                </button>
              </div>
            )
          }
        </div>

        {
          board && (
            <Board
              locked={ this.props.boardLocked }
              t={ this.props.t }
              dataHasLoaded={ this.state.dataHasLoaded }
              markets={ this.props.markets }
              exchanges={ this.props.exchanges }
              balances={ this.props.balances }
              accounts={ this.props.accounts }
              board={ board }
              panels={ this.orderPanels(this.state.activeBoardPanels) }
              newPanel={ this.openNewPanelModal.bind(this) }
              deletePanel={ this.openDeletePanelModal.bind(this) }
              editPanelInline={ this.editPanelInline.bind(this) }
              editPanelCustomSize={ this.editPanelCustomSize.bind(this) }
              cancelEditPanelInline={ this.cancelEditPanelInline.bind(this) }
              savePanelInline={ this.savePanelInline.bind(this) }
              canCreateNewPanel={ this.state.canCreateNewPanel }
              modifyingPanelsInline={ this.state.modifyingPanelsInline }
              duplicatePanel={ this.duplicatePanel.bind(this) }
              editMarket={ this.openPanelMarketSelectorModal.bind(this) }
              currencies={ this.props.currencies }
              panelTypes={ this.state.panelTypes }
              handleDragAndDrop={ this.updatePanelOrder.bind(this) }
              panelData={ this.state.panelData }
              setPanelMarket={ this.setPanelMarket.bind(this) }
              maxPanels={ this.props.maxPanels } 
              refreshBalance={ this.props.refreshBalance }
              saveNewPanel={ this.addNewPanel.bind(this) }
              isNewPanelModalOpen={ this.state.newPanelModalOpen && board && !this.state.activePanelEdit } 
              closeNewPanelModal={ this.closeNewPanelModal.bind(this) }
              isDeletePanelModalOpen={ this.state.deletePanelModalOpen }
              deletePanelId={ this.deletePanel.bind(this) }
              closeDeletePanelModal={ this.closeDeletePanelModal.bind(this) }/>
          )
        }

        {
          board && board.preview && !this.state.panelUpgradeRequiredModalOpen && (
            <ImportBoardModal
              confirm={ this.importBoard.bind(this) }
              cancel={ this.exitSharedBoard.bind(this) }
              board={ board }
              disabled={ this.state.boards.filter((b) => !b.preview).length + 1 > this.props.maxBoards } />
          )
        }

        {
          this.state.newBoardModalOpen && (
            <BoardFormModal
              importDemo={ this.importDemoBoard.bind(this) }
              save={ this.addNewBoard.bind(this) }
              close={ this.closeNewBoardModal.bind(this) }
              title={ this.props.t(`newBoard`) } 
              disabled={ !this.state.canCreateNewBoard } />
          )
        }
        {
          this.state.marketSelectorModalOpen && (
            <MarketSelectorModal
              showLSCX={ true }
              exchanges={ this.props.exchanges }
              markets={ this.props.markets }
              accounts={ this.props.accounts }
              panel={ this.state.activePanelEdit }
              exit={ this.exitPanelMarketSelectorModal.bind(this) }
              setMarket={ this.setPanelMarket.bind(this) } />
          )
        }
        {
          this.state.editBoardModalOpen && board && (
            <BoardFormModal
              save={ this.editBoard.bind(this) }
              close={ this.closeEditBoardModal.bind(this) }
              label={ board.label }
              title={ this.props.t(`editBoard`) } />
          )
        }
        {
          this.state.deleteBoardModalOpen && board && (
            <DeleteBoardModal
              label={ board.label }
              delete={ this.deleteBoard.bind(this) }
              close={ this.closeDeleteBoardModal.bind(this) } />
          )
        }
        {
          this.state.newPanelModalOpen && this.state.activePanelEdit && board && (
            <PanelModal
              title={ this.props.t(`editPanel`) }
              panelTypes={ this.state.panelTypes }
              markets={ this.props.markets }
              exchanges={ this.props.exchanges }
              accounts={ this.props.accounts }
              data={ this.state.activePanelEdit }
              save={ this.editPanel.bind(this) }
              disabled={ !this.state.canCreateNewPanel }
              close={ this.closeNewPanelModal.bind(this) } />
          )
        }
        {
          this.state.boardUpgradeRequiredModalOpen && (
            <UpgradeRequiredModal
              max={ this.props.maxBoards }
              type="board"
              cancel={ this.closeBoardUpgradeRequiredModal.bind(this) } />
          )
        }
        {
          this.state.panelUpgradeRequiredModalOpen && (
            <UpgradeRequiredModal
              max={ this.props.maxPanels }
              type="panel"
              cancel={ this.closePanelUpgradeRequiredModal.bind(this) }/>
          )
        }
        {
          this.state.boardSnapshotUrl.length > 0 && (
            <BoardSnapshotModal
              boardTag={ board ? board.tag : `` }
              url={ this.state.boardSnapshotUrl }
              close={ () => this.setState({ boardSnapshotUrl: `` }) } />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeBoardId: state.boards.activeBoardId,
  maxBoards: state.userInfo.userPrefs.prefBoardLimit,
  maxPanels: state.userInfo.userPrefs.prefPanelLimit,
  boardLocked: state.boards.boardLocked,
  platformId: state.userInfo.user.platformId
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveBoardId: (id: number) => dispatch(updateActiveBoardId(id)),
  toggleBoardLock: () => dispatch(toggleBoardLocked())
});

export { BoardsDataLayerComponent as PureBoardsDataLayerComponent };
export default withRouter(translate(`boards`)(connect(mapStateToProps, mapDispatchToProps)(BoardsDataLayerComponent)));
