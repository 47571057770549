// @flow
'use strict';

import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import BalancesEmptyData from './BalancesEmptyData.jsx';
import EntityTable from "../utilities/EntityTable.jsx";
import { toFixedDecimals } from "../../helpers/NumberHelper.js";
import { getActiveTheme } from '../../helpers/ThemeHelper.js';
import { CHART_COLORS } from "../../constants/balances.js";
import type { Exchange } from "../../types/Exchange.js";
import type { Account } from "../../types/Account.js";
import type { Balance } from "../../types/Balance.js";

type Props = {
  t: any,
  hasData: boolean,
  balances?: Array<Balance>,
  currencyHistoryData: Map<string, Array<any>>,
  exchanges: Array<Exchange>,
  accounts: Array<Account>,
  theme: string,
  selectedQuoteCurrencyCode: string,
};





const GainersLosersComponent = (props: Props) => {
  const { balances, currencyHistoryData, exchanges, accounts, theme, selectedQuoteCurrencyCode } = props;

  if (!balances || !accounts || !exchanges || !currencyHistoryData
      || Array.from(currencyHistoryData.entries()).length === 0) {
    return (<BalancesEmptyData l={ props.hasData } />);
  }

  const data = Array.from(
    balances.reduce((currencyMap: Map<number, any>, currentBalance) => {
      if (currencyMap.has(currentBalance.balanceCurrId)) {
        const current = currencyMap.get(currentBalance.balanceCurrId);
        if (current) {
          current.quoteBalance = currentBalance.quoteBalance + current.quoteBalance;
          currencyMap.set(currentBalance.balanceCurrId, current);
        }
      } else {
        currencyMap.set(currentBalance.balanceCurrId, { ...currentBalance });
      }
       
      return currencyMap;
    }, new Map()).values()
  );

  const currencyDataTable = data.map((item, index) => {
    const getCurrency = currencyHistoryData.get(item.balanceCurrCode),
      initialCurrencyPrice = parseFloat(getCurrency ? getCurrency[0].quoteBalance : 0),
      firstPrice = parseFloat(getCurrency ? getCurrency[getCurrency.length -1].quoteBalance : 0);
    let diff = Math.round((initialCurrencyPrice - firstPrice) / firstPrice * 10000) / 100;
    if (isNaN(diff) || !isFinite(diff)) {
      diff = 0;
    }
    return ({
      ...item,
      initialCurrencyPrice,
      color: CHART_COLORS[index % CHART_COLORS.length],
      diff
    });
  });
  
  return (
    <div className="balance-chart">
      <EntityTable
        columns={ [
          {
            title: ``,
            key: `logo`,
            display: (row) => {
              const image = row.hasImage ? row.balanceCurrCode.toUpperCase() : `empty`;

              return (
                <img 
                  src={ `${ window.WWW_URL }/assets/img/currency/${ image }.png` } 
                  width={ 20 } />
              );
            },
            thClass: `align-right`,
            tdClass: `align-right`
          },
          {
            title: props.t(`app:currency`),
            key: `balanceCurrCode`,
            display: (row) => {
              return (
                <div style={ { whiteSpace: `nowrap` } }>
                  <span className="point-color" style={ { backgroundColor: row.color } } />
                  { row.balanceCurrCode }
                  { ` ` }
                  <span className="muted">
                      (
                    { row.diff }
                      %)
                  </span>
                </div>
              );
            },
          },
          {
            title: `${ props.t(`app:qty`) }`.toUpperCase() + `(${ selectedQuoteCurrencyCode })`,
            key: `quoteBalance`,
            display: (row) => toFixedDecimals(row.quoteBalance),
          },
          {
            title: `% ${ props.t(`app:change`) } (${ selectedQuoteCurrencyCode } Value)`,
            key: `diff`,
            display: (row) => {
              return (
                <div
                  title={ `${ row.diff }%` }
                  style={ {
                    width: `${ Math.abs(row.diff) > 100 ? 100 : Math.abs(row.diff) }%`,
                    backgroundColor: row.diff > 0 ? getActiveTheme(theme).green : getActiveTheme(theme).red,
                    height: 3,
                  } } />
              );
            }
          },
        ] }
        pageSize={ 999 }
        totalRecords={ data.length }
        totalPages={ 1 }
        hidePagination={ true }
        defaultSortBy={ `-diff` }
        data={ currencyDataTable }/>
    </div>    
  );
};

const mapStateToProps = (state) => ({
  theme: state.redisPrefs.theme,
  selectedQuoteCurrencyCode: state.app.selectedQuoteCurrencyCode
});

export { GainersLosersComponent };
export default translate(`balances`)(connect(mapStateToProps)(GainersLosersComponent));
