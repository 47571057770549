// @flow
'use strict';

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Marquee from "react-fast-marquee";
import { InView } from 'react-intersection-observer';
import FavoriteFooter from "../FavoriteFooter.jsx";
import { changeMarket } from '../../actions/markets/changeMarket.js';
import type { Favorite } from "../../types/Favorite";
import type { Market } from "../../types/Market";

type Props = {
  t: any,
  markets: Array<Market>,
  favorites: Array<Favorite>,
  marketsAreClickable: boolean,
  changeMarket: (b: boolean) => void,
};


const Ticker = (props: Props) => {
  const { t, markets, favorites, marketsAreClickable, changeMarket } = props;
  
  const [ favs, setFavs ] = useState([]);
  const [ play, setPlay ] = useState(false);

  const lastIdx  = useRef(0);
  const inViewArr  = useRef([]);

  useEffect(() => {
    const _favs = favorites.reduce((accum, f) => {
      const market = markets?.find((m) =>  m.exchCode == f.exchCode && m.displayName == f.displayName);
      if (market) {
        return [
          ...accum,
          {
            favorite: f,
            market: market
          }
        ];
      }
      return accum;
    }, []);

    inViewArr.current = inViewArr.current.filter((mkt) => {
      if(_favs.find((f) => `${f.market.exchCode}:${f.market.displayName}` === mkt)) return mkt;
    });

    if (lastIdx.current > inViewArr.current.length) {
      setPlay(false);
      lastIdx.current = inViewArr.current.length;
    }

    setFavs(_favs);

  }, [favorites, markets]);

  return (
    <div className="ticker-component">
      <ul>
        <Marquee loop={ play ? 0 : -1 } pauseOnHover={ play } gradient={ false }>
          {
            favs.map((f, i) => 
              <InView
                as="li" key={ `favorite-${ f.market.exchmktId }` } threshold={ 1 } onChange={ (inView) => { 
                  const mkt =`${f.market.exchCode}:${f.market.displayName}`;

                  if (!inViewArr.current.find((m) => m === mkt))  {
                    inViewArr.current.push(mkt);

                    if (!inView) {
                      lastIdx.current = i;
                      setPlay(true);
                    }
                  }
                } }>
                <FavoriteFooter
                  favorite={ f.favorite }
                  market={ f.market }
                  clickable={ marketsAreClickable }
                  isPercentVisible={ true }
                  changeMarket={ changeMarket } />
              </InView>
            )
          }
        </Marquee>
      </ul>
      <Link to="/user/favorites" className='add-favorite'>
        { t(`tour:favStar`) }
      </Link>
    </div>
  );
  
};


const mapStateToProps = (state) => ({
  favorites: state.app.favorites,
  marketsAreClickable: state.browser.marketsAreClickable,
});

const mapDispatchToProps = (dispatch) => ({
  changeMarket: (b) => dispatch(changeMarket(b))
});

export { Ticker as PureTicker };
export default connect(mapStateToProps, mapDispatchToProps)(Ticker);
