// @flow
'use strict';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import EntityTable from '../utilities/EntityTable.jsx';
import Tabs from '../utilities/Tabs.jsx';
import PillToggle from "../utilities/PillToggle.jsx";
import Button from '../utilities/Button.jsx';
import SelectField from '../utilities/SelectField.jsx';
import Trash from '../../svgs/Trash.jsx';
import { getMarketPair } from '../../helpers/MarketPairHelper';
import { toLocalDate } from '../../helpers/DateHelper.js';
import { updateRedisPrefPage } from '../../actions/redisPrefs/updateRedisPrefs.js';
import { changeMarket } from '../../actions/markets/changeMarket.js';
import type { ActiveAlert } from '../../types/ActiveAlert.js';
import type { HistoryAlert } from '../../types/HistoryAlert.js';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import RightAngleBracket from '../../svgs/RightAngleBracket.jsx';
import LeftAngleBracket from '../../svgs/LeftAngleBracket.jsx';
import { emitEvent, POPULATE_ALERT_FORM } from '../../helpers/EventHelper.js';
import { mapRawAlertsToActiveAlerts, mapRawHistoryAlertsToHistoryAlerts } from '../../helpers/MapAlertsHelper';

const API_POLL_MS = 90000;
const PAGE_SIZES = [10, 25, 50, 100, 250];
const DEFAULT_PAGE_SIZE = 25;

export type Props = {
  t: any,
  markets?: Array<Market>,
  exchanges?: Array<Exchange>,
  active: {
    market: Market,
    exchange: Exchange
  },
  activeAlerts: Array<ActiveAlert>,
  activeAlertsPage: number,
  activeAlertsPages: number,
  alertHistory: Array<HistoryAlert>,
  alertHistoryPage: number,
  alertHistoryPages: number,
  deleteAlert: (id: number) => boolean,
  marketsAreClickable: boolean,
  history: { push: (any) => void },
  pageSize: number,
  changePageSize: (n: any) => void,
  getUserAlerts: (pageNumber: number, searchTerm: string) => void,
  changeMarket: (b: boolean) => void,
};

const MarketAlerts = (props: Props) => {
  const { active: { market, exchange } } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [exchFilter, setExchFilter] = useState(exchange.exchCode);
  const [mktFilter, setMktFilter] = useState(getMarketPair(market).toString());
  const [apiInterval, setApiInterval] = useState(null);

  useEffect(() => {
    setExchFilter(exchange.exchCode);
  }, [exchange.exchCode]);

  useEffect(() => {
    setMktFilter(getMarketPair(market).toString());
  }, [getMarketPair(market).toString()]);

  const getFilteredData = (tab: string): Array<ActiveAlert> | Array<HistoryAlert> => {
    if (!tab) return Array<ActiveAlert>(0);
    if (!props.hasOwnProperty(tab)) return Array<ActiveAlert>(0);
    return (tab === `activeAlerts`) ? mapRawAlertsToActiveAlerts(props[tab]) : mapRawHistoryAlertsToHistoryAlerts(props[tab]);
  };

  const displayTime = (row: any, date: string) => {
    if (!row || !date) return null;
    return toLocalDate(date);
  };

  const displayDeleteButton = (row: any, id: number) => {
    if (!row || !id) return null;
    return (
      <Button
        type="cancel"
        onClick={ () => props.deleteAlert(id) }>
        { Trash(`Delete${ id }`) }
      </Button>
    );
  };

  const getUserAlerts = (pageNumber: number, searchTerm: string) => {    
    const intervalId = setInterval(() => { 
      props.getUserAlerts(pageNumber, searchTerm); 
    }, API_POLL_MS);
    
    setApiInterval(intervalId);

    props.getUserAlerts(pageNumber, searchTerm); 
  };  

  const fetchUserAlerts = (pageNumber: number) => {
    let searchTerm = ``;

    if (exchFilter !== `all` && mktFilter == `all`) {
      searchTerm = `${exchFilter}:`;
    }

    if (exchFilter == `all` && mktFilter !== `all`) {
      searchTerm = mktFilter;
    }

    if (exchFilter == `all` && mktFilter == `all`) {
      searchTerm = `all`;
    }

    getUserAlerts(pageNumber, searchTerm);
  };
  
  useEffect(() => {
    let searchTerm = ``;
    if (exchFilter !== `all` && mktFilter == `all`) {
      searchTerm = exchFilter;
    }
    if (exchFilter == `all` && mktFilter !== `all`) {
      searchTerm = mktFilter;
    }
    if (exchFilter == `all` && mktFilter == `all`) {
      searchTerm = `all`;
    }

    getUserAlerts(1, searchTerm);
  }, [props.pageSize, activeTab, exchFilter, mktFilter]);

  useEffect(() => {
    return () => {
      if (apiInterval) clearInterval(apiInterval);
    };
  }, [apiInterval]);

  const activeColumns = [
    {
      title: props.t(`app:market`),
      key: `market`,
      display: (row: ActiveAlert) => (
        <Link
          to={ `/markets/${ row.data.exchCode }/${ row.market }` }
          onClick={ (e) => {
            e.preventDefault();
            props.history.push(`/markets/${row.data.exchCode}/${row.market}`);
            setTimeout(() => emitEvent(POPULATE_ALERT_FORM, row), 0);
            props.changeMarket(true);
          } } >
          { `${ row.data.exchCode }:${ row.market }` }
        </Link>
      )
    },
    {
      title: props.t(`app:criteria`),
      key: `criteria`,
      nosort: true
    },
    {
      title: props.t(`app:time`),
      key: `time`,
      display: displayTime
    },
    {
      title: ``,
      key: `alertId`,
      nosort: true,
      display: displayDeleteButton.bind(this)
    }
  ];
  const historyColumns = [
    {
      title: props.t(`app:market`),
      key: `market`,
      display: (row: ActiveAlert) => (
        <Link
          to={ `/markets/${ row.data.exchCode }/${ row.market }` }
          onClick={ (e) => {
            e.preventDefault();
            props.history.push(`/markets/${row.data.exchCode}/${row.market}`);
            setTimeout(() => emitEvent(POPULATE_ALERT_FORM, row), 0);
            props.changeMarket(true);
          } } >
          { `${ row.data.exchCode }:${ row.market }` }
        </Link>
      )

    },
    {
      title: props.t(`app:criteria`),
      key: `criteria`,
      nosort: true
    },
    {
      title: props.t(`app:triggered`),
      key: `time`,
      display: displayTime
    }
  ];

  const currentPage = activeTab === 0 ? props.activeAlertsPage : props.alertHistoryPage;
  const firstPage = currentPage === 1;
  const lastPage = activeTab === 0 ? currentPage === props.activeAlertsPages : currentPage === props.alertHistoryPages;

  return (
    <div className="market-alerts">
      <div className="header">
        <PillToggle
          options={ [
            { label: props.active.exchange.exchCode, value: props.active.exchange.exchCode },
            { label: props.t(`app:all`), value: `all` }
          ] }
          value={ exchFilter }
          onChange={ (e, v) => setExchFilter(v) } />
        <PillToggle
          options={ [
            {
              label: getMarketPair(props.active.market).toString(),
              value: getMarketPair(props.active.market).toString()
            },
            { label: props.t(`app:all`), value: `all` }
          ] }
          value={ mktFilter }
          onChange={ (e, v) => setMktFilter(v) } />
      </div>
      <Tabs
        activeTab={ activeTab }
        tabPosition="center"
        onChangeTab={ (v) => setActiveTab(v) }
        tabNames={ [props.t(`alerts:activeAlerts`), props.t(`alerts:alertHistory`)] }>
        {
          [`activeAlerts`, `alertHistory`].map((item, i) => (
            <EntityTable
              key={ i }
              columns={ item == `activeAlerts` ? activeColumns : historyColumns }
              data={ getFilteredData(item) }
              defaultSortBy={ `-time` }
              hidePagination={ true }
              scrollFix={ true }/>
          ))
        }
      </Tabs>
      <div className="footer">
        <div className="page-size">
          { props.t(`app:rows`) }
          <SelectField
            label=""
            name="market-alerts"
            value={ props.pageSize }
            hideValue={ false }
            options={ PAGE_SIZES.map((value) => {
              return ({
                label: value.toString(),
                value: value.toString()
              });
            }) }
            onChange={ (e, v) => props.changePageSize(v) }/>
        </div>
        <div className="page-nav">
          <div className="pages-text">
            Page 
            { ` ` }
            { activeTab === 0 ? props.activeAlertsPage : props.alertHistoryPage }
            { ` ` }
            of 
            { ` ` }
            { activeTab === 0 ? props.activeAlertsPages : props.alertHistoryPages }
          </div>
          <a
            className={ firstPage ? `noclick` : `` }
            onClick={ () => !firstPage ? fetchUserAlerts(currentPage === 1 ? 1 : currentPage - 1) : null }>
            <LeftAngleBracket />
          </a>
          { ` ` }
          <a
            className={ lastPage ? `noclick` : `` }
            onClick={ () => !lastPage ? fetchUserAlerts(currentPage + 1) : null }>
            <RightAngleBracket />
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  marketsAreClickable: state.browser.marketsAreClickable,
  pageSize: state.redisPrefs.prefPage?.marketAlerts?.pageSize || DEFAULT_PAGE_SIZE
});

const mapDispatchToProps = (dispatch) => ({
  changePageSize: (p) => dispatch(updateRedisPrefPage({ key: `marketAlerts`, value: { 'pageSize': p } })),
  changeMarket: (b) => dispatch(changeMarket(b))
});

export { MarketAlerts as PureMarketAlerts };
export default withRouter(translate(`markets`)(connect(mapStateToProps, mapDispatchToProps)(MarketAlerts)));

