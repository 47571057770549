// @flow
'use strict';

import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import NewOrder from '../orders/NewOrder.jsx';
import type { Account } from '../../types/Account.js';
import type { Currency } from '../../types/Currency.js';
import type { Balance } from '../../types/Balance.js';
import type { Market } from '../../types/Market.js';
import type { Exchange } from '../../types/Exchange.js';
import { toFixedDecimals } from '../../helpers/NumberHelper.js';
import { useHistory } from 'react-router-dom';

type Props = {
  setPanelMarket: (exchCode: string, mktName: string, activePanelEdit: any) => void,
  t: any,
  panelData: any, 
  settings: any, 
  panel: any,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  currencies: Array<Currency>,
  balances: Array<Balance>,
  accounts: Array<Account>,
};

const OrderFormPanel = (props: Props) => {
  const { settings, t, panelData, panel, exchanges, markets, currencies, balances, accounts } = props;
  const [exchangeIdSelectBox, setExchangeIdSelectBox] = useState<number>(-1);
  const [currentAuthId, setCurrentAuthId] = useState<number>(-1);
  const history = useHistory();

  const redirectToAccounts = () => {
    history.push(`/accounts`);
  };

  const handleExchangeMarketOnChange = (e: any) => {
    const { field, value } = e;
    if (field === `market`) {
      const newExchange = exchanges.find((exchange) => exchange.exchId === exchangeIdSelectBox);
      if (newExchange) {
        props.setPanelMarket(newExchange.exchCode, value.currencyText+`/`+ value.baseCurrencyText, panel);
      }
    } 
    if (field === `exchange`) {
      setExchangeIdSelectBox(value.exchId);
    }
  };

  const setCurrentAccount = (id: number) => {
    if (currentAuthId !== id) {
      setCurrentAuthId(id);
    }
  };

  const activeExchange = exchanges.filter((exchange) => exchange.exchCode === settings.exchange)[0];
  const activeMarket = markets.filter((market) => market.exchCode === activeExchange.exchCode && market.displayName === settings.market)[0];
    
  const active = {
    exchange: activeExchange,
    market: activeMarket
  };
  
  useEffect(() => {
    setExchangeIdSelectBox(activeExchange.exchId);
  }, []);
  
  const mkt = `${ settings.exchange }:${ settings.market }`;

  const isReady = (
    markets.length > 0 &&
    exchanges.length > 0 && 
    currencies.length > 0 &&
    panelData.hasOwnProperty(mkt)
  );
  
  if (isReady) {
    const options = Object.keys(panelData[mkt].ticker).filter((t) => t !== `volume`).map((k) => ({
      label: t(`app:${ k }`) + `(${ toFixedDecimals(panelData[mkt].ticker[k], true, `price`, active.market) })`,
      value: toFixedDecimals(panelData[mkt].ticker[k], true, `price`, active.market)
    }));

    
    return (
      <NewOrder
        t={ t }
        markets={ markets }
        exchanges={ exchanges }
        currencies={ currencies }
        balances={ balances ? balances : [] }
        accounts={ accounts ? accounts : [] }
        isDetached={ true }
        redirectToAccounts={ redirectToAccounts }
        detachedOnChange={ handleExchangeMarketOnChange }
        active={ active }
        lastPrice={ panelData[mkt].ticker.last }
        bestBid={
          panelData[mkt].depth.bids.length > 0 ?
            panelData[mkt].depth.bids.reduce(function(prev, curr) {
              return prev.price > curr.price ? prev : curr;
            }).price :
            panelData[mkt].ticker.bid
        }
        bestAsk={
          panelData[mkt].depth.asks.length > 0 ?
            panelData[mkt].depth.asks.reduce(function(prev, curr) {
              return prev.price < curr.price ? prev : curr;
            }).price :
            panelData[mkt].ticker.ask
        }
        updateBOHTab={ () => {} }
        orderFormType={ `dual` } // NewOrderPanel will only support DualOrderForm
        options={ options }
        setCurrentAccount={ setCurrentAccount }
        currentAuthId={ currentAuthId }/> 
    );
  } else {
    return (
      <div className="panel-loading">
        <span>
          { t(`app:loading`) }
        </span>
      </div>
    );
  }

};

// $FlowIgnore: suppressing this error
OrderFormPanel.getPanelTitle = (settings: Settings) => {
  return settings ? `${settings.exchange}:${settings.market}` : `New Order`;
};

export { OrderFormPanel };
export default translate(`boards`)(OrderFormPanel);
