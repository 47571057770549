import React, { useState, useEffect } from 'react';
import Modal from '../../../utilities/Modal.jsx';
import Button from '../../../utilities/Button.jsx';
import SelectField from '../../../utilities/SelectField.jsx';
import { getAssetTransferMethodsBosonic, postWithdraw } from '../../../../helpers/api/UserApi.js';
import { sanitizeNumberInputs } from '../../../../helpers/NumberHelper.js';
import TextField from '../../../utilities/TextField.jsx';
import Trash from "../../../../svgs/Trash.jsx";

export const CryptoWithdrawModal = (props) => {
  const { t, close, currencies, currCode, openNewWalletAddressModal, walletAddresses, setAccountInfo, setAccountTransfers, onDeleteAddressClick } = props;
  const [errors, setErrors] = useState(``);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(``);

  const [assetTransferMethodId, setAssetTransferMethodId] = useState(``);

  useEffect(() => {
    setAccountTransfers();
  }, []);

  const formatWalletAddresses = (array) => {
    if (array) {
      const currObj = currencies.filter((c) => c.currCode == currCode);
      return array
        .filter((a) => a.currId === currObj[0].currId)
        .filter((el) => !el.inactive)
        .map((account) => {
          const label = [account.label, account.walletAddress].filter((d) => d).join(` - `);

          return {
            label,
            value: account.assetTransferMethodId
          };
        });
    }
    return [{ value: null, label: `No wallets found` }];
  };

  const walletAddressesOptions = formatWalletAddresses(walletAddresses);

  useEffect(() => {
    if (walletAddressesOptions.length > 0) {
      setAssetTransferMethodId(walletAddressesOptions[0].value);
    }
  }, [walletAddresses]);

  const makeWithdraw = () => {
    setLoading(true);

    const body = {
      assetTransferMethodId
    };
    getAssetTransferMethodsBosonic(body, (data) => {
      if (data.success) {
        const transferRequestId = data.result.data.id;
        const body = {
          currCode: currCode,
          amount,
          transferRequestId
        };
        postWithdraw(body, (data) => {
          if (data.success) {
            setLoading(false);
            close();
            setAccountInfo();
          } else {
            setErrors(data.error);
            setLoading(false);
          }
        });
      } else {
        setLoading(false);
        setErrors(data.error);
      }
    });
  };

  const handleDeleteClick = (event, option) => {
    event.stopPropagation();
    const { value, label } = option;
    onDeleteAddressClick({ id: value, label, type: `crypto`, currency: currCode });
  };

  const renderSelectRow = (option, index, optionList, defaultProps) => (
    <span { ...defaultProps } className="select-option-field">
      { option.label }
      <Button type="cancel" onClick={ (event) => handleDeleteClick(event, option) }>
        { Trash(`DeleteWalletAddress${index}`) }
      </Button>
    </span>
  );

  return (
    <div className="withdraw-modal">
      <Modal
        title={ t(`accounts:withdrawCurrency`, { currency: currCode }) }
        onClose={ () => close(false) }
        onConfirm={ true }>
        {
          <div className="row add-new">
            <SelectField
              options={ walletAddressesOptions }
              value={ assetTransferMethodId }
              label={ `Select Wallet Address` }
              onChange={ (e, value) => { setAssetTransferMethodId(value); } }
              searchable={ false }
              renderOption={ renderSelectRow } />
            <Button type="primary" onClick={ () => openNewWalletAddressModal() }>
              { `+ New` }
            </Button>
          </div>
        }
        <div className="row">
          <TextField
            label={ t(`app:amount`) }
            value={ amount }
            name={ `amount` }
            type={ `text` }
            hintText={ `Enter the exact amount you wish to withdraw.` }
            errorText={ null }
            onChange={ (e) => sanitizeNumberInputs(e.target.value, setAmount) } />
        </div>
        {
          loading &&
          <div className="row loading-message-row">
            { t(`app:loading`) }
          </div>
        }
        {
          errors &&
          <div className="row deposit-errors">
            { errors }
          </div>
        }
        <div className="row modal-footer">
          <Button type="primary" disabled={ amount === `` } onClick={ () => makeWithdraw() }>
            { t(`Submit Withdrawal Request`) }
          </Button>
          <Button type="secondary" onClick={ () => close(false) }>
            { t(`cancel`) }
          </Button>
        </div>
      </Modal>
    </div>
  );
};
