import React, { useEffect, useState } from 'react';
import QRCode from "react-qr-code";
import Modal from '../../../utilities/Modal.jsx';
import TextField from '../../../utilities/TextField.jsx';
import Button from '../../../utilities/Button.jsx';
import DatePicker from "../../../utilities/DatePicker.jsx";
import Checkbox from "../../../utilities/Checkbox.jsx";
import { sanitizeNumberInputs } from '../../../../helpers/NumberHelper.js';
import { postAssetTransfer } from '../../../../helpers/api/UserApi.js';
import { getOhlc } from '../../../../helpers/api/BalanceApi.js';
import Clipboard from '../../../../svgs/Clipboard.jsx';

export const CryptoDepositModal = ({ close, t, currCode, setAccountInfo, exchCode, quoteCurrCode }) => {
  const [ errors, setErrors ] = useState(``);
  const [ loading, setLoading ] = useState(false);
  const [ amount, setAmount] = useState(``);
  const [ label, setLabel ] = useState(``);
  const [ confirmStep, setConfirmStep ] = useState(false);
  const [ confirmWalletAddress, setConfirmWalletAddress ] = useState(``);
  const [ costBasis, setCostBasis ] = useState(``);
  const [ acquisitionOn, setAcquisitionOn ] = useState(new Date());
  const [ fillPrice, setFillPrice ] = useState(true);

  const createAssetTransfer = () => {
    setLoading(true);
    setErrors();
    const assetBody = {
      transferDirection: `Incoming`,
      currCode: currCode,
      label: label,
      quantity: amount,
      currencyType: costBasis !== `` ? `USD`: undefined,
      costBasis: costBasis !== `` ? costBasis : undefined,
      acquisitionOn: costBasis !== `` && acquisitionOn ? acquisitionOn.toISOString() : undefined,
    };
    postAssetTransfer(assetBody, (data) => {
      if (data.success) {
        setConfirmWalletAddress(data.result.data.walletAddress);
        setConfirmStep(true);
      } else {
        setLoading(false);
        setErrors(data.error);
      }
    }, () => {
      setLoading(false);
      setErrors(`Unexpected error, please try again.`);
    });
  };

  const copyAddressToClipboard = () => {
    navigator.clipboard.writeText(confirmWalletAddress);
    return false;
  };

  useEffect(() => {
    if (fillPrice) {
      getOhlc({ 
        exchange: exchCode,
        market: `${ currCode }/${ quoteCurrCode }`,
        data: `H`,
        params: {
          StartDate: acquisitionOn.toISOString(),
          EndDate: acquisitionOn.toISOString()
        }
      },
      (data) => {
        if (data.success && data.result) {
          setCostBasis(data.result[0].close);
        } else {
          setCostBasis(``);
        }
      }
      );
    }
  }, [acquisitionOn]);


  // Simulate crypto deposit, removed by william
  // const proceedCryptoDeposit = () => {
  //   depositAsset({ amount: amount, currCode: currCode, label: label }, (data) => {
  //     if (data.success) {
  //       setLoading(false);
  //       setErrors();
  //       close();
  //     } else {
  //       setLoading(false);
  //       setErrors(data.error);  
  //     }
  //   }, () => {});
  // };

  const renderConfirmCryptoDeposit = () => {
    return (
      <div className={ `crypto-deposit-form` }>
        <div className="row">
          <span className="confirm-deposit-body">
            { `Please deposit the exact amount shown to the following ` + currCode + ` address:` }
          </span>
        </div>
        <div className="row">
          <code>
            { amount + ` ` + currCode }
          </code>
        </div>
        <div className="row">
          <QRCode value={ confirmWalletAddress } className="qr-code"/>
        </div>
        <div className="row"> 
          <div className="align-center">
            { confirmWalletAddress }
          </div>
          <div className="copy-to-clipboard" onClick={ () => copyAddressToClipboard() }>
            { Clipboard }
          </div>
        </div>
        <div className="row modal-footer">
          <Button
            type="secondary" onClick={ () => {
              setLoading(false);
              setErrors(``);
              close(false);
              setAccountInfo();
            } }>
            { t(`close`) }
          </Button>
        </div>
      </div>
    );
  };
  
  return (
    <div className="deposit-modal">
      <Modal
        onConfirm={ true }
        title={ `New ` + currCode + ` Deposit` }
        onClose={ () => close(false) }>
        {
          (!confirmStep) ?
            <>
              <div className="row">
                <TextField 
                  label={ t(`Amount`) + ` (`+currCode+`)` }
                  name={ `amount` }
                  value={ amount }
                  type={ `text` }
                  hintText={ `Enter the exact amount of ` + currCode + ` you will be sending.` }
                  onChange={ (e) => sanitizeNumberInputs(e.target.value, setAmount) }/>
              </div>
              <div className="row">
                <TextField 
                  label={ t(`user:depositLabel`) }
                  name={ `label` }
                  value={ label }
                  hintText={ `This descriptive label will show up on the transaction ledger.` }
                  onChange={ (e) => setLabel(e.target.value) }/>
              </div>
              <div className="row">
                <div className="row">
                  <div className="column align-left">
                    <TextField 
                      label={ t(`user:costBasis`) }
                      name={ `costBasis` }
                      value={ costBasis }
                      type={ `text` }
                      hintText={ t(`user:costBasisHint`) }
                      onChange={ (e) => sanitizeNumberInputs(e.target.value, setCostBasis) }/>

                    <Checkbox
                      label={ t(`user:fillPriceFromDate`) }
                      name={ `fillPriceFromDate` }
                      value={ fillPrice } 
                      onChange={ (e) => setFillPrice(e.target.checked) } />
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <div className="acquision-date-picker">
                      <span className="label">
                        { t(`user:acquisitionDate`) }
                      </span>
                      <DatePicker
                        value={ acquisitionOn }
                        max={ new Date() }
                        onChange={ (v) => setAcquisitionOn(v) } />
                      <div className="hint-text">
                        Date when the cryptocurrency was acquired.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                loading &&
                <div className="row loading-message-row">
                  { t(`app:loading`) }
                </div>
              }
              {
                errors &&
                <div className="row errors-message-row">
                  { errors }
                </div>
              }
              <div className="row modal-footer">
                <Button type="primary" disabled={ label === ``|| amount === `` } onClick={ () => createAssetTransfer() }>
                  { t(`Get Deposit Address`) }
                </Button>
                <Button type="secondary" onClick={ () => close(false)  }>
                  { t(`cancel`) }
                </Button>
              </div>
            </> 
            : 
            renderConfirmCryptoDeposit()
        }
      </Modal>
    </div>
  );
};
