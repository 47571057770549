'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default () => SvgHelper(
  `<path d="M11.0094 8.53281L6.75938 12.7828C6.46563 13.0766 5.99063 13.0766 5.7 12.7828L4.99375 12.0766C4.7 11.7828 4.7 11.3078 4.99375 11.0172L8.00625 8.00469L4.99375 4.99219C4.7 4.69844 4.7 4.22344 4.99375 3.93281L5.69687 3.22031C5.99062 2.92656 6.46562 2.92656 6.75625 3.22031L11.0062 7.47031C11.3031 7.76406 11.3031 8.23906 11.0094 8.53281Z" />`,
  {
    width: `2rem`,
    height: `2rem`,
    viewBox: `0 0 16 16`,
  }
);
/* eslint-enable */

