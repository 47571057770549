// @flow :)
'use strict';

import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { connect } from 'react-redux';
import { getMarketPair } from '../../helpers/MarketPairHelper.js';
import { convertScinotToDecimal, toFixedDecimals, formatLongNumber } from '../../helpers/NumberHelper.js';
import { removeEventListener } from '../../helpers/EventHelper.js';
import type { Market } from "../../types/Market";
import type { MarketOrder } from '../../types/MarketOrder.js';
import { getActiveTheme } from '../../helpers/ThemeHelper.js';
import {
  listenForEvent,
  ORDER_LEVEL_HOVER,
  ORDER_TABLE_UNHOVER,
  MARKET_BUY_MODE_ACTIVATED,
  MARKET_SELL_MODE_ACTIVATED,
  MARKET_ALERT_MODE_ACTIVATED,
  MARKET_ALERT_MODE_DEACTIVATED
} from '../../helpers/EventHelper.js';
import CoinigyBaseComponent from '../CoinigyBaseComponent.jsx';
require(`highcharts/modules/broken-axis`)(Highcharts);

type Props = {
  width: number,
  height: number,
  onPriceClick?: () => void,
  buys: Array<MarketOrder>,
  sells: Array<MarketOrder>,
  limitDeviation: boolean,
  market: Market,
  maxBid: number,
  minAsk: number,
  theme: string,
  resetZoomText: string,
  openOrders: Array<any>,
  activeAlerts: Array<any>,
  showDepthOrdersAlerts: boolean
};

type State = {
  isZooming: boolean,
  selectedBidLevel: number,
  selectedAskLevel: number,
  markers: any,
  isDragging: boolean
};

type ChartConfig = {
  x: { min: number, max: number },
  y: { min: number, max: number }
};

type WallData = {
  price: number,
  quantityDiff: number,
  xOffSet: number,
  yOffSet: number,
  color: string,
  type: string
};

const yBuyOffsetValue = 35;
const ySellOffsetValue = 55;
const wallsToDisplay = 3;

// formerly RobsSuperAwesomeMarketDepthChartSurprise
class MarketLargeDepthChart extends CoinigyBaseComponent<Props, State> {
  chart = null;
  chartData = {};
  resizeHandler: () => void;
  line = null;
  hoverMsgHandler: () => void;
  markerMsgHandler: () => void;

  constructor(props: Props) {
    super(props);

    this.resizeHandler = this.onWindowResize.bind(this);
    
    this.hoverMsgHandler = this.handleHoverMessage.bind(this);
    this.markerMsgHandler = this.handleMarkerMessage.bind(this);

    this.state = {
      isZooming: false,
      selectedBidLevel: -1,
      selectedAskLevel: -1,
      markers: [],
      isDragging: false
    };
  }

  componentDidMount() {
    this.chart = this.refs.chart;
    const chart = this.chart;

    if (chart) {
      const chartData = this.getChartData(this.props);
      const bidsSeries = this.generateSeries(chartData, this.props, `bids`);
      const asksSeries = this.generateSeries(chartData, this.props, `asks`);
      chart.chart.series[0].setData(bidsSeries.data);
      chart.chart.series[1].setData(asksSeries.data);
      chart.chart.series[0].update(bidsSeries);
      chart.chart.series[1].update(asksSeries);

      listenForEvent(ORDER_LEVEL_HOVER, this.hoverMsgHandler);
      listenForEvent(ORDER_TABLE_UNHOVER, this.hoverMsgHandler);
      

      listenForEvent(MARKET_BUY_MODE_ACTIVATED, this.markerMsgHandler);
      listenForEvent(MARKET_SELL_MODE_ACTIVATED, this.markerMsgHandler);
      listenForEvent(MARKET_ALERT_MODE_ACTIVATED, this.markerMsgHandler);
      listenForEvent(MARKET_ALERT_MODE_DEACTIVATED, this.markerMsgHandler);
    }

    window.addEventListener(`resize`, this.resizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener(`resize`, this.resizeHandler);
    
    removeEventListener(ORDER_LEVEL_HOVER, this.hoverMsgHandler);
    removeEventListener(ORDER_TABLE_UNHOVER, this.hoverMsgHandler);

    removeEventListener(MARKET_BUY_MODE_ACTIVATED, this.markerMsgHandler);
    removeEventListener(MARKET_SELL_MODE_ACTIVATED, this.markerMsgHandler);
    removeEventListener(MARKET_ALERT_MODE_ACTIVATED, this.markerMsgHandler);
    removeEventListener(MARKET_ALERT_MODE_DEACTIVATED, this.markerMsgHandler);
  }

  onWindowResize() {
    this.forceUpdate();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.market.marketId !== nextProps.market.marketId) {
      if(this.chart) { this.chart.chart.zoomOut(); }
      this.setState({
        isZooming: false,
      });
    }
  }

  handleHoverMessage(e: any) {
    if (!e) return;

    this.chart = this.refs.chart;
    const chart = this.chart;
    if (chart) {
      switch(e.type) {
      
      case ORDER_LEVEL_HOVER:
        if(e.detail.type == `buy`){
          if(e.detail.state.sortDirection != `-` || e.detail.state.sortBy != `price`) return;
          this.setState({
            selectedBidLevel: e.detail.index,
            selectedAskLevel: -1
          }, () => {
            this.redrawSelectedBidLevel(chart.chart);
          });
        }else{
          if(e.detail.state.sortDirection != `+` || e.detail.state.sortBy != `price`) return;
          this.setState({
            selectedAskLevel: e.detail.index,
            selectedBidLevel: -1
          }, () => {
            this.redrawSelectedAskLevel(chart.chart);
          });
        }
        break;

      case ORDER_TABLE_UNHOVER:
        if(e.detail.type == `buy`){
          this.setState({
            selectedBidLevel: -1
          }, () => {
            chart.chart.series[0].points.forEach((point) => {
              point.select(false, true);
            });
            chart.chart.xAxis[0].hideCrosshair();
            chart.chart.tooltip.hide();
          });
        }else{
          this.setState({
            selectedAskLevel: -1
          }, () => {
            chart.chart.series[1].points.forEach((point) => {
              point.select(false, true);
            });
            chart.chart.xAxis[0].hideCrosshair();
            chart.chart.tooltip.hide();
          });
        }
        break;

      }
    }

  }

  handleMarkerMessage(e: any) {
    if (!e) return;

    this.chart = this.refs.chart;
    const chart = this.chart;
    if (chart) {
      switch(e.type) {
      case MARKET_BUY_MODE_ACTIVATED:
        this.setState({
          markers: [{
            type: `order`,
            side: 1,
            price: e.detail.price,
            quantity: e.detail.orderQty,
            total: e.detail.total
          }]
        }, () => this.addMarkers(chart.chart));
        break;
      case MARKET_SELL_MODE_ACTIVATED:
        this.setState({
          markers: [{
            type: `order`,
            side: 2,
            price: e.detail.price,
            quantity: e.detail.orderQty,
            total: e.detail.total
          }]
        }, () => this.addMarkers(chart.chart));
        break;
      case MARKET_ALERT_MODE_ACTIVATED:
        this.setState({
          markers: [{
            type: `alert`,
            price: e.detail.price,
            string: e.detail.string,
            alertType: e.detail.alertType
          }]
        }, () => this.addMarkers(chart.chart));
        break;
      case MARKET_ALERT_MODE_DEACTIVATED:
        this.setState({
          markers: []
        }, () => this.addMarkers(chart.chart));
        break;
      }
    }
  }

  shouldComponentUpdate(nextProps: Props): boolean {
    const chart = this.chart;
    if (chart) {
      const chartData = this.getChartData(nextProps);
      const bidsSeries = this.generateSeries(chartData, this.props, `bids`);
      const asksSeries = this.generateSeries(chartData, this.props, `asks`);
      chart.chart.series[0].setData(bidsSeries.data);
      chart.chart.series[1].setData(asksSeries.data);
      chart.chart.series[0].update(bidsSeries);
      chart.chart.series[1].update(asksSeries);
      
      chart.chart.xAxis[0].options.breaks[0].from = chartData.maxBid; 
      chart.chart.xAxis[0].options.breaks[0].to = chartData.minAsk;
      
      if(this.state.selectedBidLevel >= 0) this.redrawSelectedBidLevel(chart.chart);
      if(this.state.selectedAskLevel >= 0) this.redrawSelectedAskLevel(chart.chart);
    }

    return this.props.theme !== nextProps.theme ||
           this.props.width !== nextProps.width ||
           this.props.height !== nextProps.height ||
           this.props.market.marketId !== nextProps.market.marketId;
  }

  redrawSelectedAskLevel(chart: any){
    chart.series[1].points.forEach((point) => {
      point.select(false, true);
    });

    if(chart.series[1].points.length > 0 && this.state.selectedAskLevel >= 0){
      let point = chart.series[1].points[this.state.selectedAskLevel];
      if (point) {
        point.select(true, true);
        chart.tooltip.refresh(point);
        chart.xAxis[0].drawCrosshair(null, point);
      }
    }
  }

  redrawSelectedBidLevel(chart: any){
    chart.series[0].points.forEach((point) => {
      point.select(false, true);
    });

    if(chart.series[0].points.length > 0 && this.state.selectedBidLevel >= 0){
      let point = chart.series[0].points[chart.series[0].points.length-1-this.state.selectedBidLevel];
      if (point) {
        point.select(true, true);
        chart.tooltip.refresh(point);
        chart.xAxis[0].drawCrosshair(null, point);
      }
    }
  }

  onZoom = (e: any) => {
    const isZooming = !e.resetSelection;
    this.setState({ isZooming }, () => {
      this.removeExistingPlotLines(e.target);
    });
  };

  //Get the min, max data points of the graph to be able to determine walls.
  getChartConfig(chart: any) {
    let xExtremes = chart.xAxis[0].getExtremes();
    let yExtremes = chart.yAxis[0].getExtremes();
    //Within the highcharts getExtremes api call, when the zooming the minimum and maximum
    //chart values  for x-axis are:
    //  - Contained within the min and max properties.
    //  - The dataMin and dataMax properties always contain the min/max of the original chart.
    // For the y-axis
    //  - the min and max properties contain the correct boundaries for the chart regardless if it is in zoom state.
    if (this.state.isZooming) {
      return {
        x: { min: xExtremes.min, max: xExtremes.max },
        y: { min: yExtremes.min, max: yExtremes.max }
      };
    }else{
      return {
        x: { min: xExtremes.dataMin, max: xExtremes.dataMax },
        y: { min: yExtremes.min, max: yExtremes.max }
      };
    }
  }

  getBids(buys: Array<MarketOrder>): Array<any> {
    return buys.reduce((v: any, buy: MarketOrder) => {
      let price = parseFloat(buy.price);

      //Dedupe prices
      if (v.length > 0 && buy.price == v[0][0]){
        let quantity = buy.quantity,
          cumulativeQuantity = quantity + v[0][1],
          totalPrice = price * quantity,
          cumulativeTotalPrice = totalPrice + v[0][2];

        v[0] = [price, cumulativeQuantity, cumulativeTotalPrice];
        return v;
      } else {
        let quantity = buy.quantity,
          cumulativeQuantity = quantity + (v.length > 0 ? v[0][1] : 0),
          totalPrice = price * quantity,
          cumulativeTotalPrice = totalPrice + (v.length > 0 ? v[0][2] : 0);

        return [[price, cumulativeQuantity, cumulativeTotalPrice]].concat(v);
      }
    }, []); //Calculate y-axis data point. Each y-axis point is sum of all previous quantities
  }

  getDecimalPlaces(value: any) {
    if (Math.floor(value) !== value) {
      // There are instances where if all digits after decimal are zero, decimal is being truncated.
      // Need to protect against this.
      let arrayNumberDecimalSplit = value.toString().split(`.`);
      if(arrayNumberDecimalSplit.length > 1 ){
        return arrayNumberDecimalSplit[1].length;
      }
    }
    return 0;
  }


  getAsks(sells: Array<MarketOrder>): Array<any> {
    return sells.reduce((v: Array<any>, sell: MarketOrder) => {
      let price = parseFloat(sell.price);

      //Dedupe prices
      if (v.length > 0 && sell.price == v[v.length - 1][0]){
        let quantity = sell.quantity,
          cumulativeQuantity = sell.quantity + v[v.length - 1][1],
          totalPrice = price * quantity,
          cumulativeTotalPrice = totalPrice + v[v.length - 1][2];

        v[v.length - 1] = [price, cumulativeQuantity, cumulativeTotalPrice];
        return v;
      } else {
        let quantity = sell.quantity,
          cumulativeQuantity = quantity + (v.length > 0 ? v[v.length - 1][1] : 0),
          totalPrice = price * quantity,
          cumulativeTotalPrice = totalPrice + (v.length > 0 ? v[v.length - 1][2] : 0);

        return v.concat([[price, cumulativeQuantity, cumulativeTotalPrice]]);
      }
    }, []);
  }

  insertBreaks(maxBid: number, minAsk: number) {
    let breakBegin, breakEnd, decimalPrecision;
    //If either maxBid or minAsk are whole numbers add or subtract 0.00000001 is safest thing to do so numbers don't
    //overlap and cause an issue with highcharts receiving values out of order. If x-axis values don't always
    //increment an error will be thrown.
    if(convertScinotToDecimal(maxBid).toString().split(`.`).length == 1){
      //value is whole number, increment by 0.00000001
      breakBegin = Number(maxBid) + 0.00000001;
    }else{
      //Need to figure out how many decimals are in the number, create a new number with an extra decimal place with
      // the value of 1 and add it to original number to get a small enough number greater than maxBid.
      decimalPrecision = this.getDecimalPlaces(convertScinotToDecimal(maxBid));
      //Create new number that has one more decimal place with a value of '1' at the end.
      let addend = parseFloat(parseFloat(Number(0).toFixed(decimalPrecision) + `1`));
      //Multiple by one ensures coercion to number so concatenation does not occur.
      breakBegin = parseFloat(((parseFloat(addend.toFixed(decimalPrecision + 1))) +
        (maxBid * 1)).toFixed(decimalPrecision + 1));
    }

    if(convertScinotToDecimal(minAsk).toString().split(`.`).length == 1){
      //value is whole number, increment by 0.00000001
      breakEnd = Number(minAsk) - 0.00000001;
    }else{
      // Need to figure out how many decimals are in the number, create a new number with an extra decimal place with
      // the value of 1 and add it to original number to get a small enough number greater than maxBid.
      decimalPrecision = this.getDecimalPlaces(convertScinotToDecimal(minAsk));
      let subtrahend = parseFloat(Number(0).toFixed(decimalPrecision) + `1`);
      breakEnd = parseFloat((minAsk - parseFloat(subtrahend.toFixed(decimalPrecision + 1)))
        .toFixed(decimalPrecision + 1));
    }
    return [[breakBegin, null], [breakEnd, null]];
  }


  getWallPlotLines(walls: Array<WallData>): any{
    return walls.length > 0 ? this.createPlotLinesFromWalls(walls) : [];
  }

  getOpenOrderPlotLines(): any{
    if (this.props.openOrders) {
      return this.props.openOrders.length > 0 ? this.createPlotLinesFromOpenOrders() : [];
    }
    
    return [];
  }

  getActiveAlertPlotLines(): any{
    if (this.props.activeAlerts) {
      return this.props.activeAlerts.length > 0 ? this.createPlotLinesFromActiveAlerts() : [];
    }
 
    return [];
  }


  //Need to offset x-label for walls what are close to the beginning or end of chart so they are not cutoff.
  getOffsetX(price: number, widthFromFirstPoint: number, widthFromLastPoint: number){

    const percentWidthFromFirstPoint = widthFromFirstPoint / this.props.width * 100;
    const percentWidthFromLastPoint = widthFromLastPoint / this.props.width * 100;
    //Determine where wall exists in chart. Determine offset based on location.
    let offset = 0;
    switch(true) {
    case (percentWidthFromFirstPoint <= 30): offset = 4; break;
    case (percentWidthFromLastPoint <= 30): offset = -4; break;
    default: return 0;
    }

    return convertScinotToDecimal(price).length * offset;
  }

  getChartData(props: Props) {
    let { buys, sells, minAsk, maxBid, limitDeviation } = props;

    /* LIMIT DEVIATION */
    if (limitDeviation && buys.length > 0 && sells.length > 0) {
      let buyDeviation = (buys[0].price - buys[buys.length - 1].price) / buys[0].price,
        sellDeviation = (sells[sells.length - 1].price - sells[0].price) / sells[sells.length - 1].price,
        lesserDeviation = buyDeviation < sellDeviation ? buyDeviation : sellDeviation;

      buys = buys.filter((b) => 
        ((buys[0].price - b.price) / buys[0].price) <= lesserDeviation);
      sells = sells.filter((s) => 
        ((sells[sells.length - 1].price - s.price) / sells[sells.length - 1].price) <= lesserDeviation);
    }
    /* END LIMIT DEVIATION */

    const bids = this.getBids(buys);
    const asks = this.getAsks(sells);

    this.chartData = {
      minAsk,
      maxBid,
      bids,
      asks,
    };
    return this.chartData;
  }

  generateSeries(chartData: any, props: Props, chartType: any) {
    const { market } = props;
    const { bids, asks } = chartData;

    return  {
      type: `area`,
      name: `bidAsks`,
      data: chartType == `bids`
        ? bids.filter((bid) => bid[0] > 0).map(([ x, y, t ]) => ({ x, y, t }))
          .sort((a, b) => a.x > b.x ? 1 : a.x < b.x ? -1 : 0) 
        : asks.filter((ask) => ask[0] > 0).map(([ x, y, t ]) => ({ x, y, t }))
          .sort((a, b) => a.x > b.x ? 1 : a.x < b.x ? -1 : 0),
      showInNavigator: false,
      gapUnit: `value`,
      color: `none`,
      marker: {
        symbol: `diamond`,
        radius: 3,
        states: {
          select: {
            fillColor: chartType == `bids` ? getActiveTheme(this.props.theme).green : getActiveTheme(this.props.theme).red,
            lineColor: `white`,
            lineWidth: 1,
            radius: 3
          }
        }
      },
      fillColor: {
        linearGradient: [0, 0, 0, 1500],
        stops: [
          [0, chartType == `bids` ? getActiveTheme(this.props.theme).green : getActiveTheme(this.props.theme).red],
          [1, chartType == `bids` ? `rgba(91, 155, 113, 0.1)` : `rgba(187, 79, 75, 0.01)`]
        ]
      },
      tooltip: {
        followPointer: true,
        headerFormat: ``,
        valueDecimals: 4,
        pointFormatter: function() {
          // return `${ getMarketPair(market).toString() }<br/>
          //         Quantity<br/>
          //         ${ toFixedDecimalsHTML(this.options.t, false, `quantity`, market).__html } ` +
          //         getMarketPair(market).quote + `
          //         <br/>
          //         Price<br/>
          //         ${ 
          //           toFixedDecimalsHTML(this.options.x, false, `price`, market).__html
          //         } ` + 
          //         getMarketPair(market).toString();
          return `Price (`+getMarketPair(market).toString()+`)<br/><b>` +
          formatLongNumber(this.options.x, false) + `</b><br/>` +
          `Cum. Quantity (`+getMarketPair(market).base+`)<br/><b>` +
          formatLongNumber(this.options.y, false) + `</b><br/>` + 
          `Cum. Total (`+getMarketPair(market).quote+`)<br/><b>` +
          formatLongNumber(this.options.x * this.options.y, false) + `</b>`;
        }
      },
      
      // zoneAxis: `x`,
      // zones: [{
      //   value: maxBid,
      //   color: getActiveTheme(this.props.theme).green,
      //   fillColor: {
      //     linearGradient: [0, 0, 0, 400],
      //     stops: [
      //       [0, getActiveTheme(this.props.theme).green],
      //       [1, `rgba(91, 155, 113, 0.1)`]
      //     ]
      //   }
      // }, {
      //   value: minAsk,
      //   color: getActiveTheme(this.props.theme).green,
      //   fillColor: {
      //     linearGradient: [0, 0, 0, 400],
      //     stops: [
      //       [0, getActiveTheme(this.props.theme).green],
      //       [1, `rgba(91, 155, 113, 0.1)`]
      //     ]
      //   }
      // }, {
      //   color: getActiveTheme(this.props.theme).red,
      //   fillColor: {
      //     linearGradient: [0, 0, 0, 400],
      //     stops: [
      //       [0, getActiveTheme(this.props.theme).red],
      //       [1, `rgba(187, 79, 75, 0.01)`]
      //     ]
      //   }
      // }]
    };
  }

  //Find largest differences in quantities per price point for both bids and asks.
  findWalls(buyOrSellData: any, isAsk: boolean, config?: ChartConfig){
    // Subtract quantity value against previous array element quantity value.
    // Retain original price value to be used as wall point in graph
    // Sort and return top diff.
    const sorted = buyOrSellData.filter((item) => {
    // If zoom applied, filter only within boundaries
      if (config) {
        const [price, quantity] = [parseFloat(item[0]), parseFloat(item[1])];
        return price >= config.x.min
        && price <= config.x.max
        && quantity >= config.y.min
        && quantity <= config.y.max;
      }
      return true;
    })
      .map((n, key: number, arr) => {
        const condition = isAsk ? key > 0 : key < arr.length - 1 ;
        const previousKey = isAsk ? key - 1 : key + 1;
        const offsetY = isAsk ? ySellOffsetValue : yBuyOffsetValue;
        const wallType = isAsk ? `sell` : `buy`;
        const color =  isAsk ? getActiveTheme(this.props.theme).red : getActiveTheme(this.props.theme).green;
        const quantityDiff = condition ? Math.abs(parseFloat(n[1]) - parseFloat(arr[previousKey][1])) : 0;

        return {
          price: n[0],
          quantityDiff: quantityDiff,
          color: color,
          yOffSet: offsetY,
          xOffSet: 0,
          type: wallType
        };
      });

    sorted.sort((a, b) => parseFloat(a.quantityDiff) - parseFloat(b.quantityDiff)).reverse();
    if (sorted.length === 0) {
      return [];
    }
    //Per requirements, only return one wall. If more are needed, increase slice amount.
    return sorted.slice(0, wallsToDisplay);
  }

  //Add plot lines is called on redraw event. This is done beecause it will allow the calculation
  //of difference in pixels between the beginning/end of chart and the walls. This will allow the
  //label of the wall to have the correct xOffset so it doesn't get cut off if it is near the edge.
  addPlotlines = (e: any) => {
    const chart = e.target;

    //Remove any previously existing plotlines.
    this.removeExistingPlotLines(chart);

    //Get min, max data points. If zoomed, will return min/max for visible graph.
    const chartConfig = this.getChartConfig(chart);

    //Calculate walls.
    const buyWalls: Array<WallData> = this.findWalls(this.chartData.bids, false, chartConfig);
    const sellWalls: Array<WallData> = this.findWalls(this.chartData.asks, true, chartConfig);

    let walls = buyWalls.concat(sellWalls);
    //Get data points on graph
    let xAxisChartPoints = chart.xAxis[0].series[0].points.filter((point) => {
      return point.x >= chartConfig.x.min
        && point.x <= chartConfig.x.max;
    });

    //Iterate through data points. When a match is found with a wall that will allow us to get the plotX
    //object which will allow us to calculate the difference in pixels between the beginning and end of chart.
    xAxisChartPoints.forEach((point) => {
      walls.forEach((wall) => {
        if (convertScinotToDecimal(wall.price) == convertScinotToDecimal(point.x)) {
          wall.xOffSet = this.getOffsetX(wall.price, point.plotX -
            xAxisChartPoints[0].plotX, xAxisChartPoints[xAxisChartPoints.length - 1].plotX - point.plotX);
        }
      });
    });

    let wallLines = this.getWallPlotLines(walls);
    if (wallLines) {
      wallLines.forEach((wallLine) => {

        let wallPlotLine = {
          value: wallLine.value,
          width: 1,
          color: wallLine.color,
          dashStyle: `dash`,
          opacity: 0.5,
          label: {
            text: `<span style="color:`+wallLine.color+`;">` + wallLine.value + `</span>`,
            verticalAlign: `middle`,
            textAlign: `center`
          }
        };
        chart.xAxis[0].addPlotLine(wallPlotLine);
      });
    }

    if (this.props.showDepthOrdersAlerts) {
      let openOrderLines = this.getOpenOrderPlotLines();
      if (openOrderLines) {
        openOrderLines.forEach((openOrderLine) => {

          chart.xAxis[0].addPlotLine(openOrderLine);
        });
      } 

      let activeAlertLines = this.getActiveAlertPlotLines();
      if (activeAlertLines) {
        activeAlertLines.forEach((activeAlertLine) => {

          chart.xAxis[0].addPlotLine(activeAlertLine);
        });
      } 
    }

  }

  removeExistingPlotLines(chart: any){
    if (chart.xAxis !== undefined) {
      const plotlineValues = chart.xAxis[0].plotLinesAndBands.map((plotLine)  =>  plotLine.options.id);

      plotlineValues.forEach((id) => {
        if (id != `activeMarkerLine`) chart.xAxis[0].removePlotLine(id);
      });
    }
  }

  createPlotLinesFromWalls(walls: Array<WallData>): any{
    if (walls.length === 0) return;
    const opacity = 1;
    return walls.map((wall, i) => {
      const color = wall.color;
      return ({
        color: color,
        dashStyle: `dash`,
        value: wall.price,
        width: 1,
        opacity: opacity,
        id: wall.type + `_` + i
      });
    });
  }

  createPlotLinesFromOpenOrders(): any{
    
    // filter out "Placing Order" status orders for now, causing issues
    return this.props.openOrders.filter((order) => order.orderStatusId != 1).map((order, i) => {
      

      let orderTypeText = order.side == 1 ? `Limit Buy` : `Limit Sell`;
      let orderColor = order.side == 1 ? `var(--green-)` : `var(--red-)`;
      return ({
        value: order.limitPrice,
        width: 1,
        color: orderColor,
        dashStyle: `dotted`,
        opacity: 1,
        id: `openOrderLine_` + i,
        label: {
          useHTML: true,
          text: `<div style="color:${orderColor};background-color:var(--gray-2-theme-alpha);font-weight: bold; 
          border:1px solid ${orderColor};padding:0.5rem;margin-bottom:1rem;font-size:8pt;"> ` + 
          orderTypeText + 
          ` ` + order.quantity + ` ` + (order.baseCurrency ? order.baseCurrency : order.displayName) + ` </div>`,
          verticalAlign: `top`,
          x: 9,
          y: 0
        }
      });
    });
  }

  createPlotLinesFromActiveAlerts(): any{
    // only show price alerts matching active exchmktid
    return this.props.activeAlerts.filter((alert) => { return alert.type == 0 && alert.exchMktId == this.props.market.exchmktId; }).map((alert, i) => {

      let alertColor = `var(--brand-blue-)`;

      return ({
        value: alert.price,
        width: 1,
        color: alertColor,
        dashStyle: `dotted`,
        opacity: 1,
        id: `activeAlertLine_` + i,
        label: {
          useHTML: true,
          text: `<div style="color:${alertColor};background-color:var(--gray-2-theme-alpha);font-weight: bold; 
          border:1px solid ${alertColor};padding:0.5rem;margin-bottom:1rem;font-size:8pt;"> ` + 
          alert.price + ` </div>`,
          verticalAlign: `top`,
          x: 9,
          y: 0
        }
      });
    });
  }

  // TODO: draggable plotLines
  // startDrag(e) {

  //   //console.log(`startDrag`, e);
    
  //   //  this.clickX = e.pageX - this.line.translateX;
  //   // if (this.line) {
  //   //   console.log(e.pageX, this.line.translateX);
  //   // }
  // }

  // stepDrag(e) {
  //   //console.log(`stepDrag`, e);
  //   //this.line.translate(e.pageX - this.state.clickX, 0);
  // }

  // stopDrag(e) {
  //   //console.log(`stopDrag`, e);
  // }

  addMarkers(chart: any) {

    if (chart) {
      chart.xAxis[0].removePlotLine(`activeMarkerLine`);
      if (this.props.showDepthOrdersAlerts) {
        
        if (this.state.markers.length > 0) {
          this.state.markers.map((marker) => {
          
            if (marker.type == `order`) {
              if (parseFloat(marker.quantity) > 0) {
                chart.xAxis[0].addPlotLine(this.createMarker(marker));


                // draggable plotLine - you're not ready for this yet, but your kids will love it
                // for (var i = 0; i < chart.xAxis[0].plotLinesAndBands.length; i++) {
                //   if (chart.xAxis[0].plotLinesAndBands[i].id == `activeMarkerLine`) {
                //     this.line = chart.xAxis[0].plotLinesAndBands[i].svgElem.attr({
                //       stroke: `yellow`,
                //       width: 4
                //     })
                //       .css({
                //         'cursor': `pointer`
                //       })
                //       .translate(0, 0)
                //       .on(`mousedown`, (e) => this.startDrag(e))
                //       .on(`mousemove`,  (e) => this.stepDrag(e))
                //       .on(`mouseup`,  (e) => this.stopDrag(e)); 
                //   }
                // }
            
            
              }
        
            }
            if (marker.type == `alert`) {
              if (parseFloat(marker.price) > 0 && marker.alertType == 0) {
                chart.xAxis[0].addPlotLine(this.createMarker(marker));
              }
            }
          });      
        }
      }
    }
  }

  createMarker(marker: any): any{
    
    let markerTypeText = `Limit`; //marker.side == 1 ? `Limit Buy` : `Limit Sell`;
    let markerColor = marker.side == 1 ? `var(--green-)` : `var(--red-)`;

    if (marker.type == `alert`) {
      markerTypeText = `Place Alert`;
      markerColor = `var(--brand-blue-)`;
    }

    return ({
      value: marker.price,
      width: 1,
      color: markerColor,
      dashStyle: `dotted`,
      opacity: 1,
      id: `activeMarkerLine`,
      label: {
        useHTML: true,
        text: `<div style="color:${markerColor};background-color:var(--gray-2-theme-alpha);font-weight: bold; 
        border:1px solid ${markerColor};padding:0;margin-bottom:1rem;font-size:8pt;display:flex;flex-direction:row;">
        <div id="activeMarker" style="background-color:${markerColor};color:var(--body-text-color);padding:0.5rem 1rem 0.5rem 1rem;">
        ${markerTypeText}</div><div style="padding: 0.5rem 1rem 0.5rem 1rem;">` + (marker.total ? marker.total : marker.price) + 
        `</div></div>`,
        verticalAlign: `top`,
        x: 9,
        y: 0
      }
    });
  }

  
  


  render() {
    const chartData = this.getChartData(this.props);
    const { market } = this.props;
    const axesColor = getActiveTheme(this.props.theme, true)[`--body-text-color-alpha-54`];

    const options = {
      legend: {
        enabled: false,
      },
      title: {
        text: ``,
      },
      plotOptions: {
        series: {
          animation: false,
          turboThreshold: 2000,
          marker: {
            enabled: false
          }
        }
      },
      chart: {
        backgroundColor: `rgba(0,0,0,0)`, //getActiveTheme(this.props.theme).gray2,
        zoomType: `x`,
        height: this.props.height,
        width: this.props.width,
        marginLeft: 0,
        marginRight: 1,
        id: `depthChart`,
        resetZoomButton: {
          theme: {
            display: `block`,
            fill: getActiveTheme(this.props.theme).gray2,
            style: {
              color: getActiveTheme(this.props.theme).body,
            },
            states: {
              hover: {
                fill: getActiveTheme(this.props.theme).gray2,
                style: {
                  color: getActiveTheme(this.props.theme).body,
                }
              }
            }
          }
        },
        events: {
          selection: this.onZoom,
          load: this.addPlotlines,
          redraw: this.addPlotlines,
          click: this.props.onPriceClick
        }
      },
      credits: {
        enabled: true,
        text: market.exchName + `:` + market.displayName,
        href: ``,
        position: {
          align: `center`,
          verticalAlign: `middle`
        },
        style: {
          cursor: `default`,
          color: getActiveTheme(this.props.theme).body,
          fontSize: `5rem`,
          opacity: 0.1
        }
      },
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      rangeSelector: {
        inputEnabled: false,
        buttonTheme: {
          visibility: `hidden`
        },
        labelStyle: {
          visibility: `hidden`
        }
      },
      series: [{
        type: `area`,
        data: this.generateSeries(chartData, this.props, `bids`),
        states: {
          inactive: {
            opacity: 0.8
          }
        }
      },
      {
        type: `area`,
        data: this.generateSeries(chartData, this.props, `asks`),
        states: {
          inactive: {
            opacity: 0.8
          }
        }
      }],
      xAxis: {
        lineWidth: 0,
        lineColor: `${ axesColor }`,
        labels: {
          enabled: true,
          formatter: function() {
            return toFixedDecimals(parseFloat(this.value), false, `price`, market);
          },
          style: {
            color: `${ axesColor } !important`,
            fontSize: `1.2rem`
          }
        },
        crosshair: true,
        breaks: [{}]
      },
      yAxis: {
        gridLineWidth: 0,
        tickLength: 10,
        tickWidth: 1,
        opposite: true,
        tickPosition: `outside`,
        tickAmount: 8,
        title: {
          enabled: false,
        },
        lineWidth: 0,
        lineColor: `${ axesColor }`,
        labels: {
          formatter: function() {
            if ( this.isFirst ) { return ``; }

            return this.value;
          },
          align: `right`,
          x: -10,
          y: 5,
          style: {
            color: `${ axesColor } !important`,
            fontSize: `1.2rem`
          }
        }
      }
    };
    return (
      <HighchartsReact
        ref="chart"
        highcharts={ Highcharts }
        options={ options } />
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.redisPrefs.theme,
  limitDeviation: state.redisPrefs.limitDepthChartDeviation
});

export { MarketLargeDepthChart as PureMarketLargeDepthChart };
export default connect(mapStateToProps)(MarketLargeDepthChart);
