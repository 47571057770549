// @flow
'use strict';

import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { 
  emitEvent, 
  NOTIFICATION
} from '../../helpers/EventHelper.js';
import { getTransfers } from '../../helpers/api/UserApi.js';
import Button from '../utilities/Button.jsx';
import Modal from '../utilities/Modal.jsx';
import type { Account } from "../../types/Account.js";
import type { Exchange } from "../../types/Exchange.js";
import LogoLightspeedCrypto from '../../svgs/LogoLightspeedCrypto.jsx';

type Props = {
  t: any,
  accounts: Array<Account>,
  exchanges: Array<Exchange>,
};

const WireFundsModal = (props: Props) => {
  const { t, accounts, exchanges,  } = props;
  const [ showModal, setShowModal] = useState(false);

  const history = useHistory();

  const handleClick = () =>{
    const LSCXExchange = exchanges?.filter((e) => e.exchCode === `LSCX`);
    const LSCXAccount = LSCXExchange ? accounts?.filter((a) => a.authExchId == LSCXExchange[0].exchId) : [];

    if (LSCXAccount?.length && LSCXAccount[0]?.authId) {
      history.push(`/wallet/${LSCXAccount[0].authId}`);
    } else {
      emitEvent(NOTIFICATION, {
        notification_id: new Date().getTime(),
        title: `Error`,
        title_vars: ``,
        message_raw: `No valid account found.`,
        message: `No valid account found.`,
        message_vars: ``,
        pinned: false,
        style: `error`,
        url: ``
      });
    }

    setShowModal(false);
    
    return false;
  };

  const onClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getTransfers((response) => {
      if (response.success) {
        setShowModal(response.result.length === 0);
      }
    });
  }, []);

  if (!showModal) return null;

  return (
    <div className={ `wire-funds-modal` }>
      <Modal 
        title={ `` }
        onClose={ onClose }>
        <div>
          <div>
            <LogoLightspeedCrypto />
          </div>
          <br/>
          <h2>
            { t(`title`) }
          </h2>

          <div className={ `message` }>
            { t(`message2`) }
            <em>
              { t(`message3`) }
            </em>
            { t(`message4`) }
            <h5>
              { t(`message5`) }
            </h5>
            
          </div>

          <Button 
            type={ `primary` }
            onClick={ handleClick }>
            { t(`depositFunds`) }
          </Button>          
        </div>
      </Modal>
    </div>
  );
};

export default translate(`wireFundsModal`)(WireFundsModal);
