import React, { useState, useEffect } from 'react';
import Modal from '../../../utilities/Modal.jsx';
import Button from '../../../utilities/Button.jsx';
import SelectField from '../../../utilities/SelectField.jsx';
import { postWithdraw } from '../../../../helpers/api/UserApi.js';
import { sanitizeNumberInputs } from '../../../../helpers/NumberHelper.js';
import TextField from '../../../utilities/TextField.jsx';
import Trash from "../../../../svgs/Trash.jsx";

export const FiatWithdrawModal = (props) => {
  const { t, close, currCode, openNewBankAccountModal, bankAccounts, setAccountInfo, onDeleteAddressClick } = props;
  const [loading, setLoading] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState(``);
  const [amount, setAmount] = useState(``);

  const formatBankAccounts = (array) => {
    if (array) {
      return array
        .filter((el) => !el.inactive)
        .map((account) => {
          const label = account.label ? account.label : `Untitled`;
          return {
            label: label,
            value: account.fundTransferMethodId
          };
        });
    }
    return [{ value: null, label: `No accounts found` }];
  };

  const bankAccountOptions = formatBankAccounts(bankAccounts);

  const unableToWithdraw = () => {
    return !(bankAccounts.length > 0 && selectedBankAccount.length);
  };

  useEffect(() => {
    if (bankAccountOptions.length > 1) {
      setSelectedBankAccount(bankAccountOptions[0].value);
    }
  }, [bankAccounts]);

  const makeWithdraw = () => {
    setLoading(true);
    const transferRequestId = selectedBankAccount;
    const body = {
      CurrCode: currCode,
      amount,
      transferRequestId
    };
    if (!unableToWithdraw()) {
      setLoading(true);
      postWithdraw(body, () => {
        setLoading(false);
        close(false);
        setAccountInfo();
      });
    }
  };

  const handleDeleteClick = (event, data) => {
    event.stopPropagation();
    const { label, value } = data;
    onDeleteAddressClick({ id: value, label, type: `fiat` });
  };

  const renderSelectRow = (option, index, optionList, defaultProps) => (
    <span { ...defaultProps } className="select-option-field">
      { option.label }
      { option.value && 
        <Button type="cancel" onClick={ (event) => handleDeleteClick(event, option) }>
          { Trash(`DeleteFiatAddress${index}`) }
        </Button>
      }
    </span>
  );

  return (
    <div className="withdraw-modal">
      <Modal
        title={ t(`accounts:withdrawCurrency`, { currency: currCode }) }
        onClose={ () => close(false) }
        onConfirm={ true }>
        <div className="row add-new">
          <SelectField
            options={ bankAccountOptions }
            value={ selectedBankAccount }
            label={ `Choose Bank Account` }
            onChange={ (e, v) => setSelectedBankAccount(v) }
            searchable={ false }
            renderOption={ renderSelectRow } />
          <Button type="primary" onClick={ () => openNewBankAccountModal() }>
            { `+ New` }
          </Button>
        </div>
        <div className="row">
          <TextField
            label={ t(`app:amount`) }
            value={ amount }
            name={ `amount` }
            type={ `text` }
            hintText={ `Enter the exact amount you wish to withdraw.` }
            errorText={ null }
            onChange={ (e) => sanitizeNumberInputs(e.target.value, setAmount) } />
        </div>
        {
          loading &&
          <div className="row loading-message-row">
            { t(`app:loading`) }
          </div>
        }
        <div className="row modal-footer">
          <Button type="primary" disabled={ amount === `` } onClick={ () => makeWithdraw() }>
            { t(`Submit Withdrawal Request`) }
          </Button>
          <Button type="secondary" onClick={ () => close(false) }>
            { t(`cancel`) }
          </Button>
        </div>
      </Modal>
    </div>
  );
};
