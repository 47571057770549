import React from 'react';
import ReactDOM from 'react-dom';
import store from './helpers/StoreHelper.js';


if (process.env.NODE_ENV === `production`) {
  window.console.debug = window.console.log;
  window.console.log = () => {};
  window.console.warn = () => {};
}

window.API_V2_URL = process.env.API_V2_URL;
window.WWW_URL = process.env.WWW_URL;
window.CLIB_URL = process.env.CLIB_URL;
window.COOKIE_DOMAIN = process.env.COOKIE_DOMAIN;


const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),
  isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

if (isChrome || isSafari) window.document.body.classList.add(`supports-webkit`);

import Root from './components/Root.jsx';

ReactDOM.render(<Root store={ store } aiKey={ process.env.AI_KEY } />, document.getElementById(`app`));
