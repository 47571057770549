'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default () => SvgHelper(
  `<path d="M4.99063 7.46719L9.24063 3.21719C9.53438 2.92344 10.0094 2.92344 10.3 3.21719L11.0063 3.92344C11.3 4.21719 11.3 4.69219 11.0063 4.98281L7.99375 7.99531L11.0063 11.0078C11.3 11.3016 11.3 11.7766 11.0063 12.0672L10.3031 12.7797C10.0094 13.0734 9.53438 13.0734 9.24375 12.7797L4.99375 8.52969C4.69688 8.23594 4.69688 7.76094 4.99063 7.46719Z" />`,
  {
    width: `2rem`,
    height: `2rem`,
    viewBox: `0 0 16 16`,
  }
);
/* eslint-enable */

