// @flow
'use strict';

import React from 'react';
import Gear from "../../svgs/Gear.jsx";

export const ToggleSettingsButton = (props: any) => {
  const { text, size, toggleSettingsMenu } = props;

  return(
    <a className="settings-menu-link" data-testid={ `settings-menu-link` } onClick={ () => toggleSettingsMenu() }>
      { Gear }
      { size === `xs` &&
        <label>
          &nbsp;
          { text }
        </label>
      }      
    </a>
  );
};
