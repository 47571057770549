// @flow
'use strict';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import EntityTable from '../utilities/EntityTable.jsx';
import Tabs from '../utilities/Tabs.jsx';
import PillToggle from "../utilities/PillToggle.jsx";
import SelectField from '../utilities/SelectField.jsx';
import CancelOrderModal from '../orders/CancelOrderModal.jsx';
import { getMarketPair } from '../../helpers/MarketPairHelper.js';
import { emitEvent, SET_MARKET_FILTER } from '../../helpers/EventHelper.js';
import { toFixedDecimalsHTML, toFixedDecimals } from '../../helpers/NumberHelper.js';
import { toLocalDate } from '../../helpers/DateHelper.js';
import { getExchangeTradeApiVersion } from '../../helpers/ExchangeTradingHelper';
import { updateRedisPrefPage } from '../../actions/redisPrefs/updateRedisPrefs.js';
import { changeMarket } from '../../actions/markets/changeMarket.js';
import type { Order } from '../../types/Order.js';
import type { Market } from "../../types/Market.js";
import type { Exchange } from "../../types/Exchange.js";
import type { Balance } from "../../types/Balance.js";
import type { Account } from "../../types/Account.js";
import Trash from "../../svgs/Trash.jsx";
import OrderStatus from '../utilities/OrderStatus.jsx';
import RightAngleBracket from '../../svgs/RightAngleBracket.jsx';
import LeftAngleBracket from '../../svgs/LeftAngleBracket.jsx';

const PAGE_SIZES = [10, 25, 50, 100, 250];
const DEFAULT_PAGE_SIZE = 10;

type Props = {
  t: any,
  active: {
    market: Market,
    exchange: Exchange
  },
  showLarger: boolean,
  className: string,
  isFull: boolean,
  balances: Array<Balance>,
  openOrders: Array<Order>,
  openOrdersPage: number,
  openOrdersPages: number,
  orderHistory: Array<Order>,
  orderHistoryPage: number,
  orderHistoryPages: number,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  accounts: Array<Account>,
  deleteOrder: (authId: number, orderId: number, exchCode: string) => boolean,
  currentAuthId?: number,
  activeBOHTab: number,
  updateBOHTab: (tab: number) => void,
  marketsAreClickable: boolean,
  priceTypes: *,
  statusTypes: *,
  orderTypes: *,
  marketSwitcherOpen?: boolean,
  pageSize: any,
  changePageSize: (n: any) => void,
  exchFilter: string,
  mktFilter: string,
  authFilter: string,
  getCurrentAuthIdValue: () => string,
  updateUserOrderFilters: (exchFilter: ?string, mktFilter: ?string, authFilter: ?string) => void,
  getUserOrderData: (pageNumber: number, activeTab: number) => void,
  toggleOpen: (n: string) => void,
  changeMarket: (b: boolean) => void,
  tabs?: Array<any>,
  showMultiAuth?: boolean,
};


const BalancesOrdersHistory = (props: Props) => {
  const { t, exchFilter, mktFilter, authFilter, getCurrentAuthIdValue, updateUserOrderFilters, active: { market, exchange }, showLarger, className, isFull, accounts, openOrdersPage, openOrdersPages,
    orderHistoryPage, orderHistoryPages, markets, exchanges, deleteOrder, currentAuthId, activeBOHTab, updateBOHTab,
    marketsAreClickable, priceTypes, statusTypes, orderTypes, marketSwitcherOpen, pageSize = DEFAULT_PAGE_SIZE, changePageSize, getUserOrderData, toggleOpen,changeMarket,  tabs: propsTabs, showMultiAuth } = props;

  const [cancelOrderModalOpen, setCancelOrderModalOpen] = useState(false);
  const [cancelOrder, setCancelOrder] = useState({});
  const [rowHoverOrderId, setRowHoverOrderId] = useState(null);
  let currentPage;
  let totalPages;
  let lastPage;
  const firstPage = currentPage === 1;
  
  const tabs = propsTabs || [
    { key: `balances`, title: t(`balances:balances`) },
    { key: `openOrders`, title: t(`orders:openOrders`) },
    { key: `orderHistory`, title: t(`orders:orderHistory`) }
  ];

  const getTabIndex = (key: string): number => tabs.findIndex((t) => t.key === key);
  
  if (activeBOHTab === getTabIndex(`openOrders`)) {
    currentPage = openOrdersPage;
    totalPages = openOrdersPages;
    lastPage = currentPage === openOrdersPages;
  }
  if (activeBOHTab === getTabIndex(`orderHistory`)) {
    currentPage = orderHistoryPage;
    totalPages = orderHistoryPages;
    lastPage = currentPage === orderHistoryPages;
  }

  useEffect(() => {
    exchange && market && updateUserOrderFilters(exchange.exchCode, market.displayName ,null);
  }, [exchange.exchCode, market.displayName]);

  useEffect(() => {
    updateUserOrderFilters(null, null , getCurrentAuthIdValue());
  }, [currentAuthId]);

  useEffect(() => {
    getUserOrderData(1, activeBOHTab);
  }, [pageSize, exchFilter, mktFilter, authFilter]);

  useEffect(() => {
    getUserOrderData(1, activeBOHTab);
  }, [activeBOHTab]);

  const getFilteredData = (tab: string) => {
    if (![`openOrders`, `orderHistory`, `balances`].includes(tab)) return [];
    if (props[tab] === undefined) return [];

    return props[tab].filter((item) => {
      const conditions = [true, true, true];
      if ([`balances`].includes(tab)) {
        if (item.balanceHidden) return false;

        if (exchFilter !== `all`) {
          let account = accounts.filter((a) => a.authId == item.balanceAuthId)[0];
          if (!account) {
            conditions[0] = false;
          } else {
            conditions[0] = account.authExchId == exchange.exchId;
          }
        }

        if (mktFilter !== `all`) {
          conditions[1] = mktFilter.split(`/`).indexOf(item.balanceCurrCode) > -1;
        }

        if (authFilter && authFilter !== `all`) {
          conditions[2] = item.balanceAuthId === parseInt(authFilter);
        }
      }
      return !conditions.includes(false);
    });

  };

  const openCancelOrderModal = (authId: number, orderId: number, exchCode: string) => {
    setCancelOrderModalOpen(true);
    setCancelOrder({
      authId,
      orderId,
      exchCode
    });
  };

  const closeCancelOrderModal = () => {
    setCancelOrderModalOpen(false);
    setCancelOrder({});
  };

  const displayMarket = (row: Order) => {
    if (marketsAreClickable) {
      return (
        <Link 
          onClick={ () => changeMarket(true) }
          to={ `/markets/${ row.exchCode }/${ row.displayName }` }>
          { row.exchCode }
          { ` ` }
          { row.displayName }
        </Link>
      );
    } else {
      return (
        <a className="market-link-disabled">
          { row.exchCode }
          { ` ` }
          { row.displayName }
        </a>
      );
    }
  };

  const displayPrice = (row: any, price: number, key: string = ``, requireMarket: boolean = false, html: boolean = true): any => {
    let market;

    if (requireMarket) {
      market = markets.find((m) => m.exchCode == row.exchCode && m.displayName == row.displayName);
    }

    let args = requireMarket ? [price, true, key, market] : [price];

    if (html) {
      return (
        <span
          className="nowrap"
          dangerouslySetInnerHTML={
            toFixedDecimalsHTML(...args)
          } />
      );
    }

    return toFixedDecimals(...args);
  };

  const sendBalanceFilter = (row: Balance) => {
    const account = accounts.find((a) => a.authId == row.balanceAuthId);
    if (account) {
      const exchange = exchanges.find((e) => e.exchId == account.authExchId);

      if (exchange) {
        !marketSwitcherOpen ? toggleOpen(`marketSwitcherOpen`) : null;
        emitEvent(SET_MARKET_FILTER, `${ exchange.exchCode }:${ row.balanceCurrCode }`);
      }
    }
  };

  const displayAccount = (id: string) => {
    let account = accounts.find((a) => a.authId == parseInt(id));
    if (account) {
      return account.authNickname;
    }
    return ``;
  };

  const getTotalHint = (row: any) => { 
    let content = `${t(`app:total`)}: ${ displayPrice(row, (parseFloat(row.limitPrice) * parseFloat(row.quantity)), `price`, true, false)} ${row.quoteCurrency}`;

    if (row.triggerExchMktId > 0) {
      const triggerMarket = markets.find((mkt) => mkt.exchmktId === row.triggerExchMktId);
      if (triggerMarket) {
        return `Trigger: ${triggerMarket.exchCode}:${triggerMarket.displayName} @${row.stopPrice} | ` + content;
      }
    }

    return content;
  };

  const displayOrderStatus = (status: number) => {
    const text = statusTypes[status];

    return <OrderStatus status={ status } text={ text }/>;
  };

  // This validates and submits the order. This where we're going to submit buy or sell
  const deleteOrderFunc = () => {
    if(cancelOrder && cancelOrder.orderId > 0){
      deleteOrder(cancelOrder.authId, cancelOrder.orderId, cancelOrder.exchCode);
    }

    closeCancelOrderModal();
  };

  const addColumnBeforeKey = (columns, beforeKey, newColumn) => {
    const idx = columns.findIndex((col) => col.key === beforeKey);
    columns.splice(idx, 0, newColumn);
  };

  
  const getOrderColumns = () => {
    const columns = [
      {
        title: t(`app:account`),
        key: `authNickname`,
        display: (row: Order, name: string) => (
          <span className="nowrap">
            { name }
          </span>
        )
      },
      {
        title: t(`app:market`),
        key: `market`,
        display: (row: Order) => displayMarket(row)
      },
      {
        title: t(`app:type`),
        key: `orderType`,
        display: (row: Order, data: number) => {
          const orderType = orderTypes[row.side];
          return (
            <div className="order-type">
              <div>  
                <figure className={ orderType === `Buy` ? `buy-color-indicator` : `sell-color-indicator` } />   
              </div>
              <div>
                { orderType }
                { ` ` }
                { priceTypes[data] } 
              </div>
            </div>
          );
        }
      },
      {
        title: t(`app:qty`),
        key: `quantity`,
        display: (row: any, quantity: number) => {
          const price =  activeBOHTab === getTabIndex(`orderHistory`) ? 
            displayPrice(row, row.quantity - row.quantityRemaining) : displayPrice(row, quantity, `quantity`, true);

          const total =  getTotalHint(row);

          return <span className='quantity-container'>
            { price }
            { rowHoverOrderId === row.orderId && <span className='total-hint'> 
              { total }
            </span> }
          </span>;
        }
      },
      {
        title: t(`app:price`),
        key: `limitPrice`,
        display: (row: any, limitPrice: number) => {
          const price = statusTypes[row.status] === `Executed` ? (row.executedPrice ? row.executedPrice : limitPrice) : limitPrice;
          return displayPrice(row, price, `price`, true);
        }
      },
      {
        title: t(`app:time`),
        key: `orderTime`,
        display: (row: any, date: string) => toLocalDate(date)
      }
    ];
    
    if (activeBOHTab === getTabIndex(`openOrders`)) {
      columns.push({
        title: ``,
        key: `deleteOrder`,
        nosort: true,
        display: (row: Order) => {
          if (rowHoverOrderId !== row.orderId) return displayOrderStatus(row.status);

          return <div
            onClick={ (e) => {
              e.preventDefault();
              if (deleteOrder) {
                openCancelOrderModal(row.authId, row.orderId, row.exchCode);
              }
            } }>
            <OrderStatus status={ `7 btn-cancel` } text={ Trash(`Trash${ row.orderId }`)  }/>
          </div>;
        }
      });
    } else {
      const statusColumn = {
        title: t(`orders:status`),
        key: `status`,
        display: (row: any, status: number) => displayOrderStatus(status)
      };

      addColumnBeforeKey(columns, `orderTime`, statusColumn);
    }

    return columns;
  };

  const getBalanceColumns = () => {
    return [
      {
        title: t(`app:account`),
        key: `balanceAuthId`,
        display: (row: Balance) => displayAccount(row.balanceAuthId.toString())
      },
      {
        title: t(``),
        key: `balanceCurrCodeLogo`,
        display: (row: Balance) => {
          const image = row.hasImage ? row.balanceCurrCode.toUpperCase() : `empty`;

          return (
            <img 
              src={ `${ window.WWW_URL }/assets/img/currency/${ image }.png` } 
              width={ 20 } />
          );
        },
        thClass: `align-right`,
        tdClass: `align-right`
      },
      {
        title: t(`app:currency`),
        key: `balanceCurrCode`,
        display: (row: Balance, data: string) => (
          <a onClick={ () => sendBalanceFilter(row) }>
            { data }
          </a>
        )
      },
      {
        title: t(`app:available`),
        key: `balanceAmountAvailable`,
        display: (row, price: number) => displayPrice(row, price),
        thClass: `align-right`,
        tdClass: `align-right`
      },
      {
        title: t(`app:held`),
        key: `balanceAmountHeld`,
        display: (row, price: number) => displayPrice(row, price),
        thClass: `align-right`,
        tdClass: `align-right`
      },
      {
        title: t(`app:total`),
        key: `balanceAmountTotal`,
        display: (row, price: number) => displayPrice(row, price),
        thClass: `align-right`,
        tdClass: `align-right`
      }
    ];
  };

  const authName = displayAccount(getCurrentAuthIdValue());

  const handleOrdersRowHover = (row, isHover) => {
    if (activeBOHTab === getTabIndex(`openOrders`) || activeBOHTab === getTabIndex(`orderHistory`)) {
      if (isHover) {
        setRowHoverOrderId(row.orderId);
      } else if (!isHover && rowHoverOrderId === row.orderId) {
        setRowHoverOrderId(null);
      }
    }
  };

  return (
    <div className={ 
      `balances-orders-history ${ className ? className : ``} ` +
        `${ showLarger ? `larger` : `` } ` + 
        `${ isFull ? `full` : `` }` 
    }>
      <div className="header">
        { showMultiAuth ? 
          <div>
            <SelectField
              searchable={ true }
              label={ props.t(`app:account`) }
              name={ `authId` }
              value={ getCurrentAuthIdValue() }
              options={ [...accounts
                .filter((acct) => acct.authVersion == getExchangeTradeApiVersion(exchange) && acct.authExchId === exchange.exchId && acct.authTrade && acct.authActive)
                .map((acct) => ({
                  label: acct.authNickname,
                  value: acct.authId.toString()
                })).sort((a, b) => {
                  if (a.label > b.label) return 1;
                  if (b.label > a.label) return -1;
                  return 0;
                }), { label: t(`app:all`), value: `all` }] }
              onChange={ (e, v) => updateUserOrderFilters(null, null , v) } />
          </div> 
          : 
          getCurrentAuthIdValue() && getCurrentAuthIdValue() !== `all` &&
          <PillToggle
            optionalClass={ `grow` }
            options={ [
              {
                value: getCurrentAuthIdValue(),
                label: authName.length > 16 ? `${ authName.slice(0, 16) }...` : authName
              },
              { label: t(`app:all`), value: `all` }
            ] }
            value={ authFilter }
            onChange={ (e, v) => updateUserOrderFilters(null, null , v) } />
        }
        <PillToggle
          options={ [
            { label: exchange.exchCode, value: exchange.exchCode },
            { label: t(`app:all`), value: `all` }
          ] }
          value={ exchFilter }
          onChange={ (e, v) => updateUserOrderFilters(v, null , null) } />
        <PillToggle
          options={ [
            {
              label: getMarketPair(market).toString(),
              value: getMarketPair(market).toString()
            },
            { label: t(`app:all`), value: `all` }
          ] }
          value={ mktFilter }
          onChange={ (e, v) => updateUserOrderFilters(null, v , null) } />
      </div>
      <Tabs
        tabPosition="center"
        tabNames={ tabs.map((item) => item.title) }
        activeTab={ activeBOHTab }
        onChangeTab={ (tab) => updateBOHTab(tab) }>
        {
          tabs.filter((t) => t.key !== `marketDepth`).map(({ key: item }) => {     
            return (
              <div className={ `boh-key-container` } key={ item }>
                <EntityTable
                  columns={ item == `balances` ? getBalanceColumns() : getOrderColumns() }
                  hasActionButtons={ item == `balances` ? false : true }
                  defaultSortBy={ item == `balances` ? `balanceCurrCode` : `-orderTime` }
                  data={ getFilteredData(item) }
                  hidePagination={ true }
                  pageSize={ activeBOHTab !== getTabIndex(`balances`) ? pageSize : null }
                  isBalancesOrdersHistory={ true }
                  rowHover={ (idx, row) => handleOrdersRowHover(row, true) }
                  rowUnhover={ (idx, row) => handleOrdersRowHover(row, false) }/>
              </div>
            );}
          )
        }
      </Tabs>
      
      { activeBOHTab !== getTabIndex(`balances`) && 
        <div className="footer">
          <div className="page-size">
            { t(`app:rows`) }
            <SelectField
              label=""
              name="open-orders"
              value={ pageSize }
              hideValue={ false }
              options={ PAGE_SIZES.map((value) => {
                return ({
                  label: value.toString(),
                  value: value.toString()
                });
              }) }
              onChange={ (e, v) => {
                changePageSize(v);
              } }/>
          </div>
          <div className="page-nav">
            <div className="pages-text">
                Page 
              { ` ` }
              { currentPage }
              { ` ` }
                of 
              { ` ` }
              { totalPages }
            </div>
            <a
              className={ firstPage ? `noclick` : `` }
              onClick={ () => !firstPage ? getUserOrderData(currentPage === 1 ? 1 : currentPage - 1, activeBOHTab) : null }>
              <LeftAngleBracket />
            </a>
            { ` ` }
            <a
              className={ lastPage ? `noclick` : `` }
              onClick={ () => !lastPage ? getUserOrderData(currentPage + 1, activeBOHTab) : null }>
              <RightAngleBracket />
            </a>      
          </div>
        </div>
      }
      
      {
        cancelOrderModalOpen && (
          <CancelOrderModal
            label={ `Cancel Order` }
            confirm={ () => deleteOrderFunc() }
            close={ () => closeCancelOrderModal() } />
        )
      }
    </div>
  );
};


const mapStateToProps = (state) => ({
  marketsAreClickable: state.browser.marketsAreClickable,
  priceTypes: state.orders.priceTypes,
  statusTypes: state.orders.statusTypes,
  orderTypes: state.orders.orderTypes, 
  pageSize: state.redisPrefs.prefPage?.balancesOrderHistory?.pageSize
});

const mapDispatchToProps = (dispatch) => ({
  changePageSize: (p) => dispatch(updateRedisPrefPage({ key: `balancesOrderHistory`, value: { 'pageSize': p } })),
  changeMarket: (b) => dispatch(changeMarket(b))
});

export { BalancesOrdersHistory as PureBalancesOrdersHistory };
export default translate(`markets`)(connect(mapStateToProps, mapDispatchToProps)(BalancesOrdersHistory));
