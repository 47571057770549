// @flow
'use strict';

import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from "history";

// Set up App Insights
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking, AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import GenericError from '../components/GenericError.jsx';

// Set up Redux
import { Provider, Store } from 'react-redux';

// Set up i18n
import { I18nextProvider } from 'react-i18next';
import i18n from '../helpers/I18nHelper.js';

// Set up URL tracking
import { emitEvent, listenForEvent, MARKET_CHANGE, SET_SYMBOL_COMPLETE } from '../helpers/EventHelper.js';
import { updateMarketsAreClickable } from '../actions/browser/updateMarketsAreClickable.js';
import GoogleAnalytics from 'react-ga';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';


import App from './App.jsx';
import Demo from './Demo.jsx';




var reactPlugin = new ReactPlugin();

type Props = {
  store: Store,
  aiKey: string
};

const browserHistory = createBrowserHistory();

//console.log(`platform id = ` +  window.platformId);
//console.log(`GTM code = ` +  window.gtmCode);
//console.log(`analytics code = ` +  window.analyticsCode);

GoogleAnalytics.initialize(window.analyticsCode || `UA-2770327-52`);
GoogleAnalytics.set({ page: window.location.pathname });
GoogleAnalytics.pageview(window.location.pathname);


export const Root = (props: Props) => {

  let previousPath = window.location.pathname;

  browserHistory.listen((location, action) => {

    let p = previousPath;

    if (action == `PUSH`) {
      GoogleAnalytics.set({ page: location.pathname });
      GoogleAnalytics.pageview(location.pathname);

      if (location.pathname.split(`/markets/`).length > 1 && location.pathname !== previousPath) {
        let m = location.pathname.split(`/markets/`)[1].split(`/`);
        if (m.length == 3) {
          emitEvent(MARKET_CHANGE, `${ m[0] }:${ m[1] }/${ m[2] }`);
        }
  
        if (props && props.store) {
          if (props.store.getState().redisPrefs.marketChartOpen) {
            props.store.dispatch(updateMarketsAreClickable(false));
  
            let t = setTimeout(() => {
              props.store.dispatch(updateMarketsAreClickable(true));
            }, 10000);
  
            listenForEvent(SET_SYMBOL_COMPLETE, () => {
              clearTimeout(t);
  
              props.store.dispatch(updateMarketsAreClickable(true));
            }, true);
          }
        }
      }
    }

    previousPath = p;
  });

  var appInsights = new ApplicationInsights({
    config: { //https://github.com/microsoft/ApplicationInsights-JS#configuration
      instrumentationKey: props.aiKey, //process.env.AI_KEY,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory }
      }
    }
  });
  appInsights.loadAppInsights();

  const isDemo = browserHistory.location.pathname.includes(`/demo/`);

  const queryClient = new QueryClient();

  return (
    <AppInsightsContext.Provider value={ reactPlugin }>
      <AppInsightsErrorBoundary onError={ () => <GenericError /> } appInsights={ reactPlugin }>
        <I18nextProvider i18n={ i18n } >
          <Provider store={ props.store }>
            <QueryClientProvider client={ queryClient }>
              <Router history={ browserHistory }>
                { isDemo ? <Demo /> : <App /> }
              </Router>
            </QueryClientProvider>
          </Provider>
        </I18nextProvider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
};

export default withAITracking(reactPlugin, Root);
