// @flow

import { $ } from "./ApiHelper";
import { readCookie } from '../../helpers/CookieHelper.js';

export function getSubscriptionData(onSuccess: (data: any) => void) {
  $({
    url: `/private/user/subscription`,
    success: onSuccess
  });
}

export function getUserData(onSuccess: (data: any) => void) {
  $({
    url: `/private/user`,
    success: onSuccess
  });
}

export function setEnable2fa(options: any, onSuccess?: (data: any) => void) {
  $({
    url: `/private/user/2fa`,
    type: `PUT`,
    body: options.payload,
    success: onSuccess
  });
}

export function post2fa(onSuccess?: (data: any) => void) {
  $({
    url: `/private/user/2fa`,
    type: `POST`,
    success: onSuccess
  });
}

export function setUserData(options: any, onSuccess?: (data: any) => void) {
  $({
    url: `/private/user`,
    type: `PUT`,
    body: options.payload,
    success: onSuccess
  });
}

export function setUserPreferences(options: any, onSuccess?: (data: any) => void) {
  $({
    url: `/private/user/preferences`,
    type: `PUT`,
    body: options.payload,
    success: onSuccess
  });
}

export function setUserLightSpeedAgreement(body: any, onSuccess?: (data: any) => void) {
  $({
    url: `/private/user/lightspeed/agreement`,
    type: `PUT`,
    body: body,
    success: onSuccess
  });
}

export function setUsername(options: any, onSuccess?: (data: any) => void) {
  $({
    url: `/private/user/username`,
    type: `POST`,
    body: options.payload,
    success: onSuccess
  });
}

export function getUserKeys(onSuccess: (data: any) => void) {
  $({
    url: `/private/user/keys`,
    success: onSuccess
  });
}

export function setUserKey(onSuccess: (data: any) => void) {
  $({
    url: `/private/user/keys`,
    type: `POST`,
    success: onSuccess
  });
}

export function delUserKeys(options: any, onSuccess?: (data: any) => void) {
  $({
    url: `/private/user/keys/${ options.key }`,
    type: `DELETE`,
    success: onSuccess
  });
}

export function getReferrals(onSuccess: (data: any) => void) {
  $({
    url: `/private/user/referrals`,
    success: onSuccess
  });
}

export function getSessions(onSuccess: (data: any) => void) {
  $({
    url: `/private/user/sessions`,
    success: onSuccess
  });
}

export function getActivity(options: any, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/activity`,
    params: options && options.params || {},
    success: onSuccess
  });
}

export function getFavorites(onSuccess: (data: any) => void) {
  $({
    url: `/private/user/ticker`,
    success: onSuccess
  });
}

export function addFavorite(options: any, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/ticker`,
    type: `POST`,
    body: options.body || {},
    success: onSuccess || (() => {})
  });
}

export function deleteFavorite(options: any, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/ticker/${ options.exchmktId }`,
    type: `DELETE`,
    success: onSuccess || (() => {})
  });
}

export function getInvoices(onSuccess: (data: any) => void) {
  $({
    url: `/private/user/invoices`,
    success: onSuccess
  });
}

export function getInvoicesPdf(paymentId: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/invoices/${paymentId}/print`,
    responseType: `blob`,
    success: onSuccess
  });
}
export function killSession(id: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/sessions/${ id }`,
    type: `DELETE`,
    success: onSuccess
  });
}

export function getNotes(marketPair: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/${ marketPair }/notes`,
    success: onSuccess
  });
}

export function saveNotes(options: any, onSuccess?: (data: any) => void) {
  $({
    url: `/private/user/${options.marketPair}/notes`,
    type: `POST`,
    body: { note: options.note } || {},
    success: onSuccess || (() => {})
  });
}

export function setSettings(options: any, onSuccess?: (data: any) => void) {
  $({
    url: `/private/user/settings`,
    type: `PUT`,
    body: options.body,
    success: onSuccess || (() => {})
  });
}

export function getSettings(onSuccess?: (data: any) => void) {
  $({  
    url: `/private/user/settings`,
    success: onSuccess
  });
}

export function createAccount(body: any, onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/accounts`,
    type: `POST`,
    body,
    success: onSuccess || (() => {}),
    error: onError
  });
}

export function updateAccount(authId: number, body: any, onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/accounts/${authId}`,
    type: `PUT`,
    body,
    success: onSuccess || (() => {}),
    error: onError
  });
}

export function createWallet(body: any, onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/wallets`,
    type: `POST`,
    body,
    success: onSuccess || (() => {}),
    error: onError
  });
}

export function bugReport(body: any, onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/bugReport`,
    type: `POST`,
    body,
    success: onSuccess || (() => {}),
    error: onError
  });
}

export function getPrimeTrustToken(options: any, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/lightspeed/resource-token`,
    type: `GET`,
    params: options && options.params || {},
    success: onSuccess || (() => {})
  });
}
export function createPrimeTrustToken(options: any, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/lightspeed/resource-token`,
    type: `POST`,
    params: options && options.params || {},
    success: onSuccess || (() => {})
  });
}

export function postPrimeTrustAccountId(body: any, onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/lightspeed/account`,
    type: `POST`,
    body,
    success: onSuccess || (() => {}),
    error: onError
  });
}

export function postSendSmsTestMessage(body: any, onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/sendsmstestmessage`,
    type: `POST`,
    body,
    success: onSuccess || (() => {}),
    error: onError
  });
}

export function postVerifySmsNumber(body: any, onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/verifysmsnumber`,
    type: `POST`,
    body,
    success: onSuccess || (() => {}),
    error: onError
  });
}

export function deleteUnlinkSmsNumber(onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/unlinksmsnumber`,
    type: `DELETE`,
    success: onSuccess || (() => {}),
    error: onError
  });
}

export function getUserApplications(options: any, onSuccess: (data: any) => void) {
  const { exchId } = options.params;
  const qs = `${exchId ? `&exchId=`+ exchId :``}`;

  $({
    url: `/private/user/applications${qs}`,
    success: onSuccess
  });
}

export function getUserApplicationsStatuses(onSuccess: (data: any) => void) {
  $({
    url: `/private/user/applications/statuses`,
    success: onSuccess
  });
}

export function getTransferTypes(onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/lightspeed/assetTransferTypes`,
    type: `GET`,
    success: onSuccess || (() => {}),
    error: onError,
  });
}

export function getAssetTransferMethods(onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/lightspeed/account/asset-transfer-methods`,
    type: `GET`,
    success: onSuccess || (() => {}),
    error: onError,
  }); 
}

export function getCurrencies(onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/lightspeed/currencies`,
    success: onSuccess || (() => {}),
    error: onError,
  });
}

export function postWithdraw(body: any, onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/lightspeed/account/funds/withdraw`,
    type: `POST`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}

export function uploadKycDocuments(body: any, onSuccess: (data: any) => void, onError?: any) { 

  let formData = new FormData();
  body.Image.map((file) => {   
    formData.append(`Image`, file, file.name);
  });

  const csrfToken = readCookie(`coinigy_csrf_token`);
  
  
  fetch(`${window.API_V2_URL}/private/user/lightspeed/uploadKycDocuments`, {
    method: `PUT`,
    body: formData,
    headers: {      
      'Connection': `keep-alive`,
      'X-CSRF-TOKEN': csrfToken
    },
    credentials: `include`
  })
    .then(function(response) {
      // Data is returned as a ReadableStream
      // The response is a Response instance.
      // Parse the data into a useable format using `.json()`
      return response.json();
    }).then(function(data) {
      // `data` is the parsed version of the JSON returned from the above endpoint.
      onSuccess(data);
    })
    .catch(onError);
 
}



//////////////////////////////
// Asset Transfer Methods     
//////////////////////////////

export function getAssetTransferDetailsBosonic(body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Returns detailed information on ALL asset transfer methods directly from Bosonic  
  $({
    url: `/private/user/lightspeed/account/asset-transfer-methods/details`,
    type: `GET`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}
export function postAssetTransfer(body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Creates a new asset transfer method
  $({
    url: `/private/user/lightspeed/account/asset-transfer-methods`,
    type: `POST`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}
export function getAssetTransferMethodsBosonic(body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Gets detailed information on SPECIFIED asset transfer method directly from Bosonic  
  $({
    url: `/private/user/lightspeed/account/asset-transfer-methods/${body.assetTransferMethodId}/details`,
    type: `GET`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}
export function updateAssetTransferMethodBosonic(body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Updates specified asset transfer method with given data
  $({
    url: `/private/user/lightspeed/account/asset-transfer-methods/${body.assetTransferMethodId}`,
    type: `PATCH`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}

export function deleteAssetTransferMethod(body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Deactivates the specified fund transfer method 
  $({
    url: `/private/user/lightspeed/account/asset-transfer-methods/${body.assetTransferMethodId}`,
    type: `PATCH`,
    body: { assetTransferId: body.assetTransferMethodId, inactive: true },
    success: onSuccess || (() => {}),
    error: onError,
  });
}

//////////////////////////////
// Fund Transfer Methods     
//////////////////////////////

export function getFundTransferMethods(onSuccess?: (data: any) => void, onError?: any) {
  // Returns detailed information on all fund transfer methods directly from Bosonic  
  $({
    url: `/private/user/lightspeed/account/fund-transfer-methods`,
    type: `GET`,
    success: onSuccess || (() => {}),
    error: onError,
  }); 
}
export function postFundTransfer(body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Creates a new fund transfer method    
  $({
    url: `/private/user/lightspeed/account/fund-transfer-methods`,
    type: `POST`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}
export function deleteFundTransfer(body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Deactivates the specified fund transfer method 
  $({
    url: `/private/user/lightspeed/account/fund-transfer-methods/${body.fundTransferMethodsId}`,
    type: `DELETE`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}
export function getFundTransferById(body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Gets detailed information on specified fund transfer method directly from Bosonic     
  $({
    url: `/private/user/lightspeed/account/fund-transfer-methods/${body.fundTransferMethodId}/details`,
    type: `GET`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}

//////////////////////////////
// Contact Transfer Methods     
//////////////////////////////

export function getContactTransferRequest(onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/lightspeed/account/contact-transfer-references`,
    type: `GET`,
    success: onSuccess || (() => {}),
    error: onError,
  });
}
export function createContactTransfer(body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Creates a new contact transfer reference    
  $({
    url: `/private/user/lightspeed/account/contact-transfer-references`,
    type: `POST`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}
export function getWireDetails(referenceId: string, onSuccess?: (data: any) => void, onError?: any) {
  // Gets detailed information for the specified contact transfer reference directly from Bosonic
  $({
    url: `/private/user/lightspeed/account/contact-transfer-references/${referenceId}/details`,
    type: `GET`,
    success: onSuccess || (() => {}),
    error: onError,
  });
}
export function deleteContactTransferById(referenceId: string, body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Gets detailed information for the specified contact transfer reference directly from Bosonic
  $({
    url: `/private/user/lightspeed/account/contact-transfer-references/${body.contactTransferReferencesId}`,
    type: `DELETE`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}

//////////////////////////////
// Transfers (Bosonic API)
//////////////////////////////

export function getTransfers(onSuccess?: (data: any) => void, onError?: any) { 
  $({
    url: `/private/user/lightspeed/account/transfers`,
    type: `GET`,
    success: onSuccess || (() => {}),
    error: onError,
  }); 
}
export function deleteAssetTransfer(referenceId: string, body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Gets detailed information for the specified contact transfer reference directly from Bosonic
  $({
    url: `/private/user/lightspeed/account/asset-transfers/${body.assetTransferId}/cancel`,
    type: `DELETE`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}

export function cancelFundTransfer(referenceId: string, body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Attempts to cancel and revert specified fund transfer 
  $({
    url: `/private/user/lightspeed/account/fund-transfers/${body.fundTransferId}/cancel`,
    type: `DELETE`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}
export function getAllFundTransfers(onSuccess?: (data: any) => void, onError?: any) { 
  // Returns detailed information on al fund transfers directly from Bosonic   
  $({
    url: `/private/user/lightspeed/account/fund-transfers/details`,
    type: `GET`,
    success: onSuccess || (() => {}),
    error: onError,
  }); 
}
export function getAllAssetTransfers(onSuccess?: (data: any) => void, onError?: any) { 
  // Returns detailed information on al fund transfers directly from Bosonic   
  $({
    url: `/private/user/lightspeed/account/asset-transfers/details`,
    type: `GET`,
    success: onSuccess || (() => {}),
    error: onError,
  }); 
}
export function getPushTransferDetails(): Promise<any>  {
  return new Promise((resolve, reject) => {
    $({
      url: `/private/user/lightspeed/account/push-transfer-methods/details`,
      type: `GET`,
      success: resolve,
      error: reject
    });
  });
}
export function postPushTransfer(): Promise<any>  {
  return new Promise((resolve, reject) => {
    $({
      url: `/private/user/lightspeed/account/push-transfer-methods`,
      type: `POST`,
      success: resolve,
      error: reject
    });
  });
}

//////////////////////////////
// Production Bosonic Endpoints
//////////////////////////////

export function getPrimeTrustAccount(body: any, onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/lightspeed/account`,
    type: `GET`,
    body,
    success: onSuccess || (() => {}),
    error: onError
  });
}
export function getPrimeTrustContacts(body: any, onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/lightspeed/account/contacts`,
    type: `GET`,
    body,
    success: onSuccess || (() => {}),
    error: onError
  });
}
export function postKycDocumentCheck(body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Creates a new fund transfer method    
  $({
    url: `/private/user/lightspeed/account/contacts/${body.contactId}/kyc-document-check`,
    type: `POST`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}
export function getKycDocuments(body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Get a list of user's uploaded KYC documents
  $({
    url: `/private/user/lightspeed/account/contacts/${body.contactId}/documents`,
    type: `GET`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}
export function patchContactById(body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Creates a new fund transfer method    
  $({
    url: `/private/user/lightspeed/account/contacts/${body.contactId}`,
    type: `PATCH`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}
export function postApplicationStarted(body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Tells the backend that the user has started their LSCX application
  $({
    url: `/private/user/lightspeed/application-started`,
    type: `POST`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}

//////////////////////////////
// Bosonic UAT-Specific Endpoints
//////////////////////////////

export function getPrimeTrustTokenUAT(options: any, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/lightspeed/resource-token-uat`,
    type: `GET`,
    params: options && options.params || {},
    success: onSuccess || (() => {})
  });
}
export function createPrimeTrustTokenUAT(options: any, onSuccess: (data: any) => void) {
  $({
    url: `/private/user/lightspeed/resource-token-uat`,
    type: `POST`,
    params: options && options.params || {},
    success: onSuccess || (() => {})
  });
}
export function getPrimeTrustAccountUAT(body: any, onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/lightspeed/account-uat`,
    type: `GET`,
    body,
    success: onSuccess || (() => {}),
    error: onError
  });
}
export function getPrimeTrustContactsUAT(body: any, onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/lightspeed/account/contacts-uat`,
    type: `GET`,
    body,
    success: onSuccess || (() => {}),
    error: onError
  });
}
export function depositFiatFundsUAT(body: any, onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/lightspeed/account/funds`,
    type: `POST`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}
export function depositAsset(body: any, onSuccess?: (data: any) => void, onError?: any) {
  $({
    url: `/private/user/lightspeed/account/assets`,
    type: `POST`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}
export function uploadKycDocumentsUAT(body: any, onSuccess: (data: any) => void, onError?: any) { 

  let formData = new FormData();
  body.Image.map((file) => {   
    formData.append(`Image`, file, file.name);
  });

  const csrfToken = readCookie(`coinigy_csrf_token`);
  
  
  fetch(`${window.API_V2_URL}/private/user/lightspeed/uploadKycDocuments-uat`, {
    method: `PUT`,
    body: formData,
    headers: {      
      'Connection': `keep-alive`,
      'X-CSRF-TOKEN': csrfToken
    },
    credentials: `include`
  })
    .then(function(response) {
      // Data is returned as a ReadableStream
      // The response is a Response instance.
      // Parse the data into a useable format using `.json()`
      return response.json();
    }).then(function(data) {
      // `data` is the parsed version of the JSON returned from the above endpoint.
      onSuccess(data);
    })
    .catch(onError);
 
}
export function postKycDocumentCheckUAT(body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Creates a new fund transfer method    
  $({
    url: `/private/user/lightspeed/account/contacts/${body.contactId}/kyc-document-check-uat`,
    type: `POST`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}

export function getKycDocumentsUAT(body: any, onSuccess?: (data: any) => void, onError?: any) {
  // Get a list of user's uploaded KYC documents
  $({
    url: `/private/user/lightspeed/account/contacts/${body.contactId}/documents-uat`,
    type: `GET`,
    body,
    success: onSuccess || (() => {}),
    error: onError,
  });
}

////////////////// END UAT-Specific Endpoints


//////////////////////////////
// Custom Statements Endpoints
//////////////////////////////

export function getCustomerStatementsPdf(fromDate: string, toDate: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/lightspeed/report/customerstatement/pdf/range/${fromDate}/${toDate}`,
    responseType: `blob`,
    success: onSuccess,
  });
}

export function getCustomerStatementsPdfFromDay(day: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/lightspeed/report/customerstatement/pdf/day/${day}`,
    responseType: `blob`,
    success: onSuccess,
  });
}

export function getCustomerStatementsPdfFromMonth(month: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/lightspeed/report/customerstatement/pdf/month/${month}`,
    responseType: `blob`,
    success: onSuccess,
  });
}

export function getCustomerStatementsPdfFromYear(year: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/lightspeed/report/customerstatement/pdf/year/${year}`,
    responseType: `blob`,
    success: onSuccess,
  });
}

export function getCustomerStatementsJson(fromDate: string, toDate: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/lightspeed/report/customerstatement/range/${fromDate}/${toDate}`,
    success: onSuccess,
  });
}

export function getCustomerStatementsJsonFromDay(day: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/lightspeed/report/customerstatement/day/${day}`,
    success: onSuccess,
  });
}

export function getCustomerStatementsJsonFromMonth(month: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/lightspeed/report/customerstatement/month/${month}`,
    success: onSuccess,
  });
}

export function getCustomerStatementsJsonFromYear(year: string, onSuccess: (data: any) => void) {
  $({
    url: `/private/lightspeed/report/customerstatement/year/${year}`,
    success: onSuccess,
  });
}

export function getUserExistingNotes(onSuccess: (data: any) => void) {
  $({
    url: `/private/user/notes`,
    success: onSuccess,
  });
}
