import React, { useState } from 'react';
import Modal from '../../../utilities/Modal.jsx';
import Button from '../../../utilities/Button.jsx';
import TextField from '../../../utilities/TextField.jsx';
import { postAssetTransfer } from '../../../../helpers/api/UserApi.js';

export const NewCryptoWalletAddressModal = ({ close, t, currCode, setAccountTransfers }) => {
  const [ errors, setErrors ] = useState(``);
  const [ loading, setLoading ] = useState(false);
  const [ walletAddress, setWalletAddress ] = useState(``);
  const [ walletLabel, setWalletLabel ] = useState(``);

  const createNewWalletAddress = () => {
    setLoading(true);
    setErrors();
   
    const body = {
      walletAddress: walletAddress,
      transferDirection: `outgoing`,
      currCode: currCode,
      label: walletLabel,
    };
    postAssetTransfer(body, (data) => {
      if (data.success) {
        setErrors();
        setAccountTransfers();
        setLoading(false);
        close(false);
      } else {
        setLoading(false);
        setErrors(data.error);
      }
    }, () => {});
  };

  return (
    <div className="withdraw-modal">
      <Modal
        title={ `New ` + currCode + ` Wallet Address` }
        onClose={ () => close(false) }
        onConfirm={ true }>
        <div className="row">
          <TextField 
            label={ t(`Wallet Label`) }
            name={ `walletLabel` }
            value={ walletLabel }
            onChange={ (e) => setWalletLabel(e.target.value) }/>
        </div>
        <div className="row">
          <TextField 
            label={ t(`Wallet Address`) }
            name={ `walletAddress` }
            value={ walletAddress }
            onChange={ (e) => setWalletAddress(e.target.value) }/>
        </div>      
        {
          loading &&
            <div className="row loading-message-row">
              { t(`app:loading`) }
            </div>
        }
        {
          errors &&
            <div className="row errors-message-row">
              { errors }
            </div>
        }
        <div className="row modal-footer">
          <Button type="primary" onClick={ () => createNewWalletAddress() }>
            { t(`create`) }
          </Button>
          <Button type="secondary" onClick={ () => close(false)  }>
            { t(`cancel`) }
          </Button>
        </div>
      </Modal>
    </div>
  );
};
