// @flow
'use strict';

import React, { useEffect, useState } from 'react';
import { useStateRef } from '../../helpers/useStateRef';
import { translate } from 'react-i18next';
import PresetTextField from '../utilities/PresetTextField.jsx';
import Button from '../utilities/Button.jsx';
import TextField from '../utilities/TextField.jsx';
import SelectField from '../utilities/SelectField.jsx';
import Checkbox from '../utilities/Checkbox.jsx';
import { getMarketPair } from '../../helpers/MarketPairHelper';
import { toFixedDecimalsHTML, toFixedDecimals, formatLongNumber } from '../../helpers/NumberHelper.js';
import type { Exchange } from '../../types/Exchange.js';
import type { Market } from '../../types/Market.js';
import type { Account } from '../../types/Account';
import Refresh from '../../svgs/Refresh.jsx';
import Trash2 from '../../svgs/Trash2.jsx';
import CollapseLeft from '../../svgs/CollapseLeft.jsx';
import CollapseRight from '../../svgs/CollapseRight.jsx';
import MarketSelectorModal from '../boards/MarketSelectorModal.jsx';
import {
  listenForEvent,
  removeEventListener,
  SWITCH_TO_BUY_MODE,
  SWITCH_TO_SELL_MODE,
  SWITCH_TO_BUY_MODE_WITH_PRICE,
  SWITCH_TO_SELL_MODE_WITH_PRICE,
  SWITCH_TO_BUY_MODE_WITH_STOP_PRICE,
  SWITCH_TO_SELL_MODE_WITH_STOP_PRICE
} from '../../helpers/EventHelper.js';

type Props = {
  t: any,
  markets: Array<Market>,
  exchanges: Array<Exchange>,
  active: {
    exchange: Exchange,
    market: Market
  },
  options?: Array<{
    label: string,
    value: any,
    icon?: any,
    compareValue?: any
  }>,
  buyBalanceOptions: Array<{
    label: string,
    value: any,
    compareValue?: any,
    icon?: any
  }>,
  sellBalanceOptions: Array<{
    label: string,
    value: any,
    compareValue?: any,
    icon?: any
  }>,
  apiVersion: number,
  activeAccounts: Array<Account>,
  hasActiveMarket: boolean,
  tradingEnabled: boolean,
  mktId: number,
  exchId: number,
  authId: number,
  lastPrice: number,
  limitPrice: string,
  stopMarketId: number,
  stopMarketName: string,
  stopPricePrecision: number,
  setStopMarket: (e?: any, b?: any) => void,
  stopPrice: string,
  orderQty: string,
  orderTotal: string,
  orderTypeId: number,
  orderTypeText: string,
  currencyText: string,
  priceTypeId: number,
  baseCurrencyText: string,
  limitPriceError?: string,
  stopPriceError?: string,
  orderQuantityError?: string,
  orderTotalError?: string,
  balanceData: any,
  onChange: (key?: string, value: any, forceNumber?: boolean, callback?: () => void) => void,
  detachedOnChange?: (value: any) => void,
  onSubmit: (e?: any) => boolean,
  addNewAccount?: () => void,
  accountStalenessClass: () => string,
  refreshBalance: () => void,
  refreshEnabled: () => boolean,
  refreshState: string,
  resetOrderForm: () => void,
  updateOrderFormType?: (orderFormType: string) => void,
  autoConfirm: boolean,
  showPrecision: boolean,
  showMarketSelectorModal: () => void,
  hideMarketSelectorModal: () => void,
  marketSelectorModalOpen: boolean,
  updateInputDirtiness: (e: boolean) => void,
  bestBid: number, 
  bestAsk: number,
  isDetached: boolean,
};

function setCursorToLastPosition(event) {
  event.persist();
  setTimeout(() => { //defer until call stack is clear
    const pos = String(event.target.value).length;
    event.target.setSelectionRange(pos, pos);
  }, 0);
}

const OrderForm = (props: Props) =>  {  
  const [buyLimitPrice, setBuyLimitPrice, buyLimitPrice_ref] = useStateRef<string>(`0`);
  const [buyStopPrice, setBuyStopPrice, buyStopPrice_ref] = useStateRef<string>(`0`);
  const [buyStopMarketId, setBuyStopMarketId, buyStopMarketId_ref] = useStateRef<number>(0);
  const [buyStopMarketName, setBuyStopMarketName, buyStopMarketName_ref] = useStateRef<string>(``);
  const [buyStopPricePrecision, setBuyStopPricePrecision, buyStopPricePrecision_ref] = useStateRef<number>(0);
  const [buyOrderQty, setBuyOrderQty, buyOrderQty_ref] = useStateRef(`0`);
  const [buyOrderTotal, setBuyOrderTotal, buyOrderTotal_ref] = useStateRef<string>(`0`);
  const [sellLimitPrice, setSellLimitPrice, sellLimitPrice_ref] = useStateRef<string>(`0`);
  const [sellStopPrice, setSellStopPrice, sellStopPrice_ref] = useStateRef<string>(`0`);
  const [sellStopMarketId, setSellStopMarketId, sellStopMarketId_ref] = useStateRef<number>(0);
  const [sellStopMarketName, setSellStopMarketName, sellStopMarketName_ref] = useStateRef<string>(``);
  const [sellStopPricePrecision, setSellStopPricePrecision, sellStopPricePrecision_ref] = useStateRef<number>(0);
  const [sellOrderQty, setSellOrderQty, sellOrderQty_ref] = useStateRef<string>(`0`);
  const [sellOrderTotal, setSellOrderTotal, sellOrderTotal_ref] = useStateRef<string>(`0`);
  const subscribeEvents: Array<string> = [
    SWITCH_TO_BUY_MODE,
    SWITCH_TO_SELL_MODE,
    SWITCH_TO_BUY_MODE_WITH_PRICE,
    SWITCH_TO_SELL_MODE_WITH_PRICE,
    SWITCH_TO_BUY_MODE_WITH_STOP_PRICE,
    SWITCH_TO_SELL_MODE_WITH_STOP_PRICE
  ];

  const [detachedExchId, setDetachedExchId] = useState(null);
  const [activeLimitButton, setActiveLimitButton] = useState(`inactive`);

  const isLSCXActiveMarket = props.active.market.exchCode === `LSCX`;
  const getLSCXdefaultStopMarket = (markets, marketName) => {
    return markets.find((mkt) => mkt.exchCode === `GDAX` && mkt.marketName === marketName);
  };

  const resetState = () => {
    setBuyLimitPrice(`0`);
    setBuyStopPrice(`0`);
    setBuyStopPricePrecision(props.active.market.basePricePrecision);
    setBuyOrderQty(`0`);
    setBuyOrderTotal(`0`);
    setSellLimitPrice(`0`);
    setSellStopPrice(`0`);
    setSellStopPricePrecision(props.active.market.basePricePrecision);
    setSellOrderQty(`0`);
    setSellOrderTotal(`0`);
    setActiveLimitButton(`inactive`);

    if (isLSCXActiveMarket) {
      const mktName = props.active.market.displayName;
      const market = getLSCXdefaultStopMarket(props.markets, mktName);
      setBuyStopMarketId(market?.exchmktId);
      setSellStopMarketId(market?.exchmktId);
      setBuyStopMarketName(`GDAX:` + props.active.market.displayName);
      setSellStopMarketName(`GDAX:` + props.active.market.displayName);
    } else {
      setBuyStopMarketId(props.active.market.exchmktId);
      setSellStopMarketId(props.active.market.exchmktId);
      setBuyStopMarketName(props.active.market.exchCode + `:` + props.active.market.displayName);
      setSellStopMarketName(props.active.market.exchCode + `:` + props.active.market.displayName);
    }
  };

  useEffect(() => {
    if (isLSCXActiveMarket) {
      const mktName = props.active.market.displayName;
      const market = getLSCXdefaultStopMarket(props.markets, mktName);
      setBuyStopMarketId(market?.exchmktId);
    } else {
      setBuyStopMarketId(props.active.market.exchmktId);
    }
  }, [props.active.market.exchmktId]);

  
  useEffect(() => {
    if (isLSCXActiveMarket) {
      const mktName = props.active.market.displayName;
      const market = getLSCXdefaultStopMarket(props.markets, mktName);
      setBuyStopMarketId(market?.exchmktId);
    } else {
      setSellStopMarketId(props.active.market.exchmktId); 
    }
  }, [props.active.market.exchmktId]);
  
  useEffect(() => {
    if (isLSCXActiveMarket) {
      const mktName = props.active.market.displayName;
      const market = getLSCXdefaultStopMarket(props.markets, mktName);
      setBuyStopMarketName(market?.displayName);
    } else {
      setBuyStopMarketName(props.active.market.displayName);
    }
  }, [props.active.market.displayName]);
  
  
  useEffect(() => {
    if (isLSCXActiveMarket) {
      const mktName = props.active.market.displayName;
      const market = getLSCXdefaultStopMarket(props.markets, mktName);
      setSellStopMarketName(market?.displayName);
    } else {
      setSellStopMarketName(props.active.market.displayName);
    }
  }, [props.active.market.displayName]);
  
  
  useEffect(() => {
    setBuyStopPricePrecision(props.active.market.basePricePrecision);
  }, [props.active.market.basePricePrecision]);
  
  
  useEffect(() => {
    setSellStopPricePrecision(props.active.market.basePricePrecision);
  }, [props.active.market.basePricePrecision]);

  useEffect(() => {
    props.updateInputDirtiness(false);
    subscribeEvents.forEach((event) => listenForEvent(event, (e) => handleTVMessage(e)));
    return () => subscribeEvents.forEach((event) => removeEventListener(event, (e) => handleTVMessage(e)));
  }, []);

  useEffect(() => {
    if (props.active) {
      resetState();
    }
  }, [props.active.market.exchmktId]);

  useEffect(() => {
    if (parseFloat(buyLimitPrice) == 0 && parseFloat(props.limitPrice) > 0) {
      setBuyLimitPrice(props.limitPrice );
    }
  }, [props.limitPrice]);

  useEffect(() => {
    if (parseFloat(sellLimitPrice) == 0 && parseFloat(props.limitPrice) > 0) {
      setSellLimitPrice(props.limitPrice );
    }
  }, [props.limitPrice]);

  useEffect(() => {
    if (parseFloat(buyStopPrice) == 0 && parseFloat(props.stopPrice) > 0) {
      setBuyStopPrice(props.stopPrice);
    }
  }, [props.stopPrice]);

  useEffect(() => {
    if (parseFloat(sellStopPrice) == 0 && parseFloat(props.stopPrice) > 0) {
      setSellStopPrice(props.stopPrice);
    }
  }, [props.stopPrice]);

  useEffect(() => {
    (props.orderTypeId == 1) ?
      setBuyStopPrice(props.stopPrice):
      setSellStopPrice(props.stopPrice);

  }, [props.stopPrice]);

  useEffect(() => {
    (props.orderTypeId == 1) ?
      setBuyLimitPrice(props.limitPrice) :
      setSellLimitPrice(props.limitPrice);

  }, [props.limitPrice]);

  useEffect(() => {
    (props.orderTypeId == 1) ?
      setBuyOrderQty(props.orderQty) :
      setSellOrderQty(props.orderQty);

  }, [props.orderQty]);
  
  useEffect(() => {
    (props.orderTypeId == 1) ? 
      setBuyOrderTotal(props.orderTotal) : 
      setSellOrderTotal(props.orderTotal);
      
  }, [props.orderTotal]);

  useEffect(() => {
    if (props.orderTypeId == 1) {
      if (isLSCXActiveMarket) {
        const mktName = props.active.market.displayName;
        const market = getLSCXdefaultStopMarket(props.markets, mktName);
        setBuyStopMarketId(market?.exchmktId);
      } else {
        setBuyStopMarketId(props.stopMarketId);
      }
      props.stopMarketName && setBuyStopMarketName(props.stopMarketName);
      setBuyStopPricePrecision(props.stopPricePrecision);
    } else {
      if (isLSCXActiveMarket) {
        const mktName = props.active.market.displayName;
        const market = getLSCXdefaultStopMarket(props.markets, mktName);
        setSellStopMarketId(market?.exchmktId);
      } else {
        setSellStopMarketId(props.stopMarketId);
      }
      setSellStopMarketName(props.stopMarketName);
      setSellStopPricePrecision(props.stopPricePrecision);
    }
  }, [props.stopMarketId, props.stopMarketName, props.stopPricePrecision]);

  useEffect(() => {
    if (props.orderQty != `0`) {
      props.updateInputDirtiness(false);
    }
  }, [props.orderTypeId]);

  useEffect(() => setLimitValues(), [activeLimitButton, props.bestBid, props.bestAsk]);

  useEffect(() => {
    const isBuyActive = activeLimitButton.indexOf(`buy`) === 0;
    const isSellActive = activeLimitButton.indexOf(`sell`) === 0;
    
    if ((props.orderTypeId === 1 && isSellActive) || (props.orderTypeId === 2 && isBuyActive)) {
      setActiveLimitButton(`inactive`);
    }
  }, [props.orderTypeId]);
  
  const setLimitValues = () => {    
    const isBuyActive = activeLimitButton.indexOf(`buy`) === 0;
    
    if(activeLimitButton !== `inactive` && ((props.orderTypeId === 1 && isBuyActive) || (props.orderTypeId === 2 && !isBuyActive))) {
      let priceValue = ``;

      switch (activeLimitButton) {
      case `buy-mid`:
      case `sell-mid`:
        priceValue = formatLongNumber((parseFloat(props.bestBid) + parseFloat(props.bestAsk)) / 2, true);
        break;
      case `buy-bid`:
        priceValue = formatLongNumber(props.bestBid, true);
        break;
      case `buy-01`:
        priceValue = formatLongNumber(props.bestBid * 0.99, true);
        break;
      case `buy-03`:
        priceValue = formatLongNumber(props.bestBid * 0.97, true);
        break;
      case `buy-05`:
        priceValue = formatLongNumber(props.bestBid * 0.95, true);
        break;
      case `sell-ask`:
        priceValue = formatLongNumber(props.bestAsk, true);
        break;
      case `sell-01`:
        priceValue = formatLongNumber(props.bestAsk * 1.01, true);
        break;
      case `sell-03`:
        priceValue = formatLongNumber(props.bestAsk * 1.03, true);
        break;
      case `sell-05`:
        priceValue = formatLongNumber(props.bestAsk * 1.05, true);
        break;
      }

      props.onChange(`limitPrice`, priceValue.replace(/,/g, ``));
    }
  };

  const handleTVMessage = (e: any) => {
    if (!e) return;
    switch(e.type) {
    case SWITCH_TO_SELL_MODE:
      props.onChange(undefined, {
        orderTypeText: `Sell`,
        orderTypeId: 2,
        orderQty: sellOrderQty_ref.current,
        limitPrice: sellLimitPrice_ref.current,
        stopPrice: sellStopPrice_ref.current,
        stopMarketId: sellStopMarketId_ref.current,
        stopMarketName: sellStopMarketName_ref.current,
        stopPricePrecision: sellStopPricePrecision_ref.current,
        orderTotal: sellOrderTotal_ref.current
      });
      break;
    case SWITCH_TO_BUY_MODE:
      props.onChange(undefined, {
        orderTypeText: `Buy`,
        orderTypeId: 1,
        orderQty: buyOrderQty_ref.current,
        limitPrice: buyLimitPrice_ref.current,
        stopPrice: buyStopPrice_ref.current,
        stopMarketId: buyStopMarketId_ref.current,
        stopMarketName: buyStopMarketName_ref.current,
        stopPricePrecision: buyStopPricePrecision_ref.current,
        orderTotal: buyOrderTotal_ref.current
      });
      break;
    case SWITCH_TO_BUY_MODE_WITH_PRICE:
      props.onChange(undefined, {
        orderTypeText: `Buy`,
        orderTypeId: 1,
        orderQty: buyOrderQty_ref.current,
        limitPrice: e.detail,
        stopPrice: buyStopPrice_ref.current,
        stopMarketId: buyStopMarketId_ref.current,
        stopMarketName: buyStopMarketName_ref.current,
        stopPricePrecision: buyStopPricePrecision_ref.current,
        orderTotal: buyOrderTotal_ref.current
      });
      props.onChange(`limitPrice`, toFixedDecimals(e.detail, true, `price`, props.active.market));
      break;
    case SWITCH_TO_SELL_MODE_WITH_PRICE:
      props.onChange(undefined, {
        orderTypeText: `Sell`,
        orderTypeId: 2,
        orderQty: sellOrderQty_ref.current,
        limitPrice: e.detail,
        stopPrice: sellStopPrice_ref.current,
        stopMarketId: sellStopMarketId_ref.current,
        stopMarketName: sellStopMarketName_ref.current,
        stopPricePrecision: sellStopPricePrecision_ref.current,
        orderTotal: sellOrderTotal_ref.current
      });
      props.onChange(`limitPrice`, toFixedDecimals(e.detail, true, `price`, props.active.market));
      break;
    case SWITCH_TO_BUY_MODE_WITH_STOP_PRICE:
      props.onChange(undefined, {
        orderTypeText: `Buy`,
        orderTypeId: 1,
        orderQty: buyOrderQty_ref.current,
        limitPrice: buyLimitPrice_ref.current,
        stopPrice: e.detail,
        stopMarketId: buyStopMarketId_ref.current,
        stopMarketName: buyStopMarketName_ref.current,
        stopPricePrecision: buyStopPricePrecision_ref.current,
        orderTotal: buyOrderTotal_ref.current
      });
      props.onChange(`stopPrice`, toFixedDecimals(e.detail, true, `price`, props.active.market));
      break;
    case SWITCH_TO_SELL_MODE_WITH_STOP_PRICE:
      props.onChange(undefined, {
        orderTypeText: `Sell`,
        orderTypeId: 2,
        orderQty: sellOrderQty_ref.current,
        limitPrice: sellLimitPrice_ref.current,
        stopPrice: e.detail,
        stopMarketId: sellStopMarketId_ref.current,
        stopMarketName: sellStopMarketName_ref.current,
        stopPricePrecision: sellStopPricePrecision_ref.current,
        orderTotal: sellOrderTotal_ref.current
      });
      props.onChange(`stopPrice`, toFixedDecimals(e.detail, true, `price`, props.active.market));
      break;
    } 
  };


  const options = props.markets.filter((mkt) => {
    const useExchId = detachedExchId === null ? props.exchId : detachedExchId; 
    return mkt.exchId === useExchId;
  }).map((mkt) => ({
    label: getMarketPair(mkt).toString(),
    compareValue: mkt.marketId,
    value: {
      mktId: mkt.marketId,
      currencyText: getMarketPair(mkt).base,
      baseCurrencyText: getMarketPair(mkt).quote
    }
  })).sort((a, b) => {
    if (a.label > b.label) return 1;
    if (b.label > a.label) return -1;
    return 0;
  }); 

  const limitOrderEvent = (e, activeBtn) => {
    e.preventDefault();
    e.stopPropagation();

    setActiveLimitButton(activeBtn === activeLimitButton ? `inactive` : activeBtn);
  };

  return (
    <div>
      {
        // Exchange select box - only shown when on the Orders full page
        props.isDetached && (
          <div>
            <SelectField
              searchable={ true }
              label={ props.t(`app:exchange`) }
              name={ `exchId` }
              value={ detachedExchId === null ? props.exchId : detachedExchId }
              options={ props.exchanges.filter((exch) => {
                return exch.exchTradeEnabled || exch.exchTradeEnabledV2;
              }).map((exch) => ({
                label: exch.exchName,
                compareValue: exch.exchId,
                value: {
                  exchId: exch.exchId,
                  mktId: -1,
                  authId: -1
                }
              })).sort((a, b) => {
                if (a.label > b.label) return 1;
                if (b.label > a.label) return -1;
                return 0;
              }) }
              onChange={ (event, exchange) => {
                if (props.detachedOnChange) {
                  props.detachedOnChange({ value: exchange, field: `exchange` });
                  setDetachedExchId(exchange.exchId);
                }
              } } />
          </div>
        )
      }
      {
        // Market select box - only shown on Orders full page
        props.isDetached && (
          <div>
            <SelectField
              searchable={ true }
              label={ props.t(`app:market`) }
              name={ `mktId` }
              value={ props.mktId }
              options={ options }
              onChange={ (event, market) => props.detachedOnChange && props.detachedOnChange({ value: market, field: `market` }) } />
          </div>
        )
      }
      {
        props.mktId > 0 && props.tradingEnabled && (
          <div className={ (props.activeAccounts && props.activeAccounts.length > 0 
            ? `add-flex top-form-section` : ``) + 
        (props.orderTypeId === 1 ? ` buy-border-color` : ` sell-border-color`) }>
            <div className={ props.activeAccounts && props.activeAccounts.length > 0 
              ? `order-form-select account` : `` }>
              {
                props.activeAccounts && props.activeAccounts.length > 0 ? (
                // We have an active account, so show the authId select box
                  <SelectField
                    searchable={ true }
                    label={ props.t(`app:account`) }
                    name={ `authId` }
                    value={ props.authId }
                    options={ [...props.activeAccounts.map((acct) => ({
                      label: acct.authNickname,
                      value: acct.authId
                    })).sort((a, b) => {
                      if (a.label > b.label) return 1;
                      if (b.label > a.label) return -1;
                      return 0;
                    }), { label: `${props.t(`user:addNewAccount`)}...`, value: -2 }] }
                    onChange={ (e, v) => props.onChange(`authId`, v, true) } />
            
                ) : 
                  props.active.exchange.exchCode !== `LSCX` &&
                  (
                  // No active accounts so show the addNewAccount button
                    <Button
                      type="primary"
                      onClick={ () => { 
                        if (props.apiVersion == 2 && props.addNewAccount) {
                          props.addNewAccount();
                        } else {
                          window.location.assign(`${ window.WWW_URL }/user/api`);
                        }
                        return true;
                      } }>
                      { props.t(`addAccount`) }
                    </Button>
                  )
              }
            </div>
            {
              props.activeAccounts && props.activeAccounts.length > 0 ? (
                <div className={ `order-form-select price-type` }>
                  <SelectField
                    searchable={ false }
                    label={ props.t(`orders:orderType`) }
                    name={ `priceTypeId` }
                    value={ props.priceTypeId }
                    disabled={ props.authId === -1 }
                    options={ isLSCXActiveMarket ? [
                      { label: props.t(`app:market`), value: 5, name: `marketOrderOption` },
                      { label: props.t(`limit`), value: 3, name: `LimitOption` },
                      { label: props.t(`stopLimit`), value: 6, name: `StopLimitOption` },
                    ] : [
                      { label: props.t(`limit`), value: 3, name: `LimitOption` },
                      { label: props.t(`stopLimit`), value: 6, name: `StopLimitOption` },
                    ] }
                    onChange={ (e, v) => props.onChange(`priceTypeId`, v, true) } />
                </div>
              ) : null
            }
            {
              props.activeAccounts && props.activeAccounts.length > 0 ? (
                <a
                  onClick={ () => { 
                    props.resetOrderForm();
                    if (parseFloat(props.orderQty) > 0) {
                      props.onChange(`orderQty`, `0`);
                      resetState();
                      if (props.lastPrice) {
                        setBuyStopPrice(props.lastPrice.toString());
                        setSellStopPrice(props.lastPrice.toString());
                        setBuyLimitPrice(props.lastPrice.toString());
                        setSellLimitPrice(props.lastPrice.toString());
                        props.onChange(undefined, {
                          stopPrice: props.lastPrice,
                          limitPrice: props.lastPrice,
                        });
                      }
                    }
                  } }
                  className={ parseFloat(props.orderQty) > 0 ? `eraser-active` : `eraser-disabled` }>
                  { Trash2(`reset-order-form`) }
                </a>
              ) : null
            }
            {
              props.activeAccounts && props.activeAccounts.length > 0 ? (
                <a
                  onClick={ () => props.refreshBalance() }
                  className={ 
                    `refresh ${ props.refreshState } ${ props.refreshEnabled() ? `enabled` : `` } ` +
                  `${ props.accountStalenessClass() }`
                  }>
                  <Refresh/>
                </a>
              ) : null
            }
          </div>
        )
      }
      {
        props.authId > 0 && props.tradingEnabled && props.balanceData && (
          <div className="available-balance dual-form-balances-wrapper">
            <div
              className={ `dual-form-balances balances-left ` + (props.orderTypeId === 1 ? `active` : ``) }
              onMouseDown={ () => {
                props.onChange(undefined, {
                  orderTypeText: `Buy`,
                  orderTypeId: 1,
                  orderQty: buyOrderQty,
                  limitPrice: buyLimitPrice,
                  stopPrice: buyStopPrice,
                  stopMarketId: buyStopMarketId,
                  stopMarketName: buyStopMarketName,
                  stopPricePrecision: buyStopPricePrecision,
                  orderTotal: buyOrderTotal
                });
              } }>
              <div className="label">
                { props.t(`availableBalance`) + ` (` + props.t(`app:buy`) + `)` }
              </div>
              { 
                props.balanceData.buy ? (
                  <span
                    className={ props.orderTypeId === 1 ? `active avail-bal` : `avail-bal` }
                    onClick={ () => {
                      props.onChange(`orderTypeId`, 1);
                      setTimeout(() => props.onChange(
                        `orderQty`, 
                        toFixedDecimals(
                          props.balanceData.buy.balanceAmountAvailable, 
                          false, 
                          `balance`, 
                          props.active ? props.active.market : undefined
                        )), 
                      100);
                    } }>
                    <span dangerouslySetInnerHTML={ 
                      toFixedDecimalsHTML(
                        props.balanceData.buy.balanceAmountAvailable, 
                        false, 
                        `balance`, 
                        props.active ? props.active.market : undefined
                      ) 
                    } />
                    { ` ` }
                    { props.baseCurrencyText }
                  </span>
                ) : (
                  <span
                    className={ props.orderTypeId === 1 ? `active avail-bal` : `avail-bal` } 
                    onClick={ () => {
                      props.onChange(undefined, {
                        orderTypeText: `Buy`,
                        orderTypeId: 1,
                      });
                    } }>
                    <span 
                      dangerouslySetInnerHTML={ 
                        toFixedDecimalsHTML(
                          0, 
                          false, 
                          `balance`, 
                          props.active ? props.active.market : undefined
                        ) 
                      } />
                    { ` ` }
                    { props.baseCurrencyText }
                  </span>
                )
              }
            </div>
            <div className={ `dual-submit-middle ` + (props.orderTypeId === 1 ? `green-bg` : `red-bg`) } />
            <div
              className={ `dual-form-balances balances-right ` + (props.orderTypeId === 1 ? `` : `active`) }
              onMouseDown={ () => {
                props.onChange(undefined, {
                  orderTypeText: `Sell`,
                  orderTypeId: 2,
                  orderQty: sellOrderQty,
                  limitPrice: sellLimitPrice,
                  stopPrice: sellStopPrice,
                  stopMarketId: sellStopMarketId,
                  stopMarketName: sellStopMarketName,
                  stopPricePrecision: sellStopPricePrecision,
                  orderTotal: sellOrderTotal
                });
              } }>
              <div className="label">
                { props.t(`availableBalance`) + ` (` + props.t(`app:sell`) + `)` }
              </div>
              { 
                props.balanceData.sell ? (
                  <span
                    className={ props.orderTypeId === 2 ? `active avail-bal` : `avail-bal` } 
                    onClick={ () => {
                      props.onChange(`orderTypeId`, 2);
                      setTimeout(() => props.onChange(
                        `orderQty`, 
                        toFixedDecimals(
                          props.balanceData.sell.balanceAmountAvailable, 
                          true, 
                          `balance`, 
                          props.active ? props.active.market : undefined
                        ) 
                      ), 100);
                    } }>
                    <span dangerouslySetInnerHTML={ 
                      toFixedDecimalsHTML(
                        props.balanceData.sell.balanceAmountAvailable, 
                        true, 
                        `balance`, 
                        props.active ? props.active.market : undefined
                      ) 
                    } />
                    { ` ` }
                    { props.currencyText }
                  </span>
                ) : (
                  <span
                    className={ props.orderTypeId === 2 ? `active avail-bal` : `avail-bal` } 
                    onClick={ () => {
                      props.onChange(undefined, {
                        orderTypeText: `Sell`,
                        orderTypeId: 2,
                      });
                    } }>
                    <span 
                      dangerouslySetInnerHTML={ 
                        toFixedDecimalsHTML(
                          0, 
                          true, 
                          `balance`, 
                          props.active ? props.active.market : undefined
                        ) 
                      } />
                    { ` ` }
                    { props.currencyText }
                  </span>
                )
              }
            </div>
          </div>
        )
      }
      {
        props.mktId > 0 && props.tradingEnabled && (
          <div className={ `dual-form-wrapper ` + (props.authId === -1 ? `disabled` : ``)  }>
            { /* inputs-left start */ }
            <div
              className={ `dual-form-inputs inputs-left ` + (props.orderTypeId === 1 ? `active` : ``) + (props.priceTypeId === 5 ? ` column-reverse` : ``) }
              onMouseDown={ () => {
                props.onChange(undefined, {
                  orderTypeText: `Buy`,
                  orderTypeId: 1,
                  stopPrice: buyStopPrice,
                  stopMarketId: buyStopMarketId,
                  stopMarketName: buyStopMarketName,
                  stopPricePrecision: buyStopPricePrecision,
                  orderQty: buyOrderQty,
                  limitPrice: buyLimitPrice,
                  orderTotal: buyOrderTotal,
                });
              } }>
              {
                props.priceTypeId === 6 && (
                  <div>
                    <div className="addFlex flex-center">
                      <div className={ `order-type-hint-text` }>
                      When the 
                        <b>
                          { ` Price ` }
                        </b>
                        { ` of ` }
                        <b>
                          <div
                            className={ `stop-market-selector` + (props.apiVersion == 2 ? ` clickable` : ``) }
                            onClick={ props.apiVersion == 2 ? () => props.showMarketSelectorModal() : null }>
                            { buyStopMarketName + ` ▾` }
                          </div>
                          {
                            props.orderTypeId == 1 && props.marketSelectorModalOpen && (
                              <MarketSelectorModal
                                showLSCX={ false }
                                exchanges={ props.exchanges }
                                markets={ props.markets }
                                accounts={ [] }
                                panel={ { settingsJson: JSON.stringify({
                                  exchange: buyStopMarketName.split(`:`)[0],
                                  market: buyStopMarketName.split(`:`)[1],
                                  panelId: 1234
                                }) } }
                                exit={ () => props.hideMarketSelectorModal() }
                                setMarket={ (e, b) => props.setStopMarket(e, b) }/>
                            )
                          }
                        </b>
                        { ` reaches` }
                      </div>
                    </div>
                    <div className="addFlex">
                      <sub />
                      <div className="inputs-label bold">
                        { props.t(`stopPrice`) }
                      </div>
                      <div className="inputs-container">
                        <PresetTextField
                          label={ `` }
                          disabled={ props.authId === -1 }
                          name={ `buyStopPrice` }
                          value={ buyStopPrice }
                          options={ props.active.market.exchmktId === buyStopMarketId ? props.options : [] }
                          errorText={ props.orderTypeId == 1 ? props.stopPriceError : `` }
                          optionsLabel={ `$` }
                          compact={ true }
                          selectOnLeft={ true }
                          onChange={ (e) => props.onChange(`stopPrice`, e) }
                          onFocus={ (e) => { 
                            setCursorToLastPosition(e);
                            props.onChange(undefined, {
                              orderTypeText: `Buy`,
                              orderTypeId: 1
                            }); 
                          } } 
                          commaSeparator/>
                      </div>              
                    </div>
                    <div className="addFlex flex-center">
                      <div className={ `order-type-hint-text` }>
                        { ` Place the following  ` }
                        <b>
                          { `Limit Order` }
                        </b>
                        { ` on ` }
                        <b>
                          {
                            props.active.exchange.exchCode
                          }
                            :
                          {
                            props.active.market.displayName
                          }
                        </b>
                        { `:` }
                      </div>
                    </div>
                  </div>
                )
              }
              <div className="addFlex">
                <div className={ `inputs-label` }>
                  { props.priceTypeId === 5 ? `Estimated` : `${ props.baseCurrencyText } ${ props.t(`toSpend`) }` }
                </div>
                <div className={ `inputs-container` + (props.priceTypeId === 5 ? ` input-suffix-nopadding` : ``) } data-suffix={ props.baseCurrencyText }>
                  <PresetTextField
                    label={ `` }
                    disabled={ props.authId === -1 }
                    name={ `buyQuantity` }
                    value={ buyOrderQty }
                    options={ props.buyBalanceOptions }
                    errorText={ props.orderTypeId == 1 ? props.orderQuantityError : `` }
                    optionsLabel={ `%` }
                    compact={ true }
                    selectOnLeft={ true }
                    onChange={ (e) => {
                      e == 0 ? 
                        setBuyOrderQty(props.orderQty): 
                        setBuyOrderQty(e);
                      props.onChange(`orderQty`, e);                        
                    } }
                    onFocus={ (e) => { 
                      setCursorToLastPosition(e);
                      props.onChange(undefined, {
                        orderTypeText: `Buy`,
                        orderTypeId: 1
                      }); } } />
                </div>
              </div>
              <div className="addFlex">
                <div className={ `inputs-label` + (props.priceTypeId === 5 ? ` no-bg` : ``) }>
                  { `${ props.t(`pricePer`) } ${ props.currencyText }` }
                </div>
                <div className={ `inputs-container` + (props.priceTypeId === 5 ? ` no-bg input-suffix-nopadding` : ``) } data-suffix={ props.baseCurrencyText }>
                  <PresetTextField
                    label={ `` }
                    disabled={ props.authId === -1 || props.priceTypeId === 5 }
                    name={ `buyLimitPrice` }
                    value={ props.priceTypeId === 5 ? props.bestAsk : buyLimitPrice }
                    options={ [] }
                    errorText={ props.orderTypeId == 1 ? props.limitPriceError : `` }
                    optionsLabel={ props.priceTypeId === 5 ? `` : `$` }
                    compact={ true }
                    selectOnLeft={ true }
                    onChange={ (e) => { 
                      e == 0 ? 
                        setBuyLimitPrice(props.priceTypeId === 5 ? props.bestAsk : props.limitPrice): 
                        setBuyLimitPrice(e);
                      props.onChange(`limitPrice`, e);
                    } }
                    onFocus={ (e) => {
                      setCursorToLastPosition(e);
                      props.onChange(undefined, {
                        orderTypeText: `Buy`,
                        orderTypeId: 1
                      }); } } 
                    commaSeparator/>
                </div>
              </div>
              {
                props.priceTypeId !== 5 && (
                  <div className="addFlex">
                    <div className={ (props.priceTypeId === 5 ? `limit-orders-btn_container input-suffix-nopadding` : `limit-orders-btn_container`) } data-suffix={ props.baseCurrencyText }>
                      <Button
                        className={ `limit-orders-btn first ${ (activeLimitButton === `buy-mid`) ? `active`: ``}` }
                        onClick={ 
                          (e) => {
                            if(props.options) {
                              limitOrderEvent(e, `buy-mid`);
                            }
                            return false;
                          }
                        }>
                      Mid
                      </Button>
                      <Button
                        className={ `limit-orders-btn ${ (activeLimitButton === `buy-bid`) ? `active`: ``}` }
                        onClick={ 
                          (e) => {
                            if(props.options) {
                              limitOrderEvent(e, `buy-bid`);
                            }
                            return false;
                          }
                        }>
                      Bid
                      </Button>
                      <Button
                        className={ `limit-orders-btn ${ (activeLimitButton === `buy-01`) ? `active`: ``}` }
                        onClick={ 
                          (e) => {
                            if(props.options) {
                              limitOrderEvent(e, `buy-01`);
                            }
                            return false;
                          }
                        }>
                      -1%
                      </Button>
                      <Button
                        className={ `limit-orders-btn ${ (activeLimitButton === `buy-03`) ? `active`: ``}` } 
                        onClick={ 
                          (e) => {
                            if(props.options) {
                              limitOrderEvent(e, `buy-03`);
                            }
                            return false;
                          }
                        }>
                      -3%
                      </Button>
                      <Button
                        className={ `limit-orders-btn last ${ (activeLimitButton === `buy-05`) ? `active`: ``}` } 
                        
                        onClick={ 
                          (e) => {
                            if(props.options) {
                              limitOrderEvent(e, `buy-05`);
                            }
                            return false;
                          }
                        }>
                      -5%
                      </Button>
                 
                    </div>
                  </div>
                )
              }
              <div className="addFlex">
                <div className="inputs-label">
                  { props.priceTypeId === 5 ? `Buying` : `${ props.currencyText } ${ props.t(`toBuy`) }` }
                </div>
                <div className={ `inputs-container add-padding` + (props.priceTypeId === 5 ? ` input-suffix` : ``) } data-suffix={ props.currencyText }>
                  <TextField
                    label={ `` }
                    disabled={ props.authId === -1 }
                    name={ `buyOrderTotal` }
                    value={ buyOrderTotal }
                    placeholder={ `BTC` }
                    errorText={ props.orderTypeId == 1 ? props.orderTotalError : `` }
                    compact={ true }
                    onChange={ (e) => { 
                      e.target.value == 0 ? 
                        setBuyOrderTotal(props.orderTotal): 
                        setBuyOrderTotal(e.target.value);
                      props.onChange(`orderTotal`, e.target.value);
                    } }
                    onFocus={ (e) => {
                      setCursorToLastPosition(e);
                      props.onChange(undefined, {
                        orderTypeText: `Buy`,
                        orderTypeId: 1
                      }); } } />
                </div> 
              </div>
            </div>
            { /* inputs-left end */ }
            {
              props.priceTypeId !== 5 && props.updateOrderFormType ? (
                <div
                  className={ `collapse-middle ` + (props.orderTypeId === 1 ? `collapse-is-buy` : `collapse-is-sell`) }
                  onClick={ () => props.updateOrderFormType && props.updateOrderFormType(`single`) }>
                  <div className={ `collapse-inner-wrapper` }>
                    {
                      props.orderTypeId === 1 ?
                        CollapseRight(`collapseIsBuy`)
                        :
                        CollapseLeft(`collapseIsSell`)
                    }
                  </div>
                </div>
              ) : null
            }
            
            { /* inputs-right start */ }
            <div
              className={ `dual-form-inputs inputs-right ` + (props.orderTypeId === 1 ? `` : `active`) }
              onMouseDown={ () => {
                props.onChange(undefined, {
                  orderTypeText: `Sell`,
                  orderTypeId: 2,
                  stopPrice: sellStopPrice,
                  stopMarketId: sellStopMarketId,
                  stopMarketName: sellStopMarketName,
                  stopPricePrecision: sellStopPricePrecision,
                  orderQty: sellOrderQty,
                  limitPrice: sellLimitPrice,
                  orderTotal: sellOrderTotal,
                });
              } }>
              {
                props.priceTypeId === 6 && (
                  <div>
                    <div className="addFlex flex-center">
                      <div className={ `order-type-hint-text` }>
                        { `When the  ` }
                        <b>
                          { `Price` }
                        </b>
                        { ` of ` }
                        <b>
                          <div
                            className={ `stop-market-selector` + (props.apiVersion == 2 ? ` clickable` : ``) }
                            onClick={  props.apiVersion == 2 ? () => props.showMarketSelectorModal() : null  }>
                            { sellStopMarketName + ` ▾` }
                          </div>
                          {
                            props.orderTypeId == 2 && props.marketSelectorModalOpen && (
                              <MarketSelectorModal
                                showLSCX={ false }
                                exchanges={ props.exchanges }
                                markets={ props.markets }
                                accounts={ [] }
                                panel={ { settingsJson: JSON.stringify({
                                  exchange: sellStopMarketName.split(`:`)[0],
                                  market: sellStopMarketName.split(`:`)[1],
                                  panelId: 1234
                                }) } }
                                exit={ () => props.hideMarketSelectorModal() }
                                setMarket={ (e, b) => props.setStopMarket(e, b) } />
                            )
                          }
                        </b>
                        { ` reaches` }
                      </div>
                    </div>
                    <div className="addFlex">
                      <div className="inputs-label bold">
                        { props.t(`stopPrice`) }
                      </div>
                      <div className="inputs-container">
                        <PresetTextField
                          label={ `` }
                          disabled={ props.authId === -1 }
                          name={ `sellStopPrice` }
                          value={ sellStopPrice }
                          options={ props.active.market.exchmktId === sellStopMarketId ? props.options : [] }
                          errorText={ props.orderTypeId == 2 ? props.stopPriceError : `` }
                          optionsLabel={ `$` }
                          compact={ true }
                          selectOnLeft={ true }
                          onChange={ (e) => { 
                            e == 0 ? 
                              setSellStopPrice(props.stopPrice): 
                              setSellStopPrice(e);
                            props.onChange(`stopPrice`, e);
                          } }
                          onFocus={ (e) => {
                            setCursorToLastPosition(e);
                            props.onChange(undefined, {
                              orderTypeText: `Sell`,
                              orderTypeId: 2
                            }); 
                          } } 
                          commaSeparator/>
                      </div>
                    </div>
                    <div className="addFlex flex-center">
                      <div className={ `order-type-hint-text` }>
                          Place the following 
                        { ` ` }
                        <b>
                          Limit Order
                        </b>
                        { ` ` }
                          on
                        { ` ` }
                        <b>
                          {
                            props.active.exchange.exchCode
                          }
                                                      :
                          {
                            props.active.market.displayName
                          }
                        </b>
                          :
                      </div>
                    </div>
                  </div>
                )
              }
              <div className="addFlex">
                <div className="inputs-label">
                  { props.priceTypeId === 5 ? `Selling` : `${ props.currencyText } ${ props.t(`toSell`) }` }
                </div>
                <div className={ `inputs-container` + (props.priceTypeId === 5 ? ` input-suffix-nopadding` : ``) } data-suffix={ props.currencyText }>
                  <PresetTextField
                    label={ `` }
                    disabled={ props.authId === -1 }
                    name={ `sellQuantity` }
                    value={ sellOrderQty }
                    options={ props.sellBalanceOptions }
                    errorText={ props.orderTypeId == 2 ? props.orderQuantityError : `` }
                    optionsLabel={ `%` }
                    compact={ true }
                    selectOnLeft={ true }
                    onChange={ (e) => { 
                      e == 0 ? 
                        setSellOrderQty(props.orderQty): 
                        setSellOrderQty(e);
                      props.onChange(`orderQty`, e);
                    } }
                    onFocus={ (e) => {
                      setCursorToLastPosition(e);
                      props.onChange(undefined, {
                        orderTypeText: `Sell`,
                        orderTypeId: 2
                      }); 
                    } } />
                </div>
              </div>
              <div className="addFlex">
                <div className={ `inputs-label` + (props.priceTypeId === 5 ? ` no-bg` : ``) }>
                  { `${ props.t(`pricePer`) } ${ props.currencyText }` }
                </div>
                <div className={ `inputs-container` + (props.priceTypeId === 5 ? ` no-bg input-suffix-nopadding` : ``) } data-suffix={ props.baseCurrencyText }>
                  <PresetTextField
                    label={ `` }
                    disabled={ props.authId === -1 || props.priceTypeId === 5 }
                    name={ `sellLimitPrice` }
                    value={ props.priceTypeId === 5 ? props.bestBid : sellLimitPrice }
                    options={ [] }
                    errorText={ props.orderTypeId == 2 ? props.limitPriceError : `` }
                    optionsLabel={ props.priceTypeId === 5 ? `` : `$` }
                    compact={ true }
                    selectOnLeft={ true }
                    onChange={ (e) => { 
                      e == 0 ?  
                        setSellLimitPrice(props.priceTypeId === 5 ? props.bestBid : props.limitPrice): 
                        setSellLimitPrice(e);
                      props.onChange(`limitPrice`, e);
                    } }
                    onFocus={ (e) => {
                      setCursorToLastPosition(e);
                      props.onChange(undefined, {
                        orderTypeText: `Sell`,
                        orderTypeId: 2
                      }); 
                    } } 
                    commaSeparator/>
                </div>
              </div>
              {
                props.priceTypeId !== 5 && (
                  <div className="addFlex">
                    <div className={ (props.priceTypeId === 5 ? `limit-orders-btn_container input-suffix-nopadding` : `limit-orders-btn_container`) } data-suffix={ props.baseCurrencyText }>
                      <Button
                        className={ `limit-orders-btn first ${ (activeLimitButton === `sell-mid`) ? `active`: ``}` }
                        onClick={ 
                          (e) => {
                            if(props.options) {
                              limitOrderEvent(e, `sell-mid`);
                            }
                            return false;
                          }
                        }>
                      Mid
                      </Button>
                      <Button
                        className={ `limit-orders-btn ${ (activeLimitButton === `sell-ask`) ? `active`: ``}` }
                        onClick={ 
                          (e) => {
                            if(props.options) {
                              limitOrderEvent(e, `sell-ask`);
                            }
                            return false;
                          }
                        }>
                      Ask
                      </Button>
                      <Button
                        className={ `limit-orders-btn ${ (activeLimitButton === `sell-01`) ? `active`: ``}` }
                        onClick={ 
                          (e) => {
                            if(props.options) {
                              limitOrderEvent(e, `sell-01`);
                            }
                            return false;
                          }
                        }>
                      +1%
                      </Button>
                      <Button
                        className={ `limit-orders-btn ${ (activeLimitButton === `sell-03`) ? `active`: ``}` }
                        onClick={ 
                          (e) => {
                            if(props.options) {
                              limitOrderEvent(e, `sell-03`);
                            }
                            return false;
                          }
                        }>
                      +3%
                      </Button>
                      <Button
                        className={ `limit-orders-btn last ${ (activeLimitButton === `sell-05`) ? `active`: ``}` }
                        onClick={ 
                          (e) => {
                            if(props.options) {
                              limitOrderEvent(e, `sell-05`);
                            }
                            return false;
                          }
                        }>
                      +5%
                      </Button>
                 
                    </div>
                  </div>
                )
              }
              <div className="addFlex">
                <div className={ `inputs-label` }>
                  {
                    props.priceTypeId === 5 ? `Estimated` : `${ props.baseCurrencyText } ${ props.t(`toReceive`) }`
                  }
                </div>
                <div className={ `inputs-container add-padding` + (props.priceTypeId === 5 ? ` input-suffix` : ``) } data-suffix={ props.baseCurrencyText }>
                  <TextField
                    label={ `` }
                    disabled={ props.authId === -1 }
                    name={ `sellOrderTotal` }
                    value={ sellOrderTotal }
                    errorText={ props.orderTypeId == 2 ? props.orderTotalError : `` }
                    compact={ true }
                    onChange={ (e) => { 
                      e.target.value == 0 ? 
                        setSellOrderTotal(props.orderTotal): 
                        setSellOrderTotal(e.target.value);
                      props.onChange(`orderTotal`, e.target.value);
                    } }
                    onFocus={ (e) => {
                      setCursorToLastPosition(e);
                      props.onChange(undefined, {
                        orderTypeText: `Sell`,
                        orderTypeId: 2
                      }); 
                    } } />
                </div>
              </div>
            </div>
            { /* inputs-right end */ }
          </div>
        )
      }
      {
        /* submit-buttons start */
        props.mktId > 0 && props.tradingEnabled && (
          <div className={ 
            `dual-form-wrapper submit-wrapper ` + 
          (props.activeAccounts && props.activeAccounts.length > 0 
            ? `` 
            : `dimmed`) 
          }>
            <div
              className="submit dual-submit-left"
              onMouseDown={ () => {
                props.onChange(undefined, {
                  orderTypeText: `Buy`,
                  orderTypeId: 1,
                  orderQty: buyOrderQty,
                  limitPrice: buyLimitPrice,
                  stopPrice: buyStopPrice,
                  orderTotal: buyOrderTotal
                });
              } }>
              <Button
                type={ `confirm` }
                disabled={ (props.authId === -1 || parseFloat(buyOrderQty) <= 0 || parseFloat(buyOrderTotal) <= 0) }
                className={ `submit-button-left ` + (props.orderTypeId === 1 ? `active` : ``) }
                onClick={ (e) => props.onSubmit(e) }>
                {
                  props.priceTypeId !== 5 && props.active ? (
                    `${ props.t(`app:buy`) } ${ parseFloat(buyOrderTotal) > 0 ? 
                      `(` + buyOrderTotal + 
                        ` ${ props.currencyText })`: ` ${ props.currencyText }` }` ) 
                    : (
                      `${ props.t(`app:buy`) } ${ props.priceTypeId === 5 ? ` at market` : props.currencyText }`
                    )
                }
              </Button>
            </div>
            <div className={ `dual-submit-middle ` + (props.orderTypeId === 1 ? `green-bg` : `red-bg`) } />
            <div
              className="submit dual-submit-right"
              onMouseDown={ () => {
                props.onChange(undefined, {
                  orderTypeText: `Sell`,
                  orderTypeId: 2,
                  orderQty: sellOrderQty,
                  limitPrice: sellLimitPrice,
                  stopPrice: sellStopPrice,
                  orderTotal: sellOrderTotal
                });
              } }>
              <Button
                type={ `cancel` }
                disabled={ (props.authId === -1 || parseFloat(sellOrderQty) <= 0 || parseFloat(sellOrderTotal) <= 0) }
                className={ `submit-button-right ` + (props.orderTypeId === 1 ? `` : `active`) }
                onClick={ (e) => props.onSubmit(e) }>
                {
                  props.priceTypeId !== 5 && props.active ? (
                    `${ props.t(`app:sell`) } ${ parseFloat(sellOrderQty) > 0 ? 
                      `(` + sellOrderQty + 
                      ` ${ props.currencyText })` : ` ${ props.currencyText }` }`
                  ) : (
                    `${ props.t(`app:sell`) } ${ props.priceTypeId === 5 ? ` at market` : props.currencyText }`
                  )
                }
              </Button>
            </div>
          </div>
        )
        /* submit-buttons end */
      }
      {
        /* this is for styling purposes only! */
        props.mktId > 0 && props.tradingEnabled && (
          <div className={ `dual-bottom-form-section` + 
        (props.orderTypeId === 1 ? ` buy-border-color ` : ` sell-border-color `) + 
        (props.activeAccounts && props.activeAccounts.length > 0 ? `` : `dimmed`) }>
            <div className={ `order-summary-text` }>
              {
                props.active ? (
                  props.orderTypeId == 1 ?
                    `${ props.t(`app:buy`) } ${ parseFloat(props.orderTotal) > 0 ? props.orderTotal : `` } ` + 
                  `${ props.currencyText } ${ props.t(`with`) }` +
                ` ${ parseFloat(props.orderQty) > 0 ? props.orderQty : `` } ${ props.baseCurrencyText }` :
                    `${ props.t(`app:sell`) } ${ parseFloat(props.orderQty) > 0 ? props.orderQty : `` } ` + 
                  `${ props.currencyText } ${ props.t(`for`) }` +
                ` ${ parseFloat(props.orderTotal) > 0 ? props.orderTotal : `` } ${ props.baseCurrencyText }`
                ) : (
                  props.orderTypeId == 1 ?
                    `${ props.t(`app:buy`) } ${ parseFloat(props.orderTotal) > 0 ? props.orderTotal : `` } ` +
                  `${ props.currencyText } ${ props.t(`with`) }` +
                ` ${ parseFloat(props.orderQty) > 0 ? props.orderQty : `` } ${ props.baseCurrencyText }` :
                    `${ props.t(`app:sell`) } ${ parseFloat(props.orderQty) > 0 ? props.orderQty : `` } ` + 
                  `${ props.currencyText } ${ props.t(`for`) }` +
                ` ${ parseFloat(props.orderTotal) > 0 ? props.orderTotal : `` } ${ props.baseCurrencyText }`
                )
              }
              { props.showPrecision == true && (
                
                <span className={ `base-quote-precision` }>
                  <br/>
                  <span className={ `precision-highlight` }>
                    { props.active.market.baseCurrCode }
                  </span>
                  { ` ` }
                   Price Precision:
                  { ` ` }
                  <span className={ `precision-highlight` }>
                    { props.active.market.basePricePrecision }
                  </span>
                  { ` ` }
                   Qty Precision:
                  { ` ` }
                  <span className={ `precision-highlight` }>
                    { props.active.market.baseQuantityPrecision }
                  </span>
                  <br/>
                  <span className={ `precision-highlight` }>
                    { props.active.market.quoteCurrCode }
                  </span>
                  { ` ` }
                   Qty Precision:
                  { ` ` }
                  <span className={ `precision-highlight` }>
                    { props.active.market.quoteQuantityPrecision }
                  </span>
                </span>
              )
              }
            </div>
            <div className={ `auto-confirm-wrapper` }>
              <Checkbox
                name={ `autoConfirm` }
                label={ props.t(`app:autoConfirm`) }
                value={ props.autoConfirm } 
                onChange={ (e) => {
                  props.onChange(`autoConfirm`, e.target.checked);
                } }/>
            </div>
          </div>
        )
      }
      { ///// dualForm end
        props.mktId > 0 && !props.tradingEnabled && (
          <div className="trading-disabled">
            <p>
              { props.t(`tradingDisabled`) }
            </p>
          </div>
        )
      }
    </div> 
  );
};

export { OrderForm as PureOrderForm };
export default translate(`orders`)(OrderForm);
