// @flow
'use strict';

export const UNAUTHORIZED = `EVENT_UNAUTHORIZED`;
export const NOTIFICATION = `EVENT_NOTIFICATION`;
export const MARKETS_PRICE_HOVER = `MARKETS_PRICE_HOVER`;
export const MARKETS_PRICE_CLICK = `MARKETS_PRICE_CLICK`;
export const PAGE_SHORTCUT = `PAGE_SHORTCUT`;
export const ENTER = `ENTER`;
export const ESCAPE = `ESCAPE`;
export const MARKET_CHANGE = `MARKET_CHANGE`;
export const MARKET_BUY_MODE_ACTIVATED = `MARKET_BUY_MODE_ACTIVATED`;
export const MARKET_BUY_MODE_DEACTIVATED = `MARKET_BUY_MODE_DEACTIVATED`;
export const MARKET_SELL_MODE_ACTIVATED = `MARKET_SELL_MODE_ACTIVATED`;
export const MARKET_SELL_MODE_DEACTIVATED = `MARKET_SELL_MODE_DEACTIVATED`;
export const MARKET_ALERT_MODE_ACTIVATED = `MARKET_ALERT_MODE_ACTIVATED`;
export const MARKET_ALERT_MODE_DEACTIVATED = `MARKET_ALERT_MODE_DEACTIVATED`;
export const DATA_MODE_REQUESTED = `DATA_MODE_REQUESTED`;
export const ALERT_MODE_REQUESTED = `ALERT_MODE_REQUESTED`;
export const ALERT_CONFIRM_REQUESTED = `ALERT_CONFIRM_REQUESTED`;
export const ALERT_ADDED = `ALERT_ADDED`;
export const ALERT_EDITED = `ALERT_EDITED`;
export const BUY_MODE_REQUESTED = `BUY_MODE_REQUESTED`;
export const BUY_MODE_REQUESTED_WITH_PRICE = `BUY_MODE_REQUESTED_WITH_PRICE`;
export const BUY_MODE_REQUESTED_WITH_STOP_PRICE = `BUY_MODE_REQUESTED_WITH_STOP_PRICE`;
export const BUY_CONFIRM_REQUESTED = `BUY_CONFIRM_REQUESTED`;
export const SELL_MODE_REQUESTED = `SELL_MODE_REQUESTED`;
export const SELL_MODE_REQUESTED_WITH_PRICE = `SELL_MODE_REQUESTED_WITH_PRICE`;
export const SELL_MODE_REQUESTED_WITH_STOP_PRICE = `SELL_MODE_REQUESTED_WITH_STOP_PRICE`;
export const SELL_CONFIRM_REQUESTED = `SELL_CONFIRM_REQUESTED`;
export const SWITCH_TO_SELL_MODE = `SWITCH_TO_SELL_MODE`;
export const SWITCH_TO_BUY_MODE = `SWITCH_TO_BUY_MODE`;
export const DELETE_BUY_NODE = `DELETE_BUY_NODE`;
export const DELETE_SELL_NODE = `DELETE_SELL_NODE`;
export const DELETE_BUY_STOP_NODE = `DELETE_BUY_STOP_NODE`;
export const DELETE_SELL_STOP_NODE = `DELETE_SELL_STOP_NODE`;
export const SWITCH_TO_SELL_MODE_WITH_PRICE = `SWITCH_TO_SELL_MODE_WITH_PRICE`;
export const SWITCH_TO_BUY_MODE_WITH_PRICE = `SWITCH_TO_BUY_MODE_WITH_PRICE`;
export const SWITCH_TO_SELL_MODE_WITH_STOP_PRICE = `SWITCH_TO_SELL_MODE_WITH_STOP_PRICE`;
export const SWITCH_TO_BUY_MODE_WITH_STOP_PRICE = `SWITCH_TO_BUY_MODE_WITH_STOP_PRICE`;
export const PRICE_CHANGE_REQUESTED = `PRICE_CHANGE_REQUESTED`;
export const STOP_PRICE_CHANGE_REQUESTED = `STOP_PRICE_CHANGE_REQUESTED`;
export const MARKET_ALERT_MODE_ACTIVATION_ACKNOWLEDGED = `MARKET_ALERT_MODE_ACTIVATION_ACKNOWLEDGED`;
export const MARKET_SELL_MODE_ACTIVATION_ACKNOWLEDGED = `MARKET_SELL_MODE_ACTIVATION_ACKNOWLEDGED`;
export const MARKET_BUY_MODE_ACTIVATION_ACKNOWLEDGED = `MARKET_BUY_MODE_ACTIVATION_ACKNOWLEDGED`;
export const WINDOW_RESIZED = `WINDOW_RESIZED`;
export const ORDER_ADDED = `ORDER_ADDED`;
export const ORDER_ADDED_FAILED = `ORDER_ADDED_FAILED`;
export const PENDING_ORDER_ADDED = `PENDING_ORDER_ADDED`;
export const SHOW_KEYBOARD_LEGEND = `SHOW_KEYBOARD_LEGEND`;
export const REFRESH_BALANCE = `REFRESH_BALANCE`;
export const SILENT_NOTIFICATION = `SILENT_NOTIFICATION`;
export const REFRESH_ORDERS = `REFRESH_ORDERS`;
export const REFRESH_ACCOUNTS = `REFRESH_ACCOUNTS`;
export const UPDATE_ACCOUNT = `UPDATE_ACCOUNT`;
export const DELETE_ACCOUNT = `DELETE_ACCOUNT`;
export const SET_SYMBOL_COMPLETE = `SET_SYMBOL_COMPLETE`;
export const MARKET_RECEIVED_NEW_TRADE = `MARKET_RECEIVED_NEW_TRADE`;
export const SET_MARKET_FILTER = `SET_MARKET_FILTER`;
export const THEME_CHANGED = `THEME_CHANGED`;
export const POPULATE_ALERT_FORM = `POPULATE_ALERT_FORM`;
export const UPDATE_CURRENT_AUTHID = `UPDATE_CURRENT_AUTHID`;
export const ORDER_LEVEL_HOVER = `ORDER_LEVEL_HOVER`;
export const ORDER_TABLE_UNHOVER = `ORDER_TABLE_UNHOVER`;
export const PUSH_REDIS_PREFS = `PUSH_REDIS_PREFS`;
export const START_TOUR = `START_TOUR`;
export const ADVANCE_TOUR = `ADVANCE_TOUR`;
export const SHOW_LSCX_MODAL = `SHOW_LSCX_MODAL`;
export const SHOW_REPORTBUG_MODAL = `SHOW_REPORTBUG_MODAL`;
export const SHOW_KEYBOARD_MODAL = `SHOW_KEYBOARD_MODAL`;
export const SHOW_THEME_MODAL = `SHOW_THEME_MODAL`;
export const TOUR_COMPLETED = `TOUR_COMPLETED`;
export const ADD_CHART_SYMBOL = `ADD_CHART_SYMBOL`;
export const DISMISS_NOTIFICATION = `DISMISS_NOTIFICATION`;
export const SHOW_FAQS_MODAL = `SHOW_FAQS_MODAL`; 
export const SHOW_MOTD_MODAL = `SHOW_MOTD_MODAL`;

export const emitEvent = (event_handle: string, data?: any) => {
  let event = new CustomEvent(event_handle, {
    detail: data
  });

  window.dispatchEvent(event);
};

export const listenForEvent = (event_handle: string, callback?: (...p: any) => any, once: boolean = false) => {
  if (once) {
    window.addEventListener(event_handle, callback, { once });
  } else {
    window.addEventListener(event_handle, callback);
  }
};

export const removeEventListener = (event_handle: string, callback?: (...p: any) => any) => {
  window.removeEventListener(event_handle, callback);
};
