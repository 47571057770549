import React, { useState, useEffect } from 'react';
import Modal from '../../../utilities/Modal.jsx';
import TextField from '../../../utilities/TextField.jsx';
import Button from '../../../utilities/Button.jsx';
import PillToggle from "../../../utilities/PillToggle.jsx";
import { 
  // depositFiatFunds, 
  getContactTransferRequest,
  createContactTransfer,
  getWireDetails,
  getPushTransferDetails,
  postPushTransfer,
} from '../../../../helpers/api/UserApi.js';
import topBankList from "./TopBankList.jsx";

export const FiatDepositModal = ({ close, t, currCode }) => {
  const [ errors, setErrors ] = useState(``);
  const [ loading, setLoading ] = useState(false);
  //const [ amount, setAmount] = useState(``);
  //const [ label, setLabel ] = useState(``);
  const [ referenceId, setReferenceId ] = useState(``);
  const [ wireType, setWireType ] = useState(0);
  const [ wireInstructions, setWireInstructions ] = useState([]);
  const [ depositType, setDepositType ] = useState(1);
  const [ pushErrors, setPushErrors ] = useState(``);
  const [ pushLoading, setPushLoading ] = useState(false);
  const [ pushInstructions, setPushInstructions ] = useState(null);
  const [ bankInstructions, setBankInstructions ] = useState(false);

  const getPushTransferData = async () => {
    setPushLoading(true);
    setPushErrors();

    const pushTransferDetails = await getPushTransferDetails();
    
    if (pushTransferDetails && pushTransferDetails.success && pushTransferDetails.result) { 
      setPushInstructions(pushTransferDetails.result.pushInstructions);
      setPushLoading(false);
    } else {
      await postPushTransfer(); 
      const newPushTransferDetails = await getPushTransferDetails();
      
      if (newPushTransferDetails && newPushTransferDetails.success && newPushTransferDetails.result) {           
        setPushInstructions(newPushTransferDetails.result.pushInstructions);
        setPushLoading(false);
      }
    }
  };

  useEffect(() => {
    getContactTransferRequest((data) => {
      setLoading(true);
      setErrors();
      if (data && data.success) {
        if (data.result.length > 0) { 
          // previously was: setReferenceId(data.result[0].contactTransferReferenceId);
          setReferenceId(data.result[0].contactTransferReferenceId);
        } else {
          createContactTransfer((contactTransferResponse) => {
            if (contactTransferResponse.success) {
              setReferenceId(contactTransferResponse.data.id);
            }
          });
        }
      } else {
        createContactTransfer((contactTransferResponse) => {
          if (contactTransferResponse.success) {
            setReferenceId(contactTransferResponse.data.id);
          }
        });
        // // error
        //setLoading(false);
        // setErrors(data.error);
      }
    }, () => {
      setLoading(false);
      setErrors(`Unexpected error, please try again.`);
    });
    
    getPushTransferData()
      .catch(() => {
        setPushInstructions(null);
        setPushErrors(`Unexpected error, please try again.`);        
        setPushLoading(false);
      });

  }, []);

  useEffect(() => {
    if (referenceId) {
      getWireDetails(referenceId, (data) => { 
        if (data.success) {
          setLoading(false);
          setWireInstructions([data.result.data.wireInstructions, data.result.data.wireInstructionsIntl]);
        } else {
          setLoading(false);
          setErrors(data.error);
        }
      },() => {
        setLoading(false);
        setErrors(`Unexpected error, please try again.`);
      });
    }
  }, [referenceId]);

  // Only used for deposit simulations
  // const createAssetTransfer = () => {
  //   setLoading(true);
  //   setErrors();
  //   const fundsBody = {
  //     'amount': amount, 
  //     'currCode': currCode, 
  //     'contactTransferReferenceId': referenceId,
  //   };
  //   depositFiatFunds(fundsBody, (data) => {
  //     if (data.success) {
  //       close();
  //       setAccountInfo();
  //     } else {
  //       setLoading(false);
  //       setErrors(data.error);
  //     }
  //   }, () => {});
  // };

  const FiatDepositForm = () => {
    return (
      <div className={ `fiat-deposit-form` }>
        <div className='transfer-type-container'>
          <PillToggle
            options={ [
              {
                value: 1,
                label: t(`Wire Transfer`)
              },
              { 
                value: 0,
                label: t(`ACH Deposit`)
              }
            ] }
            value={ depositType }
            onChange={ (e, v) => setDepositType(v) } />
        </div>

        { depositType === 0 && 
        <div>   
          { !pushLoading && pushInstructions && (
            <div>
              <TextField 
                label={ t(`Depository Bank Name`) }
                name={ `bankName` }
                value={ pushInstructions.depositorybankname }
                readOnly/>                      
              
              <TextField 
                label={ t(`Bank Address`) }
                name={ `bankAddress` }
                value={ pushInstructions.bankaddress }
                readOnly/>
              <div className='text-field-container'>
                <TextField 
                  label={ t(`Account Number`) }
                  name={ `accountNumber` }
                  value={ pushInstructions.accountnumber }
                  readOnly/>

                <TextField 
                  label={ t(`Routing Number`) }
                  name={ `routingNumber` }
                  value={ pushInstructions.routingnumber }
                  readOnly/>
              </div>
              
              <TextField 
                label={ t(`Credit To`) }
                name={ `creditTo` }
                value={ pushInstructions.creditto }
                readOnly/>
              
              <TextField 
                label={ t(`Address`) }
                name={ `beneficiaryAddress` }
                value={ pushInstructions.beneficiaryaddress }
                readOnly/>
            </div>
          ) }
        </div>
        }

        { depositType === 1 && 
          <div>
            <div className='pill-container'>
              <div className='pill-item'>
                <div className="label transfer-type-label">
                  { t(`Transfer Type`) }
                </div>
                <PillToggle
                  options={ [
                    {
                      value: 0,
                      label: `Domestic`
                    },
                    { 
                      value: 1,
                      label: `International`
                    }
                  ] }
                  value={ wireType }
                  onChange={ (e, v) => setWireType(v) } />
              </div> 
            </div>                   
            {
              !loading && wireType === 0 && wireInstructions[0] && (
                <div>
                  <TextField 
                    label={ t(`Depository Bank Name`) }
                    name={ `bankName` }
                    value={ wireInstructions[0].depositorybankname }
                    readOnly/>
                  <TextField 
                    label={ t(`Bank Address`) }
                    name={ `bankAddress` }
                    value={ wireInstructions[0].bankaddress }
                    readOnly/>
                  { wireInstructions[0]?.bankphone &&
                    <TextField 
                      label={ t(`Bank Phone`) }
                      name={ `bankPhone` }
                      value={ wireInstructions[0].bankphone }
                      readOnly/>
                  }
                  <div className='text-field-container'>
                    <TextField 
                      label={ t(`Account Number`) }
                      name={ `bankAccountNumber` }
                      value={ wireInstructions[0].accountnumber }
                      readOnly/>
                    <TextField 
                      label={ t(`Routing Number`) }
                      name={ `routingNumber` }
                      value={ wireInstructions[0].routingnumber }
                      readOnly/>
                  </div>

                  <TextField 
                    label={ t(`Credit To`) }
                    name={ `creditTo` }
                    value={ wireInstructions[0].creditto }
                    readOnly/>
                  <TextField 
                    label={ t(`Address`) }
                    name={ `beneficiaryAddress` }
                    value={ wireInstructions[0].beneficiaryaddress }
                    readOnly/>
                  <TextField 
                    label={ t(`Reference Code*`) }
                    name={ `referenceCode` }
                    value={ wireInstructions[0].reference }
                    hintText={ `* Depending on your bank's wire form, the Reference Code can be placed in the memo, comments, or "Instructions for Beneficiary" section.` }
                    readOnly/>
                </div>
              )
            }
            {
              !loading && wireType === 1 && wireInstructions[1] && (
                <div>
                  <div className="warning-text">
                      Please make sure to send only US Dollars (USD) when initiating your wire transfer.
                  </div>
                  <TextField 
                    label={ t(`Depository Bank Name`) }
                    name={ `bankName` }
                    value={ wireInstructions[1].depositorybankname }
                    readOnly/>
                  <TextField 
                    label={ t(`Bank Address`) }
                    name={ `bankAddress` }
                    value={ wireInstructions[1].bankaddress }
                    readOnly/>
                  { wireInstructions[1]?.bankphone &&
                    <TextField 
                      label={ t(`Bank Phone`) }
                      name={ `bankPhone` }
                      value={ wireInstructions[1].bankphone }
                      readOnly/>
                  }
                  <div className='text-field-container'>
                    <TextField 
                      label={ t(`Account Number`) }
                      name={ `bankAccountNumber` }
                      value={ wireInstructions[1].accountnumber }
                      readOnly/>
                    <TextField 
                      label={ t(`Routing Number`) }
                      name={ `routingNumber` }
                      value={ wireInstructions[1].routingnumber }
                      readOnly/>
                    <TextField 
                      label={ t(`Swift Code`) }
                      name={ `swiftCode` }
                      value={ wireInstructions[1].swiftcode }
                      readOnly/>
                  </div>

                  <TextField 
                    label={ t(`Credit To`) }
                    name={ `creditTo` }
                    value={ wireInstructions[1].creditto }
                    readOnly/>
                  <TextField 
                    label={ t(`Address`) }
                    name={ `beneficiaryAddress` }
                    value={ wireInstructions[1].beneficiaryaddress }
                    readOnly/>
                  <TextField 
                    label={ t(`Reference Code*`) }
                    name={ `referenceCode` }
                    value={ wireInstructions[1].reference }
                    hintText={ `* Depending on your bank's wire form, the Reference Code can be placed in the memo, comments, or "Instructions for Beneficiary" section.` }
                    readOnly/>
                </div>
              )
            }
          </div> 
        }

        { /* USA accountnumber: "1503666126"
              bankaddress: "565 Fifth Avenue, NEW YORK, NY 10017"
              bankphone: "+1 (646) 822-1500"
              beneficiaryaddress: "330 S Rampart Ave, Suite 260, Las Vegas, NV 89145"
              creditto: "Prime Trust, LLC"
              depositorybankname: "SIGNATURE BANK"
              reference: "QNCUS6NC2VE"
              routingnumber: "026013576" */ }
        { /* INTL accountnumber: "2030136050"
              bankaddress: "1055 Wilshire Blvd. Suite 1200, LOS ANGELES, CA 90017"
              bankphone: "+1 (888) 616-8188"
              beneficiaryaddress: "330 S Rampart Ave, Suite 260, Las Vegas, NV 89145"
              creditto: "Prime Trust, LLC"
              depositorybankname: "ROYAL BUSINESS BANK"
              reference: "QNCUS6NC2VE"
              routingnumber: "122045037"
              swiftcode: "RBBCUS6L" */ }
        { /* <div className="row">
              <TextField 
                label={ t(`Amount`) + ` (`+currCode+`)` }
                name={ `amount` }
                value={ amount }
                type={ `number` }
                hintText={ `Enter the exact amount of ` + currCode + ` you will be sending.` }
                onChange={ (e) => setAmount(e.target.value) }/>
            </div>
            <div className="row">
              <TextField 
                label={ t(`Deposit Label`) }
                name={ `label` }
                value={ label }
                hintText={ `This descriptive label will show up on the transaction ledger.` }
                onChange={ (e) => setLabel(e.target.value) }/>
            </div> */ }
        {
          depositType === 0 && pushLoading &&
                <div className="row loading-message-row">
                  { t(`app:loading`) }
                </div>
        }
        {
          depositType === 1 && loading &&
                <div className="row loading-message-row">
                  { t(`app:loading`) }
                </div>
        }
        {
          depositType === 0 && pushErrors &&
                <div className="row errors-message-row">
                  { pushErrors }
                </div>
        }
        {
          depositType === 1 && errors &&
                <div className="row errors-message-row">
                  { errors }
                </div>
        }
        <div className="row modal-footer">
          { /* <Button type="primary" onClick={ () => createAssetTransfer() }>
                { t(`deposit`) }
              </Button> */ }
          <Button type="secondary" onClick={ () => setBankInstructions(true)  }>
            <img src={ `/assets/img/figma/Question.svg` } width="14"/>
            { t(`Wire & ACH Instructions for Popular Banks`) }
          </Button>
        </div>
      </div> 
    );};

  const BankInstructionContent = () => {

    return (
      <div className="bank-instruction">
        <div className="flex align-center">
          <a onClick={ (e) => {
            e.preventDefault();
            setBankInstructions(false);
          } }>
            <img className="flex" src={ `/assets/img/figma/Back.svg` } width="18" />
          </a>
          <h2>
            { t(`topBank:title`) }
          </h2>
        </div>
        
        <span className="subtitle"> 
          { t(`topBank:instructions`) }
        </span>
        
        <ul className="bank-instruction_list">
          {
            topBankList.map((item, index) => {
              return <li key={ index }>
                <div className="flex align-center">
                  <span>
                    { item.bankName }
                  </span>
                </div>
                <div className="flex align-center">
                  <span className="wire_link">
                    { item.howToSendWire && 
                    <a 
                      href={ item.howToSendWire } 
                      rel="noopener noreferrer"
                      target="_blank" 
                      style={ { marginRight: `10px` } }>
                      { t(`topBank:wireTransfer`) }
                      <img src={ `/assets/img/figma/Newtab.svg` } width="15" />
                    </a>
                    }
                  </span>

                  <span className="ach_link">
                    { item.howToSendACH &&
                      <a 
                        href={ item.howToSendACH } 
                        rel="noopener noreferrer"
                        target="_blank">
                        { t(`topBank:ach`) }
                        <img src={ `/assets/img/figma/Newtab.svg` } width="15" />
                      </a>
                    }
                  </span>
                  
                </div>
              </li>;
            })
          }

        </ul>

      </div>
    );
  };
  
  return (
    <div className={ `deposit-modal fiat-deposit-modal }` }>
      <Modal
        title={ !bankInstructions ? `New ` + currCode + ` Deposit` : `` }
        onConfirm={ true }
        onClose={ () => close(false) }
        displayOnClose={ bankInstructions }>
        { bankInstructions ? <BankInstructionContent /> : <FiatDepositForm /> }
      </Modal>
    </div>
  );
};
