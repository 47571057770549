// @flow
'use strict';

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from './languages/en.json';
import it from './languages/it.json';
import cs from './languages/cs.json';
import ru from './languages/ru.json';
import hr from './languages/hr.json';
import af from './languages/af.json';
import id from './languages/id.json';
import nl from './languages/nl.json';
import hi from './languages/hi.json';
import de from './languages/de.json';
import tr from './languages/tr.json';
import es from './languages/es.json';
import sr from './languages/sr.json';
import bg from './languages/bg.json';

i18n.use(LanguageDetector).init({
  resources: {
    en,
    it,
    cs, 
    ru,
    hr,
    af,
    id,
    in: id,
    nl,
    hi,
    de,
    tr,
    es,
    sr,
    bg
  },
  fallbackLng: `en`,
  debug: false,
  ns: [`app`],
  defaultNS: `app`,
  keySeparator: false,
  react: {
    wait: true
  }
});

export const languages = [
  `en`,
  `it`,
  `cs`, 
  `ru`,
  `hr`,
  `af`,
  `id`,
  `in`,
  `nl`,
  `hi`,
  `de`,
  `tr`,
  `es`,
  `sr`,
  `bg`
];

export default i18n;
