// @flow
'use strict';

import React, { useState } from 'react';
import CollapseDown from '../../svgs/CollapseDown.jsx';
import CollapseUp from '../../svgs/CollapseUp.jsx';

type Props = {
  isOpen?: boolean,
  title: any,
  children: any,
};

const Collapsible = (props: Props) =>  {
  const { title, children } = props;
  const [isOpen, setIsOpen] = useState(props.isOpen || false);
      
  return(
    <div className={ `collapsible ${isOpen ? `open` : `close`}` }>
      <div className={ `collapsible-title` }>
        <span>
          { title }
        </span>
        <a onClick={ () => setIsOpen(!isOpen) } data-testid="collapsible-toggle">
          { isOpen ? (
            <div data-testid="collapsible-up">
              <CollapseUp />
            </div>
          ) : <CollapseDown/> }
        </a>
      </div>
      { isOpen && <div className={ `collapsible-body` }>
        { children }
      </div>
      }
    </div>
  );
};

export default Collapsible;
