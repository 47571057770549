// @flow
`use strict`;

import { $ } from "./ApiHelper";


export function getMarketSummaries(pageSize: number = 10, pageNumber: number = 1,sort: number, sortOrder: number, range: number, searchTerm: string = ``, ignoreDead: boolean = false): Promise<any> {
  const qs = `?PageSize=${pageSize}&PageNumber=${pageNumber}${sort !== undefined ? `&Sort=`+sort:``}${sortOrder !== undefined ? `&SortOrder=`+sortOrder:``}
${range !== undefined ? `&Range=`+range:``}${searchTerm ? `&SearchTerm=`+searchTerm:``}${ignoreDead ? `&IgnoreDead=true`:``}`;

  return new Promise((resolve, reject) => {
    $({
      url: `/public/markets/market-summaries${qs}`,
      success: resolve,
      error: reject
    });
  });
}


export function getMarkets(exchange: string = ``, baseCurrCode: string = ``, quoteCurrCode: string = ``, pageSize: number): Promise<any> {
  const exchangeQs = exchange ? `exchange=`+exchange:``;
  const baseCurrCodeQs = baseCurrCode ? `baseCurrCode=`+baseCurrCode:``;
  const quoteCurrCodeQs = quoteCurrCode ? `quoteCurrCode=`+quoteCurrCode:``;
  const pageSizeQs = pageSize ? `pageSize=`+pageSize:``;

  const qs = [exchangeQs, baseCurrCodeQs, quoteCurrCodeQs, pageSizeQs].filter((s) => s).join(`&`);

  return new Promise((resolve, reject) => {
    $({
      url: `/public/markets?${qs}`,
      success: resolve,
      error: reject
    });
  });
}


export function getMarketsOverview(displayName: string): Promise<any> {
  return new Promise((resolve, reject) => {
    $({
      url: `/private/markets/${displayName}/overview`,
      success: resolve,
      error: reject
    });
  });
}
