// @flow
'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MarketSwitcher from '../components/markets/MarketSwitcher/MarketSwitcher.jsx';
import MarketDataLayer from '../components/markets/MarketDataLayer.jsx';
import { updateRedisPrefs } from '../actions/redisPrefs/updateRedisPrefs.js';
import type { Market } from '../types/Market.js';
import type { Exchange } from '../types/Exchange.js';
import type { Currency } from '../types/Currency.js';
import type { Favorite } from '../types/Favorite.js';
import type { Balance } from '../types/Balance.js';
import type { Account } from '../types/Account.js';
import type { UserApplication } from '../types/UserApplication.js';
import { 
  emitEvent, 
  SHOW_LSCX_MODAL,
} from '../helpers/EventHelper.js';
import { getUserApplication } from '../helpers/UserApplicationsHelper';
import { changeMarket } from '../actions/markets/changeMarket.js';

type Props = {
  markets: Array<Market>,
  aggregatedMarkets: [],
  exchanges: Array<Exchange>,
  currencies: Array<Currency>,
  favorites: Array<Favorite>,
  balances: Array<Balance>,
  accounts: Array<Account>,
  size: string,
  params: {
    exch?: string,
    primaryCoin?: string,
    baseCoin?: string
  },
  marketSwitcherOpen: boolean,
  marketInfoOpen: boolean,
  userInfo: any,
  updatePrefs: (obj: any) => void,
  history: any,
  location: any,
  platformId: number,
  userApplications: Array<any>,
  applicationsStatuses: Array<any>,
  changeMarket: (b: boolean) => void,
};

type State = {
  marketSwitcherClass: string,
  smallScreen: boolean,
  userApplication: UserApplication
};

export class MarketsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      marketSwitcherClass: this.props.marketSwitcherOpen ? `` : `out`,
      smallScreen: false,
      userApplication: {}
    };
  }

  componentDidMount() {
    // TO DO: Refactor showLSCXmodal as a useEffect when MarketDataLayer Hooks is merged.
    this.setPrefsBasedOnSize(this.props.size);
    
    const userApp = getUserApplication(`LSCX`, this.props.userApplications, this.props.applicationsStatuses);
    this.setState({ userApplication: userApp });
    
    this.showLSCXModal();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // TO DO: Refactor showLSCXmodal as a useEffect when MarketDataLayer Hooks is merged.
    const { location: { pathname }, userApplications, applicationsStatuses } = this.props;
    const userApp = getUserApplication(`LSCX`, userApplications, applicationsStatuses);

    if (
      prevState.userApplication.isEligible !== userApp.isEligible || 
      prevState.userApplication.isAwaiting !== userApp.isAwaiting
    ) {
      this.setState({ userApplication: userApp });
      this.showLSCXModal();
    }

    if (prevProps.location.pathname !== pathname) {
      this.showLSCXModal();
    }
  }

  showLSCXModal() {
    const { isEligible, isAwaiting } = this.state.userApplication;
    
    const { params: { exch } } = this.props;

    //console.log(`myplatformId`, platformId);
    if (exch === `LSCX` && isEligible && isAwaiting ) {
      // show this for LSCX-Eligible Coinigy users only
      if (this.props.platformId === 0) {
        emitEvent(SHOW_LSCX_MODAL);
      }
      
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.size !== nextProps.size) {
      this.setPrefsBasedOnSize(nextProps.size);
    }

    if (this.props.marketSwitcherOpen !== nextProps.marketSwitcherOpen) {
      this.toggleOpen(`marketSwitcherOpen`, nextProps, false, nextProps.marketSwitcherOpen);
    }
  }

  setPrefsBasedOnSize(size: string) {
    if (size == `md` || size == `sm`) {
      this.setState({ 
        marketSwitcherClass: `out`,
        //As it can rewrite "marketSwitcherOpen" in "refreshLongPollData" a method of the App component
        smallScreen: true
      });

      this.props.updatePrefs({
        key: `marketSwitcherOpen`,
        value: false
      });

      this.props.updatePrefs({
        key: `marketInfoOpen`,
        value: false
      });
    } else {
      this.setState({ smallScreen: false });
    }
  }

  toggleOpen(key: string, props: Props = this.props, push: boolean = true, override?: boolean) {
    let classKey = `marketSwitcherClass`,
      state = { };

    if (typeof override == `boolean`) {
      state[classKey] = override ? `in` : `out`;
    } else {
      state[classKey] = props[key] ? `out` : `in`;
    }

    this.setState(state, () => {
      if (state[classKey] == `in`) {
        if (push) {
          props.updatePrefs({
            key,
            value: !props[key]
          });
        }

        setTimeout(() => {
          this.setState({
            marketSwitcherClass: `in moving`
          }, () => {
            setTimeout(() => {
              this.setState({
                marketSwitcherClass: ``
              }, () => window.dispatchEvent(new Event(`resize`)));
            }, 1);
          });
        }, 1);
      } else {
        setTimeout(() => {
          if (push) {
            props.updatePrefs({
              key,
              value: !props[key]
            });
          }

          this.setState({
            marketSwitcherClass: `out`
          }, () => window.dispatchEvent(new Event(`resize`)));
        }, 1);
      }
    });
  }

  render() {
    const { params, markets, exchanges, currencies, favorites, accounts, balances, aggregatedMarkets, changeMarket } = this.props;
    if (markets.length == 0 || exchanges.length == 0) return (<div />);

    const { exch, primaryCoin, baseCoin } = params;
    let redirect = false;
    let exchange: Exchange;
    let market: Market;
    const lastMarket = localStorage.getItem(`lastMarket`);

    if (!exch || !primaryCoin || !baseCoin) {
      redirect = true;
    } else {
      exchange = exchanges.filter((e) => e.exchCode == exch)[0];

      market = markets.filter((m) => {
        if (!exchange) return false;

        return m.exchId == exchange.exchId && m.displayName === [primaryCoin, baseCoin].filter((p) => p).join(`/`);
      })[0];

      if (!exchange || !market) {
        redirect = true;
      }
    }

    if (redirect) {
      if (!lastMarket || lastMarket.length == 0) {
        this.props.history.replace(`/markets/LSCX/BTC/USD`);
      } else if (window.location.pathname.split(`/markets/`)[1] !== lastMarket) {
        this.props.history.replace(`/markets/${ lastMarket }`);
      }

      return (<div />);
    }

    if (exch && primaryCoin && baseCoin) {
      const currentMarket = `${ exch }/${ primaryCoin }/${ baseCoin }`;
      if (currentMarket !== lastMarket) {
        localStorage.setItem(`lastMarket`, currentMarket);
        changeMarket(true);
      }
    }

    // to satisfy flow errors. this should never actually happen.
    if (!exchange || !market) return (<div />);

    return (
      <div className={
        `market-cols ` + 
        `${ this.props.marketSwitcherOpen ? `switcher-open` : `` } ` + 
        `${ this.props.marketInfoOpen ? `info-open` : `` }`
      }>
        <MarketSwitcher
          className={ this.state.marketSwitcherClass }
          exchanges={ exchanges }
          markets={ markets }
          accounts={ accounts }
          favorites={ favorites }
          active={ {
            exchange,
            market
          } } />
        <MarketDataLayer
          marketSwitcherClass={ this.state.marketSwitcherClass }
          favorites={ favorites }
          markets={ markets }
          aggregatedMarkets={ aggregatedMarkets }
          exchanges={ exchanges }
          currencies={ currencies }
          accounts={ accounts }
          balances={ balances }
          active={ {
            exchange,
            market
          } }
          toggleOpen={ this.toggleOpen.bind(this) } 
          size={ this.props.size }/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  marketSwitcherOpen: state.redisPrefs.marketSwitcherOpen,
  marketInfoOpen: state.redisPrefs.marketInfoOpen,
  userInfo: state.userInfo.userPrefs,
  platformId: state.userInfo.user.platformId,
  userApplications: state.userInfo.userApplications,
  applicationsStatuses: state.userInfo.applicationsStatuses,  
});

const mapDispatchToProps = (dispatch) => ({
  updatePrefs: (collapse) => dispatch(updateRedisPrefs(collapse)),
  changeMarket: (b) => dispatch(changeMarket(b)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MarketsPage));
