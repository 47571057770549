const topBankList = [
  
  {
    bankName: `Bank of America Corp.`,
    howToSendWire: `https://bettermoneyhabits.bankofamerica.com/en/personal-banking/ways-to-send-money`,
    howToSendACH: `https://business.bofa.com/en-us/content/ach.html`,
  },
  {
    bankName: `Capital One Financial Corp.`,
    howToSendWire: `https://www.capitalone.com/bank/money-management/banking-basics/how-to-transfer-money/`,
    howToSendACH: `https://www.capitalone.com/bank/money-management/banking-basics/how-to-transfer-money/`,
  },
  {
    bankName: `Citigroup Inc.`,
    howToSendWire: `https://online.citi.com/US/JRS/pands/detail.do?ID=WireTransfers#:~:text=To%20send%20a%20wire%20transfer,PM%20ET%20for%20domestic%20transfers.`,
    howToSendACH: `https://www.citibank.com/tts/solutions/payments/automated-clearing-house/`,
  },
  {
    bankName: `Fifth Third Bank`,
    howToSendWire: `https://www.53.com/content/fifth-third/en/customer-service/personal-banking-faqs.html`,
    howToSendACH: `https://www.53.com/content/fifth-third/en/customer-service/personal-banking-faqs.html`,
  },
  {
    bankName: `Goldman Sachs Group Inc.`,
    howToSendWire: `https://www.marcus.com/us/en/resources/saving/money-movement`,
    howToSendACH: `https://www.marcus.com/us/en/resources/saving/money-movement`,
  },
  {
    bankName: `JPMorgan Chase & Co.`,
    howToSendWire: `https://www.chase.com/digital/customer-service/helpful-tips/online-banking/mobile/wire-transfer-send#:~:text=Choose%20the%20Pay%20from%20account,additional%20information%20and%20tap%20%22Next%22`,
    howToSendACH: `https://www.chase.com/business/online-banking/ach-payments`,
  },
  {
    bankName: `Morgan Stanley`,
    howToSendWire: `https://advisor.morganstanley.com/jennifer.a.segura/documents/field/j/je/jennifer-a-segura/Morgan%20Stanley%20Money%20Movement%20Instructions.pdf`,
    howToSendACH: `https://advisor.morganstanley.com/the-park-bay-group/documents/field/p/pa/park-bay-group/Morgan_Stanley_Online_Transfers___Online_and_Mobile_App_Setup.pdf`,
  },
  {
    bankName: `M&T Bank`,
    howToSendWire: `https://www3.mtb.com/commercial/treasury-management/wire-services`,
    howToSendACH: `https://www3.mtb.com/personal/personal-banking/online-and-mobile-services/m-and-t-online-banking/banktobank-transfer-help`,
  },
  {
    bankName: `PNC Financial Services Group Inc.`,
    howToSendWire: `https://wiretransfer.io/pnc-bank-wire-transfer/`,
    howToSendACH: `https://www.pnc.com/en/corporate-and-institutional/treasury-management/payables/automated-clearing-house--ach.html/#credit`,
  },
  {
    bankName: `Silicon Valley Bank`,
    howToSendWire: `https://www.svb.com/support/pb-wire-transfer`,
    howToSendACH: `https://www.svb.com/private-bank/help/resources/digital-banking/one-time-ach-and-wire-payments`,
  },
  {
    bankName: `TD Group US Holdings LLC`,
    howToSendWire: `https://www.td.com/ca/en/personal-banking/ways-to-bank/ways-to-send/international-money-transfer/td-global-transfer/international-bank-transfer/`,
    howToSendACH: `https://www.td.com/us/en/personal-banking/digital-payments/`,
  },
  {
    bankName: `Truist Bank`,
    howToSendWire: `https://www.truist.com/content/dam/truist/us/en/documents/internal/olb/QRG-OLB-WireServices.pdf`,
    howToSendACH: `https://www.truist.com/content/dam/truist-bank/us/en/documents/merger/ach-origination-transtion-information-for-suntrust-clients.pdf`,
  },
  {
    bankName: `U.S. Bancorp`,
    howToSendWire: `https://www.usbank.com/customer-service/knowledge-base/KB0069532.html`,
    howToSendACH: `https://www.usbank.com/online-mobile-banking/transfer-money.html`,
  },
  {
    bankName: `Wells Fargo & Co.`,
    howToSendWire: `https://www.wellsfargo.com/online-banking/wires/`,
    howToSendACH: `https://www.wellsfargo.com/biz/wells-fargo-works/financial-management/payments/the-power-of-ACH-and-your-small-business/`,
  },
];

export default topBankList;
