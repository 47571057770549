// @flow
'use strict';

import {
  UPDATE_FAVORITES,
  UPDATE_CURRENT_FAVORITE,
  SET_CURRENT_MARKET,
  UPDATE_CSRF,
  SELECT_QUOTE_CURRENCY_CODE,
  SET_MARKET_SWITCHER_COLOR_MODE,
  CHANGE_GLOBAL_PAGE_SIZE,
  UPDATE_NEW_ORDER_AUTO_CONFIRM
} from '../actions/index.js';

import type { Favorite } from '../types/Favorite.js';

type State = {
  version: number,
  favorites: Array<Favorite>,
  currentMarket: ?{ exchCode: string, displayName: string },
  selectedQuoteCurrencyCode: string,
  csrf: string,
  marketSwitcherColorFilled: boolean,
  pageSize: any,
  newOrderAutoConfirm: boolean,
};

type Action = {
  type: string,
  payload: any
};

export const initialState: State = {
  version: 1,
  favorites: [],
  currentMarket: null,
  selectedQuoteCurrencyCode: `btc`,
  csrf: ``,
  marketSwitcherColorFilled: false,
  pageSize: 10,
  newOrderAutoConfirm: false
};

export default function app(state: State = initialState, action: Action) {
  switch(action.type) {
  case UPDATE_FAVORITES: {
    const { currentMarket } = state;
    if (currentMarket) {
      const nextFavorites: Array<Favorite> = [...action.payload.favorites];
      const currentFavorite = state.favorites.find((favorite) => (
        favorite.exchCode === currentMarket.exchCode && favorite.displayName === currentMarket.displayName
      ));
      if (currentFavorite) {
        const index = nextFavorites.findIndex(({ exchCode, displayName }) => (
          exchCode === currentMarket.exchCode && displayName === currentMarket.displayName
        ));
        if (index != null) {
          nextFavorites[index] = currentFavorite;
        }
      }
      return {
        ...state,
        favorites: nextFavorites
      };
    }
    return {
      ...state,
      favorites: action.payload.favorites
    };
  }
  case UPDATE_CURRENT_FAVORITE: {
    const { updateFavorite } = action.payload;
    const favorites: Array<Favorite> = state.favorites.map((favorite) => {
      if (updateFavorite && 
          favorite.exchCode === updateFavorite.exchCode && 
          favorite.displayName == updateFavorite.displayName) {
        return { ...favorite, ...updateFavorite };
      }
      return favorite;
    });
    return {
      ...state,
      favorites
    };
  }
  case SET_CURRENT_MARKET:
    return {
      ...state,
      currentMarket: action.payload
    };
  case UPDATE_CSRF:
    return {
      ...state,
      csrf: action.payload.token
    };
  case SELECT_QUOTE_CURRENCY_CODE:
    return {
      ...state,
      selectedQuoteCurrencyCode: action.payload
    };
  case SET_MARKET_SWITCHER_COLOR_MODE:
    return {
      ...state,
      marketSwitcherColorFilled: action.payload
    };
  case CHANGE_GLOBAL_PAGE_SIZE:
    return {
      ...state,
      pageSize: action.payload
    };
  case UPDATE_NEW_ORDER_AUTO_CONFIRM:
    return {
      ...state,
      newOrderAutoConfirm: action.payload
    };
  }
  return state;
}
