// @flow
'use strict';

import React, { useState } from 'react';
import { translate } from 'react-i18next';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import Checkbox from '../utilities/Checkbox.jsx';
import Modal from '../utilities/Modal.jsx';
import SelectField from '../utilities/SelectField.jsx';
import TextField from '../utilities/TextField.jsx';
import PresetTextField from '../utilities/PresetTextField.jsx';
import Search from "../../svgs/Search.jsx";
import Tabs from '../utilities/Tabs.jsx';

type Props = {
  t: any,
  label: string,
  close: () => void,
  changeSettings: (obj: any) => void,
  reset: () => void,
  myPositions: boolean,
  miningAlgorithm: string,
  miningType: string,
  exchangeTags: string,
  exchangeTagsOptions: Array<any>,
  rsi7Min: any,
  rsi14Min: any,
  rsi7Hour: any,
  rsi14Hour: any,
  rsi7Day: any,
  rsi14Day: any,
  ignoreDead: boolean,
  percentBuys: any,
  activityScore: any,
};

const RSI_DEFAULT_CONDITION = `<=`;

const ScreenerFilterModal = (props: Props) => {
  const { t, close, changeSettings, reset, myPositions, miningAlgorithm, miningType, exchangeTags, exchangeTagsOptions,
    rsi7Min, rsi14Min, rsi7Hour, rsi14Hour, rsi7Day, rsi14Day, ignoreDead, percentBuys, activityScore } = props;
  const [openPopover, setOpenPopover] = useState(false);
  const [filters, setFilters] = useState({ myPositions, miningAlgorithm, miningType, exchangeTags, rsi7Min, rsi14Min, rsi7Hour, rsi14Hour, rsi7Day, rsi14Day, ignoreDead, percentBuys, activityScore });
  const [ activeTab, setActiveTab ] = useState(0);

  const rsiOptions = [
    { label: `${t(`screener:less`)}`, value: `<=` },
    { label: `${t(`screener:greater`)}`, value: `>=` },
  ];

  const handleFilter = (filter) => {
    setFilters((prev) => ({ ...prev, ...filter }));
  };

  const confirmFilters = () => {
    changeSettings(filters);
    reset();
    close();
  };

  const getRsiConditionName = (condition: string) => rsiOptions.find((r) => r.value === condition)?.label;

  const handleRsiChange = (indicator: string, value: any) => {
    if ([`<=`, `>=`].includes(value)) {
      handleFilter({ 
        [indicator]: {
          value: filters[indicator]?.value,
          condition: value,
          conditionName: getRsiConditionName(value)
        } 
      });
    } else {
      handleFilter({ 
        [indicator]: {
          value: value,
          condition: filters[indicator]?.condition || RSI_DEFAULT_CONDITION,
          conditionName: getRsiConditionName(filters[indicator]?.condition || RSI_DEFAULT_CONDITION),
        }
      });
    }
  };

  const tabs = [
    { key: `filters`, title: t(`app:Filters`) },
    { key: `indicators`, title: t(`screener:indicators`) }
  ];

  return (
    <Modal
      title={ t(`screener:filterMarkets`) }
      onConfirm={ confirmFilters }
      onClose={ close }
      confirmText={ t(`app:confirm`) }>
      <Tabs
        tabPosition="center"
        tabNames={ tabs.map((item) => item.title) }
        activeTab={ activeTab }
        onChangeTab={ (tab) => setActiveTab(tab) }>
        {
          tabs.map(({ key: item }) => {     
            return (
              <div key={ item }/>
            );
          }
          )
        }
      </Tabs>
      {
        activeTab === 0 && ( 
          <div className='filter-container'>
            <div className="my-position-filter">
              <Checkbox 
                label={ t(`screener:myPositions`) } 
                name="my-positions" 
                data-testid='screener-my-positions'
                value={ filters.myPositions }
                onChange={ () => {
                  handleFilter({ 'myPositions': !filters.myPositions });
                } }/>
          
              <Popover
                isOpen={ openPopover }
                positions={ [`bottom`] }
                content={ ({ position, childRect, popoverRect }) => (
                  <ArrowContainer 
                    position={ position }
                    childRect={ childRect }
                    popoverRect={ popoverRect }
                    arrowColor={ `var(--gray-1)` }>
                    <div className={ `popover-content` }>
                      { t(`screener:myPositionsHelp`) }
                    </div>
                  </ArrowContainer>
                ) }>
                <a
                  onClick={ (e) => e.preventDefault() }
                  onMouseEnter={ () => setOpenPopover(true) }
                  onMouseLeave={ () => setOpenPopover(false) }>
                  <img src={ `/assets/img/figma/Info.svg` } width="9" height="9" className={ `derived-exchrate-icon` } />
                </a>
              </Popover>
            </div>
            <div className="show-inactive-markets-filter">
              <Checkbox 
                label={ t(`screener:showInactiveMarkets`) } 
                name="inactive-markets" 
                data-testid='screener-inactive-markets'
                value={ !filters.ignoreDead }
                onChange={ () => {
                  handleFilter({ 'ignoreDead': !filters.ignoreDead });
                } }/>
            </div>
            <div className="search">
              <TextField
                icon={ Search }
                label={ props.t(`screener:miningAlgorithm`) }
                name={ `miningAlgorithm` }
                value={ filters.miningAlgorithm || `` }
                onChange={ (e) => {
                  handleFilter({ 'miningAlgorithm': e.target.value });
                } }
                onDoubleClick={ () => { 
                  handleFilter({ 'miningAlgorithm': `` }); 
                } } />
            </div>
            <div className="search">
              <TextField
                icon={ Search }
                label={ props.t(`screener:miningType`) }
                name={ `miningType` }
                value={ filters.miningType || `` }
                onChange={ (e) => {
                  handleFilter({ 'miningType': e.target.value }); 
                } }
                onDoubleClick={ () => {
                  handleFilter({ 'miningType': `` });
                } } />
            </div>
            <div className="exchange-tags-filter">
              <SelectField
                searchable={ false }
                label={ props.t(`screener:exchangeTags`) }
                name={ `exchangeTags` }
                value={ filters.exchangeTags || `` }
                options={ exchangeTagsOptions }
                onChange={ (e, v) => {
                  handleFilter({ exchangeTags: v });
                } }/>
            </div>
            <div className='percent-buys-filter'>
              <label>
                { `% ${props.t(`screener:buys`)}` }
              </label> 
              <div className={ `inputs-container` }>
                <PresetTextField
                  label={ `` }
                  name={ `percentBuys` }
                  value={ filters.percentBuys?.value || `` }
                  options={ rsiOptions }
                  errorText={ `` }
                  optionsLabel={ getRsiConditionName(filters.percentBuys?.condition || RSI_DEFAULT_CONDITION ) }
                  compact={ true }
                  selectOnLeft={ true }
                  onChange={ (e) => handleRsiChange(`percentBuys`, e) }/>
              </div>
            </div>
            <div className='activity-score-filter'>
              <label>
                { props.t(`screener:activityScore`) }
              </label> 
              <div className={ `inputs-container` }>
                <PresetTextField
                  label={ `` }
                  name={ `activityScore` }
                  value={ filters.activityScore?.value || `` }
                  options={ rsiOptions }
                  errorText={ `` }
                  optionsLabel={ getRsiConditionName(filters.activityScore?.condition || RSI_DEFAULT_CONDITION ) }
                  compact={ true }
                  selectOnLeft={ true }
                  onChange={ (e) => handleRsiChange(`activityScore`, e) }/>
              </div>
            </div>                        
          </div>
        )
      }
      {
        activeTab === 1 && ( 
          <div className='filter-container'>
            <div className='rsi-filter'>
              <label>
                { `Relative Strength Index (7, Min)` }
              </label> 
              <div className={ `inputs-container` }>
                <PresetTextField
                  label={ `` }
                  name={ `RSI7Min` }
                  value={ filters.rsi7Min?.value || `` }
                  options={ rsiOptions }
                  errorText={ `` }
                  optionsLabel={ getRsiConditionName(filters.rsi7Min?.condition || RSI_DEFAULT_CONDITION ) }
                  compact={ true }
                  selectOnLeft={ true }
                  onChange={ (e) => handleRsiChange(`rsi7Min`, e) }/>
              </div>
            </div>

            <div className='rsi-filter'>
              <label>
                { `Relative Strength Index (14, Min)` }
              </label> 
              <div className={ `inputs-container` }>
                <PresetTextField
                  label={ `` }
                  name={ `RSI14Min` }
                  value={ filters.rsi14Min?.value || `` }
                  options={ rsiOptions }
                  errorText={ `` }
                  optionsLabel={ getRsiConditionName(filters.rsi14Min?.condition || RSI_DEFAULT_CONDITION ) }
                  compact={ true }
                  selectOnLeft={ true }
                  onChange={ (e) => handleRsiChange(`rsi14Min`, e) }/>
              </div>
            </div>

            <div className='rsi-filter'>
              <label>
                { `Relative Strength Index (7, Hour)` }
              </label> 
              <div className={ `inputs-container` }>
                <PresetTextField
                  label={ `` }
                  name={ `RSI7Hour` }
                  value={ filters.rsi7Hour?.value || `` }
                  options={ rsiOptions }
                  errorText={ `` }
                  optionsLabel={ getRsiConditionName(filters.rsi7Hour?.condition || RSI_DEFAULT_CONDITION ) }
                  compact={ true }
                  selectOnLeft={ true }
                  onChange={ (e) => handleRsiChange(`rsi7Hour`, e) }/>
              </div>
            </div>

            <div className='rsi-filter'>
              <label>
                { `Relative Strength Index (14, Hour)` }
              </label> 
              <div className={ `inputs-container` }>
                <PresetTextField
                  label={ `` }
                  name={ `RSI14Hour` }
                  value={ filters.rsi14Hour?.value || `` }
                  options={ rsiOptions }
                  errorText={ `` }
                  optionsLabel={ getRsiConditionName(filters.rsi14Hour?.condition || RSI_DEFAULT_CONDITION ) }
                  compact={ true }
                  selectOnLeft={ true }
                  onChange={ (e) => handleRsiChange(`rsi14Hour`, e) }/>
              </div>
            </div>

            <div className='rsi-filter'>
              <label>
                { `Relative Strength Index (7, Day)` }
              </label> 
              <div className={ `inputs-container` }>
                <PresetTextField
                  label={ `` }
                  name={ `RSI7Day` }
                  value={ filters.rsi7Day?.value || `` }
                  options={ rsiOptions }
                  errorText={ `` }
                  optionsLabel={ getRsiConditionName(filters.rsi7Day?.condition || RSI_DEFAULT_CONDITION ) }
                  compact={ true }
                  selectOnLeft={ true }
                  onChange={ (e) => handleRsiChange(`rsi7Day`, e) }/>
              </div>
            </div>

            <div className='rsi-filter'>
              <label>
                { `Relative Strength Index (14, Day)` }
              </label> 
              <div className={ `inputs-container` }>
                <PresetTextField
                  label={ `` }
                  name={ `RSI14Day` }
                  value={ filters.rsi14Day?.value || `` }
                  options={ rsiOptions }
                  errorText={ `` }
                  optionsLabel={ getRsiConditionName(filters.rsi14Day?.condition || RSI_DEFAULT_CONDITION ) }
                  compact={ true }
                  selectOnLeft={ true }
                  onChange={ (e) => handleRsiChange(`rsi14Day`, e) }/>
              </div>
            </div>
          </div>  )
      }    
    </Modal>
  );
};


export { ScreenerFilterModal as PureScreenerFilterModal };
export default translate(`screener`)(ScreenerFilterModal);
