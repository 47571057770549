// @flow
'use strict';

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import Checkbox from '../utilities/Checkbox.jsx';
import SelectField from "../utilities/SelectField.jsx";
import Button from '../utilities/Button.jsx';
import type { ProfileProps } from "./ProfileDataLayer.jsx";
import { postSendSmsTestMessage, postVerifySmsNumber, deleteUnlinkSmsNumber, setSettings } from '../../helpers/api/UserApi.js';
import { 
  emitEvent, 
  NOTIFICATION
} from '../../helpers/EventHelper.js';

const Preferences = (props: ProfileProps) => {
  const { 
    t,
    autoSaveEnabled,
    onChange,
    save,
    purchasePackSms,
    currentPack,
    fiatCurrencies,
    //nativeCurrency,
    nativeCurrencyId,
    prefAccountingValuation,
    user
  } = props;

  const [phoneNumber, setPhoneNumber] = useState(``);
  const [smsCode, setSmsCode] = useState(``);
  const [selectPack, setSelectPack] = useState(`0.00 ≈ 0`);
  const [timeEnd, setTimeEnd] = useState(60);
  const [selectedNativeCurrency, setselectedNativeCurrency] = useState(``);
  const [confirmPhoneNumber, setConfirmPhoneNumber] = useState(false);
  const [confirmSmsСode, setConfirmSmsСode] = useState(false);
  const [incorrectSmsCode, setIncorrectSmsCode] = useState(false);
  const betaRelease = process.env.NODE_ENV === `production` && !process.env.STAGING;
  let toggleInterval;

  const isLscxUser = user?.platformId === 1;

  useEffect(() => { 
    if (confirmPhoneNumber) {
      toggleInterval = setInterval(() => {
        setTimeEnd((prevTime) => prevTime - 1);
      }, 1000);
    } 
    if (timeEnd === 0) {
      setConfirmPhoneNumber(false);
      setIncorrectSmsCode(false);
      setTimeEnd(60);
    }
    return () => {
      clearInterval(toggleInterval);
    };
  }, [confirmPhoneNumber, timeEnd]);

  const handleInputSmsCode = () => {
    postVerifySmsNumber(smsCode,(data) => {
      if (!data.success) {
        emitEvent(NOTIFICATION, {
          notification_id: new Date().getTime(),
          title: `Error`,
          title_vars: ``,
          message_raw: `There was an error trying to validate this code: ${data.error}`,
          message: `There was an error trying to validate this code: ${data.error}`,
          message_vars: ``,
          pinned: false,
          style: `error`,
          url: ``
        });
        setConfirmSmsСode(false);
        setIncorrectSmsCode(true);
      }
      if (data.success) {
        setConfirmSmsСode(true);
        setIncorrectSmsCode(false);
        if (toggleInterval) clearInterval(toggleInterval);
      }
    });

    return false;
  };

  const handleUnlink = () => {
    deleteUnlinkSmsNumber((data) => {
      if (!data.success) {
        emitEvent(NOTIFICATION, {
          notification_id: new Date().getTime(),
          title: `Error`,
          title_vars: ``,
          message_raw: `There was an error trying to unlink this phone number: ${data.error}`,
          message: `There was an error trying to unlink this phone number: ${data.error}`,
          message_vars: ``,
          pinned: false,
          style: `error`,
          url: ``
        });
      }
      if (data.success) {
        setConfirmPhoneNumber(false);
        setSmsCode(``);
        setTimeEnd(60);
        setConfirmSmsСode(false);
        setIncorrectSmsCode(false);
        if (toggleInterval) clearInterval(toggleInterval);
      }
    });
  };

  const handleConfirmPhoneNumber = () => {
    postSendSmsTestMessage( `+${phoneNumber}`, (data) => {
      if (!data.success) {
        emitEvent(NOTIFICATION, {
          notification_id: new Date().getTime(),
          title: `Error`,
          title_vars: ``,
          message_raw: `There was an error trying to confirm this phone number: ${data.error}`,
          message: `There was an error trying to confirm this phone number: ${data.error}`,
          message_vars: ``,
          pinned: false,
          style: `error`,
          url: ``
        });
      }
      if (data.success) {
        setConfirmPhoneNumber(true);
      }
    });
  };

  const handleChangeNativeCurrency = (currId: any) => {
    const options = {}; 
    const nativeCurrToSave = fiatCurrencies?.find((e) => e.currId === currId);
    if (nativeCurrToSave) {
      options.body = {
        "pref_native_currency_id": nativeCurrToSave.currId,
        "pref_native_currency": nativeCurrToSave.currCode,
      };
      setSettings(options, (data) => {
        if (data.success) {
          emitEvent(NOTIFICATION, {
            notification_id: new Date().getTime(),
            title: `Native currency changed`,
            title_vars: ``,
            message_raw: `Your native currency was changed to ${nativeCurrToSave.currCode}`,
            message: `Your native currency was changed to ${nativeCurrToSave.currCode}`,
            message_vars: ``,
            pinned: false,
            style: `success`,
            url: ``
          });
        } else {
          emitEvent(NOTIFICATION, {
            notification_id: new Date().getTime(),
            title: `Error`,
            title_vars: ``,
            message_raw: `There was an error trying to change your native currency`,
            message: `There was an error trying to change your native currency`,
            message_vars: ``,
            pinned: false,
            style: `error`,
            url: ``
          });
        }
      });
    }
  };

  return (
    <div className="preferences">
      <h1 className="page-title">
        { isLscxUser ? `Notification Preferences` : t(`header:accountPrefs`) }
      </h1>
      <section>
        { !betaRelease &&
            <div className="sms-notifications">
              <h1>
                { t(`smsNotifications`) }
              </h1>
              <div>
                <h1>
                  { t(`addPhoneNumber`) }
                </h1>
                <p>
                  { t(`addPhoneNumberText`) }
                </p>
                <div className="group-phone">
                  <label htmlFor="phoneNumber">
                    <h4>
                      { t(`phoneNumber`) }
                    </h4>
                  </label>
                  <PhoneInput
                    autoFormat={ true }
                    enableLongNumbers={ true }
                    country={ `us` }
                    dropdownClass={ `dropdown-phone` }
                    value={ phoneNumber }
                    onChange={ (phoneNumber) => setPhoneNumber(phoneNumber) }/>
                  { !confirmPhoneNumber &&
                    <Button
                      disabled={ phoneNumber.length < 8 }
                      type="primary"
                      onClick={ () => { handleConfirmPhoneNumber(); return true;} }>
                      { t(`verify`) }
                    </Button>
                  }
                  { confirmPhoneNumber &&
                    <Button
                      type="cancel"
                      onClick={ () => {handleUnlink(); return false; } }>
                      { t(`unlink`) }
                    </Button>
                  }
                </div>
                { confirmPhoneNumber && !confirmSmsСode &&
                  <div className="group-sms">
                    <div>
                      <label htmlFor="smsCode">
                        <h4>
                          { t(`smsCode`) }
                        </h4>
                        <input
                          data-testid={ `sms-code-testid` }
                          type="text"
                          placeholder={ t(`enterYourCode`) }
                          value={ smsCode }
                          onChange={ (e) => {
                            setSmsCode(e.target.value);
                            setIncorrectSmsCode(false);
                          } }/>
                        <span className="sms-timer">
                          { `${ timeEnd } ${ t(`secondsAbbreviated`) }.` }
                        </span>

                        { incorrectSmsCode &&
                        <span className="incorrect-code">
                          { t(`wrongCode`) }
                        </span>
                        }
                      </label>
                      <Button
                        type="primary"
                        onClick={ () => handleInputSmsCode() }>
                        { t(`submit`) }
                      </Button>
                    </div>
                  </div>
                }

                { confirmSmsСode &&
                  <div className="group-purchase">
                    <p className="purchase-amount">
                      { 
                        currentPack ? 
                          `${ t(`youHave`) } $${ currentPack || `` } ${ t(`sms`) }` : 
                          t(`app:loading`) 
                      }
                    </p>
                    <div>
                      <h1>
                        { t(`purchaseSms`) }
                      </h1>
                      <div>
                        <SelectField
                          label={ t(`selectPack`) }
                          name={ `selectPack` }
                          value={ selectPack }
                          options={ [
                            { value: `5 ≈ 250`, label: `$5 ≈ 250 ${ t(`sms`) }` },
                            { value: `10 ≈ 500`, label: `$10 ≈ 500 ${ t(`sms`) }` },
                            { value: `25 ≈ 1250`, label: `$25 ≈ 1250 ${ t(`sms`) }` },
                            { value: `50 ≈ 2500`, label: `$50 ≈ 2500 ${ t(`sms`) }` },
                            { value: `100 ≈ 5000`, label: `$100 ≈ 5000 ${ t(`sms`) }` },
                            { value: `250 ≈ 12500`, label: `$250 ≈ 12500 ${ t(`sms`) }` },
                          ] }
                          onChange={ (e, v) => setSelectPack(v) }/>
                        <Button
                          type="primary"
                          onClick={ () => {
                            if (purchasePackSms) {
                              purchasePackSms(selectPack);
                            }
                            return true;
                          } }>
                          { t(`purchase`) }
                        </Button>
                      </div>
                    </div>
                  </div>
                }
              </div>
              { confirmSmsСode &&
                <div className="cc">
                  <p>
                    { t(`paymentsAccepted`) }
                  </p>
                  <img src="/assets/img/cc.png" />
                </div>
              }
            </div>
        }
        <div className="native-currency">
          <h1>
            { t(`nativeCurrency`) }
          </h1>
          <div>
            <SelectField
              label={ t(`Change native currency`) }
              name={ `currency` }
              value={ selectedNativeCurrency === `` ? nativeCurrencyId : selectedNativeCurrency }
              options={ fiatCurrencies ? fiatCurrencies.map((c) => ({ value: c.currId, label: c.currCode + ` (` + c.currName + `)` })) : [] }
              onChange={ (e, v) => {
                setselectedNativeCurrency(v);
              } }/>
            <Button
              type="primary"
              onClick={ () => {
                handleChangeNativeCurrency(selectedNativeCurrency);
                return false;
              } }>
              { t(`Change Currency`) }
            </Button>
          </div>
        </div>
        <div className="platform">
          <h1>
            { t(`platformPreferences`) }
          </h1>
          <div>
            <div>
              <h4>
                { t(`tvAutoSave`) }
              </h4>
              <Checkbox
                label={ t(`autoSave`) }
                name={ `autoSaveEnabled` }
                value={ !!autoSaveEnabled }
                onChange={ (e) => {
                  if (onChange) onChange(`autoSaveEnabled`, e.target.checked);
                } } />
            </div>
            <Button
              type="primary"
              onClick={ () => {
                if (save) return save();
                return false;
              } }>
              { t(`saveChanges`) }
            </Button>
          </div>
        </div>
        <div className="reporting-preferences">
          <h1>
            { t(`reportingPreferences`) }
          </h1>
          <div>
            <SelectField
              label={ t(`accountingMethod`) }
              name={ `prefAccountingValuation` }
              value={ prefAccountingValuation || `` }
              options={ [
                { value: `FIFO`, label: `FIFO (First-In, First-Out)` },
                { value: `LIFO`, label: `LIFO (Last-In, First-Out)` }
              ] }
              onChange={ (e, v) => {
                if (onChange) onChange(`prefAccountingValuation`, v);
              } }/>
            <Button
              type="primary"
              onClick={ () => {
                if (save) return save();
                return false;
              } }>
              { t(`saveChanges`) }
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withRouter(Preferences);

